import { AbstractControl, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';
import { Resource } from '@oper-client/shared/data-model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ValidatorApiService } from '../services/validator-api.service';

export function nationalNumberAsyncValidator(countryResources: Resource[], validatorApiService): AsyncValidatorFn {
	return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
		const nactionalityId: number = control?.parent?.controls['nationality.id']?.value;
		const countryDefinition: string = countryResources?.find((country) => country.id === nactionalityId)?.definition;
		return getNationalNumberValidationFunctionByCountry(countryDefinition, control.value, validatorApiService);
	};
}

function getNationalNumberValidationFunctionByCountry(
	country: string,
	nationalNumber: string,
	validatorApiService: ValidatorApiService
): Observable<null | { [key: string]: boolean }> {
	switch (country) {
		case 'BE':
			return belgiumNationalNumberValidation(nationalNumber, validatorApiService);
		case 'NL':
			// return netherlandsNationalNumberValidation(nationalNumber); Currently this validation is not used, but it will be in future
			return of(null);
		default:
			return of(null);
	}
}

function belgiumNationalNumberValidation(
	nationalNumber: string,
	validatorApiService: ValidatorApiService
): Observable<null | { [key: string]: boolean }> {
	let errorMessage = null;
	return validatorApiService.validateBelgiumNationalNumberValidation(nationalNumber).pipe(
		catchError((error) => {
			if (error.status >= 400 && error.status < 500) {
				errorMessage = { nationalNumberInvalid: true };
				return of(null);
			}
			return throwError(error);
		}),
		map(() => {
			return errorMessage;
		})
	);
}

/* Currently this validation is not used, but it will be in future

function netherlandsNationalNumberValidation(nationalNumber: string): Observable<null | { [key: string]: boolean }> {
	// eslint-disable-next-line no-useless-escape
	const regex = new RegExp('^[0-9]{9}$');
	const error: { [key: string]: boolean } = { nationalNumberInvalid: true };
	// eslint-disable-next-line no-useless-escape
	const nationalNumberToTest: string = nationalNumber?.replace(/\./g, '')?.replace(/\-/g, '') ?? '';
	if (!regex.test(nationalNumberToTest)) {
		return of(error);
	}
	let sum = 0;
	for (let i = 9; i >= 2; i--) {
		sum += i * +nationalNumberToTest[9 - i];
	}

	const reminderBy11: number = sum % 11;
	if (reminderBy11 === 10 || +nationalNumberToTest[8] !== reminderBy11) {
		return of(error);
	}
	return of(null);
} */

export function nationalNumberFormValidators(): { validators: ValidatorFn[]; asyncValidators?: AsyncValidatorFn[] } {
	return { validators: [nationalNumberFormValidator()]};
}


/**
 * Validates that national number control:
 *   - has 11 digits
 *   - starts with 1-8
 *   - followed by date of birth of format YYMMDD (same as birthDate control value if set)
 *   - followed by any 4 digits
 * */
export function nationalNumberFormValidator(): ValidatorFn {
	return (control: FormGroup): { [key: string]: any } | null => {
		const nationalNumberControl = control.get('nationalNumber');
		const nationalNumber: string = nationalNumberControl?.value;
		
		if(!nationalNumber) {
			return null;
		}
		
		const dateOfBirth: string = control.get('birthDate')?.value;
		const regex = /^[1-8]\d{10}$/;
		

		if (!regex.test(nationalNumber)) {
			nationalNumberControl.setErrors({ nationalNumberInvalid: true });
			return null;
		}
		

		const dobFromNationalNumber = nationalNumber.substring(1, 7);
		
		
		if (dateOfBirth && dobFromNationalNumber !== formatDateOfBirth(dateOfBirth)) {
			nationalNumberControl.setErrors({ nationalNumberInvalid: true });
			return null;
		}

		if (!dateOfBirth && !isValidDate(dobFromNationalNumber)) {
			nationalNumberControl.setErrors({ nationalNumberInvalid: true });
			return null;
		}
		
		nationalNumberControl.setErrors(null);
		
		return null;
	};
}

/** Checks if date of format YYMMDD is valid. */
function isValidDate(dateString: string): boolean {
	if (!/^\d{6}$/.test(dateString)) {
		return false;
	}
	
	const year = parseInt(dateString.substring(0, 2), 10);
	const month = parseInt(dateString.substring(2, 4), 10);
	const day = parseInt(dateString.substring(4, 6), 10);
	
	if (month < 1 || month > 12) {
		return false;
	}
	
	const date = new Date(year, month - 1, day);
	return date.getFullYear() % 100 === year && date.getMonth() + 1 === month && date.getDate() === day;
}

function formatDateOfBirth(dateOfBirth: string): string {
	const date = new Date(dateOfBirth);
	const yy = String(date.getFullYear()).slice(-2);
	const mm = String(date.getMonth() + 1).padStart(2, '0');
	const dd = String(date.getDate()).padStart(2, '0');
	return yy + mm + dd;
}