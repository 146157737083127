import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as FeatureFlagReducer from './+state/feature-flag.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FeatureFlagEffects } from './+state/feature-flag.effects';
import { FeatureFlagFacade } from './+state/feature-flag.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureFlagReducer.featureFlagFeatureKey, FeatureFlagReducer.reducer),
		EffectsModule.forFeature([FeatureFlagEffects]),
	],
	providers: [FeatureFlagFacade],
})
export class FeatureFlagModule {}
