import { BorrowerModes } from '@oper-client/shared/data-model';

import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function(formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'borrowerMode',
			label: 'ç.feature.selfService.steps.borrowerMode.single',
			value: BorrowerModes.SINGLE,
			type: 'button',
		}),
		new InputField({
			key: 'borrowerMode',
			label: 'ç.feature.selfService.steps.borrowerMode.multi',
			value: BorrowerModes.MULTI,
			type: 'button',
		}),
	];
}
