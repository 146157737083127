import { Income, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../../services/validator-constants';

export default function (income: Partial<Income>, resources: PartialNormalizedResource): InputBase<any>[] {
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();
	return [
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			value: income?.amount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span12',
		}),
		new InputField({
			key: 'startDate',
			label: 'ç.question.startDate.label',
			value: income?.startDate,
			required: true,
			validators: [
				ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE),
			],
			max: currentDate,
			type: 'date',
			class: 'span12',
		}),
	];
}
