import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState, CommissionStatementFilterParams } from '@oper-client/shared/data-model';
import * as CommissionActions from './commission.actions';
import { CommissionPartialState, CommissionActionTypes } from './commission.reducer';
import * as CommissionSelectors from './commission.selectors';

@Injectable()
export class CommissionFacade {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	commissions$ = this.store.pipe(select(CommissionSelectors.getCommissions));
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	commissionStatement$ = this.store.pipe(select(CommissionSelectors.getCommissionStatement));

	loadCommissionsActionState$ = this.selectActionState(CommissionActionTypes.LOAD_COMMISSIONS);

	constructor(private store: Store<CommissionPartialState>) {}

	clearCommissions(): void {
		this.store.dispatch(CommissionActions.clearCommissions());
	}

	loadCommissions(commissionFilterParams: CommissionStatementFilterParams): void {
		this.store.dispatch(CommissionActions.loadCommissions({ commissionFilterParams }));
	}

	loadCommissionStatement(commissionFilterParams: CommissionStatementFilterParams): void {
		this.store.dispatch(CommissionActions.loadCommissionStatement({ commissionFilterParams }));
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: CommissionActionTypes): Observable<ParsedActionState> {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return this.store.pipe(select(CommissionSelectors.getActionState(actionType)), map(parseActionState));
	}
}
