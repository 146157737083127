import { createAction, props } from '@ngrx/store';

export const loadFeatureFlags = createAction('[FeatureFlag] Load FeatureFlags');

export const loadFeatureFlagsSuccess = createAction(
	'[FeatureFlag] Load FeatureFlags Success',
	props<{ featureFlags: Record<string, boolean> }>()
);

export const loadFeatureFlagsFailure = createAction('[FeatureFlag] Load FeatureFlags Failure', props<{ error: any }>());
