import { createAction, props } from '@ngrx/store';
import { InsuranceProvider } from '@oper-client/shared/data-model';
import { HttpErrorResponse } from '@angular/common/http';

export const loadInsuranceProviders = createAction('[InsuranceProvider] Load InsuranceProviders');

export const loadInsuranceProvidersSuccess = createAction(
	'[InsuranceProvider] Load InsuranceProviders Success',
	props<{ insuranceProviders: InsuranceProvider[] }>()
);

export const loadInsuranceProvidersFailure = createAction(
	'[InsuranceProvider] Load InsuranceProviders Failure',
	props<{ error: HttpErrorResponse }>()
);
