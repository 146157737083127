import { Injectable, ErrorHandler, Inject } from '@angular/core';
import { ApplicationInsights, Environment, ENVIRONMENT, APP_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';
import { HttpErrorResponse } from '@angular/common/http';
import { init } from '@sentry/angular-ivy';
import { captureException, captureMessage, setTag, GlobalHandlers } from '@sentry/angular-ivy';
import { CaptureContext } from '@sentry/types';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
	constructor(
		@Inject(ENVIRONMENT) private env: Environment,
		@Inject(APP_INSIGHTS_CONFIG) private applicationInsights: ApplicationInsights
	) {
		if (this.env.production) {
			console.warn('Initializing Sentry error proxy forwarding!');
			init({
				environment: this.applicationInsights.sentryEnvironmentName ?? this.applicationInsights.name,
				dsn: this.applicationInsights.sentryDNS,
				integrations: [new GlobalHandlers({ onerror: false, onunhandledrejection: true })],
				release: this.applicationInsights.version,
				tracesSampleRate: 0.2,
				ignoreErrors: [
					/Loading chunk [a-zA-Z0-9_.]+ failed/,
					/Maximum call stack size exceeded/,
					/Non-Error exception captured/,
					'@webkit-masked-url',
					'otBanner',
				],
			});
		}
	}

	captureMessage(message: string, captureContext?: CaptureContext): string {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return captureMessage(message, captureContext);
	}

	handleError(error) {
		console.error(error);

		if (this.env.production && this.applicationInsights.sentryDNS) {
			const parsedError = error.originalError || error;

			// HTTP errors don't have a stack and not presented well in Sentry.
			// This makes sure the information gets into Sentry
			if (parsedError instanceof HttpErrorResponse) {
				return;
			}

			// Set reviewEnvironment tag if aplication runs on a review environment
			if (this.env.reviewEnvironment) {
				setTag('reviewEnvironment', true);
			}

			// Capture the exception finally
			captureException(parsedError);
			// Uncomment to show error report popup every time an error fires
			// const eventId = Sentry.captureException(error.originalError || error);
			// Sentry.showReportDialog({ eventId });
		}
	}
}
