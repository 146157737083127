import { createFeatureSelector, createSelector } from '@ngrx/store';

import { REALTIES_FEATURE_KEY, State, propertiesAdapter, RealtyActionTypes } from './realty.reducer';
import { Realty } from '@oper-client/shared/data-model';

export const getRealtiesState = createFeatureSelector<State>(REALTIES_FEATURE_KEY);

const selectors = propertiesAdapter.getSelectors();

export const getRealties = createSelector(getRealtiesState, selectors.selectAll);
export const getRealtyEntities = createSelector(getRealtiesState, selectors.selectEntities);
export const getCurrentRealtyId = createSelector(getRealtiesState, (state: State) => state.currentRealtyId);

export const getCurrentRealty = createSelector(getRealtyEntities, getCurrentRealtyId, (entities, currentId) => {
	if (currentId === null) {
		return null;
	} else if (currentId === 0) {
		return <Realty>{};
	}
	return entities[currentId];
});

export const getCreateNewPropertyModalShown = createSelector(getRealtiesState, (state) => state.createNewPropertyModalShown);
export const getActionStates = createSelector(getRealtiesState, (state) => state.actions);
export const getActionState = (actionType: RealtyActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
