import { ChangeDetectorRef, Inject, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { IProductDurationConfigService, PRODUCT_DURATION_CONFIG_SERVICE } from '@oper-client/shared/data-access';
import { convertMonthsToYears } from '../datetime/util.datetime';

@Pipe({
	name: 'productDuration',
	pure: false,
})
export class ProductDurationPipe extends TranslatePipe implements PipeTransform {
	private formatAsYear: boolean;

	constructor(
		@Inject(PRODUCT_DURATION_CONFIG_SERVICE) private readonly productDurationConfigService: IProductDurationConfigService,
		translateService: TranslateService,
		_ref: ChangeDetectorRef
	) {
		super(translateService, _ref);
		this.productDurationConfigService.configuration$.subscribe((config) => {
			this.formatAsYear = config ? config.formatAsYear : false;
		});
	}

	transform(duration: string | number, forceFormatAsYear?: boolean): string | null {
		if (!duration) {
			return null;
		}
		const formatAsYear: boolean = this.formatAsYear || forceFormatAsYear;

		const label = super.transform(`ç.misc.duration.${formatAsYear ? 'year' : 'month'}.${+duration === 1 ? 'singular' : 'plural'}`);

		return `${formatAsYear ? convertMonthsToYears(+duration) : duration} ${label}`;
	}
}
