import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PaymentFile } from '@oper-client/shared/data-model';

// Clear PaymentFiles
export const clearPaymentFiles = createAction('[PaymentFile] Clear PaymentFiles');

// Clear PaymentFiles
export const clearDownloadPaymentFileContent = createAction('[PaymentFile] Clear PaymentFile download content');

// Load PaymentFiles
export const loadPaymentFiles = createAction('[PaymentFile] Load PaymentFiles');
export const loadPaymentFilesSuccess = createAction('[PaymentFile] Load PaymentFiles Success', props<{ paymentFiles: PaymentFile[] }>());
export const loadPaymentFilesFailure = createAction('[PaymentFile] Load PaymentFiles Failure', props<{ error: HttpErrorResponse }>());

// Regenerate PaymentFile
export const regeneratePaymentFile = createAction('[PaymentFile] Regenerate PaymentFile', props<{ paymentFileId: number }>());
export const regeneratePaymentFileSuccess = createAction(
	'[PaymentFile] Regenerate PaymentFile Success',
	props<{ paymentFile: PaymentFile }>()
);
export const regeneratePaymentFileFailure = createAction(
	'[PaymentFile] Regenerate PaymentFile Failure',
	props<{ error: HttpErrorResponse }>()
);

// Download PaymentFile
export const downloadPaymentFile = createAction('[PaymentFile] Download PaymentFile', props<{ paymentFileId: number }>());
export const downloadPaymentFileSuccess = createAction(
	'[PaymentFile] Download PaymentFile Success',
	props<{ downloadPaymentFile: { fileName: string; content: string } }>()
);
export const downloadPaymentFileFailure = createAction('[PaymentFile] Download PaymentFile Failure', props<{ error: HttpErrorResponse }>());

// Process PaymentFile
export const processPaymentFile = createAction('[PaymentFile] Process PaymentFile', props<{ paymentFileId: number }>());
export const processPaymentFileSuccess = createAction('[PaymentFile] Process PaymentFile Success', props<{ paymentFile: PaymentFile }>());
export const processPaymentFileFailure = createAction('[PaymentFile] Process PaymentFile Failure', props<{ error: HttpErrorResponse }>());
