import { PartialNormalizedResource, Renovation, ResourceType } from '@oper-client/shared/data-model';
import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData?: Partial<Renovation>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const hasSingleRenovationType = resources?.[ResourceType.RENOVATION_TYPE]?.length === 1;

	if (hasSingleRenovationType) {
		const renovationTypeId =
			resources?.[ResourceType.RENOVATION_TYPE]?.find((item) => item.definition === 'generic')?.id ??
			resources?.[ResourceType.RENOVATION_TYPE]?.[0]?.id;

		return [
			new InputField({
				key: 'renovationType.id',
				value: renovationTypeId,
				type: 'hidden',
			}),
			new InputField({
				key: 'amountContractor',
				errorLabel: 'ç.feature.validator.required',
				label: 'ç.feature.financials.costRenovation',
				value: formData?.amountContractor,
				type: 'number',
				currency: true,
				required: true,
				class: 'span12',
			}),
		];
	}

	return [
		new InputSelect({
			key: 'renovationType.id',
			errorLabel: 'ç.feature.validator.required',
			value: formData?.renovationType?.id,
			required: true,
			options: resources[ResourceType.RENOVATION_TYPE] || [],
			class: 'span12',
			validators: [],
		}),
		new InputField({
			key: 'amountContractor',
			errorLabel: 'ç.feature.validator.required',
			value: formData?.amountContractor,
			type: 'number',
			currency: true,
			required: true,
			class: 'span12',
		}),
	];
}
