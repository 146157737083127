import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';

export interface invalidPhoneNumberError {
	invalidPhoneNumber: boolean;
}

export const phoneNumberValidator = (control: any): invalidPhoneNumberError | null => {
	const error: invalidPhoneNumberError = { invalidPhoneNumber: true };
	let numberInstance: PhoneNumber;
	if (control?.value?.value) {
		try {
			const value = `${control.value.countryCode.definition || ''}${control.value.value}`;
			numberInstance = parsePhoneNumber(value);
		} catch (e) {
			control.setValue(null);
			return error;
		}

		if (numberInstance && !numberInstance.isValid()) {
			control.setValue(null);
			if (!control.touched) {
				control.markAsTouched();
			}
			return error;
		}
	}
	return null;
};
