import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, combineReducers } from '@ngrx/store';

import { DOCUMENT_FEATURE_KEY } from './+state/document.reducer';
import { PROOF_ENTITY_KEY, reducer as proofReducer } from './+state/proof/proof.reducer';
import { ProofEffects } from './+state/proof/proof.effects';
import { ProofFacade } from './+state/proof/proof.facade';
import {
	COMMISSION_STATEMENT_DOCUMENT_ENTITY_KEY,
	reducer as commissionStatementDocumentReducer,
} from './+state/commission-statement/commission-statement.reducer';
import { CommissionStatementDocumentEffects } from './+state/commission-statement/commission-statement.effects';
import { CommissionStatementDocumentFacade } from './+state/commission-statement/commission-statement.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			DOCUMENT_FEATURE_KEY,
			combineReducers({
				[PROOF_ENTITY_KEY]: proofReducer,
				[COMMISSION_STATEMENT_DOCUMENT_ENTITY_KEY]: commissionStatementDocumentReducer,
			})
		),
		EffectsModule.forFeature([ProofEffects, CommissionStatementDocumentEffects]),
	],
	providers: [ProofFacade, CommissionStatementDocumentFacade],
})
export class DataAccessDocumentModule {}
