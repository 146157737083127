import { Client, Email, I18nPhoneNumber, PartialNormalizedResource, PhoneNumber, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputPhone, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource, locale?: string): InputBase<any>[] {
	const defaultClientRole = resources['client-role'].find((resource) => resource.definition === 'borrower');
	const defaultLanguage = resources['language'].find((resource) => resource.definition === locale);
	const email: Email = (formData?.emails?.length && formData?.emails[0]) || <Email>{};
	const mainPhoneNumber: PhoneNumber =
		formData?.phoneNumbers?.find((item) => item.phoneType?.definition === 'main') ||
		(formData?.phoneNumbers?.length > 0 && formData?.phoneNumbers[0]) ||
		<PhoneNumber>{};

	return [
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'role.id',
			label: 'ç.question.clientRole.label',
			value: formData?.role?.id ?? defaultClientRole?.id,
			required: true,
			options: resources?.[ResourceType.CLIENT_ROLE] || [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'language.id',
			label: 'ç.question.language.label',
			value: formData?.language?.id || defaultLanguage?.id,
			options: resources?.[ResourceType.LANGUAGE],
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'emails[0].value',
			label: 'ç.question.email.label',
			value: email?.value || '',
			type: 'text',
			required: false,
			validators: [ValidatorService.trimmedEmailValidator],
			class: 'span12',
		}),
		new InputPhone({
			key: 'phoneNumbers[0]',
			label: 'ç.question.mobileNumber.label',
			value: (mainPhoneNumber as I18nPhoneNumber) || '',
			allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
			class: 'span12',
			required: false,
			allowedTypes: ['MOBILE'],
		}),
	];
}
