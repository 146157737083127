import { InjectionToken } from '@angular/core';
import { getCustomer, Configuration, CustomersEnum } from '@oper-client/shared/configuration';
import { Simulator } from '@oper-client/shared/data-model';
import huSimulatorConfiguration from '../config/advisor-simulator/hu-simulator.configuration';
import atSimulatorConfiguration from '../config/advisor-simulator/at-simulator.configuration';
import defaultSimulatorConfiguration from '../config/advisor-simulator/default-simulator.configuration';
import { mergeObjects } from '@oper-client/shared/util-object';

export const ADVISOR_SIMULATOR_FEATURE_CONFIGURATION = new InjectionToken<Simulator.CustomerConfiguration>(
	'AdvisorSimulatorFeatureConfiguration'
);

export function advisorSimulatorConfigurationFactory(
	customerConfig: Configuration,
	featureKey = 'advisorSimulator'
): Simulator.CustomerConfiguration {
	if (!customerConfig?.applicationInsights) {
		throw new Error('Customer configuration was not provided');
	}

	const featureConfiguration = customerConfig.featureConfigurations[featureKey] as Simulator.CustomerConfiguration;
	const customer = getCustomer(customerConfig.applicationInsights) ?? CustomersEnum.BELGIAN;
	const defaultConfiguration = getDefaultCustomerConfiguration(customer as CustomersEnum);
	if (featureConfiguration) {
		// merge default configuration with feature configuration
		return mergeObjects(defaultConfiguration, featureConfiguration);
	} else {
		console.warn(
			`featureConfigurations.${featureKey} was not found in customer.json! Default '${customer}' simulator configuration will be used instead.`
		);

		return defaultConfiguration;
	}
}

/**
 * This function returns the advisor simulator configuration for the given customer.
 * @param customer - The customer for which the configuration should be returned.
 * @returns - The advisor simulator configuration for the given customer.
 */
export function getDefaultCustomerConfiguration(customer: CustomersEnum): Simulator.CustomerConfiguration {
	switch (customer) {
		case CustomersEnum.FINPORTA:
			return huSimulatorConfiguration;
		case CustomersEnum.HYPOOOE:
		case CustomersEnum.AUSTRIA:
		case CustomersEnum.GERMAN:
			return atSimulatorConfiguration;
		default:
			return defaultSimulatorConfiguration;
	}
}
