import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@oper-client/shared/data-model';
import { Inject, Injectable } from '@angular/core';
import { API_SERVICE, IApiService, IUserService } from '@oper-client/shared/data-access';

@Injectable()
export class UserService implements IUserService {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	public getUsers(params: HttpParams = new HttpParams()): Observable<User[]> {
		return this.apiService.get(`/api/users/`, params);
	}
}
