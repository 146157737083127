import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import { CreditProviderService } from '../../services/credit-provider.service';
import * as CreditProviderActions from './credit-provider.actions';

@Injectable()
export class CreditProviderEffects {
	constructor(private actions$: Actions, private creditProviderService: CreditProviderService) {}

	loadCreditProvider$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CreditProviderActions.loadCreditProviders),
			switchMap((action) => {
				const { ...props } = action;
				return this.creditProviderService.getCreditProviders(props).pipe(
					map((creditProviders) => CreditProviderActions.loadCreditProvidersSuccess({ creditProviders })),
					catchError((error) => of(CreditProviderActions.loadCreditProvidersFailure({ error })))
				);
			})
		)
	);
	
	loadBrokerCreditProvider$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CreditProviderActions.loadBrokerCreditProviders),
			switchMap((action) => {
				return this.creditProviderService.getCreditProviders({...action, excludeBlacklisted: true}).pipe(
					map((creditProviders) => CreditProviderActions.loadBrokerCreditProvidersSuccess({ creditProviders })),
					catchError((error) => of(CreditProviderActions.loadBrokerCreditProvidersFailure({ error })))
				);
			})
		)
	);
}
