import { Component, Input } from '@angular/core';
import { UnsavedDataService } from '@oper-client/shared/data-access';

@Component({
	selector: 'oper-client-deployment-announcement',
	template: `
		<oper-client-modal
			[size]="'small'"
			[title]="'ç.deploymentAnnouncement.title' | translate"
			[headerBorder]="false"
			[closeButton]="false"
			[closeOnClickOutside]="false"
			[shown]="shown"
			[actionPrimary]="'ç.deploymentAnnouncement.actionPrimary' | translate"
			(clickedPrimary)="handleClickedPrimary()"
		>
			{{ 'ç.deploymentAnnouncement.content' | translate }}
		</oper-client-modal>
	`,
})
export class DeploymentAnnouncementComponent {
	@Input() shown: boolean;

	constructor(private readonly unsavedDataService: UnsavedDataService) {}
	handleClickedPrimary(): void {
		this.unsavedDataService.untrackAll();
		window.location.reload();
	}
}
