import { Client, CreditProvider, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { DashedInputField, InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { Validators } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';


export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource, creditProviders?: any[]): InputBase<any>[] {
	const creditProvidersTransformed = creditProviders.map((creditProvider: CreditProvider) => ({
		id: creditProvider.id,
		definition: creditProvider.name,
		key: creditProvider.name,
		order: creditProvider.order,
	}));
	return [
		new InputSelect({
			key: 'socialInsuranceStartDate.id',
			label: 'ç.question.socialInsuranceStartDate.label',
			value: formData?.socialInsuranceStartDate?.id,
			options: resources?.[ResourceType.SOCIAL_INSURANCE_START_DATE] || [],
			required: false,
			class: 'span3',
		}),
		new InputSelect({
			key: 'employmentStatus.id',
			label: 'ç.question.employmentStatus.label',
			value: formData?.employmentStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.EMPLOYMENT_TYPE] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'numberOfIncomeEarnersInTheHousehold',
			label: 'ç.question.numberOfIncomeEarnersInTheHousehold.label',
			value: formData?.numberOfIncomeEarnersInTheHousehold,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputSelect({
			key: 'livingStatus.id',
			label: 'ç.question.livingStatus.label',
			value: formData?.livingStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.LIVING_STATUS] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'education.id',
			label: 'ç.question.education.label',
			value: formData?.education?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.EDUCATION] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'creditProvider.id',
			label: 'ç.question.accountManagingBank.label',
			value: formData?.creditProvider?.id,
			required: false,
			validators: [],
			options: creditProvidersTransformed || [],
			class: 'span3',
		}),
		new DashedInputField({
			key: 'localBankAccount',
			label: 'ç.question.localBankAccount.label',
			value: formData?.localBankAccount,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getBankAccountValidator()
			],
			class: 'span3',
			dashesConfig: [8, 16]
		}),
		new InputField({
			key: 'isFirstTimeBuyer',
			label: 'ç.question.isFirstTimeBuyer.label',
			value: formData?.isFirstTimeBuyer || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new InputField({
			key: 'hasPoliticallyExposedPersonStatus',
			label: 'ç.question.hasPoliticallyExposedPersonStatus.label',
			value: formData?.hasPoliticallyExposedPersonStatus || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
			badgeLabel: 'PEP',
		}),
		new InputField({
			key: 'is36MonthMoratoriumOnRepaymentsRequired',
			label: 'ç.question.is36MonthMoratoriumOnRepaymentsRequired.label',
			value: formData?.is36MonthMoratoriumOnRepaymentsRequired || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new InputField({
			key: 'isClaimingTaxRefund',
			label: 'ç.question.isClaimingTaxRefund.label',
			value: formData?.isClaimingTaxRefund || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
	];
}
