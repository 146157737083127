import { createSelector } from '@ngrx/store';

import { getThirdPartyState } from '../third-party.selectors';
import {
	InsuranceProviderState,
	InsuranceProviderPartialState,
	InsuranceProviderActionTypes,
	INSURANCE_PROVIDER_FEATURE_KEY,
	selectAll,
} from './insurance-provider.reducer';

export const getInsuranceProviderState = createSelector(
	getThirdPartyState,
	(state: InsuranceProviderPartialState) => state[INSURANCE_PROVIDER_FEATURE_KEY]
);
export const getInsuranceProviders = createSelector(getInsuranceProviderState, (state: InsuranceProviderState) => selectAll(state));

export const getActionStates = createSelector(getInsuranceProviderState, state => state.actions);
export const getActionState = (actionType: InsuranceProviderActionTypes) => createSelector(getActionStates, state => state[actionType]);
