import { HttpErrorResponse } from '@angular/common/http';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as CommissionStatementActions from './commission-statement.actions';
import { CommissionStatement } from '@oper-client/shared/data-model';

export const COMMISSIONSTATEMENT_FEATURE_KEY = 'commissionStatement';

export interface CommissionStatementState extends EntityState<CommissionStatement> {
	error: HttpErrorResponse | undefined;
}

export interface CommissionStatementPartialState {
	readonly [COMMISSIONSTATEMENT_FEATURE_KEY]: CommissionStatementState;
}

export const commissionStatementAdapter: EntityAdapter<CommissionStatement> = createEntityAdapter<CommissionStatement>();

export const commissionStatementInitialState: CommissionStatementState = commissionStatementAdapter.getInitialState({
	error: undefined,
});

const commissionStatementReducerInternal = createReducer(
	commissionStatementInitialState,
	on(CommissionStatementActions.loadCommissionStatements, state => ({ ...state, error: null })),
	on(CommissionStatementActions.loadCommissionStatementsSuccess, (state, { commissionStatements }) =>
		commissionStatementAdapter.setAll(commissionStatements, { ...state })
	),
	on(CommissionStatementActions.loadCommissionStatementsFailure, (state, { error }) => ({ ...state, error }))
);

export function commissionStatementReducer(state: CommissionStatementState | undefined, action: Action) {
	return commissionStatementReducerInternal(state, action);
}
