import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { yearOptions1500 } from '@oper-client/shared/util-formatting';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'conditionOfProperty.id',
			label: 'ç.question.conditionOfProperty.label',
			value: formData?.conditionOfProperty?.id,
			required: false,
			options: resources?.[ResourceType.CONDITION_OF_PROPERTY] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'yearOfBuilt',
			label: 'ç.question.yearOfBuilt.label',
			value: formData?.yearOfBuilt || null,
			options: yearOptions1500,
			validators: [],
			required: false,
			class: 'span3',
			alreadySorted: true,
		}),
		new InputField({
			key: 'landRegister',
			label: 'ç.question.landRegister.label',
			value: formData?.landRegister,
			type: 'text',
			required: false,
			class: 'span3',
			order: 1,
		}),
		new InputSelect({
			key: 'features',
			label: 'ç.question.propertyFeatures.label',
			value: formData?.features,
			bindValue: 'id',
			multiple: true,
			required: false,
			options: resources?.[ResourceType.REALTY_FEATURE] || [],
			class: 'span3',
			beta: false,
			clearable: true,
			alreadySorted: true,
		}),
	];
}
