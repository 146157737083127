import { Client, ClientRoleEnum, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../../services/validator-constants';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MAX_AGE).getTime();
	const maxBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MIN_AGE).getTime();
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();

	const maxAgeV = ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST);
	const pastDateV = ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE);
	const minAgeV = ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE);

	const roleTypeChildId = resources?.[ResourceType.CLIENT_ROLE]?.find((item) => item.definition === ClientRoleEnum.CHILD)?.id;
	const getBirthDateValidators = (id: number) => {
		if (id === roleTypeChildId) {
			return [maxAgeV, pastDateV];
		} else {
			return [maxAgeV, minAgeV];
		}
	};

	const getBirthDateMax = (id: number) => {
		if (id === roleTypeChildId) {
			return currentDate;
		} else {
			return maxBirthDate;
		}
	};
	return [
		new InputSelect({
			key: 'title.id',
			label: 'ç.question.title.label',
			value: formData?.title?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CLIENT_TITLE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'role.id',
			label: 'ç.question.clientRole.label',
			value: formData?.role?.id,
			required: true,
			options: resources?.[ResourceType.CLIENT_ROLE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'isFirstTimeBuyer',
			label: 'ç.question.isFirstTimeBuyer.label',
			value: formData?.isFirstTimeBuyer || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
		}),
		new InputSelect({
			key: 'language.id',
			label: 'ç.question.language.label',
			value: formData?.language?.id || '',
			options: resources?.[ResourceType.LANGUAGE],
			required: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'employmentStatus.id',
			label: 'ç.question.employmentStatus.label',
			value: formData?.employmentStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.EMPLOYMENT_TYPE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'birthDate',
			label: 'ç.question.birthDate.label',
			value: formData?.birthDate,
			type: 'date',
			required: false,
			validators: getBirthDateValidators(formData?.role?.id),
			min: minBirthDate,
			max: getBirthDateMax(formData?.role?.id),
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputIndex = formConfiguration.formControl.questions.findIndex((q) => q.key === 'birthDate');
				const formControl = <FormControl>formGroup.controls['birthDate'];
				formControl.markAsDirty({ onlySelf: false, emitEvent: false });
				if (!formData) {
					return of(null);
				}

				return formGroup.controls['role.id'].valueChanges.pipe(
					distinctUntilChanged(),
					map((id: number) => {
						const inputField = <InputField>formConfiguration.formControl.questions[inputIndex];
						const max = getBirthDateMax(id);

						if (inputField.max !== max) {
							inputField.validators = getBirthDateValidators(id);
							formConfiguration.formControl.questions[inputIndex] = new InputField({
								...inputField,
								max: max,
							});
						}
						return inputField;
					})
				);
			},
		})
	];
}
