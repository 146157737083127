import { Realty, Resource } from '@oper-client/shared/data-model';

import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	realty: Partial<Realty>,
	resourсes: Resource[],
	canChangeVenalValueAfter: boolean,
	canChangeEstimationExpirationDate: boolean,
	realtyPurposeId: number,
	buildingPurpose: Resource
): InputBase<any>[] {
	return [
		...(!realtyPurposeId || buildingPurpose?.id !== realtyPurposeId
			? [
					new InputField({
						key: 'price',
						label: 'ç.question.price.label',
						value: realty?.price,
						type: 'number',
						currency: true,
						required: true,
						class: 'span6',
					}),
			  ]
			: [
					new InputField({
						key: 'priceLand',
						label: 'ç.question.priceLand.label',
						value: realty?.priceLand,
						type: 'number',
						currency: true,
						required: true,
						class: 'span6',
					}),
					new InputField({
						key: 'priceBuilding',
						label: 'ç.question.priceBuilding.label',
						value: realty?.priceBuilding,
						type: 'number',
						currency: true,
						required: true,
						class: 'span6',
					}),
			  ]),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.venalValueBeforeRenovation.label',
			value: realty?.venalValueBefore,
			type: 'number',
			currency: true,
			required: false,
			class: 'span6',
		}),
		new InputField({
			key: 'venalValueAfter',
			label: 'ç.question.venalValueAfterRenovation.label',
			value: realty?.venalValueAfter,
			type: 'number',
			currency: true,
			required: false,
			class: 'span6',
			disabled: !canChangeVenalValueAfter,
		})
	];
}
