import { InjectionToken } from '@angular/core';
import { Milestone } from '@oper-client/shared/data-model';
import { Observable } from 'rxjs';
import { LoanRequestEntityService } from './loan-request-entity.service';

export const MILESTONE_SERVICE = new InjectionToken<IMilestoneService>('MILESTONE_SERVICE');

export interface IMilestoneService extends LoanRequestEntityService<Milestone> {
	getAll(loanRequestId: number): Observable<Milestone[]>;
	
	updateMilestone(loanRequestId: number, milestoneId: number, milestone: Partial<Milestone>): Observable<Milestone>;
}
