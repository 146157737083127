import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IApiService } from '@oper-client/shared/data-access';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';

@Injectable()
export class ApiV2Service implements IApiService {
	private readonly API_VERSION = '2.0';
	constructor(private httpClient: HttpClient, @Inject(CUSTOMER_INSIGHTS_CONFIG) private customerInsights: CustomerInsights) {}

	private prepareHeaders(headers: HttpHeaders | null | undefined): HttpHeaders {
		if (!headers) {
			headers = new HttpHeaders();
		}
		if (!headers.has('Content-Type')) {
			// We need to default this else we get 415 errors
			headers = headers.set('Content-Type', 'application/json');
		} else if (headers.get('Content-type').toLowerCase() === 'multipart/form-data') {
			// We hope that Angular is smart enough to find the correct content type and then set the boundary
			// If we just set multipart/form-data, we don't set the boundary and Django screws up
			// Solves https://sentry.io/organizations/oper-engineering-yq/issues/1636963366/?project=1779687 if application/json is set / We don't set content type
			// And https://sentry.io/organizations/oper-engineering-yq/issues/1637253775/?project=1761984&query=is%3Aunresolved if multipart/form-data is set manually without a boundary
			headers = headers.delete('Content-type');
		}
		if (!headers.has('Accept')) {
			headers = headers.set('Accept', `*/*; version=${this.API_VERSION}`);
		} else {
			if (!headers.get('Accept').includes('version')) {
				// Add '; version=1.0' to each part of the accept
				headers = headers.set(
					'Accept',
					headers
						.get('Accept')
						.split(',')
						.map(type => `${type}; version=${this.API_VERSION}`)
						.join(',')
				);
			}
		}
		return headers;
	}

	public get(
		path: string,
		params: HttpParams = new HttpParams(),
		headers = new HttpHeaders(),
		options: { [key: string]: string | boolean } = {}
	): Observable<any> {
		headers = this.prepareHeaders(headers);
		return this.httpClient
			.get(this.customerInsights.serverUrl + path, { params, headers, ...options })
			.pipe(catchError(this.formatErrors));
	}

	public put(
		path: string,
		body: object = {},
		params = new HttpParams(),
		headers = new HttpHeaders(),
		options: { [key: string]: string | boolean }
	): Observable<any> {
		headers = this.prepareHeaders(headers);
		return this.httpClient
			.put(this.customerInsights.serverUrl + path, body, { params, headers, ...options })
			.pipe(catchError(this.formatErrors));
	}

	public patch(
		path: string,
		body: object = {},
		params = new HttpParams(),
		headers = new HttpHeaders(),
		options: { [key: string]: string | boolean } = {}
	): Observable<any> {
		params = params ?? new HttpParams();
		headers = this.prepareHeaders(headers);
		options = options ?? {};
		return this.httpClient
			.patch(this.customerInsights.serverUrl + path, body, { params, headers, ...options })
			.pipe(catchError(this.formatErrors));
	}

	public post(
		path: string,
		body: object = {},
		params = new HttpParams(),
		headers = new HttpHeaders(),
		options: { [key: string]: string | boolean } = {}
	): Observable<any> {
		params = params ?? new HttpParams();
		headers = this.prepareHeaders(headers);
		options = options ?? {};
		return this.httpClient
			.post(this.customerInsights.serverUrl + path, body, { params, headers, ...options })
			.pipe(catchError(this.formatErrors));
	}

	public delete(path: string, headers: HttpHeaders = new HttpHeaders()): Observable<any> {
		headers = this.prepareHeaders(headers);
		return this.httpClient.delete(this.customerInsights.serverUrl + path, { headers }).pipe(catchError(this.formatErrors));
	}

	public formatErrors(error: any): Observable<any> {
		return throwError(error);
	}
}
