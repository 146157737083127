import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Document } from '@oper-client/shared/data-model';
import { DocumentService } from '../../services/document.service';
import * as CommissionStatementActions from './commission-statement.actions';

@Injectable()
export class CommissionStatementDocumentEffects {
	loadCommissionStatementDocuments$ = createEffect(() => (): Observable<Action> =>
		this.actions$.pipe(
			ofType(CommissionStatementActions.loadCommissionStatementDocuments),
			switchMap(payload =>
				this.documentService.getCommissionStatementDocuments(payload.brokerId).pipe(
					map((documents: Document[]) =>
						CommissionStatementActions.loadCommissionStatementDocumentsSuccess({ documents: documents })
					),
					catchError(error => of(CommissionStatementActions.loadCommissionStatementDocumentsFailure({ error: error })))
				)
			)
		)
	);

	constructor(private actions$: Actions, private documentService: DocumentService) {}
}
