import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, IMortgageSimulatorService } from '@oper-client/shared/data-access';
import {
	AdvisorSimulationParameters,
	MortgageReport,
	MortgageReportRequest,
	MortgageSimulationResult,
	MortgageSimulationResultDocumentResponse,
	Resource,
	Simulator,
} from '@oper-client/shared/data-model';
import { LocalStorageService, StorageKeysEnum } from '@oper-client/shared/util-client-storage';

@Injectable()
export class MortgageSimulatorService implements IMortgageSimulatorService {
	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		private readonly localStorageService: LocalStorageService
	) {}

	createSimulation(request: Partial<Simulator.Simulation>): Observable<Simulator.Simulation> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/simulations/`, request);
	}

	syncSimulation(simulationId: number): Observable<any> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/simulations/${simulationId}/sync-simulation/`);
	}

	updateSimulation(simulationResultId: number, request?: Partial<Simulator.Simulation>): Observable<Simulator.Simulation> {
		return this.apiService.patch(`/api/simulators/mortgage/advisor/simulations/${simulationResultId}/`, request);
	}

	calculateClientSimulation(mortgageReportRequest: MortgageReportRequest): Observable<MortgageReport> {
		return this.apiService.post(`/api/simulators/mortgage/client/`, mortgageReportRequest);
	}

	calculateAdvisorSimulation(simulationParameters: AdvisorSimulationParameters): Observable<MortgageSimulationResult> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/`, simulationParameters);
	}

	createSimulationResult(simulationResult: MortgageSimulationResult): Observable<MortgageSimulationResult> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/simulations/`, simulationResult);
	}

	updateSimulationResult(
		simulationResultId: number,
		simulationResult: Partial<MortgageSimulationResult>
	): Observable<MortgageSimulationResult> {
		return this.apiService.patch(`/api/simulators/mortgage/advisor/simulations/${simulationResultId}/`, simulationResult);
	}

	loadSimulationResults(): Observable<MortgageSimulationResult[]> {
		return this.apiService.get(`/api/simulators/mortgage/advisor/simulations/`);
	}

	loadSimulationResult(simulationResultId: number): Observable<MortgageSimulationResult> {
		return this.apiService.get(`/api/simulators/mortgage/advisor/simulations/${simulationResultId}/`);
	}

	removeSimulationResult(simulationResultId: number): Observable<void> {
		return this.apiService.delete(`/api/simulators/mortgage/advisor/simulations/${simulationResultId}/`);
	}

	cleanUpSimulationResults(): Observable<void> {
		return this.apiService.post(`/api/simulators/mortgage/advisor/simulations/clean-up/`);
	}

	generateSimulationResultDocument(simulationResultId: number, language: Resource): Observable<MortgageSimulationResultDocumentResponse> {
		const payload = {
			documentType: {
				definition: 'simulation',
			},
			...(language && { language: language }),
		};

		return this.apiService.post(`/api/simulators/mortgage/advisor/simulations/${simulationResultId}/generate-documents/`, payload);
	}

	saveSimulatorDataToLocalStorage(payload: MortgageReportRequest, mortgageReport: MortgageReport) {
		if (mortgageReport?.simulationResults?.[0]?.errors?.length > 0) {
			return;
		}

		const clientSimulatorData = {
			...payload,
			...mortgageReport.simulationResults[0],
		};

		this.localStorageService.set(StorageKeysEnum.BORROWER_SIMULATOR_DATA, JSON.stringify(clientSimulatorData));
	}

	clearSimulatorDataFromLocalStorage() {
		this.localStorageService.unset(StorageKeysEnum.BORROWER_SIMULATOR_DATA);
	}
}
