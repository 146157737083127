import { Address, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { Validators } from '@angular/forms';

export default function (
	formData?: Address,
	resources?: PartialNormalizedResource,
	prefix = 'address.',
	addAddressType = false
): InputBase<any>[] {
	return [
		new InputField({
			key: `${prefix}street`,
			label: 'ç.question.street.label',
			value: formData?.street || '',
			type: 'text',
			required: false,
			class: 'span12',
			updateValidityOnFormValueChanges: true,
			order: 1,
		}),
		new InputField({
			key: `${prefix}houseNumber`,
			label: 'ç.question.houseNumber.label',
			value: formData?.houseNumber || '',
			type: 'text',
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: `${prefix}box`,
			label: 'ç.question.box.label',
			value: formData?.box || '',
			type: 'text',
			required: false,
			validators: [],
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: `${prefix}floor`,
			label: 'ç.question.floor.label',
			value: formData?.floor,
			type: 'text',
			required: false,
			validators: [],
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: `${prefix}city`,
			label: 'ç.question.city.label',
			value: formData?.city || '',
			type: 'text',
			required: false,
			validators: [],
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: `${prefix}zipCode`,
			label: 'ç.question.zipCode.label',
			value: formData?.zipCode || '',
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputSelect({
			key: `${prefix}country.id`,
			label: 'ç.question.country.label',
			value: formData?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: `${prefix}mapConfig`,
			value: formData?.mapConfig || null,
			required: false,
			type: 'hidden',
		}),
		...(addAddressType
			? [
					new InputField({
						key: `${prefix}addressType.definition`,
						value: formData?.addressType?.definition,
						required: false,
						type: 'hidden',
					}),
				]
			: []),
	];
}
