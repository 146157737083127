import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as ProductActions from './product.actions';
import { IProductService, PRODUCT_SERVICE } from '@oper-client/shared/data-access';

@Injectable()
export class ProductEffects {
	constructor(private actions$: Actions, @Inject(PRODUCT_SERVICE) private productService: IProductService) {}

	loadProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.loadProducts),
			switchMap(({ loanRequestId, params, body }) => {
				return this.productService.getAll(loanRequestId, params, body).pipe(
					map((products) => ProductActions.loadProductsSuccess({ products })),
					catchError((error) => of(ProductActions.loadProductsFailure({ error: error })))
				);
			})
		)
	);

	loadProductVariations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductActions.loadProductVariations),
			switchMap(() =>
				this.productService.getProductVersions().pipe(
					map((productVariations) => ProductActions.loadProductVariationsSuccess({ products: productVariations[0].products })),
					catchError((error) => of(ProductActions.loadProductsFailure({ error: error })))
				)
			)
		)
	);
}
