import { createReducer, on, Action } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { NormalizedResource, ResourceActionState, ResourcesEntities } from '@oper-client/shared/data-model';

import * as ResourcesActions from './resources.actions';

export const RESOURCES_FEATURE_KEY = 'resources';

export interface ResourcesState {
	entities: NormalizedResource;
	actions: ResourceActionState;
}

export interface ResourcesPartialState {
	readonly [RESOURCES_FEATURE_KEY]: ResourcesState;
}

export const resourcesAdapter: EntityAdapter<ResourcesEntities> = createEntityAdapter<ResourcesEntities>();

export const initialState: ResourcesState = {
	entities: undefined,
	actions: undefined,
};

const resourcesReducer = createReducer(
	initialState,
	on(ResourcesActions.loadResources, (state, { kinds }) => {
		const resourceTypeActionStates = kinds.reduce((previous, current) => {
			return {
				...previous,
				[current]: {
					load: {
						httpError: undefined,
						processing: true,
						success: false,
					},
				},
			};
		}, {});
		return {
			...state,
			actions: {
				...state.actions,
				...resourceTypeActionStates,
			},
		};
	}),
	on(ResourcesActions.loadResourcesSuccess, (state, { resources, kind }) => {
		return {
			...state,
			entities: {
				...state.entities,
				[kind]: resources,
			},
			actions: {
				...state.actions,
				[kind]: {
					load: {
						httpError: undefined,
						processing: false,
						success: true,
					},
				},
			},
		};
	}),
	on(ResourcesActions.loadResourcesFailure, (state, { httpError, kind }) => ({
		...state,
		actions: {
			...state.actions,
			[kind]: {
				load: {
					httpError,
					processing: false,
					success: false,
				},
			},
		},
	}))
);

export function reducer(state: ResourcesState | undefined, action: Action) {
	return resourcesReducer(state, action);
}
