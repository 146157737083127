import { Inject, Injectable } from '@angular/core';
import { getCurrencySymbol, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG, NUMBER_FORMAT_OPTIONS, NumberFormatOptions } from '@oper-client/shared/configuration';
import { LanguageService } from '@oper-client/shared/util-language';
import { NumberInputOptions } from '../models/number-input-options';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentNumberFormatService {
	private static readonly symbolFormat = 'narrow';

	defaultCurrencyInputOptions$ = this.languageService.languageChanged$.pipe(
		map(
			(locale) =>
				<NumberInputOptions>{
					decimal: getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal),
					thousands: getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup),
					inputMode: 'currency',
					digitsInfo: this.numberFormatOptions.currencyDigitsInfo,
				}
		),
		shareReplay(1)
	);

	defaultDecimalInputOptions$: Observable<NumberInputOptions> = this.languageService.languageChanged$.pipe(
		map(
			(locale) =>
				<NumberInputOptions>{
					decimal: getLocaleNumberSymbol(locale, NumberSymbol.Decimal),
					thousands: getLocaleNumberSymbol(locale, NumberSymbol.Group),
					inputMode: 'decimal',
					digitsInfo: this.numberFormatOptions.decimalDigitsInfo,
				}
		),
		shareReplay(1)
	);

	defaultPercentageInputOptions$: Observable<NumberInputOptions> = this.languageService.languageChanged$.pipe(
		map(
			(locale) =>
				<NumberInputOptions>{
					decimal: getLocaleNumberSymbol(locale, NumberSymbol.Decimal),
					thousands: getLocaleNumberSymbol(locale, NumberSymbol.Group),
					inputMode: 'percentage',
					digitsInfo: this.numberFormatOptions.percentageDigitsInfo,
				}
		),
		shareReplay(1)
	);

	constructor(
		@Inject(CUSTOMER_INSIGHTS_CONFIG) readonly customerInsights: CustomerInsights,
		private readonly languageService: LanguageService,
		@Inject(NUMBER_FORMAT_OPTIONS) private readonly numberFormatOptions: NumberFormatOptions
	) {}

	getCode(): string {
		return this.customerInsights.currency.code;
	}

	getLocale(): string {
		return this.languageService.getCurrentLanguage();
	}

	getCurrencySymbol(): string {
		return getCurrencySymbol(this.getCode(), EnvironmentNumberFormatService.symbolFormat);
	}

	getCurrencyDigitsInfo(): string {
		return this.numberFormatOptions.currencyDigitsInfo;
	}

	getDefaultCurrencyInputOptions(): NumberInputOptions {
		const locale = this.getLocale();
		return {
			decimal: getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal),
			thousands: getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup),
			inputMode: 'currency',
			digitsInfo: this.numberFormatOptions.currencyDigitsInfo,
		};
	}
}
