import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { Broker, CommissionConfig } from '@oper-client/shared/data-model';

import { ThirdPartyService } from './third-party.service';

@Injectable({
	providedIn: 'root',
})
export class BrokerService {
	private thirdPartyName = 'brokers';

	constructor(private thirdPartyService: ThirdPartyService, @Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getBrokers(params: HttpParams = new HttpParams()): Observable<Broker[]> {
		return this.thirdPartyService.getThirdParties(this.thirdPartyName, params);
	}

	public getBroker(id: number, params: HttpParams = new HttpParams()): Observable<Broker> {
		return this.thirdPartyService.getThirdParty(this.thirdPartyName, id, params) as Observable<Broker>;
	}

	public updateBroker(id: number, body: Partial<Broker>): Observable<Broker> {
		return this.thirdPartyService.updateThirdParty(this.thirdPartyName, id, body);
	}

	public transferPortfolio(id: number, beneficiaryBroker: Partial<Broker>): Observable<HttpResponseBase> {
		return this.apiService.patch(`/api/third-parties/brokers/${id}/transfer-commissions/`, { beneficiaryBroker });
	}

	public updateCommissionConfig(config: CommissionConfig): Observable<CommissionConfig> {
		return this.apiService.post('/api/commissions/configurations/', config) as Observable<CommissionConfig>;
	}
}
