import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { Step } from '@oper-client/shared/data-model';

@Injectable()
export class ApplicationFlowService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getConfiguration(): Observable<Step[]> {
		return this.apiService.get(`/configuration/flow-configurations/`);
	}
}
