import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ThirdParty } from '@oper-client/shared/data-model';
import { ThirdPartyService } from './third-party.service';

@Injectable({
	providedIn: 'root',
})
export class NotaryService {
	readonly thirdPartyName = 'notaries';
	constructor(private thirdPartyService: ThirdPartyService) {}

	public getNotaries(params: HttpParams = new HttpParams()): Observable<ThirdParty[]> {
		return this.thirdPartyService.getThirdParties(this.thirdPartyName, params);
	}
}
