import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, ICommissionService } from '@oper-client/shared/data-access';
import {
	CommissionFile,
	Commission,
	PaymentFile,
	CommissionStatement,
	CommissionStatementFilterParams,
	Payment,
	IncomingPayment,
} from '@oper-client/shared/data-model';

@Injectable()
export class CommissionService implements ICommissionService {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	public getCommissionFiles(): Observable<CommissionFile[]> {
		return this.apiService.get(`/api/commissions/files/`);
	}

	public getCommissions(commissionFilterParams: CommissionStatementFilterParams): Observable<Commission[]> {
		const { year, month } = commissionFilterParams;
		return this.apiService.get(`/api/commissions/objects/${year}/${month < 10 ? '0' + month : month}/`);
		// return this.apiService.get(`/api/commissions/objects/`); // Temporary keep this until Alex's update on BE
	}

	public getPaymentFiles(): Observable<PaymentFile[]> {
		return this.apiService.get(`/api/commissions/payment-files/`);
	}

	public regeneratePaymentFile(paymentFileId: number): Observable<PaymentFile> {
		return this.apiService.patch(`/api/commissions/payment-files/${paymentFileId}/regenerate/`);
	}

	public downloadPaymentFile(paymentFileId: number): Observable<string> {
		const options = { responseType: 'text', observe: 'response' };
		return this.apiService.get(`/api/commissions/payment-files/${paymentFileId}/download/`, null, null, options);
	}

	public loadCommissionStatement(commissionFilterParams: CommissionStatementFilterParams): Observable<CommissionStatement> {
		const { year, month } = commissionFilterParams;
		return this.apiService.get(`/api/commissions/documents/${year}/${month < 10 ? '0' + month : month}/`);
	}

	public processPaymentFile(paymentFileId: number): Observable<PaymentFile> {
		return this.apiService.patch(`/api/commissions/payment-files/${paymentFileId}`, { processed: true });
	}

	public getCommissionFile(commissionFileId: number): Observable<CommissionFile> {
		return this.apiService.get(`/api/commissions/files/${commissionFileId}`);
	}

	public recoverCommissionFile(commissionFileId: number): Observable<CommissionFile> {
		return this.apiService.post(`/api/commissions/files/${commissionFileId}/recoveries/`);
	}

	public createCommissionFile(commissionFile: Partial<CommissionFile>): Observable<CommissionFile> {
		return this.apiService.post('/api/commissions/files/', commissionFile);
	}

	public getCommissionStatements(brokerId: number): Observable<CommissionStatement[]> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set('broker', brokerId.toString());
		return this.apiService.get(`/api/commissions/statements/`, httpParams);
	}

	public getPayments(brokerId: number): Observable<Payment[]> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set('broker', brokerId.toString());
		return this.apiService.get(`/api/commissions/payments/`, httpParams);
	}

	public createPayment(payment: Partial<IncomingPayment>): Observable<IncomingPayment> {
		return this.apiService.post(`/api/commissions/payments/`, payment);
	}
}
