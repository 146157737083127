import { of } from 'rxjs';
import { DataConnection } from '@oper-client/shared/data-model';
import { InputBase } from '../../../models/input-base.model';
import { InputField } from '../../../models/input-types.model';
import { emailLoginValidator } from '../../../validators/email-validator';

export default function (formData: Partial<DataConnection>): InputBase<any>[] {
	const emailDomain = `@${formData.integrator?.documentsPushEmail?.split('@')?.[1] || ''}`;
	return [
		new InputField({
			key: 'emailRecipient',
			label: 'ç.question.email.label',
			validators: [emailLoginValidator(emailDomain)],
			type: 'text',
			required: true,
			class: 'span12',
			suffix: emailDomain,
		}),
		new InputField({
			key: 'reference',
			helpText: of('ç.question.pushDocumentsReference.helpText'),
			label: 'ç.question.pushDocumentsReference.label',
			value: formData.reference,
			type: 'text',
			required: true,
			class: 'span12',
		}),
	];
}
