import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { COMMISSION_SERVICE, ICommissionService } from '@oper-client/shared/data-access';
import { PaymentFile } from '@oper-client/shared/data-model';
import * as PaymentFileActions from './payment-file.actions';

@Injectable()
export class PaymentFileEffects {
	loadPaymentFiles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PaymentFileActions.loadPaymentFiles),
			switchMap(() =>
				this.commissionService.getPaymentFiles().pipe(
					map((paymentFiles: PaymentFile[]) =>
						PaymentFileActions.loadPaymentFilesSuccess({
							paymentFiles: paymentFiles.map(PaymentFileEffects.mapPaymentFile),
						})
					),
					catchError(error => of(PaymentFileActions.loadPaymentFilesFailure({ error: error })))
				)
			)
		)
	);

	regeneratePaymentFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PaymentFileActions.regeneratePaymentFile),
			switchMap(({ paymentFileId }) =>
				this.commissionService.regeneratePaymentFile(paymentFileId).pipe(
					map((paymentFile: PaymentFile) =>
						PaymentFileActions.regeneratePaymentFileSuccess({
							paymentFile: PaymentFileEffects.mapPaymentFile(paymentFile),
						})
					),
					catchError(error => of(PaymentFileActions.regeneratePaymentFileFailure({ error: error })))
				)
			)
		)
	);

	downloadPaymentFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PaymentFileActions.downloadPaymentFile),
			switchMap(({ paymentFileId }) =>
				this.commissionService.downloadPaymentFile(paymentFileId).pipe(
					map((payload: any) =>
						PaymentFileActions.downloadPaymentFileSuccess({
							downloadPaymentFile: {
								fileName: `payment-file-${paymentFileId}.xml`,
								content: payload.body,
							},
						})
					),
					catchError(error => of(PaymentFileActions.downloadPaymentFileFailure({ error: error })))
				)
			)
		)
	);

	processPaymentFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PaymentFileActions.processPaymentFile),
			switchMap(({ paymentFileId }) =>
				this.commissionService.processPaymentFile(paymentFileId).pipe(
					map((paymentFile: PaymentFile) =>
						PaymentFileActions.processPaymentFileSuccess({
							paymentFile: PaymentFileEffects.mapPaymentFile(paymentFile),
						})
					),
					catchError(error => of(PaymentFileActions.processPaymentFileFailure({ error: error })))
				)
			)
		)
	);

	private static mapPaymentFile(paymentFile: PaymentFile): PaymentFile {
		const { id, internalReference, created, lastModified, downloaded, processed } = paymentFile;
		return {
			id,
			internalReference,
			created,
			lastModified,
			downloaded,
			processed,
		};
	}

	constructor(private actions$: Actions, @Inject(COMMISSION_SERVICE) private commissionService: ICommissionService) {}
}
