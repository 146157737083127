import { trigger, transition, group, query, style, animate } from '@angular/animations';

export const fadeSlideAnimation = (timings = 200, translateXPercentage = 40) => {
	return trigger('fadeSlide', [
		transition(':enter', [
			group([
				query(
					'.animated',
					[
						style({ opacity: 0, transform: `translateX(${translateXPercentage}%)` }),
						animate(timings, style({ opacity: 1, transform: 'translateX(0)' })),
					],
					{ optional: true }
				),
			]),
		]),
		transition(':leave', [
			group([
				query('.animated', [animate(timings, style({ opacity: 0, transform: `translateX(${translateXPercentage}%)` }))], {
					optional: true,
				}),
			]),
		]),
	]);
};
