import { Component, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
	AppFacade,
	DeviceDetectionService,
	OnlineDetectionService,
	VisibilityDetectionService,
} from '@oper-client/shared/app/data-access-app';
import { IAM } from '@oper-client/shared/data-model';
import { LanguageService } from '@oper-client/shared/util-language';
import { AUTH_SERVICE, IAuthService } from '@oper-client/shared/data-access';

import { environment } from '../environments/environment';
import { ZendeskService } from '@oper-client/shared/zendesk';

@Component({
	selector: 'oper-client-brokerage-root',
	styles: [':host { width: 100%; }'],
	template: `
		<router-outlet />
		<oper-client-deployment-announcement [shown]="newDeploymentDetected$ | async" />
	`,
})
export class AppComponent {
	readonly newDeploymentDetected$: Observable<boolean>;

	constructor(
		@Inject(AUTH_SERVICE) private readonly auth: IAuthService,
		private readonly languageService: LanguageService,
		private readonly appFacade: AppFacade,
		private readonly zendeskService: ZendeskService,
		private readonly visibilityDetectionService: VisibilityDetectionService,
		private readonly deviceDetectionService: DeviceDetectionService,
		private readonly onlineDetectionService: OnlineDetectionService
	) {
		this.languageService.init();
		this.zendeskService.init();
		this.visibilityDetectionService.init();
		this.deviceDetectionService.init();
		this.onlineDetectionService.init();
		this.newDeploymentDetected$ = environment.production ? this.appFacade.newDeploymentDetected$ : of(false);

		this.auth.getCurrentUser().subscribe((user: IAM.User) => {
			this.languageService.init(user);
		});
	}
}
