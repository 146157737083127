import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { CommissionFile } from '@oper-client/shared/data-model';
import * as CommissionFileActions from './commission-file.actions';
import { COMMISSION_SERVICE, ICommissionService } from '@oper-client/shared/data-access';

@Injectable()
export class CommissionFileEffects {
	loadCommissionFiles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommissionFileActions.loadCommissionFiles),
			switchMap(() =>
				this.commissionService.getCommissionFiles().pipe(
					map((loadedCommissionFiles: CommissionFile[]) =>
						CommissionFileActions.loadCommissionFilesSuccess({
							commissionFiles: loadedCommissionFiles.map(CommissionFileEffects.mapCommissionFile),
						})
					),
					catchError(error => of(CommissionFileActions.loadCommissionFilesFailure({ error: error })))
				)
			)
		)
	);

	loadCommissionFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommissionFileActions.loadCommissionFile),
			mergeMap(payload =>
				this.commissionService.getCommissionFile(payload.commissionFileId).pipe(
					map((loadedCommissionFile: CommissionFile) =>
						CommissionFileActions.loadCommissionFileSuccess({
							commissionFile: CommissionFileEffects.mapCommissionFile(loadedCommissionFile),
						})
					),
					catchError(error => of(CommissionFileActions.loadCommissionFileFailure({ error: error })))
				)
			)
		)
	);

	recoverCommissionFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommissionFileActions.recoverCommissionFile),
			mergeMap(({ commissionFileId }) =>
				this.commissionService.recoverCommissionFile(commissionFileId).pipe(
					map(commissionFile => CommissionFileActions.recoverCommissionFileSuccess({ commissionFile })),
					catchError(error => of(CommissionFileActions.recoverCommissionFileFailure({ error: error })))
				)
			)
		)
	);

	createCommissionFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommissionFileActions.createCommissionFile),
			mergeMap(payload =>
				this.commissionService.createCommissionFile(payload.commissionFile).pipe(
					map(commissionFile => CommissionFileActions.createCommissionFileSuccess({ commissionFile })),
					catchError(error => of(CommissionFileActions.createCommissionFileFailure({ error: error })))
				)
			)
		)
	);

	private static mapCommissionFile(commissionFile: CommissionFile): CommissionFile {
		const {
			id,
			status,
			productItem,
			amount,
			loanAmount,
			paidAmount,
			outstandingAmount,
			externalReference,
			borrowerReference,
			incomingDate,
			broker,
			creditProvider,
		} = commissionFile;
		return {
			id,
			status,
			productItem,
			amount,
			loanAmount,
			paidAmount,
			outstandingAmount,
			externalReference,
			borrowerReference,
			incomingDate,
			broker,
			creditProvider,
		};
	}

	constructor(private actions$: Actions, @Inject(COMMISSION_SERVICE) private commissionService: ICommissionService) {}
}
