import { of } from 'rxjs';
import { InputField } from '../../../models/input-types.model';
import { InputBase } from '../../../models/input-base.model';

export default function (): InputBase<any>[] {
  return [
    new InputField({
      key: `villageCsokCashLoan`,
      label: `ç.calculator.question.villageCsokCashLoan.label`,
      helpText: of(`ç.calculator.question.villageCsokCashLoan.helpText`),
      disabled: true,
      type: 'number',
      currency: true,
      validators: [],
      required: false,
      class: 'span12',
    }),
  ];
}
