import { faCalculator } from '@fortawesome/pro-light-svg-icons';
import { FinancialPlanConfig, FinancialPlanItem, PartialNormalizedResource, Simulator } from '@oper-client/shared/data-model';
import { of } from 'rxjs';
import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData: Partial<Simulator.Simulation>,
	resources: PartialNormalizedResource | null = null,
	calculatorFieldClicked: (formField: FinancialPlanItem) => void = () => {},
	readonlyMode = false,
	financialPlanConfig: FinancialPlanConfig | null = null
): InputBase<any>[] {
	if (!financialPlanConfig) {
		return [];
	}
	const items: FinancialPlanItem[] = [];

	if (!formData.financialPlanLoanAmountItems || formData.financialPlanLoanAmountItems.length === 0) {
		financialPlanConfig.financialPlanLoanAmountFields
			.filter((fieldConfig) => fieldConfig.isOverridable)
			.map((fieldConfig) => ({ field: { ...fieldConfig, type: fieldConfig.loanAmountType }, amount: 0, isOverridden: false }))
			.forEach((item) => items.push(item));
	} else {
		formData.financialPlanLoanAmountItems
			.filter((item) => item.financialPlanLoanAmountField.isOverridable)
			.map((item) => ({
				...item,
				field: { ...item.financialPlanLoanAmountField, type: item.financialPlanLoanAmountField.loanAmountType },
			}))
			.forEach((item) => items.push(item));
	}

	if (!formData.financialPlanOwnFundItems || formData.financialPlanOwnFundItems.length === 0) {
		financialPlanConfig.financialPlanOwnFundFields
			.filter((field) => field.isOverridable)
			.map((fieldConfig) => ({ field: { ...fieldConfig, type: fieldConfig.ownFundType }, amount: 0, isOverridden: false }))
			.forEach((item) => items.push(item));
	} else {
		formData.financialPlanOwnFundItems
			.filter((item) => item.financialPlanOwnFundField.isOverridable)
			.map((item) => ({ ...item, field: { ...item.financialPlanOwnFundField, type: item.financialPlanOwnFundField.ownFundType } }))
			.forEach((item) => items.push(item));
	}

	return items.map((item) => getFinancialPlanInputField(item, readonlyMode, calculatorFieldClicked)).sort((a, b) => a.order - b.order);
}

function getFinancialPlanInputField(
	item: FinancialPlanItem,
	readonlyMode: boolean,
	calculatorFieldClicked: (formField: FinancialPlanItem) => void = () => {}
): InputField {
	const defaultSettings: Partial<InputField> = {
		key: item.field.type.definition,
		label: `ç.question.${item.field.type.definition}.label`,
		helpText: !(item.field.isMain && item.field.isOverridable) ? of(`ç.question.${item.field.type.definition}.helpText`) : undefined,
		value: item.amount ?? 0,
		type: 'number',
		currency: true,
		required: false,
		readonly: readonlyMode,
		class: item.field.isMain && item.field.isOverridable ? 'span6' : 'span12',
		order: item.field.isMain && item.field.isOverridable ? 1 : item.field.type.order,
	};

	return new InputField({
		...defaultSettings,
		...(!!item.field.externalCalculatorType && !readonlyMode
			? {
					additionalFlagIcon: faCalculator,
					additionalFlagValue: of(true),
					additionalFlagClickable: of(true),
					onAdditionalFlagClicked: () => calculatorFieldClicked(item),
			  }
			: {}),
	});
}
