import { Validators } from '@angular/forms';
import { of } from 'rxjs';
import { ResourceType, PartialNormalizedResource, Realty, Client } from '@oper-client/shared/data-model';
import { InputField, InputSelect } from '../../../models/input-types.model';
import { InputBase } from '../../../models/input-base.model';
import { purposes, types } from '../../../validators/csok-cash.validator';

export default function(formData?: {realty: Realty, client: Client}, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'client.dependentChildren',
			label: 'ç.calculator.question.dependentChildren.label',
			helpText: of('ç.calculator.question.dependentChildren.helpText'),
			value: formData?.client?.dependentChildren,
			type: 'number',
			required: true,
			validators: [Validators.min(1), Validators.pattern('^[0-9]*$')],
		}),
		new InputSelect({
			key: 'realty.purposes[0].id',
			label: 'ç.calculator.question.purposes.label',
			helpText: of('ç.calculator.question.purposes.helpText'),
			value: formData?.realty?.purposes?.[0]?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_PURPOSE].filter(purpose => purposes.includes(purpose.definition) || purpose.id === formData?.realty?.purposes?.[0]?.id),
		}),
		new InputSelect({
			key: 'realty.realtyType.id',
			label: 'ç.calculator.question.realtyType.label',
			helpText: of('ç.calculator.question.realtyType.helpText'),
			value: formData?.realty?.realtyType?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_TYPE].filter(type => types.includes(type.definition) || type.id === formData?.realty?.realtyType?.id)
		}),
		new InputField({
			key: 'realty.surface',
			label: 'ç.calculator.question.surface.label',
			helpText: of('ç.calculator.question.surface.helpText'),
			value: formData?.realty?.surface,
			type: 'number',
			required: true,
			validators: [Validators.min(40)],
		})
	];
}
