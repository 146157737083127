export enum UiColor {
	DEFAULT = 'default',

	// Priority colors
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TERTIARY = 'tertiary',

	// Semantiv colors
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	DANGER = 'danger',
}
