import { of } from 'rxjs';

import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'liabilities',
			label: formData?.yearlyPeriodicity
				? 'ç.question.yearlyLiabilitiesExistingCredits.label'
				: 'ç.question.monthlyLiabilitiesExistingCredits.label',
			helpText: of(
				formData?.yearlyPeriodicity
					? 'ç.question.yearlyLiabilitiesExistingCredits.helpText'
					: 'ç.question.monthlyLiabilitiesExistingCredits.helpText'
			),
			value: typeof formData?.liabilities !== 'number' ? null : formData?.liabilities,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
		}),
	];
}
