import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraggableDirective } from './directives/draggable/draggable.directive';
import { DroppableDirective } from './directives/droppable/droppable.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [DraggableDirective, DroppableDirective],
	exports: [DraggableDirective, DroppableDirective],
})
export class DragDropUiModule {}
