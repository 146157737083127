import { HttpErrorResponse } from '@angular/common/http';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ActionState, initialActionState, ActionTypes, setActionState, Broker } from '@oper-client/shared/data-model';

import * as BrokerActions from './broker.actions';

export const BROKER_FEATURE_KEY = 'brokers';

export interface BrokerState extends EntityState<Broker> {
	currentBrokerId?: number;
	actions: BrokerActionsState;
}

export type BrokerActionTypes = 'loadBrokers' | 'loadBroker' | 'updateBroker' | 'transferPortfolio';

export type BrokerActionsState = Record<BrokerActionTypes, ActionState>;

export interface BrokerPartialState {
	readonly [BROKER_FEATURE_KEY]: BrokerState;
}

export const adapter: EntityAdapter<Broker> = createEntityAdapter<Broker>();

export const initialState: BrokerState = adapter.getInitialState({
	actions: {
		loadBrokers: initialActionState,
		loadBroker: initialActionState,
		updateBroker: initialActionState,
		transferPortfolio: initialActionState,
	},
});

function setActionStates(
	actionState: BrokerActionsState,
	action: BrokerActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): BrokerActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const brokerReducer = createReducer(
	initialState,

	// Set current Broker Id
	on(BrokerActions.setCurrentBrokerId, (state, { brokerId }) => ({
		...state,
		currentBrokerId: brokerId,
	})),

	// Reset current Broker Id
	on(BrokerActions.resetCurrentBrokerId, (state) => ({
		...state,
		currentBrokerId: undefined,
	})),

	// Clear Brokers
	on(BrokerActions.clearBrokers, (state) => adapter.removeAll(state)),

	// Load Brokers
	on(BrokerActions.loadBrokers, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadBrokers', ActionTypes.loading),
	})),
	on(BrokerActions.loadBrokersSuccess, (state, { brokers }) =>
		adapter.setAll(brokers, {
			...state,
			actions: setActionStates(state.actions, 'loadBrokers', ActionTypes.success),
		})
	),
	on(BrokerActions.loadBrokersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadBrokers', ActionTypes.failure, error),
	})),

	// Load Broker
	on(BrokerActions.loadBroker, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadBroker', ActionTypes.loading),
	})),
	on(BrokerActions.loadBrokerSuccess, (state, { broker }) =>
		adapter.upsertOne(broker, {
			...state,
			actions: setActionStates(state.actions, 'loadBroker', ActionTypes.success),
		})
	),
	on(BrokerActions.loadBrokerFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadBroker', ActionTypes.failure, error),
	})),

	// Update Broker
	on(BrokerActions.updateBroker, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateBroker', ActionTypes.loading),
	})),
	on(BrokerActions.updateBrokerSuccess, (state, { broker }) =>
		adapter.updateOne(broker, {
			...state,
			actions: setActionStates(state.actions, 'updateBroker', ActionTypes.success),
		})
	),
	on(BrokerActions.updateBrokerFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateBroker', ActionTypes.failure, error),
	})),

	// Transfer Portfolio
	on(BrokerActions.transferPortfolio, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'transferPortfolio', ActionTypes.loading),
	})),
	on(BrokerActions.transferPortfolioSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'transferPortfolio', ActionTypes.success),
	})),
	on(BrokerActions.transferPortfolioFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'transferPortfolio', ActionTypes.failure, error),
	})),
	on(BrokerActions.clearBrokers, (state) => adapter.removeAll({ ...state, ...initialState }))
);

export function reducer(state: BrokerState | undefined, action: Action) {
	return brokerReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
