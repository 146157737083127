import { ResourceType, PartialNormalizedResource, Address } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: Address, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'street',
			label: 'ç.question.street.label',
			value: formData?.street || null,
			type: 'text',
			required: true,
			class: 'span8',
		}),
		new InputField({
			key: 'houseNumber',
			label: 'ç.question.number.label',
			value: formData?.houseNumber || null,
			type: 'text',
			required: true,
			class: 'span4',
		}),
		new InputField({
			key: 'zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.zipCode || null,
			type: 'text',
			required: true,
			class: 'span4',
		}),
		new InputField({
			key: 'city',
			label: 'ç.question.city.label',
			value: formData?.city || null,
			type: 'text',
			required: true,
			class: 'span8',
		}),
		new InputSelect({
			key: 'country.id',
			label: 'ç.question.country.label',
			value: formData?.country?.id,
			required: true,
			options: resources?.[ResourceType.COUNTRY],
		}),
	];
}
