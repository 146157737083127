import { Injectable } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState } from '@oper-client/shared/data-model';
import { ThirdPartyPartialState } from '../third-party.reducer';
import * as BrokerActions from './broker.actions';
import * as BrokerSelectors from './broker.selectors';
import { BrokerActionTypes } from './broker.reducer';

@Injectable()
export class BrokerFacade {
	brokers$ = this.store.pipe(select(BrokerSelectors.getBrokers));
	currentBrokerId$ = this.store.pipe(select(BrokerSelectors.getCurrentBrokerId));
	currentBroker$ = this.store.pipe(select(BrokerSelectors.getCurrentBroker));
	loadBrokerActionState$ = this.selectActionState('loadBrokers');
	updateBrokerActionState$ = this.selectActionState('updateBroker');
	transferPortfolioActionState$ = this.selectActionState('transferPortfolio');

	constructor(private store: Store<ThirdPartyPartialState>) {}

	loadBrokers(): void {
		this.store.dispatch(BrokerActions.loadBrokers());
	}

	clearBrokers() {
		this.store.dispatch(BrokerActions.clearBrokers());
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: BrokerActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(BrokerSelectors.getActionState(actionType)), map(parseActionState));
	}

	public setCurrentBrokerId(brokerId: number | null) {
		if (brokerId === null) {
			return this.dispatch(BrokerActions.resetCurrentBrokerId());
		}
		return this.dispatch(BrokerActions.setCurrentBrokerId({ brokerId: brokerId }));
	}
}
