import { subtract } from '@oper-client/shared/util-array';
import { Offer, Product, ProductItem } from '../models/offer.model';
import { UniqueProduct } from '../models/product-extended.model';
import { SeverityEnum } from '../models/severity.model';

export function findUniqueProductIndex(list: UniqueProduct[], productMatch: UniqueProduct): number {
	return list.findIndex(
		(p) =>
			p.id === productMatch.id &&
			p.creditProvider.id === productMatch.creditProvider.id &&
			p.variability.id === productMatch.variability.id &&
			p.duration === productMatch.duration &&
			p.loanType.id === productMatch.loanType.id &&
			p.funder?.id === productMatch.funder?.id
	);
}

export function filterUniqueProducts(list: Product[], productMatch: UniqueProduct): Product[] {
	return list.filter(
		(p) =>
			p.id === productMatch.id &&
			p.creditProvider.id === productMatch.creditProvider.id &&
			p.variability.id === productMatch.variability.id &&
			p.duration === productMatch.duration &&
			p.loanType.id === productMatch.loanType.id &&
			p.funder?.id === productMatch.funder?.id
	);
}

export function getProductsFromOffers(offers: Pick<Offer, 'items'>[]): Product[] {
	const products: Product[] = [];

	offers.forEach((offer) => {
		products.push(
			...offer.items.map(
				(item) =>
					({
						...item.product,
						discounts: item.discounts,
						variability: item.variability,
						duration: item.duration,
						rate: item.interestRate,
						baseRate: item.baseRate,
						scoringRate: item.scoringRate,
						discountsRate: item.discountsRate,
						aprc: item.aprc,
						monthlyAmount: item.amortization,
						amount: item.amount,
						productVersionId: item.product.id,
						interestRate: item.interestRate,
						isFallbackRate: item.isFallbackRate,
						interestRateOverridden: item.interestRateOverridden,
						isAmountOverridden: item.isAmountOverridden,
					}) as Product
			)
		);
	});

	return products;
}

export function mapDtoToOffer(offer: Partial<Offer>): Offer {
	const {
		id,
		ltv,
		ltvValue,
		dti,
		surplus,
		items,
		isActive,
		isCreatedInSelfService,
		visualizeInSelfService,
		severity,
		success,
		totalPaybackAmount,
		discounts,
		isUsedForSimulation,
		loanRequestGenerated,
		totalCapitalAndInterest,
		isDefaultDiscountsOverridden,
	} = offer;

	const mappedOffer: Offer = {
		id,
		ltv,
		ltvValue,
		dti,
		surplus,
		items,
		isActive,
		isCreatedInSelfService,
		visualizeInSelfService,
		severity,
		success,
		totalPaybackAmount,
		discounts,
		isUsedForSimulation,
		loanRequestGenerated,
		hasBlockingError: calculateHasBlockingError(offer),
		hasVariabilityMisconfiguration: checkVariabilityMisconfigurations(offer),
		hasProductItems: offer.items.length > 0,
		totalAmount: offer.totalAmount ?? calculateTotalAmount(offer.items),
		totalMonthlyAmortization: offer.totalMonthlyAmortization ?? calculateTotalMonthlyAmortization(offer.items),
		baseProductIds: calculateBaseProductIds(offer.items),
		numberOfDiscountsApplied: calculateNumberOfDiscountsApplied(offer.items),
		totalCapitalAndInterest,
		isDefaultDiscountsOverridden: isDefaultDiscountsOverridden ?? false,
	};

	return {
		...mappedOffer,
		shouldFetchAcceptanceRules: shouldFetchAcceptanceRules(mappedOffer),
		canActivate: calculateCanActivate(mappedOffer),
	};
}

export function mapOfferToDto(offer: Partial<Offer>): Partial<Offer> {
	const {
		id,
		ltv,
		dti,
		surplus,
		items,
		isActive,
		isCreatedInSelfService,
		visualizeInSelfService,
		success,
		severity,
		totalPaybackAmount,
		discounts,
		isUsedForSimulation,
		totalCapitalAndInterest,
		isDefaultDiscountsOverridden,
	} = offer;

	return {
		id,
		ltv,
		dti,
		surplus,
		items,
		isActive,
		isCreatedInSelfService,
		visualizeInSelfService,
		success,
		severity,
		totalPaybackAmount,
		discounts,
		isUsedForSimulation,
		totalCapitalAndInterest,
		isDefaultDiscountsOverridden,
	};
}

function calculateTotalAmount(productItems: Partial<ProductItem>[]): number {
	return productItems
		.filter((item) => item.product.loanType.definition !== 'subsidy')
		.reduce<number>((acc, item) => acc + item.amount, 0);
}

function calculateTotalMonthlyAmortization(productItems: Partial<ProductItem>[]): number {
	return productItems
		.filter((item) => item.product.loanType.definition !== 'subsidy')
		.reduce((acc: number, item: ProductItem) => acc + item.amortization, 0);
}

function calculateBaseProductIds(productItems: Partial<ProductItem>[]): number[] {
	return productItems.reduce<number[]>((acc, item) => [...acc, item.product.baseProductId], []);
}

function calculateNumberOfDiscountsApplied(productItems: Partial<ProductItem>[]): number {
	return productItems.reduce<number>((acc, item) => acc + item.discounts.length, 0);
}

function calculateHasBlockingError(offer: Partial<Offer>): boolean {
	return !offer.success && offer.severity && offer.severity.definition === SeverityEnum.ERROR;
}

function checkVariabilityMisconfigurations(offer: Partial<Offer>): boolean {
	return offer.items.some(
		(item) => item.product.allowedVariabilities.findIndex((allowedVariability) => allowedVariability.id === item.variability.id) === -1
	);
}

/**
 *
 * @param {number} amountMismatch
 * @returns {boolean}
 *
 * Check if there is an amount mismatch
 * (there is if amount mismatch is greater than zero)
 *
 */
export function hasAmountMismatch(amountMismatch: number): boolean {
	if (typeof amountMismatch !== 'number') {
		throw new Error('amountMismatch is not a number.');
	}

	return Number(amountMismatch.toFixed(2)) !== 0;
}

/**
 * @param {number} loanAmount
 * @param {number} offerTotalAmount
 * @returns {number}
 *
 * Amount mismatch is the difference between the sum of the product item amounts
 * and the loan amount on the loan request
 *
 */
export function calculateAmountMismatch(loanAmount: number, offerTotalAmount: number): number {
	const loanAmountFixed = Number(loanAmount?.toFixed(2));
	const offerTotalAmountFixed = Number(offerTotalAmount.toFixed(2));
	const amountMismatchFixed = Number(subtract(offerTotalAmountFixed, loanAmountFixed).toFixed(2));
	return amountMismatchFixed;
}

/**
 *
 * @param offer
 * @returns boolean
 *
 * Identify if frontend should fetch acceptance rules
 *
 * Cases when acceptance rules should NOT fetch:
 * - Offer doesn't have product items
 * - Offer is active
 * - Offer has variability misconfiguration
 */
function shouldFetchAcceptanceRules(offer: Offer): boolean {
	if (offer.items.length === 0) {
		return false;
	}

	if (offer.isActive) {
		return false;
	}

	if (offer.hasVariabilityMisconfiguration) {
		return false;
	}

	return true;
}

/**
 *
 * @param offer
 * @param offers
 * @returns boolean
 *
 * Identify if offer can be activated
 *
 * Cases when offer can not be activated:
 * - Offer doesn't have product items
 * - Offer has blocking errors
 * - Other offer is already activated
 */
function calculateCanActivate(offer: Offer): boolean {
	if (offer.items.length === 0) {
		return false;
	}

	if (offer.hasBlockingError) {
		return false;
	}

	return true;
}
