import { PartialNormalizedResource, Simulator } from '@oper-client/shared/data-model';
import { SelectOption } from '@oper-client/shared/util-data-model-transform';
import { InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	analystOptions: SelectOption[] = [],
	brokerOptions: SelectOption[] = []
): InputBase<any>[] {
	const questions = [
		new InputSelect({
			key: 'broker.id',
			label: 'ç.feature.loanRequest.broker',
			value: formData?.broker?.id,
			options: brokerOptions,
			required: true,
			class: 'span6',
		}),
		new InputSelect({
			key: 'analyst.id',
			label: 'ç.feature.loanRequest.analyst',
			value: formData?.analyst?.id ?? formData?.createdBy?.id,
			options: analystOptions,
			required: true,
			class: 'span6',
		}),
	];

	return questions;
}
