import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, LoanRequestSubentityService, IApiService } from '@oper-client/shared/data-access';
import { Client, FinancialAsset, Resource } from '@oper-client/shared/data-model';
import { map } from 'rxjs/operators';

@Injectable()
export class FinancialAssetService implements LoanRequestSubentityService<FinancialAsset> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	getAll(loanRequestId: number, clientId: number, params: HttpParams = new HttpParams()): Observable<FinancialAsset[]> {
		return this.apiService
			.get(`/api/loan-requests/${loanRequestId}/clients/${clientId}/assets/`, params)
			.pipe(map(financialAssets => this.convertFinancialAssetsFromDto(financialAssets, loanRequestId, clientId)));
	}

	getAllByLoanRequestId(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<FinancialAsset[]> {
		return this.apiService
			.get(`/api/loan-requests/${loanRequestId}/assets/`, params)
			.pipe(map(financialAssets => this.convertFinancialAssetsFromDto(financialAssets, loanRequestId)));
	}

	get(loanRequestId: number, clientId: number, id: number, params: HttpParams = new HttpParams()): Observable<FinancialAsset> {
		return this.apiService
			.get(`/api/loan-requests/${loanRequestId}/clients/${clientId}/assets/${id}/`, params)
			.pipe(map(financialAsset => this.convertFinancialAssetFromDto(financialAsset, loanRequestId, clientId)));
	}

	create(
		loanRequestId: number,
		clientId: number,
		financialAsset: Partial<FinancialAsset>,
		params: HttpParams = new HttpParams()
	): Observable<FinancialAsset> {
		return this.apiService
			.post(
				`/api/loan-requests/${loanRequestId}/clients/${clientId}/assets/`,
				this.convertFinancialAssetToDto(financialAsset),
				params
			)
			.pipe(map(createdFinancialAsset => this.convertFinancialAssetFromDto(createdFinancialAsset, loanRequestId, clientId)));
	}

	update(
		loanRequestId: number,
		clientId: number,
		financialAssetId: number,
		financialAsset: Partial<FinancialAsset>,
		params: HttpParams = new HttpParams()
	): Observable<FinancialAsset> {
		return this.apiService
			.patch(
				`/api/loan-requests/${loanRequestId}/clients/${clientId}/assets/${financialAssetId}/`,
				this.convertFinancialAssetToDto(financialAsset),
				params
			)
			.pipe(map(updatedFinancialAsset => this.convertFinancialAssetFromDto(updatedFinancialAsset, loanRequestId, clientId)));
	}

	delete(loanRequestId: number, clientId: number, financialAssetId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/clients/${clientId}/assets/${financialAssetId}/`);
	}

	private convertFinancialAssetFromDto(financialAsset: FinancialAssetDto, loanRequestId: number, clientId?: number): FinancialAsset {
		return {
			...financialAsset,
			loanRequest: { id: loanRequestId },
			...(!financialAsset.client ? { client: { id: clientId } } : {}),
			isPastWithdrawal: !!financialAsset.withdrawalYear,
		};
	}

	private convertFinancialAssetsFromDto(
		financialAssets: FinancialAssetDto[],
		loanRequestId: number,
		clientId?: number
	): FinancialAsset[] {
		return financialAssets.map(financialAsset => this.convertFinancialAssetFromDto(financialAsset, loanRequestId, clientId));
	}

	private convertFinancialAssetToDto(financialAsset: Partial<FinancialAsset>): FinancialAssetDto {
		const { id, amount, assetType, usage, withdrawalYear } = financialAsset;
		return { id, amount, assetType, usage, withdrawalYear };
	}
}

interface FinancialAssetDto {
	id: number;
	amount: number;
	assetType: Resource;
	usage: Resource;
	withdrawalYear: number;
	client?: Partial<Client>;
}
