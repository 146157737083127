import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function trimmedMinLengthValidator(minLength: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value == null || typeof control.value.length !== 'number' || control.value.length === 0) {
			return null;
		}

		return control.value.replace(/\s+/g, '').length < minLength
			? { minlength: { requiredLength: minLength, actualLength: control.value.length } }
			: null;
	};
}
