import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Document } from '@oper-client/shared/data-model';
import * as CommissionStatementSelectors from './commission-statement.selectors';
import * as CommissionStatementActions from './commission-statement.actions';

@Injectable()
export class CommissionStatementDocumentFacade {
	commissionStatementDocuments$: Observable<Document[]> = this.store.pipe(
		select(CommissionStatementSelectors.getCommissionStatementDocuments)
	);

	constructor(private store: Store<any>) {}

	loadCommissionStatementDocuments(brokerId: number) {
		this.store.dispatch(CommissionStatementActions.loadCommissionStatementDocuments({ brokerId: brokerId }));
	}
}
