import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { API_SERVICE, IApiService, LoanRequestEntityService } from '@oper-client/shared/data-access';
import { DataConnection } from '@oper-client/shared/data-model';

@Injectable()
export class DataConnectionService implements LoanRequestEntityService<DataConnection> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	public getAll(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/data-connections/`, params);
	}

	public create(
		loanRequestId: number,
		dataConnection: DataConnection,
		params: HttpParams = new HttpParams()
	): Observable<DataConnection> {
		return !dataConnection.proofObjectsIds
			? this.apiService.post(`/api/loan-requests/${loanRequestId}/data-push/`, dataConnection, params)
			: this.apiService.post(`/api/loan-requests/${loanRequestId}/documents-push/`, dataConnection, params);
	}
}
