import { AbstractControl, ValidatorFn } from '@angular/forms';

export function lambdaValidator(condition: (controlValue) => boolean, validationMessageKey: string): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		if (condition(control.value)) {
			return null;
		}
		return { [validationMessageKey]: control.value };
	};
}
