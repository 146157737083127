import { Realty } from '@oper-client/shared/data-model';

import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { Validators } from '@angular/forms';

export default function (formData?: Partial<Realty>): InputBase<any>[] {
	return [
		new InputField({
			key: 'landRegister',
			label: 'ç.question.landRegister.label',
			value: formData?.landRegister,
			type: 'text',
			required: false,
			class: 'span3',
			order: 1,
		}),
		new InputField({
			key: 'districtCourt',
			label: 'ç.question.districtCourt.label',
			value: formData?.districtCourt,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'plotNumber',
			label: 'ç.question.plotNumber.label',
			value: formData?.plotNumber,
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
		}),
		new InputField({
			key: 'propertyNumber',
			label: 'ç.question.propertyNumber.label',
			value: formData?.propertyNumber,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'cadastralLocation',
			label: 'ç.question.cadastralLocation.label',
			value: formData?.cadastralLocation,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'surface',
			label: 'ç.question.surface.label',
			value: formData?.surface,
			type: 'number',
			required: false,
			class: 'span3',
			digitsInfo: '1.2-4',
		}),
		new InputField({
			key: 'rooms',
			label: 'ç.question.rooms.label',
			value: formData?.rooms,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcBeforeRenovations.label',
			value: formData?.epcBeforeRenovations,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcAfterRenovations.label',
			value: formData?.epcAfterRenovations,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcContractNumber.label',
			value: formData?.epcContractNumber,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: false,
			validators: [],
			class: 'span3',
		}),
	];
}
