import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { snakeCase } from 'change-case';

export const convertObjectToHttpParams = (params: { [key: string]: string | boolean | number }): HttpParams => {
	return Object.keys(params).reduce(
		(acc, key) => acc.set(snakeCase(key), params[key]),
		new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() })
	);
};

export const camelCaseToDashed = (key: string): string => {
	return key.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase());
};

/**
 * CustomHttpUrlEncodingCodec
 * Fix plus sign (+) not encoding, so sent as blank space
 * See: https://github.com/angular/angular/issues/11058#issuecomment-247367318
 */
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
	encodeKey(k: string): string {
		k = super.encodeKey(k);
		return k.replace(/\+/gi, '%2B');
	}
	encodeValue(v: string): string {
		v = super.encodeValue(v);
		return v.replace(/\+/gi, '%2B');
	}
}
