import { Resource } from '../models/resource.model';

export function sortResourcesByOrder(r1: Resource, r2: Resource) {
	const doc1Order = r1?.order;
	const doc2Order = r2?.order;
	if (doc1Order !== doc2Order) {
		return (doc1Order === null ? Number.MAX_VALUE : doc1Order) - (doc2Order === null ? Number.MAX_VALUE : doc2Order);
	}
	return 0;
}
