import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Notification } from '../interfaces/notification';
import { generateGuid } from '@oper-client/shared/util-object';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	public notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);

	add(notificationText: string, notificationType: string) {
		this.notifications.next([
			...this.notifications.value,
			...([
				{
					id: generateGuid(),
					text: notificationText,
					type: notificationType,
				},
			] as Notification[]),
		]);
	}

	addInfo(notificationText: string) {
		this.add(notificationText, null);
	}

	addSuccess(notificationText: string) {
		this.add(notificationText, 'success');
	}

	addWarn(notificationText: string) {
		this.add(notificationText, 'warning');
	}

	addError(notificationText: string) {
		this.add(notificationText, 'error');
	}

	remove(notificationId: string) {
		this.notifications.next(this.notifications.value.filter((notification) => notification.id !== notificationId));
	}
}
