import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleContentDirective } from './directives/collapsible-content.directive';
import { CollapsibleHeaderDirective } from './directives/collapsible-header.directive';
import { CollapsibleComponent } from './collapsible.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	declarations: [CollapsibleContentDirective, CollapsibleHeaderDirective, CollapsibleComponent],
	imports: [CommonModule, FontAwesomeModule],
	exports: [CollapsibleContentDirective, CollapsibleHeaderDirective, CollapsibleComponent],
})
export class CollapsibleModule {}
