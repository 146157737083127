import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';

import { OperOverlayRef } from '../overlay-ref';
import { OverlayComponent } from '../components/overlay/overlay.component';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class OverlayService {
	private readonly _modalIsOpen$ = new BehaviorSubject<boolean>(false);
	readonly isModalClosed$ = this._modalIsOpen$.pipe(map((result) => result === false));
	readonly isModalOpen$ = this._modalIsOpen$.pipe(map((result) => result === true));
	protected readonly _overlayReferences: Set<OperOverlayRef> = new Set<OperOverlayRef>();

	get overlayReferences(): Set<OperOverlayRef> {
		return this._overlayReferences;
	}

	constructor(
		private overlay: Overlay,
		private injector: Injector
	) {}

	open<R = any, T = any>(
		component: ComponentType<any>,
		config: OverlayConfig,
		content: string | TemplateRef<any> | Type<any>,
		data: T,
		wrapperClasses?: string[],
		overlayClasses?: string[]
	): OperOverlayRef<R> {
		const overlayRef = this.overlay.create(config);

		const myOverlayRef = new OperOverlayRef<R, T>(overlayRef, content, data, wrapperClasses, overlayClasses);

		const injector = this.createInjector(myOverlayRef, this.injector);
		overlayRef.attach(new ComponentPortal(component, null, injector));
		this._overlayReferences.add(myOverlayRef);
		this._modalIsOpen$.next(this.overlayReferences.size > 0);
		return myOverlayRef;
	}

	openModal<R = any, T = any>(
		content: string | TemplateRef<any> | Type<any>,
		data: T,
		wrapperClasses?: string[],
		overlayClasses?: string[]
	): OperOverlayRef<R> {
		const config = this.getModalOverlayConfig();
		return this.open(OverlayComponent, config, content, data, wrapperClasses, overlayClasses);
	}

	close(overlayReference: OperOverlayRef) {
		if (this.overlayReferences.has(overlayReference)) {
			this._overlayReferences.delete(overlayReference);
			this._modalIsOpen$.next(this.overlayReferences.size > 0);
		}
		overlayReference.close();
	}

	closeAll() {
		this._overlayReferences.forEach((overlayReference) => this.close(overlayReference));
	}

	createInjector(ref: OperOverlayRef, inj: Injector) {
		const injectorTokens = new WeakMap([[OperOverlayRef, ref]]);
		return new PortalInjector(inj, injectorTokens);
	}

	getModalOverlayConfig() {
		return new OverlayConfig({
			hasBackdrop: true,
			panelClass: ['modal', 'is-active'],
			backdropClass: 'modal-background',
			/**
			 * Disable scrolling of the background.
			 * It is native CDK approach
			 * ("position fixed" was being added to <body> in the modal.component previously)
			 */
			scrollStrategy: this.overlay.scrollStrategies.block(),
		});
	}
}
