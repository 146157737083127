import { Realty } from '@oper-client/shared/data-model';

import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData?: Partial<Realty>): InputBase<any>[] {
	return [
		new InputField({
			key: 'landRegister',
			label: 'ç.question.landRegister.label',
			value: formData?.landRegister,
			type: 'text',
			required: false,
			class: 'span6',
			order: 1,
		}),
	];
}
