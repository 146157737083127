import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { Client } from '@oper-client/shared/data-model';
import { LoanRequestEntityService } from './loan-request-entity.service';

export const CLIENT_SERVICE = new InjectionToken<LoanRequestEntityService<Client>>('CLIENT_SERVICE');

export interface IClientService extends LoanRequestEntityService<Client> {
	invite(loanRequestId: number, clientId: number): Observable<void>;
}
