import { InjectionToken } from '@angular/core';

import { AiValidationConfig, Configuration, getCustomer } from '@oper-client/shared/configuration';

export const AI_VALIDATION_CONFIG = new InjectionToken<string>('ai-validation-config');

export function aiValidationConfigurationFactory(customerConfig: Configuration): AiValidationConfig {
	if (!customerConfig || !customerConfig?.applicationInsights) {
		throw new Error('Customer configuration was not provided');
	}

	const featureConfiguration = customerConfig.aiValidationConfig.current;
	if (featureConfiguration) {
		return featureConfiguration;
	} else {
		const customer = getCustomer(customerConfig.applicationInsights);
		console.warn(`Ai validation config was not found for '${customer}! Default configuration will be used instead.`);

		return customerConfig.aiValidationConfig.default;
	}
}
