import { ModelReference } from './model-reference.model';
import { Resource, ResourceParamValue } from './resource.model';
import { Severity, SeverityEnum } from './severity.model';

export type AcceptanceRuleParameterValue = boolean | string | number | ResourceParamValue;

export interface AcceptanceState {
	severity: Severity;
	success: boolean;
}

export interface AcceptanceRuleCriterion extends AcceptanceState {
	parameters: AcceptanceRuleParameter[];
	relatedTo?: ModelReference;
}

export interface AcceptanceRuleParameter extends AcceptanceState {
	name: string;
	type: Resource;
	actualValue: AcceptanceRuleParameterValue;
	configuredValue: AcceptanceRuleParameterValue;
}

export interface AcceptanceRule extends AcceptanceState {
	name: string;
	postCalculation: boolean;
	criteria?: AcceptanceRuleCriterion[];
}

export interface PreapprovalAcceptanceRule extends AcceptanceRule {
	severity: Severity;
	definition: string;
	success: boolean;
}

export type AcceptanceRuleState = Pick<AcceptanceRule, 'name' | 'severity' | 'success'>;

export function compareByFailedAcceptanceState<T extends AcceptanceState>(current: T, next: T) {
	// Sorting order:
	// 1. success: false && severity: ERROR
	// 2. success: false && severity: WARNING
	// 3. success: false && severity: INFO
	// 4: success: true
	return (
		(current.success
			? 1
			: current.severity.definition === SeverityEnum.WARNING
			? -2
			: current.severity.definition === SeverityEnum.ERROR
			? -3
			: -1) -
		(next.success
			? 1
			: next.severity.definition === SeverityEnum.WARNING
			? -2
			: next.severity.definition === SeverityEnum.ERROR
			? -3
			: -1)
	);
}

export function isResourceParamValue(value: any): value is ResourceParamValue {
	return value && typeof value === 'object' && 'definition' in value && 'resourceType' in value;
}
