import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Resource, Document, DocumentTypes, Proof, GenerateDocumentOptions } from '@oper-client/shared/data-model';

// Proof services
export const loadProof = createAction('[Document] Load Proof', props<{ loanRequestId: number }>());
export const loadProofSuccess = createAction('[Document] Load Proof Success', props<{ proof: Proof[] }>());
export const loadProofFailure = createAction('[Document] Load Proof Failure', props<{ error: any }>());

export const createProofDocument = createAction(
	'[Document] Create Proof Document',
	props<{ loanRequestId: number; categoryId: number; document: Partial<Document> }>()
);
export const createProofDocumentSuccess = createAction(
	'[Document] Create Proof Document Success',
	props<{ categoryId: number; document: Document }>()
);
export const createProofDocumentFailure = createAction('[Document] Create Proof Document Failure', props<{ error: HttpErrorResponse }>());

// Upload file
export const uploadFile = createAction(
	'[Document] Upload file',
	props<{ loanRequestId: number; categoryId: number; documentId: number; file: File }>()
);
export const uploadFileProgress = createAction(
	'[Document] Upload file progress',
	props<{ loanRequestId: number; documentId: number; file: File; loaded: number; total: number }>()
);
export const uploadFileSuccess = createAction(
	'[Document] Upload File Success',
	props<{ categoryId: number; document: Update<Document> }>()
);
export const uploadFileFailure = createAction(
	'[Document] Upload File Failure',
	props<{ categoryId: number; documentId: number; error: HttpErrorResponse }>()
);

// Update
export const updateProofDocument = createAction(
	'[Document] Update Proof Document',
	props<{ loanRequestId: number; categoryId: number; document: Update<Document> }>()
);
export const updateProofDocumentSuccess = createAction(
	'[Document] Update Proof Document Success',
	props<{ categoryId: number; document: Update<Document> }>()
);
export const updateProofDocumentFailure = createAction('[Document] Update Proof Document Failure', props<{ error: HttpErrorResponse }>());

// Delete
export const deleteProofDocument = createAction(
	'[Document] Delete Proof Document',
	props<{ loanRequestId: number; categoryId: number; documentId: number }>()
);

export const deleteProofDocumentFile = createAction(
	'[Document] Delete Proof Document File',
	props<{ loanRequestId: number; categoryId: number; documentId: number }>()
);

export const deleteProofDocumentSuccess = createAction(
	'[Document] Delete Proof Document Success',
	props<{ categoryId: number; documentId: number }>()
);
export const deleteProofDocumentFailure = createAction('[Document] Delete Proof Document Failure', props<{ error: HttpErrorResponse }>());

// Allowed Documents
export const loadAllowedProofDocuments = createAction(
	'[Document] Load Allowed Proof Documents',
	props<{ kind: string; loanRequestId: number; categoryId: number }>()
);
export const loadAllowedProofDocumentsSuccess = createAction(
	'[Document] Load Allowed Proof Documents Success',
	props<{ allowedDocuments: Resource[] }>()
);
export const loadAllowedProofDocumentsFailure = createAction(
	'[Document] Load Allowed Proof Documents Failure',
	props<{ error: HttpErrorResponse }>()
);

// Upload signature requried document
export const uploadDocument = createAction(
	'[Document] Upload Signature Required Proof Document',
	props<{ loanRequestId: number; proofId: number; document: Partial<Document>; file: File; kind: DocumentTypes }>()
);
export const uploadDocumentSuccess = createAction(
	'[Document] Upload Signature Required Proof Document Success'
	// props<{ allowedDocuments: Resource[] }>()
);
export const uploadDocumentFailure = createAction(
	'[Document] Load Signature Required Proof Document Failure',
	props<{ error: HttpErrorResponse }>()
);

// Download Zipped Documents
export const downloadZippedProofDocuments = createAction(
	'[Document] Download Zipped Proof Documents',
	props<{ loanRequestId: number; language: string }>()
);
export const downloadZippedProofDocumentsSuccess = createAction(
	'[Document] Download Zipped Proof Documents Success',
	props<{ zipFiles: string }>()
);
export const downloadZippedProofDocumentsFailure = createAction(
	'[Document] Download Zipped Proof Documents Failure',
	props<{ error: HttpErrorResponse }>()
);

// Generate Document
export const generateDocument = createAction(
	'[Document] Generate Document',
	props<{
		loanRequestId: number;
		documentOptions: GenerateDocumentOptions;
		data?: any;
	}>()
);
export const generateDocumentSuccess = createAction('[Document] Generate Document Success', props<{ document: Document }>());
export const generateDocumentFailure = createAction('[Document] Generate Document Failure', props<{ error: HttpErrorResponse }>());

// Reset document state
export const resetState = createAction('[Document] Reset State Documents');
