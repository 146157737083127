import { of } from 'rxjs';

import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'cash.amount',
			label: 'ç.question.cash.label',
			helpText: of('ç.question.cash.helpText'),
			value: formData?.cash?.amount,
			type: 'number',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'other.amount',
			label: 'ç.question.otherAsset.label',
			helpText: of('ç.question.otherAsset.helpText'),
			value: formData?.other?.amount || null,
			type: 'number',
			currency: true,
			required: false,
		}),
	];
}
