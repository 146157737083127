import { Action, createReducer, on } from '@ngrx/store';
import * as InsuranceProviderActions from './insurance-provider.actions';
import { ActionState, ActionTypes, initialActionState, setActionState, InsuranceProvider } from '@oper-client/shared/data-model';
import { HttpErrorResponse } from '@angular/common/http';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const INSURANCE_PROVIDER_FEATURE_KEY = 'insuranceProviders';

export interface InsuranceProviderState extends EntityState<InsuranceProvider> {
	currentInsuranceProviderId?: number;
	actions: InsuranceProviderActionsState;
}

export type InsuranceProviderActionTypes = 'loadInsuranceProviders';

export type InsuranceProviderActionsState = Record<InsuranceProviderActionTypes, ActionState>;

export interface InsuranceProviderPartialState {
	readonly [INSURANCE_PROVIDER_FEATURE_KEY]: InsuranceProviderState;
}

export const adapter: EntityAdapter<InsuranceProvider> = createEntityAdapter<InsuranceProvider>();

export const initialState: InsuranceProviderState = adapter.getInitialState({
	actions: {
		loadInsuranceProviders: initialActionState,
	},
});

function setActionStates(
	actionState: InsuranceProviderActionsState,
	action: InsuranceProviderActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): InsuranceProviderActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const insuranceProviderReducer = createReducer(
	initialState,
	// Load InsuranceProviders
	on(InsuranceProviderActions.loadInsuranceProviders, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadInsuranceProviders', ActionTypes.loading),
	})),
	on(InsuranceProviderActions.loadInsuranceProvidersSuccess, (state, { insuranceProviders }) =>
		adapter.setAll(insuranceProviders, {
			...state,
			actions: setActionStates(state.actions, 'loadInsuranceProviders', ActionTypes.success),
		})
	),
	on(InsuranceProviderActions.loadInsuranceProvidersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadInsuranceProviders', ActionTypes.failure, error),
	}))
);

export function reducer(state: InsuranceProviderState | undefined, action: Action) {
	return insuranceProviderReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
