import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Breadcrumb } from '../interfaces/breadcrumb';

@Injectable()
export class BreadcrumbService {
	private readonly _breadcrumbs$: BehaviorSubject<Breadcrumb[]>;

	constructor(private readonly viewportScroller: ViewportScroller) {
		this._breadcrumbs$ = new BehaviorSubject([]);
	}

	get breadcrumbs$(): BehaviorSubject<Breadcrumb[]> {
		return this._breadcrumbs$;
	}

	get activeBreadcrumb$(): Observable<Breadcrumb> {
		return this._breadcrumbs$.pipe(
			map((breadcrumbs) => {
				return breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : undefined;
			})
		);
	}

	activateNew(key: string, name: string): void {
		const breadcrumbs = this._breadcrumbs$.value;
		if (breadcrumbs.length > 0) {
			const notUnique = breadcrumbs.findIndex((b) => b.key === key) > -1;
			if (notUnique) {
				return;
			}

			breadcrumbs[breadcrumbs.length - 1].active = false;
		}

		breadcrumbs.push({ name, active: true, key });
		this._breadcrumbs$.next(breadcrumbs);
		this.viewportScroller.scrollToPosition([0, 0]); // scrolling to top when new breadcrumb is activated
	}

	activateFirst(): void {
		const breadcrumbs = this._breadcrumbs$.value;
		if (breadcrumbs.length > 0) {
			const first = breadcrumbs[0];
			first.active = true;
			this._breadcrumbs$.next([breadcrumbs[0]]);
		}
	}

	removeFrom(startIndex: number): void {
		if (startIndex > -1) {
			const breadcrumbs = this.breadcrumbs$.value;
			breadcrumbs[startIndex].active = true;
			breadcrumbs.splice(startIndex + 1);
			this._breadcrumbs$.next(breadcrumbs);
		}
	}

	reset(): void {
		this._breadcrumbs$.next([]);
	}
}
