import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as CommissionActions from './commission.actions';
import {
	ActionState,
	setActionState,
	ActionTypes,
	Commission,
	CommissionStatement,
	initialActionState,
} from '@oper-client/shared/data-model';

export const COMMISSION_FEATURE_KEY = 'commission';

export interface CommissionPartialState {
	readonly [COMMISSION_FEATURE_KEY]: CommissionState;
}

export enum CommissionActionTypes {
	LOAD_COMMISSIONS = 'loadCommissions',
}

export type CommissionActionsState = Record<CommissionActionTypes, ActionState>;

export interface CommissionState extends EntityState<Commission> {
	error: HttpErrorResponse | undefined;
	commissionStatement: CommissionStatement;
	actions: CommissionActionsState;
}

export const commissionAdapter: EntityAdapter<Commission> = createEntityAdapter<Commission>();

export const commissionInitialState: CommissionState = commissionAdapter.getInitialState({
	error: null,
	commissionStatement: null,
	actions: {
		loadCommissions: initialActionState,
	},
});

function setActionStates(
	actionState: CommissionActionsState,
	action: CommissionActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): CommissionActionsState {
	return {
		...commissionInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	commissionInitialState,

	on(CommissionActions.clearCommissions, state =>
		commissionAdapter.removeAll({
			...state,
			actions: commissionInitialState.actions,
		})
	),

	on(CommissionActions.loadCommissions, state => ({
		...state,
		actions: setActionStates(state.actions, CommissionActionTypes.LOAD_COMMISSIONS, ActionTypes.loading),
	})),

	on(CommissionActions.loadCommissionsSuccess, (state, { commissions }) =>
		commissionAdapter.setAll(commissions, {
			...state,
			actions: setActionStates(state.actions, CommissionActionTypes.LOAD_COMMISSIONS, ActionTypes.success),
		})
	),

	on(CommissionActions.loadCommissionsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, CommissionActionTypes.LOAD_COMMISSIONS, ActionTypes.failure, error),
	})),

	on(CommissionActions.loadCommissionStatement, state => ({
		...state,
		actions: setActionStates(state.actions, CommissionActionTypes.LOAD_COMMISSIONS, ActionTypes.loading),
	})),

	on(CommissionActions.loadCommissionStatementSuccess, (state, { commissionStatement }) => ({
		...state,
		commissionStatement,
		actions: setActionStates(state.actions, CommissionActionTypes.LOAD_COMMISSIONS, ActionTypes.success),
	})),

	on(CommissionActions.loadCommissionStatementFailure, (state, { error }) => ({
		...state,
		commissionStatement: null,
		actions: setActionStates(state.actions, CommissionActionTypes.LOAD_COMMISSIONS, ActionTypes.failure, error),
	}))
);
