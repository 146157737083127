import { of } from 'rxjs';
import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'generalCreditExpense',
			label: 'ç.question.monthlyGeneralCreditExpense.label',
			helpText: of('ç.question.monthlyGeneralCreditExpense.helpText'),
			value: formData?.generalCreditExpense,
			type: 'number',
			currency: true,
			required: false,
		}),

		new InputField({
			key: 'rent',
			label: 'ç.question.rent.label',
			helpText: of('ç.question.rent.helpText'),
			value: formData?.rent,
			type: 'number',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'alimony',
			label: 'ç.question.alimony.label',
			helpText: of('ç.question.alimony.helpText'),
			value: formData?.alimony,
			type: 'number',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'additionalLiabilities',
			label: 'ç.question.monthlyAdditionalLiabilities.label',
			helpText: of('ç.question.monthlyAdditionalLiabilities.helpText'),
			value: formData?.additionalLiabilities,
			type: 'number',
			currency: true,
			required: false,
		}),
	];
}
