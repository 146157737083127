import { Pipe, PipeTransform } from '@angular/core';
import { AcceptanceRuleParameterValue, isResourceParamValue, Resource } from '@oper-client/shared/data-model';
import { EnvironmentCurrencyPipe } from './environment-currency.pipe';
import { PercentagePipe } from './percentage.pipe';
import { TranslateResourcePipe } from './translate-resource.pipe';
import { TranslatePipe } from '@ngx-translate/core';

const EMPTY_VALUE = '-';

@Pipe({
	name: 'acceptanceRuleParameterValue',
})
export class AcceptanceRuleParameterValuePipe implements PipeTransform {
	constructor(
		private readonly percentPipe: PercentagePipe,
		private readonly currencyPipe: EnvironmentCurrencyPipe,
		private readonly translateResourcePipe: TranslateResourcePipe,
		private readonly translatePipe: TranslatePipe
	) {}

	transform(value: AcceptanceRuleParameterValue, resource: Resource = { definition: 'number' }) {
		if (value === null) {
			return EMPTY_VALUE;
		}

		if (typeof value === 'number') {
			if (resource.definition === 'percentage') {
				return value === 0 ? '0%' : this.percentPipe.transform(value);
			}

			if (resource.definition === 'currency') {
				return this.currencyPipe.transform(value);
			}
		}

		if (typeof value === 'boolean') {
			return value ? this.translatePipe.transform('ç.misc.yes') : this.translatePipe.transform('ç.misc.no');
		}

		if (isResourceParamValue(value)) {
			if (typeof value.definition === 'string') {
				return this.translateResourcePipe.transform(value.definition, value.resourceType);
			} else if (Array.isArray(value.definition)) {
				if (value.definition.length > 1) {
					const listItems: string[] = [];
					value.definition
						.map((name) => this.translateResourcePipe.transform(name, value.resourceType))
						.forEach((item) => listItems.push(`<li>${item}</li>`));
					return `<ul>${listItems.join('')}</ul>`;
				} else if (value.definition.length === 1) {
					return value.definition.map((name) => this.translateResourcePipe.transform(name, value.resourceType)).join('');
				}
			}
			return EMPTY_VALUE;
		}

		if (Array.isArray(value)) {
			return value
				.map((item) => {
					const toTranslate = `ç.feature.offer.acceptanceRule.ruleParams.${item}`;
					const translated = this.translatePipe.transform(toTranslate);
					if (toTranslate === translated) return item;
					return translated;
				})
				.join(', ');
		}

		return value;
	}
}
