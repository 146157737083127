import { DynamicFormCard, InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { BorrowerSimulationDto, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';
import { FormConfiguration } from '../models/dynamic-form.model';
import { ValidatorService } from '../services/validator.service';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../services/validator-constants';

export default function (formData?: Partial<BorrowerSimulationDto>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MAX_AGE).getTime();
	const maxBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MIN_AGE).getTime();

	const additionalFormFields: InputBase<any>[] = [];

	if (formData?.coBorrowerIncomes?.length > 0) {
		const secondaryBorrowerFormConfiguration = new FormConfiguration();
		secondaryBorrowerFormConfiguration.setName('coBorrowerPersonalDetailsForm');

		secondaryBorrowerFormConfiguration.formControl.questions = [
			new InputField({
				key: 'birthDate',
				label: 'ç.question.birthDate.label',
				value: formData?.coBorrowerPersonalDetails?.birthDate,
				type: 'date',
				required: true,
				hideRequiredAsterisk: true,
				min: minBirthDate,
				max: maxBirthDate,
				validators: [
					ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE),
					ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST),
				],
			}),

			new InputField({
				key: 'numberOfDependents',
				label: 'ç.question.numberOfDependents.label',
				value: formData?.coBorrowerPersonalDetails?.numberOfDependents,
				type: 'number',
				required: true,
				hideRequiredAsterisk: true,
				validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			}),
		];

		additionalFormFields.push(
			new DynamicFormCard({
				title: 'ç.misc.coBorrower',
				key: 'coBorrowerPersonalDetails',
				formConfiguration: secondaryBorrowerFormConfiguration,
				showDeleteButton: false,
			})
		);
	}

	const mainBorrowerFormConfiguration = new FormConfiguration();
	mainBorrowerFormConfiguration.setName('mainBorrowerPersonalDetailsForm');

	mainBorrowerFormConfiguration.formControl.questions = [
		new InputField({
			key: 'birthDate',
			label: 'ç.question.birthDate.label',
			value: formData?.mainBorrowerPersonalDetails?.birthDate,
			type: 'date',
			required: true,
			hideRequiredAsterisk: true,
			min: minBirthDate,
			max: maxBirthDate,
			validators: [
				ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE),
				ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST),
			],
		}),

		new InputField({
			key: 'numberOfDependents',
			label: 'ç.question.numberOfDependents.label',
			value: formData?.mainBorrowerPersonalDetails?.numberOfDependents,
			type: 'number',
			required: true,
			hideRequiredAsterisk: true,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
		}),
	];

	return [
		new DynamicFormCard({
			title: 'ç.misc.mainBorrower',
			key: 'mainBorrowerPersonalDetails',
			formConfiguration: mainBorrowerFormConfiguration,
			showDeleteButton: false,
		}),

		...additionalFormFields,
	];
}
