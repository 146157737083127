import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { CORE_RESOURCES_VERSION } from '@oper-client/shared/configuration';
import { Resource, ResourcePath, ResourceType } from '@oper-client/shared/data-model';

@Injectable({
	providedIn: 'root',
})
export class ResourceService {
	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(CORE_RESOURCES_VERSION) private coreVersion: string
	) {}

	public getResourcesByType(kind: ResourceType): Observable<Resource[]> {
		return this.apiService.get(`/resources/${ResourcePath[kind] || kind}/?cache_version=${this.coreVersion}`).pipe(
			map((resources: Resource[]) => {
				return resources.map(resource => ({
					...resource,
					key: `ç.resource.${kind}.${resource.definition}`,
				}));
			}),
			catchError(httpError => of(httpError))
		);
	}

	public updateResource(resource: Resource, kind: ResourceType) {
		const { id } = resource;
		const body = { [kind]: { id } };
		return this.apiService.patch(`/api/me/`, body);
	}

	public getResources() {
		return this.apiService.get(`/resources/?cache_version=${this.coreVersion}`);
	}
}
