import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FinancialAsset, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { yearOptions1700 } from '@oper-client/shared/util-formatting';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData?: Partial<FinancialAsset>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const matchedResource = resources?.[ResourceType.FINANCIAL_ASSET_TYPE]?.find((item) => item.id === +(formData?.assetType?.id ?? 0));
	switch (matchedResource?.definition) {
		case 'cash':
			return buildForm(formData, resources, false, false);
		case 'pensionPillar2':
		case 'pensionPillar3A':
			return buildForm(formData, resources, true, true);
		case 'investmentPortfolio':
		case 'other':
			return buildForm(formData, resources, true, false);
		default:
			return buildForm(formData, resources);
	}
}

function buildForm(
	formData?: Partial<FinancialAsset>,
	resources?: PartialNormalizedResource,
	showUsage?: boolean,
	showWithdrawalDetails?: boolean
): InputBase<any>[] {
	return [
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			value: formData?.amount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span6',
		}),
		...(showUsage
			? [
					new InputSelect({
						key: 'usage.id',
						label: 'ç.question.financialAssetUsageType.label',
						value: formData?.usage?.id,
						required: true,
						options: resources?.[ResourceType.FINANCIAL_ASSET_USAGE_TYPE] || [],
						class: 'span6',
					}),
				]
			: []),
		...(showWithdrawalDetails
			? [
					new InputField({
						key: 'isPastWithdrawal',
						label: 'ç.question.isPastWithdrawal.label',
						value: !!formData?.withdrawalYear,
						type: 'checkbox',
						class: 'span6',
						required: false,
					}),
					new InputSelect({
						key: 'withdrawalYear',
						label: 'ç.question.withdrawalYear.label',
						value: formData?.withdrawalYear,
						options: yearOptions1700,
						required: true,
						class: 'span6',
						hidden: withdrawalYearHiddenAsync,
					}),
				]
			: []),
	];
}

const withdrawalYearHiddenAsync = (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
	const subject = new BehaviorSubject(!formGroup.value['isPastWithdrawal']);

	formGroup.controls['isPastWithdrawal'].valueChanges
		.pipe(
			map((value) => !value),
			takeUntil(destroy$)
		)
		.subscribe({
			complete: () => subject.complete(),
			error: (error) => subject.error(error),
			next: (value) => subject.next(value),
		});

	return subject.asObservable();
};
