import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { COMMISSION_SERVICE, ICommissionService } from '@oper-client/shared/data-access';
import { Commission, CommissionStatement } from '@oper-client/shared/data-model';
import * as CommissionActions from './commission.actions';

@Injectable()
export class CommissionEffects {
	loadCommissions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommissionActions.loadCommissions),
			switchMap(({ commissionFilterParams }) =>
				this.commissionService.getCommissions(commissionFilterParams).pipe(
					map((commissions: Commission[]) =>
						CommissionActions.loadCommissionsSuccess({
							commissions: commissions.map(CommissionEffects.mapCommission),
						})
					),
					catchError(error => of(CommissionActions.loadCommissionsFailure({ error: error })))
				)
			)
		)
	);

	loadCommissionStatement$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommissionActions.loadCommissionStatement),
			switchMap(({ commissionFilterParams }) =>
				this.commissionService.loadCommissionStatement(commissionFilterParams).pipe(
					map((commissionStatement: CommissionStatement) =>
						CommissionActions.loadCommissionStatementSuccess({
							commissionStatement,
						})
					),
					catchError(error => of(CommissionActions.loadCommissionStatementFailure({ error: error })))
				)
			)
		)
	);

	private static mapCommission(commission: Commission): Commission {
		const { id, externalReference, loanRequest, amount, commissionType } = commission;
		return {
			id,
			externalReference,
			loanRequest,
			amount,
			commissionType,
		};
	}

	constructor(private actions$: Actions, @Inject(COMMISSION_SERVICE) private commissionService: ICommissionService) {}
}
