import { AnyObject, PartialNormalizedResource, Resource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { InputField, InputSelect, Section } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { FormControl, FormGroup } from '@angular/forms';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { orderBy } from '@oper-client/shared/util-array';

export default function (
	formData: Partial<Simulator.Simulation> | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false,
	allowedLoanPurposes: string[] = [],
	allowedCombinations: AnyObject<string[][] | string[]> = {}
): InputBase<any>[] {
	const loanPurposesSet = new Set(allowedLoanPurposes.map((purpose) => purpose));

	const allLoanPurposes = (resources?.[ResourceType.LOAN_REQUEST_PURPOSE] ?? []).filter((purpose) =>
		allowedLoanPurposes.length > 0 ? loanPurposesSet.has(purpose.definition) : true
	);

	const selectedPurpose = allLoanPurposes.find((purpose) => purpose.id === formData.loanPurposes?.[0]?.id);
	const combinations = allowedCombinations[selectedPurpose?.definition] || [];

	const questions: InputBase<any>[] = [
		new InputSelect({
			key: 'loanPurposes[0].id',
			value: formData.loanPurposes?.[0]?.id,
			required: true,
			options: orderBy(allLoanPurposes, 'order'),
			class: 'span12',
			disabled: readonlyMode,
		}),
	];

	if (combinations.length > 0 && allLoanPurposes.length > 1) {
		questions.push(...getAllowedCombinations(formData, allLoanPurposes, combinations, readonlyMode));
	}

	return questions;
}

function getAllowedCombinations(
	formData: Partial<Simulator.Simulation> | null = null,
	loanPurposes: Resource[],
	allowedCombinations: string[][] | string[],
	readonlyMode = false
): InputBase<any>[] {
	const questions: InputBase<any>[] = [
		new Section({
			class: 'span12',
			title: 'ç.misc.combineWith',
		}),
	];

	allowedCombinations.forEach((purpose: string[] | string) => {
		if (Array.isArray(purpose)) {
			// if purpose is an array, we add checkboxes for each purpose but only one can be selected at a time

			const purposes = purpose.map((p) => loanPurposes.find((lp) => lp.definition === p)).filter((p) => !!p);
			purposes.forEach((lp) => {
				const selectedPurpose = formData.loanPurposes.find((p) => p.id === lp.id);
				questions.push(
					new InputField({
						key: `loanPurposes[${questions.length}].id`,
						label: `ç.resource.loan-request-purpose.${lp.definition}`,
						value: selectedPurpose?.id || false,
						type: 'checkbox',
						required: false,
						disabled: readonlyMode,
						transform: (value: boolean) => (value ? lp.id : false),
						transformField: (
							formGroup: FormGroup,
							formConfiguration: FormConfiguration,
							fieldKey: string
						): Observable<InputField> => {
							const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === fieldKey);
							const formControl = <FormControl>formGroup.controls[fieldKey];

							return formControl.valueChanges.pipe(
								distinctUntilChanged(),
								filter((value) => !!value),
								map(() => {
									Object.entries(formGroup.controls)
										.filter(([key, control]) => key !== fieldKey && purposes.map((p) => p.id).includes(control.value))
										.map(([key, control]) => {
											formGroup.controls[key].reset();
											return control;
										});

									return inputField;
								})
							);
						},
					})
				);
			});
		} else {
			const loanPurpose = loanPurposes.find((p) => p.definition === purpose);

			if (loanPurpose) {
				const value = formData.loanPurposes.find((p) => p.id === loanPurpose.id);
				questions.push(
					new InputField({
						key: `loanPurposes[${questions.length}].id`,
						label: `ç.resource.loan-request-purpose.${purpose}`,
						value: value?.id || false,
						type: 'checkbox',
						required: false,
						disabled: readonlyMode,
						transform: (value: boolean) => (value ? loanPurpose.id : false),
					})
				);
			}
		}
	});

	if (questions.length === 1) {
		return [];
	}

	return questions;
}
