import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { EnvironmentNumberFormatService } from '../services/environment-number-format.service';

@Pipe({
	name: 'environmentCurrency',
	pure: false,
})
export class EnvironmentCurrencyPipe extends CurrencyPipe implements PipeTransform {
	constructor(protected readonly environmentNumberFormatService: EnvironmentNumberFormatService) {
		super(environmentNumberFormatService.getLocale());
	}

	transform(
		value: number | string,
		currencyCode?: string,
		display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
		digitsInfo?: string,
		locale?: string
	): string | null;
	transform(
		value: null | undefined,
		currencyCode?: string,
		display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
		digitsInfo?: string,
		locale?: string
	): null;
	transform(value: number | string, ...args: any[]): string | null {
		// Adding additional unbreakable space for en-UK locale, because per standard en-UK doesn't have a space after symbol
		// Char Code 160 - unbreakable whitespace - &nbsp;
		const locale: string = this.environmentNumberFormatService.getLocale();
		const symbol: string =
			locale === 'en-UK'
				? `${this.environmentNumberFormatService.getCurrencySymbol()}${String.fromCharCode(160)}`
				: this.environmentNumberFormatService.getCurrencySymbol();

		return super.transform(
			value,
			this.environmentNumberFormatService.getCode(),
			args[1] ?? symbol,
			args[0] ?? this.environmentNumberFormatService.getCurrencyDigitsInfo(),
			locale
		);
	}
}
