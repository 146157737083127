import { Directive, ElementRef, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TooltipDirective } from 'ng2-tooltip-directive';
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[tooltip][showTooltipIfTruncated]',
})
export class ShowTooltipIfTruncatedDirective implements OnInit, OnDestroy {
	readonly destroy$: Subject<void> = new Subject<void>();
	private _scrollEventListener = (scrollEvent: Event) => {
		this.document.removeEventListener('scroll', this._scrollEventListener, true);
		this.tooltip?.hide();
	};
	constructor(
		private readonly element: ElementRef,
		private readonly tooltip: TooltipDirective,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit() {
		this.tooltip.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			switch (event.type) {
				case 'shown':
					this.document.addEventListener('scroll', this._scrollEventListener, true);
					break;
				case 'hidden':
					this.document.removeEventListener('scroll', this._scrollEventListener, true);
					break;
				default:
					// other events are ignored;
					break;
			}
		});
	}

	@HostListener('mouseenter', ['$event'])
	setTooltipState(): void {
		const element = this.element.nativeElement;
		if (element.scrollWidth <= element.clientWidth) {
			this.tooltip.hide();
		} else {
			this.tooltip.show();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.unsubscribe();
	}
}
