import { Realty } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { Validators } from '@angular/forms';
import { of } from 'rxjs';

export default function (formData?: Partial<Realty>): InputBase<any>[] {
	return [
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcBeforeRenovations.label',
			value: formData?.epcBeforeRenovations,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span6',
		}),
		new InputField({
			key: 'eLevel',
			label: 'ç.question.eLevel.label',
			value: formData?.eLevel,
			type: 'number',
			class: 'span6',
			helpText: of('ç.question.eLevel.helpText'),
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(100)],
			required: false,
			prefix: 'E',
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcAfterRenovations.label',
			value: formData?.epcAfterRenovations,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span6',
		}),
		new InputSelect({
			key: 'energeticRenovations',
			label: 'ç.question.energeticRenovations.label',
			value: formData?.energeticRenovations || false,
			options: [
				{ id: 1, key: 'ç.misc.yes', definition: true },
				{ id: 2, key: 'ç.misc.no', definition: false },
			],
			bindValue: 'definition',
			class: 'span6',
			required: false,
			clearable: false,
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcContractNumber.label',
			value: formData?.epcContractNumber,
			type: 'text',
			required: false,
			class: 'span6',
		}),
		new InputField({
			class: 'span6',
			type: 'hidden',
			required: false,
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: false,
			validators: [],
			class: 'span6',
		}),
	];
}
