import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromResources from './+state/resources.reducer';
import { ResourcesEffects } from './+state/resources.effects';
import { ResourcesFacade } from './+state/resources.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromResources.RESOURCES_FEATURE_KEY, fromResources.reducer),
		EffectsModule.forFeature([ResourcesEffects]),
	],
	providers: [ResourcesFacade],
})
export class DataAccessResourceModule {}
