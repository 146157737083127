import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InputBase } from '../models/input-base.model';
import { InputField, InputSwitch } from '../models/input-types.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'cash.amount',
			label: 'ç.question.cash.label',
			helpText: of('ç.question.cash.helpText'),
			value: formData?.cash?.amount,
			type: 'number',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'other.amount',
			label: 'ç.question.otherAsset.label',
			helpText: of('ç.question.otherAsset.helpText'),
			value: formData?.other?.amount,
			type: 'number',
			currency: true,
			required: false,
		}),
		new InputSwitch({
			key: 'pensionPillar3A.enabled',
			label: 'ç.question.usePensionPillar3A.label',
			helpText: of('ç.question.usePensionPillar3A.helpText'),
			value: !!formData?.pensionPillar3A?.enabled,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
			class: 'span12 top-margin-spacing8',
		}),
		new InputField({
			key: 'pensionPillar3A.amount',
			label: 'ç.question.amount.label',
			value: formData?.pensionPillar3A?.amount,
			type: 'number',
			currency: true,
			required: true,
			hidden: pensionPillar3AHiddenAsync,
		}),
		new InputSwitch({
			key: 'pensionPillar3A.usage',
			value: formData?.pensionPillar3A?.usage === 'keep' ? 'withdraw' : formData?.pensionPillar3A?.usage,
			type: 'toggle',
			required: true,
			labelA: 'ç.feature.financials.financialAssetUsageActions.withdraw',
			labelB: 'ç.feature.financials.financialAssetUsageActions.pledge',
			valueA: 'withdraw',
			valueB: 'pledge',
			hidden: pensionPillar3AHiddenAsync,
			helpText: of('ç.question.usePensionPillar3A.helpText2'),
		}),
		new InputSwitch({
			key: 'pensionPillar2.enabled',
			label: 'ç.question.usePensionPillar2.label',
			helpText: of('ç.question.usePensionPillar2.helpText'),
			value: !!formData?.pensionPillar2?.enabled,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
			class: 'span12 top-margin-spacing8',
		}),
		new InputField({
			key: 'pensionPillar2.amount',
			label: 'ç.question.amount.label',
			value: formData?.pensionPillar2?.amount,
			type: 'number',
			currency: true,
			required: true,
			hidden: pensionPillar2HiddenAsync,
		}),
		new InputSwitch({
			key: 'pensionPillar2.usage',
			value: formData?.pensionPillar2?.usage === 'keep' ? 'withdraw' : formData?.pensionPillar2?.usage,
			type: 'toggle',
			required: true,
			labelA: 'ç.feature.financials.financialAssetUsageActions.withdraw',
			labelB: 'ç.feature.financials.financialAssetUsageActions.pledge',
			valueA: 'withdraw',
			valueB: 'pledge',
			hidden: pensionPillar2HiddenAsync,
			helpText: of('ç.question.usePensionPillar2.helpText2'),
		}),
	];
}

const pensionPillar2HiddenAsync = (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
	const subject = new BehaviorSubject(!formGroup.value['pensionPillar2.enabled']);

	formGroup.controls['pensionPillar2.enabled'].valueChanges
		.pipe(
			map((value) => !value),
			takeUntil(destroy$)
		)
		.subscribe({
			complete: () => subject.complete(),
			error: (error) => subject.error(error),
			next: (value) => subject.next(value),
		});

	return subject.asObservable();
};
const pensionPillar3AHiddenAsync = (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
	const subject = new BehaviorSubject(!formGroup.value['pensionPillar3A.enabled']);

	formGroup.controls['pensionPillar3A.enabled'].valueChanges
		.pipe(
			map((value) => !value),
			takeUntil(destroy$)
		)
		.subscribe({
			complete: () => subject.complete(),
			error: (error) => subject.error(error),
			next: (value) => subject.next(value),
		});

	return subject.asObservable();
};
