import { LOAN_REQUEST_ENTITY_KEY, LoanRequestState } from './loan-request/loan-request.reducer';
import { OFFER_ENTITY_KEY, OfferState } from './offer/offer.reducer';
import { CREDIT_PROVIDER_ENTITY_KEY, CreditProviderState } from './credit-provider/credit-provider.reducer';
import { DISCOUNT_ENTITY_KEY, DiscountState } from './discount/discount.reducer';
import { PRODUCT_ENTITY_KEY, ProductState } from './product/product.reducer';
import { MILESTONE_ENTITY_KEY, MilestoneState } from './milestone/milestone.reducer';

export const LOAN_REQUEST_FEATURE_KEY = 'loanRequest';

export interface LoanRequestEntityState {
	readonly [LOAN_REQUEST_ENTITY_KEY]?: LoanRequestState;
	readonly [OFFER_ENTITY_KEY]?: OfferState;
	readonly [CREDIT_PROVIDER_ENTITY_KEY]?: CreditProviderState;
	readonly [DISCOUNT_ENTITY_KEY]?: DiscountState;
	readonly [PRODUCT_ENTITY_KEY]?: ProductState;
	readonly [MILESTONE_ENTITY_KEY]?: MilestoneState;
}

export interface LoanRequestPartialState {
	readonly [LOAN_REQUEST_FEATURE_KEY]: LoanRequestEntityState;
}
