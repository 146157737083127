import { Action, createReducer } from '@ngrx/store';
import { BrokerState } from './broker/broker.reducer';
import { InsuranceProviderState } from './insurance-provider/insurance-provider.reducer';
import { NotaryState } from './notary/notary.reducer';

export const THIRD_PARTY_FEATURE_KEY = 'thirdParty';

export interface ThirdPartyState {
	blabla?: string;
}

export interface ThirdPartyPartialState {
	readonly [THIRD_PARTY_FEATURE_KEY]: ThirdPartyState;
	readonly brokers: BrokerState;
	readonly insuranceProviders: InsuranceProviderState;
	readonly notaries: NotaryState;
}

export const initialState: ThirdPartyState = {};

const thirdPartyReducer = createReducer(initialState);

export function reducer(state: ThirdPartyState | undefined, action: Action): ThirdPartyState {
	return thirdPartyReducer(state, action);
}
