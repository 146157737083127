import { ChangeDetectionStrategy, Component, input, Optional, output, Self } from '@angular/core';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { FormGroupWithWarning } from '../../models/form-warning.model';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UtilService } from '@oper-client/shared/util-formatting';

@Component({
	selector: 'oper-client-dynamic-form-card',
	templateUrl: './dynamic-form-card.component.html',
	styleUrl: './dynamic-form-card.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormCardComponent implements ControlValueAccessor {
	readonly key = input.required<string | null>();
	readonly title = input.required<string | null>();
	readonly formConfiguration = input.required<FormConfiguration | null>();
	readonly subtitle = input<string>('');
	readonly debounceTime = input<number>(0);
	readonly showDeleteButton = input<boolean>(false);

	readonly removeCard = output<string>();
	readonly valueChange = output<any>();

	constructor(
		@Self() @Optional() public control: NgControl,
		readonly utilService: UtilService
	) {
		if (this.control) {
			this.control.valueAccessor = this;
		}
	}

	onFormValueChange(formGroup: FormGroupWithWarning): void {
		const hasControls = Object.keys(formGroup?.controls ?? {}).length > 0;
		if (!hasControls) {
			return;
		}

		if (formGroup.valid) {
			const changes = this.utilService.erectObject(formGroup.value);
			this.valueChange.emit(changes);
		}
	}

	writeValue(obj: any): void {}

	registerOnChange(fn: any): void {}

	registerOnTouched(fn: any): void {}

	setDisabledState?(isDisabled: boolean): void {}
}
