import { Injectable, Inject } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AUTH_SERVICE, IAuthService } from '@oper-client/shared/data-access';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';
import { IAM, Resource, ResourceType } from '@oper-client/shared/data-model';
import { ResourcesFacade, updateResource } from '@oper-client/shared/resources/data-access-resource';
import { LocaleService } from './locale.service';
import { BehaviorSubject, filter, shareReplay } from 'rxjs';
import { AuthType, DEFINED_LANGUAGES } from '@oper-client/shared/configuration';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private readonly _languageChanged$ = new BehaviorSubject<string>(null);
	readonly languageChanged$ = this._languageChanged$.pipe(
		filter((language) => !!language),
		shareReplay(1)
	);

	private static getLanguageByAuthType(language: any, authType: AuthType): string {
		return authType === AuthType.BASIC ? language : language?.definition;
	}

	constructor(
		private readonly localStorageService: LocalStorageService,
		private readonly translateService: TranslateService,
		private readonly localeService: LocaleService,
		private readonly resourceFacade: ResourcesFacade,
		@Inject(DEFINED_LANGUAGES) private readonly definedLanguages: Array<string>,
		@Inject(AUTH_SERVICE) private readonly auth: IAuthService
	) {}

	init(user?: IAM.User, authType: AuthType = AuthType.BASIC): void {
		this.addLanguages();
		this.setLanguage(this.storedLanguage());
		if (user) {
			const language = LanguageService.getLanguageByAuthType(user.language, authType);
			if (language) {
				this.setLanguage(language);
			}
		}
	}

	/** @description
	 * retrieves the language from local store
	 * BUT if locale is corrupted or empty this function will set and give back the default value from config
	 * */
	private storedLanguage(): string {
		let locale = this.localStorageService.get('locale');
		if (!locale || !this.definedLanguages.includes(locale)) {
			locale = this.getDefaultLanguage();
			this.localStorageService.set('locale', locale);
		}
		return locale;
	}

	changeLanguage(languageDefinition: string, languages: Resource[]): void {
		const language = languages.find((lang) => lang.definition === languageDefinition);
		if (!language) {
			return;
		}
		if (this.auth.isAuthenticated()) {
			this.resourceFacade.dispatch(updateResource({ kind: ResourceType.LANGUAGE, resource: language }));
		}
		this.setLanguage(language.definition);
	}

	getCurrentLanguage(): string | null {
		return this.storedLanguage();
	}

	getDefaultLanguage(): string {
		return this.localeService.locale;
	}

	public setLanguagePublic(language: string): void {
		this.setLanguage(language);
	}

	private setLanguage(language?: string): void {
		const previousLanguage = this.storedLanguage();
		if (language !== previousLanguage || !this.translateService.currentLang) {
			this.localStorageService.set('locale', language);
			language = this.storedLanguage();
			this.translateService.use(language);
			this.translateService.onLangChange.emit(<LangChangeEvent>{});
			this._languageChanged$.next(language);
		}
	}

	private addLanguages(): void {
		this.translateService.addLangs(this.definedLanguages);
	}
}
