import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, combineReducers } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DataAccessResourceModule } from '@oper-client/shared/resources/data-access-resource';
import { FeatureFlagModule } from '@oper-client/shared/util-feature-flag';

import { CommissionFileFacade } from './+state/commission-file/commission-file.facade';
import { CommissionFileEffects } from './+state/commission-file/commission-file.effects';
import * as fromCommissionFile from './+state/commission-file/commission-file.reducer';

import { PaymentFileFacade } from './+state/payment-file/payment-file.facade';
import { PaymentFileEffects } from './+state/payment-file/payment-file.effects';
import * as fromPaymentFile from './+state/payment-file/payment-file.reducer';

import { CommissionFacade } from './+state/commission/commission.facade';
import { CommissionEffects } from './+state/commission/commission.effects';
import * as fromCommission from './+state/commission/commission.reducer';

import { COMMISSION_FEATURE_KEY } from './+state/commission.reducer';

@NgModule({
	imports: [
		CommonModule,
		DataAccessResourceModule,
		FeatureFlagModule,
		StoreModule.forFeature(
			COMMISSION_FEATURE_KEY,
			combineReducers({
				[fromCommissionFile.COMMISSION_FILE_FEATURE_KEY]: fromCommissionFile.reducer,
				[fromPaymentFile.PAYMENT_FILE_FEATURE_KEY]: fromPaymentFile.reducer,
				[fromCommission.COMMISSION_FEATURE_KEY]: fromCommission.reducer,
			})
		),
		EffectsModule.forFeature([CommissionFileEffects, CommissionEffects, PaymentFileEffects]),
	],
	providers: [CommissionFileFacade, CommissionFacade, PaymentFileFacade],
})
export class DataAccessCommissionModule {}
