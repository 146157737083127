import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { API_SERVICE, IApiService, LoanRequestEntityService } from '@oper-client/shared/data-access';
import { AdditionalFinancingNeed } from '@oper-client/shared/data-model';

@Injectable()
export class AdditionalFinancingNeedService implements LoanRequestEntityService<AdditionalFinancingNeed> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	public getAll(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/additional-financing-needs/`, params);
	}
	public create(
		loanRequestId: number,
		additionalFinancingNeed: AdditionalFinancingNeed,
		params: HttpParams = new HttpParams()
	): Observable<AdditionalFinancingNeed> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/additional-financing-needs/`, additionalFinancingNeed, params);
	}

	public update(
		loanRequestId: number,
		id: number,
		additionalFinancingNeed: AdditionalFinancingNeed,
		params: HttpParams = new HttpParams()
	): Observable<AdditionalFinancingNeed> {
		return this.apiService.patch(
			`/api/loan-requests/${loanRequestId}/additional-financing-needs/${id}/`,
			additionalFinancingNeed,
			params
		);
	}

	public delete(loanRequestId: number, id: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/additional-financing-needs/${id}/`);
	}
}
