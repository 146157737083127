import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { DataConnection, Integrator, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { FormGroup } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { InputBase } from '../../../models/input-base.model';
import { InputField, InputSelect } from '../../../models/input-types.model';

export default function (
	formData: Partial<DataConnection>,
	resources: PartialNormalizedResource,
	integrators: Array<Integrator>
): InputBase<any>[] {
	const isNotRecordCredits = (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
		const recordCreditId = integrators.find((_) => _.name === 'recordCredits')?.id;
		const subject = new BehaviorSubject(formGroup.value['integrator.id'] !== recordCreditId);

		formGroup.controls['integrator.id'].valueChanges
			.pipe(
				map((value) => !value || value !== recordCreditId),
				takeUntil(destroy$)
			)
			.subscribe({
				complete: () => subject.complete(),
				error: (error) => subject.error(error),
				next: (value) => subject.next(value),
			});

		return subject.asObservable();
	};

	return [
		new InputSelect({
			key: 'integrator.id',
			label: 'ç.question.searchForLender.title',
			options: integrators.map((_) => ({ ..._, key: `ç.resource.integrator.${_['name']}` })),
			value: formData?.integrator?.id,
			class: 'span12',
			searchable: true,
			required: true,
			order: 1,
		}),
		new InputField({
			key: 'reference',
			helpText: of('ç.question.pushDocumentsReference.helpText'),
			label: 'ç.question.pushDocumentsReference.label',
			hidden: isNotRecordCredits,
			value: null,
			class: 'span12',
			required: true,
			order: 1,
		}),
	];
}
