import { Inject, Injectable } from '@angular/core';
import { API_SERVICE, IApiService, IIntegrationsService } from '@oper-client/shared/data-access';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IntegrationsService implements IIntegrationsService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getAllowedIntegrations(): Observable<any> {
		return this.apiService.get(`/api/integrations/allowed-integrators/`).pipe(map(paginatedResult => paginatedResult));
	}
}
