import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState, FinancialAsset } from '@oper-client/shared/data-model';
import { ClientFacade } from '../client/client.facade';
import { ClientPartialFeatureState } from '../client.reducer';
import * as FinancialAssetActions from './financial-asset.actions';
import * as FinancialAssetSelectors from './financial-asset.selectors';
import { FinancialAssetActionTypes } from './financial-asset.reducer';

@Injectable()
export class FinancialAssetFacade {
	constructor(private store: Store<ClientPartialFeatureState>, private clientFacade: ClientFacade) {}

	public createFinancialAssetActionState$ = this.selectActionState('createFinancialAsset');
	public updateFinancialAssetActionState$ = this.selectActionState('updateFinancialAsset');
	public removeFinancialAssetActionState$ = this.selectActionState('removeFinancialAsset');
	public loadFinancialAssetActionState$ = this.selectActionState('loadFinancialAsset');
	public loadFinancialAssetsActionState$ = this.selectActionState('loadFinancialAssets');
	public loadLoanRequestFinancialAssetsActionState$ = this.selectActionState('loadLoanRequestFinancialAssets');

	public financialAssets$ = this.store.pipe(select(FinancialAssetSelectors.getFinancialAssets));
	public currentClientFinancialAssets$ = combineLatest([this.clientFacade.currentClientId$, this.financialAssets$]).pipe(
		map(([clientId, financialAssets]) => financialAssets.filter((financialAsset) => financialAsset.client.id === clientId))
	);
	public mainClientFinancialAssets$ = combineLatest([this.clientFacade.mainClientId$, this.financialAssets$]).pipe(
		map(([mainClientId, incomes]) => incomes.filter((income) => income.client.id === mainClientId))
	);
	public secondClientFinancialAssets$ = combineLatest([this.clientFacade.secondClientId$, this.financialAssets$]).pipe(
		map(([secondClientId, incomes]) => incomes.filter((income) => income.client.id === secondClientId))
	);

	public loadFinancialAsset(loanRequestId: number, clientId: number, financialAssetId: number) {
		this.store.dispatch(FinancialAssetActions.loadFinancialAsset({ loanRequestId, clientId, financialAssetId }));
	}

	public loadFinancialAssets(loanRequestId: number, clientId: number) {
		this.store.dispatch(FinancialAssetActions.loadFinancialAssets({ loanRequestId, clientId }));
	}

	public loadLoanRequestFinancialAssets(loanRequestId: number) {
		this.store.dispatch(FinancialAssetActions.loadLoanRequestFinancialAssets({ loanRequestId }));
	}

	public createFinancialAsset(loanRequestId: number, clientId: number, financialAsset: Partial<FinancialAsset>) {
		this.store.dispatch(FinancialAssetActions.addFinancialAsset({ loanRequestId, clientId, financialAsset }));
	}

	public updateFinancialAsset(
		loanRequestId: number,
		clientId: number,
		financialAssetId: number,
		financialAsset: Partial<FinancialAsset>
	) {
		this.store.dispatch(
			FinancialAssetActions.updateFinancialAsset({
				loanRequestId,
				clientId,
				financialAsset: { id: financialAssetId, changes: financialAsset },
			})
		);
	}

	public deleteFinancialAsset(loanRequestId: number, clientId: number, financialAssetId: number) {
		this.store.dispatch(FinancialAssetActions.deleteFinancialAsset({ loanRequestId, clientId, id: financialAssetId }));
	}

	public clearFinancialAssets() {
		this.store.dispatch(FinancialAssetActions.clearFinancialAssets());
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: FinancialAssetActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(FinancialAssetSelectors.getActionState(actionType)), map(parseActionState));
	}
}
