import { of } from 'rxjs';

import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'priceOfLand',
			label: 'ç.question.priceOfTheLand.label',
			helpText: of('ç.question.priceOfTheLand.helpText'),
			value: formData?.priceOfLand || null,
			type: 'text',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'buildingCosts',
			label: 'ç.question.buildingCosts.label',
			helpText: of('ç.question.buildingCosts.helpText'),
			value: formData?.buildingCosts || null,
			type: 'text',
			currency: true,
			required: true,
		}),
	];
}
