import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

const MANDATORY_CONTROLS = ['country.id', 'city', 'street', 'houseNumber', 'zipCode'];
const CONTROL_TYPES = {
	street: 'string',
	houseNumber: 'string',
	box: 'string',
	floor: 'string',
	city: 'string',
	'country.id': 'number',
	zipCode: 'string',
};
export function addressValidator(formControlPrefix = '', mandatoryControls = MANDATORY_CONTROLS): ValidatorFn {
	return (control: FormGroup): ValidationErrors | null => {
		const street = control.get([`${formControlPrefix}street`]);
		const houseNumber = control.get([`${formControlPrefix}houseNumber`]);
		const box = control.get([`${formControlPrefix}box`]);
		const floor = control.get([`${formControlPrefix}floor`]);
		const city = control.get([`${formControlPrefix}city`]);

		if (!street && !houseNumber && !city) return;

		const undefinedFields: AbstractControl[] = [];
		
		for (const name of mandatoryControls) {
			const c = control.get([`${formControlPrefix}${name}`]);
			if (!c || typeof c.value !== CONTROL_TYPES[name]) {
				undefinedFields.push(c);
			}
			
			if (CONTROL_TYPES[name] === 'string' && c.value === '') {
				undefinedFields.push(c);
			}
		}

		const floorCondition = !!floor && typeof floor.value === 'string' && floor.value !== '';

		if (undefinedFields.length < mandatoryControls.length || (typeof box.value === 'string' && box.value !== '') || floorCondition) {
			undefinedFields.forEach((field) => {
				field.setErrors({
					requiredFieldAddressCannotBeProvidedPartially: true,
				});
			});
		} else {
			undefinedFields.forEach((field) => {
				field.setErrors(null);
			});
		}

		return null;
	};
}
