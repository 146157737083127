import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as FeatureFlagActions from './feature-flag.actions';
import { FeatureFlagApiService } from '../services/feature-flag-api.service';
import { CaseStyleService } from '@oper-client/shared/util-formatting';

@Injectable()
export class FeatureFlagEffects {
	loadFeatureFlags$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FeatureFlagActions.loadFeatureFlags),
			concatMap(() =>
				this.featureFlagApiService.loadFeatureFlags().pipe(
					map((featureFlags) => {
						const objectFeatureFlags = featureFlags.reduce((pv, cv) => ({ ...pv, [cv.definition]: cv.value }), {});
						return this.caseStyleService.convertKeysToCamelCase(objectFeatureFlags);
					}),
					map((featureFlags) => FeatureFlagActions.loadFeatureFlagsSuccess({ featureFlags })),
					catchError((error) => of(FeatureFlagActions.loadFeatureFlagsFailure({ error })))
				)
			)
		);
	});

	constructor(
		private actions$: Actions,
		private readonly featureFlagApiService: FeatureFlagApiService,
		private readonly caseStyleService: CaseStyleService
	) {}
}
