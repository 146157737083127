import { AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PartialNormalizedResource, Resource, ResourceType } from '@oper-client/shared/data-model';

export const purposes = ['buy', 'build', 'extension', 'modernization'];
export const types = ['newApartment', 'newHouse', 'secondHandHouse', 'secondHandApartment'];
const newBuildTypes = ['newHouse'];
const extension = ['secondHandHouse', 'secondHandApartment'];
const modernization = ['secondHandHouse', 'secondHandApartment'];
const secondHandTypes = ['secondHandHouse', 'secondHandApartment', 'newApartment', 'newHouse'];
const houseMinSurface = 70;
const apartmentMinSurface = 40;

export default function (resources?: PartialNormalizedResource): { validators: ValidatorFn[]; asyncValidators?: AsyncValidatorFn[] } {
	const allowedPurpose = resources?.[ResourceType.REALTY_PURPOSE].filter((purpose) => purposes.includes(purpose.definition));
	const allowedTypes = resources?.[ResourceType.REALTY_TYPE].filter((type) => types.includes(type.definition));
	return {
		validators: [realtyPurposeType('realty.', allowedPurpose, allowedTypes), minSurfaceRealtyPurposeType('realty.', allowedTypes)],
	};
}

function realtyPurposeType(formControlPrefix: string, purposes: Array<Resource>, types: Array<Resource>): ValidatorFn {
	return (control: FormGroup): ValidationErrors | null => {
		const purposeControl = control.get([`${formControlPrefix}purposes[0].id`]);
		const typeControl = control.get([`${formControlPrefix}realtyType.id`]);

		const purposeDefinition = purposes?.find((purpose) => purposeControl?.value === purpose?.id)?.definition;
		const typeDefinition = types?.find((type) => typeControl?.value === type?.id)?.definition;

		let notAllowedBoth = false;
		switch (purposeDefinition) {
			case 'buy':
				if (typeDefinition && !secondHandTypes.includes(typeDefinition)) {
					notAllowedBoth = true;
				} else {
					purposeControl.setErrors(null);
				}
				break;
			case 'build':
				if (typeDefinition && !newBuildTypes.includes(typeDefinition)) {
					notAllowedBoth = true;
				} else {
					purposeControl.setErrors(null);
				}
				break;
			case 'extension':
				if (typeDefinition && !extension.includes(typeDefinition)) {
					notAllowedBoth = true;
				} else {
					purposeControl.setErrors(null);
				}
				break;
			case 'modernization':
				if (typeDefinition && !modernization.includes(typeDefinition)) {
					notAllowedBoth = true;
				} else {
					purposeControl.setErrors(null);
				}
				break;
			default:
				if (purposeControl?.value) {
					purposeControl.setErrors({
						csokNotAllowedRealtyPurpose: true,
					});
				} else {
					purposeControl.setErrors(null);
				}
		}
		if (notAllowedBoth) {
			purposeControl.setErrors({
				csokNotAllowedRealtyPurpose: true,
			});
			typeControl.setErrors({
				csokNotAllowedRealtyType: true,
			});
		} else {
			if (!typeDefinition) {
				typeControl.setErrors({
					csokNotAllowedRealtyType: true,
				});
			} else {
				typeControl.setErrors(null);
			}

			purposeDefinition
				? purposeControl.setErrors(null)
				: purposeControl.setErrors({
						csokNotAllowedRealtyPurpose: true,
				  });
		}

		return null;
	};
}

function minSurfaceRealtyPurposeType(formControlPrefix: string, types: Array<Resource>): ValidatorFn {
	return (control: FormGroup): ValidationErrors | null => {
		const typeControl = control.get([`${formControlPrefix}realtyType.id`]);
		const surfaceControl = control.get([`${formControlPrefix}surface`]);
		const typeDefinition = types?.find((type) => typeControl?.value === type?.id)?.definition;
		if (surfaceControl?.value !== null && surfaceControl?.value !== undefined) {
			switch (typeDefinition) {
				case 'newApartment':
				case 'secondHandApartment':
					if (surfaceControl.value < apartmentMinSurface) {
						surfaceControl.setErrors({ min: { min: apartmentMinSurface } });
					} else {
						surfaceControl.setErrors(null);
					}
					break;
				case 'newHouse':
				case 'secondHandHouse':
					if (surfaceControl.value < houseMinSurface) {
						surfaceControl.setErrors({ min: { min: houseMinSurface } });
					} else {
						surfaceControl.setErrors(null);
					}
					break;
				default:
					break;
			}
		}
		return null;
	};
}
