import { Validators } from '@angular/forms';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { of } from 'rxjs';
import { InputBase } from '../../../models/input-base.model';
import { InputField, PercentageInputField } from '../../../models/input-types.model';

export default function (
	formData: any | null = null,
	resources: PartialNormalizedResource | null = null,
	readonlyMode = false
): InputBase<any>[] {
	const questions = [
		new InputField({
			key: 'currentRealtyEstimatedValue',
			label: 'ç.feature.mortgageSimulator.currentRealtyEstimatedValue',
			helpText: of('ç.feature.mortgageSimulator.currentRealtyEstimatedValue.tooltip'),
			value: formData?.currentRealtyEstimatedValue ?? 0,
			type: 'number',
			currency: true,
			readonly: readonlyMode,
			required: true,
			class: 'span12',
		}),
		new PercentageInputField({
			key: 'lenderTakeoverFee',
			label: 'ç.feature.mortgageSimulator.lenderTakeoverFee',
			helpText: of('ç.feature.mortgageSimulator.lenderTakeoverFee.tooltip'),
			value: formData?.lenderTakeoverFee ?? 0,
			required: true,
			disabled: readonlyMode,
			class: 'span12',
			validators: [Validators.min(0), Validators.max(100)],
		}),
		new InputField({
			key: 'outstandingMortgage',
			label: 'ç.feature.mortgageSimulator.outstandingMortgage',
			helpText: of('ç.feature.mortgageSimulator.outstandingMortgage.tooltip'),
			value: formData?.outstandingMortgage ?? 0,
			readonly: readonlyMode,
			type: 'number',
			currency: true,
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'additionalCosts',
			label: 'ç.feature.mortgageSimulator.additionalCosts',
			helpText: of('ç.feature.mortgageSimulator.additionalCosts.tooltip'),
			value: formData?.additionalCosts ?? 0,
			type: 'number',
			readonly: readonlyMode,
			currency: true,
			required: true,
			class: 'span12',
		}),
	];

	return questions;
}
