import { Injectable } from '@angular/core';
import { select, Store, Action, ActionsSubject } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
	ParsedActionState,
	parseActionState,
	Resource,
	Document,
	Proof,
	DocumentTypes,
	GenerateDocumentOptions,
} from '@oper-client/shared/data-model';

import * as ProofSelectors from './proof.selectors';
import * as ProofActions from './proof.actions';
import { ProofActionTypes } from './proof.reducer';
import { resetState, uploadFileProgress } from './proof.actions';

@Injectable()
export class ProofFacade {
	proof$: Observable<Proof[]> = this.store.pipe(select(ProofSelectors.getProof));
	loadProofActionState$ = this.selectActionState('loadProof');
	allowedDocuments$: Observable<Resource[] | undefined> = this.store.pipe(select(ProofSelectors.getAllowedDocuments));
	zippedProofDocuments$: Observable<string | undefined> = this.store.pipe(select(ProofSelectors.getZipFiles));
	generatedDocument$: Observable<Document> = this.store.pipe(select(ProofSelectors.getGeneratedDocument));
	generatedDocumentActionState$ = this.selectActionState('generateDocument');
	createProofDocumentActionState$ = this.selectActionState('createProofDocument');
	updateProofDocumentActionState$ = this.selectActionState('updateProofDocument');
	deleteProofDocumentActionState$ = this.selectActionState('deleteProofDocument');
	uploadFileActionState$ = this.selectActionState('uploadFile');
	readonly uploadFileProgress$: Observable<any> = this.actionListener$.pipe(filter((_) => _.type === uploadFileProgress.type));

	constructor(
		private store: Store<any>,
		private actionListener$: ActionsSubject
	) {}

	dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	loadProof(loanRequestId: number) {
		this.store.dispatch(ProofActions.loadProof({ loanRequestId: loanRequestId }));
	}

	loadAllowedDocuments(loanRequestId: number, categoryId: number, kind = 'documentSigningNotRequired') {
		this.store.dispatch(ProofActions.loadAllowedProofDocuments({ kind, loanRequestId, categoryId }));
	}

	createProofDocument(loanRequestId: number, categoryId: number, document: Partial<Document>) {
		this.store.dispatch(ProofActions.createProofDocument({ loanRequestId, categoryId, document }));
	}

	uploadDocument(loanRequestId: number, proofId: number, document: Partial<Document>, file: File, kind: DocumentTypes) {
		this.store.dispatch(ProofActions.uploadDocument({ loanRequestId, proofId, document, file, kind }));
	}

	updateProofDocument(loanRequestId: number, categoryId: number, documentId: number, document: Partial<Document>) {
		this.store.dispatch(
			ProofActions.updateProofDocument({ loanRequestId, categoryId, document: { id: documentId, changes: document } })
		);
	}

	uploadFile(loanRequestId: number, categoryId: number, documentId: number, file: File) {
		this.store.dispatch(ProofActions.uploadFile({ loanRequestId, categoryId, documentId, file }));
	}

	deleteProofDocument(loanRequestId: number, categoryId: number, documentId: number) {
		this.store.dispatch(ProofActions.deleteProofDocument({ loanRequestId, categoryId, documentId }));
	}

	deleteProofDocumentFile(loanRequestId: number, categoryId: number, documentId: number) {
		this.store.dispatch(ProofActions.deleteProofDocumentFile({ loanRequestId, categoryId, documentId }));
	}

	generateDocument(loanRequestId: number, documentOptions: GenerateDocumentOptions, data?: any) {
		this.store.dispatch(ProofActions.generateDocument({ loanRequestId, documentOptions, data }));
	}

	reset() {
		this.store.dispatch(resetState());
	}

	private selectActionState(actionType: ProofActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(ProofSelectors.getActionState(actionType)), map(parseActionState));
	}
}
