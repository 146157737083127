import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IamFacade } from '@oper-client/shared/iam/data-access-iam';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { IAM } from '@oper-client/shared/data-model';
import { BehaviorSubject } from 'rxjs';
import { CONSENT_STATUS_COOKIE, ConsentStatus, CookieStorageService } from '@oper-client/shared/util-client-storage';
import { filter, take, takeUntil } from 'rxjs/operators';

declare const Beamer;
declare const beamer_config;

@Injectable({
	providedIn: 'root',
})
export class BeamerService {
	private readonly notificationsEnabledSubject = new BehaviorSubject(
		this.isFunctionCookiesAllowed() && !!this.customerConfig?.notifications?.beamer
	);
	readonly notificationsEnabled = this.notificationsEnabledSubject.asObservable();
	private initialized = false;
	private readonly beamerConfig = this.customerConfig?.notifications?.beamer;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(CUSTOMER_INSIGHTS_CONFIG) public customerConfig: CustomerInsights,
		private readonly cookieStorageService: CookieStorageService,
		private readonly translateService: TranslateService,
		private readonly iamFacade: IamFacade
	) {}

	init(selector?: string, sideBarCollapsed?: boolean) {
		if (this.initialized || !this.beamerConfig) {
			return;
		}
		this.initialized = true;
		if (!this.cookieStorageService.get(CONSENT_STATUS_COOKIE)) {
			this.cookieStorageService.cookieChanged
				.pipe(
					filter((cookie) => cookie === CONSENT_STATUS_COOKIE),
					take(1)
				)
				.subscribe(() => {
					if (this.isFunctionCookiesAllowed()) {
						this.notificationsEnabledSubject.next(true);
						this.enableNotifications(selector, sideBarCollapsed);
					}
				});
		} else if (this.notificationsEnabledSubject.getValue()) {
			this.enableNotifications(selector, sideBarCollapsed);
		}
	}

	enableNotifications(selector?: string, sideBarCollapsed?: boolean) {
		this.iamFacade.user$.subscribe((user: IAM.User) => {
			if (!user) {
				return;
			}
			if (typeof Beamer === 'undefined') {
				beamer_config.product_id = this.beamerConfig.productId;
				beamer_config.display = 'left';
				beamer_config.multi_user = true;
				// CSS class is passed to the Beamer CSS. The theme could be configured in the CustomerInsights config
				// The certain stail could be added (per theme/customer) at the Beamer dashboard
				beamer_config.theme = this.beamerConfig.theme;
				beamer_config.selector = selector || 'notificationsButton';
				beamer_config.button = false;
				beamer_config.filter = this.beamerConfig.filter;
				const body = <HTMLDivElement>document.body;
				const script = document.createElement('script');
				script.innerHTML = '';
				script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
				script.async = false;
				script.defer = true;
				script.onload = () => this.updateConfig(user);
				this.sideBarCollapsed(sideBarCollapsed);
				body.appendChild(script);
			} else {
				this.updateConfig(user);
			}
		});
	}

	sideBarCollapsed(collapsed: boolean) {
		if (collapsed) {
			document.body.classList.add('side-bar-collapsed');
		} else {
			document.body.classList.remove('side-bar-collapsed');
		}
	}

	updateConfig(user: IAM.User) {
		const lang = this.getCurrentLanguage();
		Beamer.update({
			...lang,
			//---------------Visitor Information---------------
			user_id: user?.id,
		});

		this.translateService.onLangChange
			.pipe(takeUntil(this.iamFacade.user$.pipe(filter((user) => !user))))
			.subscribe((event: LangChangeEvent) => {
				if (event.lang) {
					Beamer.update({
						...this.getCurrentLanguage(),
					});
				}
			});
	}

	private getCurrentLanguage() {
		const lang = this.translateService.currentLang && new Intl.Locale(this.translateService.currentLang).language;
		return lang ? { language: lang } : {};
	}

	private isFunctionCookiesAllowed() {
		const consentGiven: ConsentStatus = <ConsentStatus>this.cookieStorageService.get(CONSENT_STATUS_COOKIE);
		return consentGiven === 'allow' || consentGiven === 'allowOnlyFunctional';
	}
}
