import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const BIC_REGEX = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

function isValid(bic: string): boolean {
	return BIC_REGEX.test(bic);
}

export function bicValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value && !isValid(control.value)) {
			return { bic: { value: control.value } };
		}
		return null;
	};
}
