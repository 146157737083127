import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GtmTrackingClickDirective } from './directives/gtm-tracking-click.directive';
import { GtmFacade } from './gtm.facade';

@NgModule({
	imports: [CommonModule],
	providers: [GtmFacade],
	declarations: [GtmTrackingClickDirective],
	exports: [GtmTrackingClickDirective],
})
export class GtmModule {}
