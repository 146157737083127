import { COMMISSION_FILE_FEATURE_KEY, CommissionFileState } from './commission-file/commission-file.reducer';
import { PAYMENT_FILE_FEATURE_KEY, PaymentFileState } from './payment-file/payment-file.reducer';

export const COMMISSION_FEATURE_KEY = 'commission';

export interface CommissionEntityState {
	readonly [COMMISSION_FILE_FEATURE_KEY]?: CommissionFileState;
	readonly [PAYMENT_FILE_FEATURE_KEY]?: PaymentFileState;
}

export interface CommissionPartialState {
	readonly [COMMISSION_FEATURE_KEY]: CommissionEntityState;
}
