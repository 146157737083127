import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoanRequestPartialState } from '../loan-request.reducer';
import {
	applyDiscounts,
	loadProductDiscounts,
	clearProductDiscounts,
	resetDiscountsState,
	loadSelectedDiscountsFromOffer,
	setSelectedDiscounts,
	clearSelectedDiscounts,
} from './discount.actions';

import { parseActionState, ParsedActionState, DiscountsQueryParams, BaseProductDiscounts } from '@oper-client/shared/data-model';

import { DiscountActionTypes } from './discount.reducer';
import * as DiscountSelectors from './discount.selectors';

@Injectable()
export class DiscountFacade {
	discounts$ = this.store.pipe(select(DiscountSelectors.getAllDiscounts));
	selectedDiscounts$ = this.store.pipe(select(DiscountSelectors.getSelectedDiscounts));
	loadProductDiscountsActionState$ = this.selectActionState('loadProductDiscounts');
	loadSelectedDiscountsFromOfferActionState$ = this.selectActionState('loadSelectedDiscountsFromOffer');

	constructor(private store: Store<LoanRequestPartialState>) {}

	public loadProductDiscounts(loanRequestId: number, queryParams: DiscountsQueryParams) {
		this.store.dispatch(loadProductDiscounts({ loanRequestId, queryParams }));
	}

	public loadSelectedDiscountsFromOffer(loanRequestId: number, offerId: number) {
		this.store.dispatch(loadSelectedDiscountsFromOffer({ loanRequestId, offerId }));
	}

	public clearProductDiscounts() {
		this.store.dispatch(clearProductDiscounts());
	}

	public setSelectedDiscounts(discounts: BaseProductDiscounts[]): void {
		this.store.dispatch(setSelectedDiscounts({ discounts }));
	}

	public clearSelectedDiscounts() {
		this.store.dispatch(clearSelectedDiscounts());
	}

	public applyDiscounts(discounts: BaseProductDiscounts[]): void {
		this.store.dispatch(applyDiscounts({ discounts }));
	}

	public resetDiscountsState() {
		this.store.dispatch(resetDiscountsState());
	}

	private selectActionState(actionType: DiscountActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(DiscountSelectors.getActionState(actionType)), map(parseActionState));
	}
}
