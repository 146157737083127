import { Email, I18nPhoneNumber, PartialNormalizedResource, PhoneNumber, ResourceType, Client } from '@oper-client/shared/data-model';
import { InputField, InputPhone, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';

export default function (client?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const email: Email = (client?.emails?.length && client?.emails[0]) || <Email>{};
	const mainPhoneNumber: PhoneNumber =
		client?.phoneNumbers?.find((item) => item.phoneType?.definition === 'main') ||
		(client?.phoneNumbers?.length === 1 && client?.phoneNumbers[0]?.phoneType?.definition !== 'landline' && client?.phoneNumbers[0]) ||
		<PhoneNumber>{};
	return [
		new InputField({
			key: 'emails[0].value',
			label: 'ç.question.email.label',
			value: email?.value || '',
			type: 'text',
			required: false,
			validators: [ValidatorService.trimmedEmailValidator],
			class: 'span4',
		}),
		new InputSelect({
			key: 'language.id',
			label: 'ç.question.language.label',
			value: client?.language?.id || '',
			options: resources?.[ResourceType.LANGUAGE],
			required: true,
			class: 'span2',
		}),
		new InputPhone({
			key: 'phoneNumbers[0]',
			label: 'ç.question.mobileNumber.label',
			value: (mainPhoneNumber as I18nPhoneNumber) || '',
			allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
			required: false,
			class: 'span6',
			allowedTypes: ['MOBILE'],
		}),
	];
}
