import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { FileCostPrediction, MissingDataValidation, Offer } from '@oper-client/shared/data-model';
import { Update } from '@ngrx/entity';

export const setCurrentOfferId = createAction('[Offer/API] Set Current Offer Id', props<{ offerId: number }>());

export const setOpenedOfferIds = createAction('[Offer/API] Set Opened Offer Ids', props<{ offerIds: number[] }>());

export const loadOffer = createAction('[Offer/API] Load Offer', props<{ loanRequestId: number; offerId: number; validation?: boolean }>());
export const loadOfferSuccess = createAction('[Offer/API] Load Offer Success', props<{ offer: Offer }>());
export const loadOfferFailure = createAction('[Offer/API] Load Offer Failure', props<{ error: HttpErrorResponse; offerId: number }>());

export const loadOffers = createAction('[Offer/API] Load Offers', props<{ loanRequestId: number; params: { [key: string]: string } }>());
export const loadOffersSuccess = createAction('[Offer/API] Load Offers Success', props<{ offers: Offer[] }>());
export const loadOffersFailure = createAction('[Offer/API] Load Offers Failure', props<{ error: HttpErrorResponse }>());

export const createOffer = createAction('[Offer/API] Create Offer', props<{ loanRequestId: number; offer: Partial<Offer> }>());
export const createOfferSuccess = createAction('[Offer/API] Create Offer Success', props<{ offer: Offer }>());
export const createOfferFailure = createAction('[Offer/API] Create Offer Failure', props<{ error: HttpErrorResponse }>());

export const updateOffer = createAction('[Offer/API] Update Offer', props<{ loanRequestId: number; offer: any }>());
export const updateOfferSuccess = createAction('[Offer/API] Update Offer Success', props<{ offer: Update<Offer> }>());
export const updateOfferFailure = createAction('[Offer/API] Update Offer Failure', props<{ error: HttpErrorResponse; offer: any }>());

export const removeOffer = createAction('[Offer/API] Remove Offer', props<{ loanRequestId: number; offerId: number }>());
export const removeOfferSuccess = createAction('[Offer/API] Remove Offer Success', props<{ offerId: number }>());
export const removeOfferFailure = createAction('[Offer/API] Remove Offer Failure', props<{ error: HttpErrorResponse }>());

export const predictFileCosts = createAction('[Offer/API] Predict file costs', props<{ loanRequestId: number; offer: Partial<Offer> }>());
export const predictFileCostsSuccess = createAction('[Offer/API] Predict file costs success', props<{ fileCosts: FileCostPrediction }>());
export const predictFileCostsFailure = createAction('[Offer/API] Predict file costs failure', props<{ error: HttpErrorResponse }>());

export const missingDataValidation = createAction(
	'[Offer/API] Missing Data Validation',
	props<{ loanRequestId: number; offerId: number }>()
);
export const missingDataValidationSuccess = createAction(
	'[Offer/API] Missing Data Validation Success',
	props<{ offerId: number; missingDataValidation: MissingDataValidation }>()
);
export const missingDataValidationFailure = createAction(
	'[Offer/API] Missing Data Validation Failure',
	props<{ offerId: number; error: HttpErrorResponse }>()
);

export const clearOffers = createAction('[Offer] Clear Offers');
