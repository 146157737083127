import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Realty } from '@oper-client/shared/data-model';

export const setCurrentRealtyId = createAction('[Realties] Set Current Realty Id', props<{ realtyId: number }>());
export const clearCurrentRealtyId = createAction('[Realties] Clear Current Realty Id');

export const loadRealties = createAction('[Realties] Load Realties', props<{ loanRequestId: number }>());
export const loadRealtiesSuccess = createAction('[Realties] Load Realties Success', props<{ realties: Realty[] }>());
export const loadRealtiesFailure = createAction('[Realties] Load Realties Failure', props<{ error: any }>());

export const loadRealty = createAction('[Realties] Load Realty', props<{ loanRequestId: number; realtyId: number }>());
export const loadRealtySuccess = createAction('[Realties] Load Realty Success', props<{ realty: Realty }>());
export const loadRealtyFailure = createAction('[Realties] Load Realty Failure', props<{ error: any }>());

export const updateRealty = createAction(
	'[Realties] Update Realty',
	props<{ loanRequestId: number; realtyId: number; realty: Partial<Realty>; realtyMapping?: boolean }>()
);
export const updateRealtySuccess = createAction('[Realties] Update Realty Success', props<{ realty: Realty }>());
export const updateRealtyFailure = createAction('[Realties] Update Realty Failure', props<{ error: any }>());

export const createRealty = createAction(
	'[Realties] Create Realty',
	props<{ loanRequestId: number; realty: Partial<Realty>; realtyMapping?: boolean }>()
);
export const createRealtySuccess = createAction('[Realties] Create Realty Success', props<{ realty: Realty }>());
export const createRealtyFailure = createAction('[Realties] Create Realty Failure', props<{ error: any }>());

export const removeRealty = createAction('[Realty/API] Remove Realty', props<{ loanRequestId: number; realtyId: number }>());
export const removeRealtySuccess = createAction('[Realty/API] Remove Realty Success', props<{ realtyId: number }>());
export const removeRealtyFailure = createAction('[Realty/API] Remove Realty Failure', props<{ error: HttpErrorResponse }>());

export const resetRealtyState = createAction('[Realties] Reset Realty State');
export const resetRealtyActionState = createAction('[Realties] Reset Realty Action State');

export const showCreateNewPropertyModal = createAction('[Realties] Show Create New Property Modal');
export const hideCreateNewPropertyModal = createAction('[Realties] Hide Create New Property Modal');
