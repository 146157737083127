import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Milestone } from '@oper-client/shared/data-model';

export const loadMilestones = createAction('[LoanRequest] Load Milestones', props<{ loanRequestId: number }>());
export const loadMilestonesSuccess = createAction('[LoanRequest] Load Milestones Success', props<{ milestones: Milestone[] }>());
export const loadMilestonesFailure = createAction('[LoanRequest] Load Milestones Failure', props<{ error: HttpErrorResponse }>());
export const updateMilestone = createAction('[LoanRequest] Update Milestones', props<{ loanRequestId: number; milestoneId: number; milestone: Partial<Milestone>; }>());
export const updateMilestoneSuccess = createAction('[LoanRequest] Update Milestone Success', props<{ milestone: Milestone }>());
export const updateMilestoneFailure = createAction('[LoanRequest] Update Milestone Failure', props<{ error: HttpErrorResponse }>());
