import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { fetch } from '@ngrx/router-store/data-persistence';
import { of, forkJoin, from } from 'rxjs';
import { mergeMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';

import { Resource, TwoWayResourceType, ResourceType } from '@oper-client/shared/data-model';
import * as fromResources from './resources.reducer';
import * as ResourcesActions from './resources.actions';
import * as ResourcesSelectors from './resources.selectors';
import { ResourceService } from '../services/resource.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ResourcesEffects {
	loadResources$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ResourcesActions.loadResources),
			concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(ResourcesSelectors.getResourceTypes))))),
			mergeMap(([action, resourceTypesFromStore]) => {
				const { kinds } = action;
				const resourceTypesNotInStore = kinds.filter((kind) => !resourceTypesFromStore.includes(kind));
				const requests = resourceTypesNotInStore.reduce(
					(previous, current) => {
						const kind = TwoWayResourceType[TwoWayResourceType[current]];
						return {
							...previous,
							[kind]: this.resourceService.getResourcesByType(kind).pipe(catchError((httpError) => of(httpError))),
						};
					},
					{} as { [key in ResourceType]: any }
				);
				if (Object.keys(requests).length) {
					return forkJoin(requests).pipe(
						mergeMap((results: { [key in TwoWayResourceType]: Resource[] | HttpErrorResponse }) => {
							return from(
								Object.entries(results).map(([key, value]: [TwoWayResourceType, Resource[] | HttpErrorResponse]) => {
									if (value instanceof HttpErrorResponse) {
										return ResourcesActions.loadResourcesFailure({
											httpError: value,
											kind: TwoWayResourceType[TwoWayResourceType[key]],
										});
									}

									return ResourcesActions.loadResourcesSuccess({
										resources: value,
										kind: TwoWayResourceType[TwoWayResourceType[key]],
									});
								})
							);
						})
					);
				} else {
					return of(ResourcesActions.resourceIsAvailable());
				}
			})
		)
	);

	updateResource$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ResourcesActions.updateResource),
			fetch({
				run: (action) => {
					const { kind, resource } = action;
					return this.resourceService.updateResource(resource, kind).pipe(
						mergeMap((updatedtResource) => {
							return of(ResourcesActions.updateResourceSuccess({ resource: updatedtResource, kind }));
						})
					);
				},

				onError: (action, httpError: HttpErrorResponse) => {
					const { kind } = action;
					return ResourcesActions.updateResourceFailure({ httpError, kind });
				},
			})
		)
	);

	constructor(
		private actions$: Actions,
		private resourceService: ResourceService,
		private store: Store<fromResources.ResourcesState>,
		private translateService: TranslateService
	) {}
}
