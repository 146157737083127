import { HttpErrorResponse } from '@angular/common/http';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { combineReducers, createReducer, on } from '@ngrx/store';

import { CreditProvider, ActionState, ActionTypes, initialActionState, setActionState } from '@oper-client/shared/data-model';

import * as CreditProviderActions from './credit-provider.actions';

export const CREDIT_PROVIDER_ENTITY_KEY = 'creditProvider';

export const creditProviderAdapter: EntityAdapter<CreditProvider> = createEntityAdapter<CreditProvider>();
export const brokerCreditProviderAdapter: EntityAdapter<CreditProvider> = createEntityAdapter<CreditProvider>();

export interface CreditProviderState  {
	creditProviders: CreditProviderEntityState;
	brokerCreditProviders: BrokerCreditProviderEntityState;
}

export interface CreditProviderEntityState extends EntityState<CreditProvider> {
	actions: CreditProviderActionsState;
	error: HttpErrorResponse | undefined;
}

export interface BrokerCreditProviderEntityState extends EntityState<CreditProvider> {
	actions: BrokerCreditProviderActionsState;
	error: HttpErrorResponse | undefined;
}


export type CreditProviderFeatureActionTypes = CreditProviderActionTypes | BrokerCreditProviderActionTypes;

export type CreditProviderActionTypes = 'loadCreditProviders';
export type BrokerCreditProviderActionTypes = 'loadBrokerCreditProviders';
export type CreditProviderActionsState = Record<CreditProviderActionTypes, ActionState>;
export type BrokerCreditProviderActionsState = Record<BrokerCreditProviderActionTypes, ActionState>;

export const initialState: CreditProviderEntityState = creditProviderAdapter.getInitialState({
	actions: {
		loadCreditProviders: initialActionState,
	},
	error: undefined,
});

function setActionStates<T>(
	actionState: T,
	action: CreditProviderFeatureActionTypes,
	actionType: ActionTypes,
	error?: HttpErrorResponse
): T {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const creditProviderReducer = createReducer(
	initialState,
	on(CreditProviderActions.loadCreditProviders, state => ({ ...state })),
	on(CreditProviderActions.loadCreditProvidersSuccess, (state, { creditProviders }) =>
		creditProviderAdapter.setAll(creditProviders, {
			...state,
			actions: setActionStates(state.actions, 'loadCreditProviders', ActionTypes.success),
		})
	),
	on(CreditProviderActions.loadCreditProvidersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadCreditProviders', ActionTypes.failure, error),
	}))
);

export const brokerInitialState: BrokerCreditProviderEntityState = brokerCreditProviderAdapter.getInitialState({
	actions: {
		loadBrokerCreditProviders: initialActionState,
	},
	error: undefined,
});

export const brokerProviderReducer = createReducer(
	brokerInitialState,
	on(CreditProviderActions.loadBrokerCreditProviders, state => ({
		...state,
		actions: setActionStates(state.actions, 'loadBrokerCreditProviders', ActionTypes.loading)
	})),
	on(CreditProviderActions.loadBrokerCreditProvidersSuccess, (state, { creditProviders }) =>
		brokerCreditProviderAdapter.setAll(creditProviders, {
			...state,
			actions: setActionStates(state.actions, 'loadBrokerCreditProviders', ActionTypes.success),
		})
	),
	on(CreditProviderActions.loadBrokerCreditProvidersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadBrokerCreditProviders', ActionTypes.failure, error),
	}))

);

export const reducer = combineReducers({
	creditProviders: creditProviderReducer,
	brokerCreditProviders: brokerProviderReducer,
});
