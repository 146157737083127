import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadUiComponent } from './file-upload-ui.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [CommonModule, TranslateModule, FontAwesomeModule],
	declarations: [FileUploadUiComponent, FileSizePipe],
	exports: [FileUploadUiComponent],
})
export class FileUploadUiModule {}
