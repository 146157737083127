import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { ValidatorService } from '../services/validator.service';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'newPassword',
			label: 'ç.question.newPassword.label',
			value: null,
			type: 'password',
			required: true,
			class: 'span12',
			revealPassword: false,
		}),
		new InputField({
			key: 'confirmPassword',
			label: 'ç.question.confirmPassword.label',
			value: null,
			type: 'password',
			required: true,
			class: 'span12',
			revealPassword: false,
			validators: [
				ValidatorService.getConditionControlValidator({
					validationMessageKey: `passwordsDontMatch`,
					conditionControlKey: 'newPassword',
					condition: (controlValue, conditionControlValue) => controlValue === conditionControlValue,
				}),
			],
		}),
	];
}
