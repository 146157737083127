import { Resource } from './resource.model';

export enum Gender {
	F = 'F',
	M = 'M',
}

export enum IdentityState {
	LOADING = 'loading',
	VERIFIED = 'verified',
	ERROR = 'error',
}

export enum IdentityProvider {
	ITMSE = 'itsme',
	BEEID = 'beID',
}

export interface UserDataConnective {
	birthDate: string;
	city: string;
	eidSn: string;
	firstName: string;
	lastName: string;
	nationalNumber: string;
	phoneNumber: string;
	sex: Resource;
	street: string;
	zipCode: string;
	email?: string;
	nationality?: Resource;
	birthLocation?: string;
	cardNumber?: string;
	cardValidityDateTo?: string;
	cardValidityDateFrom?: string;
	cardDeliveredInTown?: string;
	firstLetterOf3rdGivenName?: string;
	nobleCondition?: unknown;
	documentType?: string;
	specialStatus?: string;
	sub?: string;
	pinverified?: string;
	dataverified?: string;
	gender?: Gender;
}

export interface IdentityPayload {
	clientId?: number;
	identityProvider?: IdentityProvider;
	state?: IdentityState;
	value?: UserDataConnective;
}
