import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { Permission } from '@oper-client/shared/data-model';
import { CaseStyleService } from '@oper-client/shared/util-formatting';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService, private caseStyleService: CaseStyleService) {}

	getPermissions(): Observable<string[]> {
		return this.apiService.get('/api/me/permissions/').pipe(
			map((permissions: Permission[]) => {
				return permissions.map((permission: Permission) => {
					return this.caseStyleService.toCamelCase(`${permission.appLabel}.${permission.codename}`);
				});
			})
		);
	}
}
