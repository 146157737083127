import { CreditProvider, DocumentLibrary, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData?: DocumentLibrary,
	resources?: PartialNormalizedResource,
	creditProviders: CreditProvider[] = []
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'creditProvider.id',
			value: formData?.creditProvider?.id,
			label: 'ç.question.creditProvider.label',
			defaultLabel: 'ç.feature.offer.product.lender',
			required: false,
			validators: [],
			options: orderBy(
				creditProviders?.map((provider) => ({ key: provider.name, id: provider.id, order: provider.order })) || [],
				'order'
			),
			alreadySorted: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'documentType.id',
			label: 'ç.question.documentType.label',
			value: formData?.documentType?.id,
			validators: [],
			options: resources?.[ResourceType.LIBRARY_DOCUMENT_TYPE] || [],
			required: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'language.id',
			label: 'ç.question.language.label',
			value: formData?.language?.id || '',
			options: resources?.[ResourceType.LANGUAGE],
			alreadySorted: true,
			prefillDefaultValue: true,
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'fileName',
			label: 'ç.question.fileName.label',
			value: formData?.fileName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
	];
}
