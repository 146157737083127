import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Integrator, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { FormGroup } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { InputBase } from '../../../models/input-base.model';
import { InputField, InputSelect } from '../../../models/input-types.model';

export default function (formData: any, resources: PartialNormalizedResource, integrators: Array<Integrator>): InputBase<any>[] {
	const isNotElantis = (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
		const elantisId = integrators.find((_) => _.name === 'elantis')?.id;
		const subject = new BehaviorSubject(formGroup.value['integrator.id'] !== elantisId);

		formGroup.controls['integrator.id'].valueChanges
			.pipe(
				map((value) => !value || value !== elantisId),
				takeUntil(destroy$)
			)
			.subscribe({
				complete: () => subject.complete(),
				error: (error) => subject.error(error),
				next: (value) => subject.next(value),
			});

		return subject.asObservable();
	};
	return [
		new InputSelect({
			key: 'integrator.id',
			label: 'ç.question.searchForLender.title',
			options: integrators.map((_) => ({ ..._, key: `ç.resource.integrator.${_['name']}` })),
			value: null,
			class: 'span12',
			searchable: true,
			required: true,
			order: 1,
		}),
		new InputField({
			key: 'additionalData.broker',
			label: 'ç.question.broker.label',
			hidden: isNotElantis,
			value: null,
			class: 'span12',
			required: true,
			order: 1,
		}),
		new InputField({
			key: 'additionalData.subBroker',
			label: 'ç.question.subBroker.label',
			hidden: isNotElantis,
			value: null,
			class: 'span12',
			required: false,
			order: 1,
		}),
	];
}
