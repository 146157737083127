import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as IncomeActions from './income.actions';
import { LoanRequestSubentityService, INCOME_SERVICE } from '@oper-client/shared/data-access';
import { Income } from '@oper-client/shared/data-model';

@Injectable()
export class IncomeEffects {
	loadIncomes$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IncomeActions.loadIncomes),
			concatMap(({ loanRequestId, clientId }) =>
				this.incomeService.getAll(loanRequestId, clientId).pipe(
					map((incomes) => IncomeActions.loadIncomesSuccess({ incomes })),
					catchError((error) => of(IncomeActions.loadIncomesFailure({ error })))
				)
			)
		);
	});

	loadIncome$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IncomeActions.loadIncome),
			concatMap(({ loanRequestId, clientId, incomeId }) =>
				this.incomeService.get(loanRequestId, clientId, incomeId).pipe(
					map((income) => IncomeActions.loadIncomeSuccess({ income })),
					catchError((error) => of(IncomeActions.loadIncomeFailure({ error })))
				)
			)
		);
	});

	addIncome$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IncomeActions.addIncome),
			concatMap(({ loanRequestId, clientId, income }) =>
				this.incomeService.create(loanRequestId, clientId, income).pipe(
					map((createdIncome) => IncomeActions.addIncomeSuccess({ income: createdIncome })),
					catchError((error) => of(IncomeActions.addIncomeFailure({ error })))
				)
			)
		);
	});

	updateIncome$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IncomeActions.updateIncome),
			concatMap(({ loanRequestId, clientId, income }) =>
				this.incomeService.update(loanRequestId, clientId, +income.id, income.changes).pipe(
					map((updatedIncome) =>
						IncomeActions.updateIncomeSuccess({
							income: {
								id: updatedIncome.id,
								changes: updatedIncome,
							},
						})
					),
					catchError((error) => of(IncomeActions.updateIncomeFailure({ error })))
				)
			)
		);
	});

	deleteIncome$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(IncomeActions.deleteIncome),
			concatMap(({ loanRequestId, clientId, id }) =>
				this.incomeService.delete(loanRequestId, clientId, id).pipe(
					map(() => IncomeActions.deleteIncomeSuccess({ id })),
					catchError((error) => of(IncomeActions.deleteIncomeFailure({ error })))
				)
			)
		);
	});

	constructor(private actions$: Actions, @Inject(INCOME_SERVICE) private readonly incomeService: LoanRequestSubentityService<Income>) {}
}
