import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ClientActions from './client.actions';
import { Client } from '@oper-client/shared/data-model';
import { CLIENT_SERVICE, IClientService } from '@oper-client/shared/data-access';

@Injectable()
export class ClientEffects {
	loadClients$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ClientActions.loadClients),
			mergeMap(({ loanRequestId }) =>
				this.clientService.getAll(loanRequestId).pipe(
					map((clients: Client[]) => ClientActions.loadClientsSuccess({ clients: clients })),
					catchError(error => of(ClientActions.loadClientsFailure({ error: error })))
				)
			)
		)
	);

	loadClient$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ClientActions.loadClient),
			mergeMap(({ loanRequestId, clientId }) =>
				this.clientService.get(loanRequestId, clientId).pipe(
					map((client: Client) => ClientActions.loadClientSuccess({ client: client })),
					catchError(error => of(ClientActions.loadClientFailure({ error: error })))
				)
			)
		)
	);

	addClient$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ClientActions.addClient),
			mergeMap(({ loanRequestId, client }) =>
				this.clientService.create(loanRequestId, client).pipe(
					map((newClient: Client) => ClientActions.addClientSuccess({ client: newClient })),
					catchError(error => of(ClientActions.addClientFailure({ error: error })))
				)
			)
		)
	);

	addClientSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ClientActions.addClientSuccess),
			map(() => ClientActions.clearClientActionStates())
		)
	);

	updateClient$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ClientActions.updateClient),
			mergeMap(({ loanRequestId, client }) =>
				this.clientService.update(loanRequestId, +client.id, client.changes).pipe(
					map((updatedClient: Client) =>
						ClientActions.updateClientSuccess({ client: { id: updatedClient.id, changes: updatedClient } })
					),
					catchError(error => of(ClientActions.updateClientFailure({ error: error })))
				)
			)
		)
	);

	inviteClient$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ClientActions.inviteClient),
			mergeMap(({ clientId, loanRequestId }) =>
				this.clientService.invite(loanRequestId, clientId).pipe(
					map(() => ClientActions.inviteClientSuccess({ client: { id: clientId, changes: { verified: false } } })),
					catchError(error => of(ClientActions.inviteClientFailure({ error })))
				)
			)
		)
	);

	removeClient$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ClientActions.removeClient),
			mergeMap(({ loanRequestId, clientId }) =>
				this.clientService.delete(loanRequestId, clientId).pipe(
					map(() => ClientActions.removeClientSuccess({ clientId })),
					catchError(error => of(ClientActions.removeClientFailure({ error })))
				)
			)
		)
	);

	constructor(private actions$: Actions, @Inject(CLIENT_SERVICE) private clientService: IClientService) {}
}
