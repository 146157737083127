import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpHeaders, HttpProgressEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { Resource, Document, Proof, Offer } from '@oper-client/shared/data-model';

@Injectable({
	providedIn: 'root',
})
export class DocumentService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	//TODO Extract params conversion from LoanRequestService::getLoanRequest, LoanRequestService::getHistories
	//     and DocumentService::setHttpParams to a static function in ApiService.
	private setHttpParams(queryParams: { [key: string]: string } = {}): HttpParams {
		let params = new HttpParams();
		for (const key of Object.keys(queryParams)) {
			params = params.set(key, queryParams[key]);
		}
		return params;

		// return new HttpParams()
		// 	.set('loan_request', params.loanRequestId.toString())
		// 	.set('page', params.pagination.pageNumber.toString())
		// 	.set('page_size', params.pagination.pageSize.toString())
		// 	.set('search', params.filter);
	}

	public getProofs(loanRequestId: number): Observable<Proof[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/`);
	}

	public getProofDocuments(loanRequestId: number, categoryId: number): Observable<Document[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/documents/`);
	}

	public getProofDocument(loanRequestId: number, categoryId: number, documentId: number): Observable<Proof> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/documents/${documentId}/`);
	}

	public getAllowedProofDocuments(kind: string, loanRequestId: number, categoryId: number): Observable<Resource[]> {
		if (!kind) {
			throw new Error('Required parameter kind was null or undefined when  calling getAllowedProofDocuments');
		}
		if (kind === 'documentSigningRequired') {
			return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/signed-document-types/`);
		}
		if (kind === 'isInformativeDocument') {
			return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/informative-document-types/`);
		}
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/allowed-document-types/`);
	}

	public updateProofDocument(
		loanRequestId: number,
		categoryId: number,
		documentId: number,
		body: Partial<Document>
	): Observable<Document> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/documents/${documentId}/`, body);
	}

	public uploadProofFile(
		loanRequestId: number,
		categoryId: number,
		documentId: number,
		file: File
	): Observable<Document | HttpProgressEvent> {
		const payload = new FormData();
		payload.set('file', file, file.name || '');
		const headers = new HttpHeaders().set('content-type', 'multipart/form-data');
		return this.apiService.patch(
			`/api/loan-requests/${loanRequestId}/proof/${categoryId}/documents/${documentId}/`,
			payload,
			null,
			headers,
			{
				reportProgress: true,
				observe: 'events',
			}
		);
	}

	public addProofDocument(loanRequestId: number, categoryId: number, body: Partial<Document>): Observable<Document> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/documents/`, body);
	}

	public deleteProofDocument(loanRequestId: number, categoryId: number, documentId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/documents/${documentId}/`);
	}

	public deleteProofDocumentFile(loanRequestId: number, categoryId: number, documentId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/proof/${categoryId}/documents/${documentId}/?type=file`);
	}

	public generateDocument(loanRequestId: number, body: { documentType: Resource; loanOffer?: Partial<Offer> }): Observable<Document> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/generate-documents/`, body);
	}

	// The content is a 64bit encoded file, so kind of a string
	public downloadProofZip(loanRequestId: number, queryParams: { [key: string]: string } = {}): Observable<string> {
		const httpParams: HttpParams = this.setHttpParams(queryParams);
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof-download/`, httpParams);
	}

	// The content is an octet stream of bytes (I think) #consistency
	public downloadDatFile(loanRequestId: number): Observable<any> {
		// Make sure that Angular doesn't parse the body to JSON and that we can access the headers
		const options = { responseType: 'text', observe: 'response' };
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/generate-documents/record/dat/`, {}, null, null, options);
	}

	public getUploadDocuments(loanRequestId: number): Observable<Document[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/documents/`);
	}

	public getUploadDocument(loanRequestId: number, uploadDocumentId: number): Observable<Document> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/documents/${uploadDocumentId}/`);
	}

	// Body value is any as there can be files as well
	public uploadDocument(loanRequestId: number, body: { [key: string]: any }, headers: HttpHeaders = new HttpHeaders()) {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/documents/`, body, null, headers);
	}

	public analyseUploadDocuments(loanRequestId: number): Observable<void> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/analysis/`);
	}

	public getCommissionStatementDocuments(brokerId: number): Observable<Document[]> {
		let httpParams = new HttpParams();
		httpParams = httpParams.set('broker', brokerId.toString());
		return this.apiService.get(`/api/commissions/documents/`, httpParams);
	}
}
