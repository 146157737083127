import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { API_SERVICE, AUTH_SERVICE, IsAuthenticatedGuard, SsoRoleGuard, ROLE_GUARD } from '@oper-client/shared/data-access';
import { EnvironmentLocaleFormatService } from '@oper-client/shared/util-formatting';
import { ApiV2Module, MsAuthService } from '@oper-client/shared/api-v2.0';

import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalService,
} from '@azure/msal-angular';
import { MsaSsoAppRoutingModule } from './msa-sso-app-routing.module';
import { MsaSsoAppComponent } from './msa-sso-app.component';
import { CommonBrokerageAppModule } from '../common-brokerage-app/common-brokerage-app.module';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(config: CustomerInsights): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: config?.msAuth.clientId,
			authority: config?.msAuth.authority,
			redirectUri: config?.msAuth.redirectUri,
			postLogoutRedirectUri: config?.msAuth.postLogoutRedirectUri,
			navigateToLoginRequestUrl: false,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE, // set to true for IE 11
		},
	});
}

export function MSALInterceptorConfigFactory(config: CustomerInsights): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set(`/version.json`, null);
	protectedResourceMap.set(`${config?.serverUrl}/contact/`, null);
	protectedResourceMap.set(`${config?.serverUrl}/configuration/version/`, null);
	protectedResourceMap.set(`${config?.serverUrl}/resources/language/`, null);
	protectedResourceMap.set(`${config?.serverUrl}/resources/features/`, null);
	protectedResourceMap.set(`${config?.serverUrl}`, config?.msAuth.apiConfig.scopes);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export function MSALGuardConfigFactory(config: CustomerInsights): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: config?.msAuth?.apiConfig?.scopes ? [...config.msAuth.apiConfig.scopes] : [],
		},
	};
}

@NgModule({
	declarations: [MsaSsoAppComponent],
	imports: [
		CommonModule,
		CommonBrokerageAppModule,
		MsaSsoAppRoutingModule,
		MsalModule,

		ApiV2Module.forRoot({
			authService: MsAuthService,
			deps: [API_SERVICE, MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, EnvironmentLocaleFormatService],
		}),
	],
	providers: [
		[
			/* AUTH providers */
			{
				provide: HTTP_INTERCEPTORS,
				useClass: MsalInterceptor,
				multi: true,
			},
			{
				provide: MSAL_INTERCEPTOR_CONFIG,
				useFactory: MSALInterceptorConfigFactory,
				deps: [CUSTOMER_INSIGHTS_CONFIG],
			},
			{
				provide: MSAL_INSTANCE,
				useFactory: MSALInstanceFactory,
				deps: [CUSTOMER_INSIGHTS_CONFIG],
			},
			{
				provide: MSAL_GUARD_CONFIG,
				useFactory: MSALGuardConfigFactory,
				deps: [CUSTOMER_INSIGHTS_CONFIG],
			},
			{
				provide: ROLE_GUARD,
				deps: [AUTH_SERVICE, Router],
				useClass: SsoRoleGuard,
			},
			MsalService,
			MsalGuard,
			MsalBroadcastService,
			{
				provide: IsAuthenticatedGuard,
				deps: [AUTH_SERVICE, Router],
			},
		],
	],
	bootstrap: [MsaSsoAppComponent],
})
export class MsaSsoAppModule {}
