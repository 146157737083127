import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { of } from 'rxjs';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'hasPoliticallyExposedPersonStatus',
			label: 'ç.question.hasPoliticallyExposedPersonStatus.label',
			value: formData?.hasPoliticallyExposedPersonStatus || false,
			options: [
				{ id: 1, key: 'ç.misc.yes', definition: true },
				{ id: 2, key: 'ç.misc.no', definition: false },
			],
			bindValue: 'definition',
			class: 'span6',
			required: false,
			clearable: false,
			helpText: of('PEP')
		}),
		new InputSelect({
			key: 'compliancePersonalizedInformation.id',
			label: 'ç.question.compliancePersonalizedInformation.label',
			value: formData?.compliancePersonalizedInformation?.id,
			required: false,
			options: resources?.[ResourceType.PERSONALIZED_INFORMATION] || [],
			class: 'span6'
		}),
	];
}
