import { Inject, Injectable } from '@angular/core';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentLocaleFormatService {
	constructor(@Inject(CUSTOMER_INSIGHTS_CONFIG) private readonly customerInsights: CustomerInsights) {}

	formatFullName(person: Partial<{ firstName: string; lastName: string }>): string {
		return (
			this.customerInsights?.fullNamePattern?.replace(/\{\{\w+\}\}/g, (placeholder) => {
				return person?.[placeholder.substring(2,placeholder.length - 2)] || '';
			}) || `${person?.firstName} ${person?.lastName}`
		).trim();
	}

	formatFullNameInitials(person: Partial<{ firstName: string; lastName: string }>): string {
		return this.formatFullName({ firstName: person?.firstName?.[0], lastName: person?.lastName?.[0] }).replace(/\s/g, '');
	}
}
