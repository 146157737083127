import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { COMMON_REGEX_EXPRESSIONS } from '../services/validator-constants';
import { validateSeg9731Alg } from '../services/validator-util';

export function vatNumberValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}
		const value = control.value.replace(/-/g, '');
		if (value.length !== 11 || !new RegExp(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS).test(value)) {
			return { vatNumber: true };
		}

		return validateSeg9731Alg(value.slice(0, 7), parseInt(value.charAt(7))) ? null : { vatNumber: true };
	};
}
