export enum Application {
	BROKERAGE = 'brokerage',
	SELF_SERVICE = 'selfService',
}

export interface DeviceInfo {
	browser: string;
	os: string;
	device: string;
	userAgent: string;
	os_version: string;
}
