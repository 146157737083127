import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Commission, CommissionStatement, CommissionStatementFilterParams } from '@oper-client/shared/data-model';

// Clear Commissions
export const clearCommissions = createAction('[Commission] Clear Commissions');

// Load Commissions
export const loadCommissions = createAction(
	'[Commission] Load Commissions',
	props<{ commissionFilterParams: CommissionStatementFilterParams }>()
);
export const loadCommissionsSuccess = createAction('[Commission] Load Commissions Success', props<{ commissions: Commission[] }>());
export const loadCommissionsFailure = createAction('[Commission] Load Commissions Failure', props<{ error: HttpErrorResponse }>());

// Load Commission Statement commissionStatementDocumentFilterParams
export const loadCommissionStatement = createAction(
	'[Commission] Load Commission Statement',
	props<{ commissionFilterParams: CommissionStatementFilterParams }>()
);
export const loadCommissionStatementSuccess = createAction(
	'[Commission] Load Commission Statement Success',
	props<{ commissionStatement: CommissionStatement }>()
);
export const loadCommissionStatementFailure = createAction(
	'[Commission] Load Commission Statement Failure',
	props<{ error: HttpErrorResponse }>()
);
