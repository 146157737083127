import { InputField, PercentageInputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { ConfigurationPortalProductVersion } from '@oper-client/configuration-portal/common';

export default function (formData?: ConfigurationPortalProductVersion, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'version',
			label: 'ç.configurationPortal.product.version',
			value: formData?.version,
			type: 'number',
			class: 'span12',
			required: false,
		}),
		new PercentageInputField({
			key: 'maxLtv',
			label: 'ç.configurationPortal.product.maxLtv',
			value: formData?.maxLtv,
			type: 'percentage',
			class: 'span12',
			required: false,
		}),
		new PercentageInputField({
			key: 'minLtv',
			label: 'ç.configurationPortal.product.minLtv',
			value: formData?.minLtv,
			type: 'percentage',
			class: 'span12',
			required: false,
		}),
		new PercentageInputField({
			key: 'maxDti',
			label: 'ç.configurationPortal.product.maxDti',
			value: formData?.maxDti,
			type: 'percentage',
			class: 'span12',
			required: false,
		}),
		new PercentageInputField({
			key: 'minDti',
			label: 'ç.configurationPortal.product.minDti',
			value: formData?.minDti,
			type: 'percentage',
			class: 'span12',
			required: false,
		}),
		new InputField({
			key: 'maxAmount',
			label: 'ç.configurationPortal.product.maxAmount',
			value: formData?.maxAmount,
			type: 'number',
			class: 'span12',
			required: false,
		}),
		new InputField({
			key: 'minAmount',
			label: 'ç.configurationPortal.product.minAmount',
			value: formData?.minAmount,
			type: 'number',
			class: 'span12',
			required: false,
		}),
		new InputField({
			key: 'maxLiquidities',
			label: 'ç.configurationPortal.product.maxLiquidities',
			value: formData?.maxLiquidities,
			type: 'number',
			class: 'span12',
			required: false,
		}),
		new InputField({
			key: 'maxRenovation',
			label: 'ç.configurationPortal.product.maxRenovation',
			value: formData?.maxRenovation,
			type: 'number',
			class: 'span12',
			required: false,
		}),
	];
}
