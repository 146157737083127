import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { FilterParams, Offer, Product, ProductExplorerStep } from '@oper-client/shared/data-model';

import { setActiveStep, reset, setOfferToUpdate, setSelectedProducts, setSearchFilterParams } from './product-explorer.actions';
import { getActiveStep, getOfferToUpdate, getSelectedProducts, getSearchFilterParams } from './product-explorer.selectors';
import { LoanRequestPartialState } from '../loan-request.reducer';
import { Observable } from 'rxjs';

@Injectable()
export class ProductExplorerFacade {
	activeStep$ = this.store.pipe(select(getActiveStep));
	offerToUpdate$: Observable<Partial<Offer>> = this.store.pipe(select(getOfferToUpdate));
	selectedProducts$: Observable<Product[]> = this.store.pipe(select(getSelectedProducts));
	searchFilterParams$ = this.store.pipe(select(getSearchFilterParams));

	constructor(private store: Store<LoanRequestPartialState>) {}

	setActiveStep(activeStep: ProductExplorerStep): void {
		this.store.dispatch(setActiveStep({ activeStep }));
	}

	setOfferToUpdate(offerToUpdate: Partial<Offer>): void {
		this.store.dispatch(setOfferToUpdate({ offerToUpdate }));
	}

	setSelectedProducts(selectedProducts: Product[]): void {
		this.store.dispatch(
			setSelectedProducts({ selectedProducts: selectedProducts.map((selectedProduct) => Object.assign({}, selectedProduct)) })
		);
	}

	setSearchFilterParams(searchFilterParams: Partial<FilterParams>): void {
		this.store.dispatch(setSearchFilterParams({ searchFilterParams }));
	}

	reset(): void {
		this.store.dispatch(reset());
	}
}
