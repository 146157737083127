import { Component, ChangeDetectionStrategy, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseSwitch } from '../base-switch/base-switch.component';

type SwitchColors = 'primary' | 'grey' | 'white';

@Component({
	selector: 'oper-client-toggle-switch',
	templateUrl: './toggle-switch.component.html',
	styleUrls: ['./toggle-switch.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ToggleSwitch),
		},
	],
})
export class ToggleSwitch extends BaseSwitch {
	@Input() backgroundColor: SwitchColors | null = null;
	@Input() buttonColor: SwitchColors | null = null;
}
