import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { yearOptions1500 } from '@oper-client/shared/util-formatting';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'comfortType.id',
			label: 'ç.question.comfortType.label',
			value: formData?.comfortType?.id,
			required: false,
			options: resources?.[ResourceType.COMFORT_TYPE] || [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'constructionType.id',
			label: 'ç.question.constructionType.label',
			value: formData?.constructionType?.id,
			required: false,
			options: resources?.[ResourceType.CONSTRUCTION_TYPE] || [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'conditionOfProperty.id',
			label: 'ç.question.conditionOfProperty.label',
			value: formData?.conditionOfProperty?.id,
			required: false,
			options: resources?.[ResourceType.CONDITION_OF_PROPERTY] || [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'yearOfBuilt',
			label: 'ç.question.yearOfBuilt.label',
			value: formData?.yearOfBuilt || null,
			options: yearOptions1500,
			validators: [],
			required: false,
			class: 'span6',
			alreadySorted: true,
		}),
		new InputField({
			key: 'surface',
			label: 'ç.question.surface.label',
			value: formData?.surface,
			type: 'number',
			required: false,
			class: 'span6',
			digitsInfo: '1.2-4',
		})
	];
}
