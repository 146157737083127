import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COMMISSIONSTATEMENT_FEATURE_KEY, CommissionStatementState, commissionStatementAdapter } from './commission-statement.reducer';

// Lookup the 'CommissionStatement' feature state managed by NgRx
export const getCommissionStatementState = createFeatureSelector<CommissionStatementState>(COMMISSIONSTATEMENT_FEATURE_KEY);

const { selectAll, selectEntities } = commissionStatementAdapter.getSelectors();

export const getCommissionStatements = createSelector(getCommissionStatementState, (state: CommissionStatementState) => selectAll(state));
export const getCommissionStatementEntities = createSelector(getCommissionStatementState, (state: CommissionStatementState) =>
	selectEntities(state)
);
export const getCommissionStatementError = createSelector(getCommissionStatementState, (state: CommissionStatementState) => state.error);
