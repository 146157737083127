import { createSelector } from '@ngrx/store';
import { getDocumentFeatureState } from '../document.selectors';
import { PROOF_ENTITY_KEY, proofAdapter, ProofActionTypes } from './proof.reducer';

export const getProofState = createSelector(getDocumentFeatureState, state => state[PROOF_ENTITY_KEY]);

const proofSelectors = proofAdapter.getSelectors();
export const getProof = createSelector(getProofState, proofSelectors.selectAll);
export const getAllowedDocuments = createSelector(getProofState, state => state.allowedDocuments);

export const getActionStates = createSelector(getProofState, state => state.actions);
export const getActionState = (actionType: ProofActionTypes) => createSelector(getActionStates, state => state[actionType]);

export const getZipFiles = createSelector(getProofState, state => state.downloadZippedProofDocuments);
export const getGeneratedDocument = createSelector(getProofState, state => state.generatedDocument);
