export enum UserPermissions {
	CREDIT_PROVIDER_MODULE_CHANGE_BASE_PRODUCT = 'creditprovidermodule.changeBaseproduct',
	PRICING_MODULE_VIEW_PRICING_SHEET = 'pricingmodule.viewPricingsheet',
	PRICING_MODULE_ADD_PRICING_SHEET = 'pricingmodule.addPricingsheet',
	PRICING_MODULE_CHANGE_PRICING_SHEET = 'pricingmodule.changePricingsheet',
	LOAN_REQUEST_MODULE_VIEW_LOANREQUEST = 'loanrequestmodule.viewLoanrequest',
	TASK_MODULE_VIEW_TASK = 'taskmodule.viewTask',
	DOCUMENT_LIBRARY_MODULE_VIEW = 'documentationlibrarymodule.viewLibrarydocument',
	DOCUMENT_LIBRARY_MODULE_ADD = 'documentationlibrarymodule.addLibrarydocument',
	DOCUMENT_LIBRARY_MODULE_CHANGE = 'documentationlibrarymodule.changeLibrarydocument',
	LOAN_REQUEST_MODULE_IGNORE_BLOCKING_ACCEPTANCE_ERRORS = 'loanrequestmodule.ignoreBlockingAcceptanceErrors',
}
