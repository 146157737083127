import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
	ActionState,
	ActionTypes,
	CalculateAmortization,
	initialActionState,
	Liability,
	LiabilityConsultation,
	setActionState,
} from '@oper-client/shared/data-model';

import * as LiabilityActions from './liability.actions';

export const LIABILITIES_ENTITY_KEY = 'liabilities';

export interface LiabilityState extends EntityState<Liability> {
	// additional entities state properties
	actions: LiabilityActionsState;
	calculatedAmortization: CalculateAmortization | null;
	consultations: LiabilityConsultation[];
}

export type LiabilityActionTypes =
	| 'loadLiability'
	| 'loadLiabilities'
	| 'loadConsultations'
	| 'triggerConsultations'
	| 'createLiability'
	| 'updateLiability'
	| 'calculateAmortization'
	| 'removeLiability';
export type LiabilityActionsState = Record<LiabilityActionTypes, ActionState>;

export const liabilityAdapter: EntityAdapter<Liability> = createEntityAdapter<Liability>();

export const initialState: LiabilityState = liabilityAdapter.getInitialState({
	// additional entity state properties
	consultations: [],
	actions: {
		loadLiability: initialActionState,
		loadLiabilities: initialActionState,
		createLiability: initialActionState,
		updateLiability: initialActionState,
		calculateAmortization: initialActionState,
		removeLiability: initialActionState,
		loadConsultations: initialActionState,
		triggerConsultations: initialActionState,
	},
	calculatedAmortization: null,
});

function setActionStates(
	actionState: LiabilityActionsState,
	action: LiabilityActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): LiabilityActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,
	on(LiabilityActions.addLiability, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.addLiabilitySuccess, (state, { liability }) =>
		liabilityAdapter.addOne(liability, { ...state, actions: setActionStates(state.actions, 'createLiability', ActionTypes.success) })
	),
	on(LiabilityActions.addLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.updateLiability, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.updateLiabilitySuccess, (state, { liability }) =>
		liabilityAdapter.updateOne(liability, { ...state, actions: setActionStates(state.actions, 'updateLiability', ActionTypes.success) })
	),
	on(LiabilityActions.updateLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.deleteLiability, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'removeLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.deleteLiabilitySuccess, (state, { id }) =>
		liabilityAdapter.removeOne(id, { ...state, actions: setActionStates(state.actions, 'removeLiability', ActionTypes.success) })
	),
	on(LiabilityActions.deleteLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.loadLiability, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiability', ActionTypes.loading),
	})),
	on(LiabilityActions.loadLiabilitySuccess, (state, { liability }) =>
		liabilityAdapter.upsertOne(liability, { ...state, actions: setActionStates(state.actions, 'loadLiability', ActionTypes.success) })
	),
	on(LiabilityActions.loadLiabilityFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiability', ActionTypes.failure, error),
	})),

	on(LiabilityActions.loadLiabilities, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiabilities', ActionTypes.loading),
	})),
	on(LiabilityActions.loadLiabilitiesSuccess, (state, { liabilities }) =>
		liabilityAdapter.upsertMany(liabilities, {
			...state,
			actions: setActionStates(state.actions, 'loadLiabilities', ActionTypes.success),
		})
	),
	on(LiabilityActions.loadLiabilitiesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLiabilities', ActionTypes.failure, error),
	})),
	// Load Consultations
	on(LiabilityActions.loadConsultations, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadConsultations', ActionTypes.loading),
	})),
	on(LiabilityActions.loadConsultationsSuccess, (state, { consultations }) => ({
		...state,
		consultations,
		actions: setActionStates(state.actions, 'loadConsultations', ActionTypes.success),
	})),
	on(LiabilityActions.loadConsultationsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadConsultations', ActionTypes.failure, error),
	})),
	// Trigger Consultations
	on(LiabilityActions.triggerConsultations, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'triggerConsultations', ActionTypes.loading),
	})),
	on(LiabilityActions.triggerConsultationsSuccess, (state, { consultations }) => ({
		...state,
		consultations,
		actions: setActionStates(state.actions, 'triggerConsultations', ActionTypes.success),
	})),
	on(LiabilityActions.triggerConsultationsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'triggerConsultations', ActionTypes.failure, error),
	})),
	on(LiabilityActions.calculateAmortization, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateAmortization', ActionTypes.loading),
	})),
	on(LiabilityActions.calculateAmortizationSuccess, (state, { calculateAmortizationResponse }) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateAmortization', ActionTypes.success),
		calculatedAmortization: calculateAmortizationResponse,
	})),
	on(LiabilityActions.calculateAmortizationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateAmortization', ActionTypes.failure, error),
		calculatedAmortization: null,
	})),

	on(LiabilityActions.clearLiabilities, (state) => liabilityAdapter.removeAll(state))
);
