import { Realty } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData?: Partial<Realty>): InputBase<any>[] {
	return [
		new InputField({
			key: 'landRegister',
			label: 'ç.question.landRegister.label',
			value: formData?.landRegister,
			type: 'text',
			required: false,
			class: 'span6',
			order: 1,
		}),
		new InputField({
			key: 'districtCourt',
			label: 'ç.question.districtCourt.label',
			value: formData?.districtCourt,
			type: 'text',
			required: false,
			class: 'span6',
		}),
		new InputField({
			key: 'cadastralLocation',
			label: 'ç.question.cadastralLocation.label',
			value: formData?.cadastralLocation,
			type: 'text',
			required: false,
			class: 'span6',
		}),
		new InputField({
			key: 'plotNumber',
			label: 'ç.question.plotNumber.label',
			value: formData?.plotNumber,
			type: 'text',
			required: false,
			validators: [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'kleinBeschrijf',
			label: 'ç.question.kleinBeschrijf.label',
			value: formData?.kleinBeschrijf || false,
			options: [
				{ id: 1, key: 'ç.misc.yes', definition: true },
				{ id: 2, key: 'ç.misc.no', definition: false },
			],
			bindValue: 'definition',
			class: 'span6',
			required: false,
			clearable: false,
		}),
		new InputField({
			key: 'propertyNumber',
			label: 'ç.question.propertyNumber.label',
			value: formData?.propertyNumber,
			type: 'text',
			required: false,
			class: 'span6',
		}),
	];
}
