import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState, Income } from '@oper-client/shared/data-model';

import { ClientPartialFeatureState } from '../client.reducer';
import { ClientFacade } from '../client/client.facade';
import { IncomeActionTypes } from './income.reducer';
import * as IncomeSelectors from './income.selectors';
import * as IncomeActions from './income.actions';

@Injectable()
export class IncomeFacade {
	constructor(
		private store: Store<ClientPartialFeatureState>,
		private clientFacade: ClientFacade
	) {}

	public createIncomeActionState$ = this.selectActionState('createIncome');
	public updateIncomeActionState$ = this.selectActionState('updateIncome');
	public removeIncomeActionState$ = this.selectActionState('removeIncome');
	public loadIncomeActionState$ = this.selectActionState('loadIncome');
	public loadIncomesActionState$ = this.selectActionState('loadIncomes');

	public incomes$ = this.store.pipe(select(IncomeSelectors.getIncomes));
	public incomeMap$ = this.store.pipe(select(IncomeSelectors.getIncomeEntities));
	public currentClientIncomes$ = combineLatest([this.clientFacade.currentClientId$, this.incomes$]).pipe(
		map(([clientId, incomes]) => incomes.filter((income) => income.client.id === clientId))
	);

	public mainClientIncomes$ = combineLatest([this.clientFacade.mainClientId$, this.incomes$]).pipe(
		map(([mainClientId, incomes]) => incomes.filter((income) => income.client.id === mainClientId))
	);

	public secondClientIncomes$ = combineLatest([this.clientFacade.secondClientId$, this.incomes$]).pipe(
		map(([secondClientId, incomes]) => incomes.filter((income) => income.client.id === secondClientId))
	);

	public loadIncome(loanRequestId: number, clientId: number, incomeId: number) {
		this.store.dispatch(IncomeActions.loadIncome({ loanRequestId, clientId, incomeId }));
	}

	public loadIncomes(loanRequestId: number, clientId: number) {
		this.store.dispatch(IncomeActions.loadIncomes({ loanRequestId, clientId }));
	}

	public createIncome(loanRequestId: number, clientId: number, income: Partial<Income>) {
		this.store.dispatch(IncomeActions.addIncome({ loanRequestId, clientId, income }));
	}

	public updateIncome(loanRequestId: number, clientId: number, incomeId: number, income: Partial<Income>) {
		this.store.dispatch(IncomeActions.updateIncome({ loanRequestId, clientId, income: { id: incomeId, changes: income } }));
	}

	public deleteIncome(loanRequestId: number, clientId: number, incomeId: number) {
		this.store.dispatch(IncomeActions.deleteIncome({ loanRequestId, clientId, id: incomeId }));
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: IncomeActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(IncomeSelectors.getActionState(actionType)), map(parseActionState));
	}
}
