import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Income } from '@oper-client/shared/data-model';

export const loadIncome = createAction('[Income/API] Load Income', props<{ loanRequestId: number; clientId: number; incomeId: number }>());
export const loadIncomeSuccess = createAction('[Income/API] Load Income Success', props<{ income: Income }>());
export const loadIncomeFailure = createAction('[Income/API] Load Income Failrue', props<{ error: HttpErrorResponse }>());

export const loadIncomes = createAction('[Income/API] Load Incomes', props<{ loanRequestId: number; clientId: number }>());
export const loadIncomesSuccess = createAction('[Income/API] Load Incomes Success', props<{ incomes: Income[] }>());
export const loadIncomesFailure = createAction('[Income/API] Load Incomes Failure', props<{ error: HttpErrorResponse }>());

export const addIncome = createAction(
	'[Income/API] Add Income',
	props<{ loanRequestId: number; clientId: number; income: Partial<Income> }>()
);
export const addIncomeSuccess = createAction('[Income/API] Add Income Success', props<{ income: Income }>());
export const addIncomeFailure = createAction('[Income/API] Add Income Failure', props<{ error: HttpErrorResponse }>());

export const updateIncome = createAction(
	'[Income/API] Update Income',
	props<{ loanRequestId: number; clientId: number; income: Update<Income> }>()
);
export const updateIncomeSuccess = createAction('[Income/API] Update Income Success', props<{ income: Update<Income> }>());
export const updateIncomeFailure = createAction('[Income/API] Update Income Failure', props<{ error: HttpErrorResponse }>());

export const deleteIncome = createAction('[Income/API] Delete Income', props<{ loanRequestId: number; clientId: number; id: number }>());
export const deleteIncomeSuccess = createAction('[Income/API] Delete Income Success', props<{ id: number }>());
export const deleteIncomeFailure = createAction('[Income/API] Delete Income Failure', props<{ error: HttpErrorResponse }>());

export const clearIncomes = createAction('[Income/API] Clear Incomes');
