import { Resource } from './resource.model';
import { ReducedProductItem } from './offer.model';
import { Broker } from './broker.model';
import { CreditProvider } from './offer.model';
import { LoanRequest } from './loan-request.model';

export interface CommissionFile {
	id: number;
	externalReference: string;
	status: Resource;
	productItem: ReducedProductItem;
	amount: number;
	loanAmount: number;
	paidAmount: number;
	outstandingAmount: number;
	borrowerReference: string;
	incomingDate: string;
	broker: Broker;
	creditProvider: CreditProvider;
}

export interface Commission {
	id: number;
	externalReference: string;
	loanRequest: LoanRequest;
	amount: number;
	commissionType: Resource;
}

export interface CommissionFileSimplified {
	id: number;
	externalReference: string;
	borrowerReference: string;
	brokerName: string;
	lenderName: string;
	totaCommission: number;
	amountToReceive: number;
	status: string;
}

export enum CommissionFileStatus {
	RECOVERY = 'recovery',
	ONGOING = 'ongoing',
	COMPLETED = 'completed',
}

export interface Recovery {
	prepaymentDate?: Date;
}
