import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { convertMonthsToYears } from '../datetime/util.datetime';

@Pipe({
	name: 'monthsToYears',
	pure: false, // otherwise translation doesn't happen when switching language
})
@Injectable({
	providedIn: 'root',
})
export class MonthsToYearsPipe extends TranslatePipe implements PipeTransform {
	static getLabelTranslationKey(numberOfYears: number) {
		return `ç.misc.duration.year.${numberOfYears === 1 ? 'singular' : 'plural'}`;
	}

	transform(numberOfMonths: any): string {
		const numberOfYears = convertMonthsToYears(numberOfMonths);
		const label = super.transform(MonthsToYearsPipe.getLabelTranslationKey(numberOfYears));
		return `${numberOfYears} ${label}`;
	}
}
