import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { EnvironmentLocaleFormatService } from '../services/environment-locale-format.service';

@Pipe({
	name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
	constructor(@Inject(CUSTOMER_INSIGHTS_CONFIG) private customerInsights: CustomerInsights, private localeFormatService: EnvironmentLocaleFormatService) {}
	transform(value: Partial<{ firstName: string; lastName: string }>): string {
		return this.localeFormatService.formatFullName(value);
	}
}
