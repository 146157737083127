import { createSelector } from '@ngrx/store';

import { getLoanRequestFeatureState } from '../loan-request.selectors';
import { PRODUCT_ENTITY_KEY, productAdapter, ProductActionTypes } from './product.reducer';

const selectors = productAdapter.getSelectors();

export const getProductState = createSelector(getLoanRequestFeatureState, state => state[PRODUCT_ENTITY_KEY]);
export const getProducts = createSelector(getProductState, selectors.selectAll);

export const getActionStates = createSelector(getProductState, state => state.actions);
export const getActionState = (actionType: ProductActionTypes) => createSelector(getActionStates, state => state[actionType]);
