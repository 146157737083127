import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromUser from './+state/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './+state/user.effects';
import { UserFacade } from './+state/user.facade';

@NgModule({
	imports: [CommonModule, StoreModule.forFeature(fromUser.USERS_FEATURE_KEY, fromUser.reducer), EffectsModule.forFeature([UserEffects])],
	providers: [UserFacade],
})
export class DataAccessUserModule {}
