import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import { UserPartialState } from './user.reducer';

@Injectable()
export class UserFacade {
	users$ = this.store.pipe(select(UserSelectors.getUsers));
	analysts$ = this.users$.pipe(map((users) => users.filter((user) => user.role.definition === 'analyst')));
	opsManagers$ = this.users$.pipe(map((users) => users.filter((user) => user.role.definition === 'operationsManager')));
	analystsAndOpsManagers$ = combineLatest([this.analysts$, this.opsManagers$]).pipe(
		map(([analysts, opsManagers]) => analysts.concat(opsManagers))
	);

	constructor(private store: Store<UserPartialState>) {}

	public loadUsers(): void {
		return this.store.dispatch(UserActions.loadUsers());
	}

	public getUserRoleById(userId: number): Observable<string> {
		return this.users$.pipe(
			map((users) => {
				const user = users.find((user) => user.id === userId);
				return user ? user.role.definition : null;
			})
		);
	}
}
