import { createFeatureSelector, createSelector } from '@ngrx/store';
import { iamAdapter, IamState, IAM_FEATURE_KEY } from './iam.reducer';

export const getIamState = createFeatureSelector<IamState>(IAM_FEATURE_KEY);

const { selectEntities } = iamAdapter.getSelectors();

export const getSelectedId = createSelector(getIamState, (state: IamState) => state.selectedId);
export const getIamUsers = createSelector(getIamState, (state: IamState) => selectEntities(state));
export const getIamUser = createSelector(getIamUsers, getSelectedId, (entities, selectedId) => selectedId && entities[selectedId]);
export const getIamLoginError = createSelector(getIamState, (state: IamState) => state.actions.login.httpError);
export const getIamActionsState = createSelector(getIamState, (state: IamState) => state.actions);
export const getLoadIdentityActionsState = createSelector(getIamState, (state: IamState) => state.actions.loadIdentity);
export const getPermissions = createSelector(getIamState, (state: IamState) => state.permissions);
export const authRequest = createSelector(getIamState, (state: IamState) => state.authRequest);
export const verifyUserActionState = createSelector(getIamState, (state: IamState) => state.actions.verifyUser);
export const activateUserActionState = createSelector(getIamState, (state: IamState) => state.actions.activateUser);
export const resetPasswordActionState = createSelector(getIamState, (state: IamState) => state.actions.resetPassword);
export const resetPassword2FaActionState = createSelector(getIamState, (state: IamState) => state.actions.resetPassword2Fa);
export const validateCredentialsActionState = createSelector(getIamState, (state: IamState) => state.actions.validateCredentials);
export const activateOTPActionState = createSelector(getIamState, (state: IamState) => state.actions.activateOTP);
export const verifyOTPActionState = createSelector(getIamState, (state: IamState) => state.actions.verifyOTP);
export const updateCurrentUserState = createSelector(getIamState, (state: IamState) => state.actions.updateCurrentUser);
export const createUserActionState = createSelector(getIamState, (state: IamState) => state.actions.createUser);
export const updateProfileActionState = createSelector(getIamState, (state: IamState) => state.actions.updateProfile);
export const inviteUserActionState = createSelector(getIamState, (state: IamState) => state.actions.inviteUser);
export const loginActionState = createSelector(getIamState, (state: IamState) => state.actions.login);
