import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	public isArray = function (array) {
		return Array.isArray(array);
	};

	public isObject = function (object) {
		return object === Object(object) && !this.isArray(object) && typeof object !== 'function';
	};

	public erectObject(shrunkenObject: any) {
		if (Object(shrunkenObject) !== shrunkenObject || Array.isArray(shrunkenObject)) {
			return shrunkenObject;
		}
		// eslint-disable-next-line no-useless-escape
		const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
		let resultHolder = {};
		for (const p in shrunkenObject) {
			if (p) {
				let cur = resultHolder;
				let prop = '';
				let m;
				while ((m = regex.exec(p))) {
					cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
					prop = m[2] || m[1];
				}
				cur[prop] = shrunkenObject[p];
			}
		}
		resultHolder = resultHolder[''] || resultHolder;
		resultHolder = this.flattenEmptyObjects(resultHolder);
		return resultHolder;
	}

	public flattenEmptyObjects<T>(object: T): T {
		/**
		 * Flattens any nested objects where the `id` is not defined
		 *
		 * @input: object to flatten nested objects of
		 * @output: flattened object
		 */
		return <T>Object.entries(object).reduce((newObject, [key, value]) => {
			if (this.isObject(value)) {
				if (value.id === null) {
					newObject[key] = null;
					return newObject;
				}
				newObject[key] = this.flattenEmptyObjects(value);
				return newObject;
			}
			newObject[key] = value;
			return newObject;
		}, {});
	}

	public convertToHtmlLineBreaks(str: string) {
		return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
	}

	public base64ToArrayBuffer(base64) {
		const binaryString = window.atob(base64);
		const len = binaryString.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes.buffer;
	}

	public isJsonValid(input: string, mustBeArray = false): boolean {
		if (!input || input === '') return true;

		try {
			JSON.parse(input);
		} catch (e) {
			return false;
		}

		if (mustBeArray) {
			return Array.isArray(JSON.parse(input));
		}

		return true;
	}
}
