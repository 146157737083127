import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client, parseActionState, ParsedActionState } from '@oper-client/shared/data-model';

import { ClientPartialFeatureState } from '../client.reducer';
import { ClientActionTypes } from './client.reducer';
import * as ClientActions from './client.actions';
import * as ClientSelectors from './client.selectors';

@Injectable()
export class ClientFacade {
	constructor(private store: Store<ClientPartialFeatureState>) {}

	public createClientActionState$ = this.selectActionState('createClient');
	public updateClientActionState$ = this.selectActionState('updateClient');
	public removeClientActionState$ = this.selectActionState('removeClient');
	public loadClientsActionState$ = this.selectActionState('loadClients');
	public loadClientActionState$ = this.selectActionState('loadClient');
	public inviteClientActionState$ = this.selectActionState('inviteClient');

	public clients$ = this.store.pipe(select(ClientSelectors.getClients));
	public clientMap$ = this.store.pipe(select(ClientSelectors.getClientEntities));
	public currentClientId$ = this.store.pipe(select(ClientSelectors.getCurrentClientId));
	public currentClient$ = this.store.pipe(select(ClientSelectors.getCurrentClient));
	public currentclientRelationships$ = this.store.pipe(select(ClientSelectors.getCurrentClientRelationships));

	public mainClientId$ = this.store.pipe(select(ClientSelectors.getMainClientId));
	public mainClient$ = this.store.pipe(select(ClientSelectors.getMainClient));
	public secondClientId$ = this.store.pipe(select(ClientSelectors.getSecondClientId));
	public secondClient$ = this.store.pipe(select(ClientSelectors.getSecondClient));
	public mainClientLanguage$ = this.store.pipe(
		select(ClientSelectors.getMainClient),
		map((x) => x?.language)
	);

	public showCreateNewClientModal$ = this.store.pipe(select(ClientSelectors.getCreateNewClientModalShown));

	public getClient(clientId: number) {
		return this.clientMap$.pipe(map((clientMap) => clientMap[clientId]));
	}

	public getClientsOfLoanRequest(loanRequestId: number | null = null) {
		return this.clients$.pipe(map((clients) => clients.filter((item) => !loanRequestId || item.loanRequest.id === loanRequestId)));
	}

	public loadClient(loanRequestId: number, clientId: number) {
		this.store.dispatch(ClientActions.loadClient({ loanRequestId, clientId }));
	}

	public loadClients(loanRequestId: number) {
		this.store.dispatch(ClientActions.loadClients({ loanRequestId }));
	}

	public createClient(loanRequestId: number, client: Partial<Client>) {
		this.store.dispatch(ClientActions.addClient({ loanRequestId, client }));
	}

	public updateClient(loanRequestId: number, clientId: number, client: Partial<Client>) {
		this.store.dispatch(ClientActions.updateClient({ loanRequestId, client: { id: clientId, changes: client } }));
	}

	public setCurrentClientId(clientId: number) {
		this.store.dispatch(ClientActions.setCurrentClientId({ clientId }));
	}

	public inviteClient(loanRequestId: number, clientId: number) {
		this.store.dispatch(ClientActions.inviteClient({ clientId, loanRequestId }));
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	public clearClients() {
		this.store.dispatch(ClientActions.clearClients());
	}

	public clearCurrentClientId(): void {
		this.store.dispatch(ClientActions.clearCurrentClientId());
	}

	public resetClientState(): void {
		this.store.dispatch(ClientActions.resetClientState());
	}

	public showCreateNewClientModal(): void {
		this.store.dispatch(ClientActions.showCreateNewClientModal());
	}

	public hideCreateNewClientModal(): void {
		this.store.dispatch(ClientActions.hideCreateNewClientModal());
	}

	private selectActionState(actionType: ClientActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(ClientSelectors.getActionState(actionType)), map(parseActionState));
	}
}
