import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, takeUntil } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { Offer } from '@oper-client/shared/data-model';
import * as OfferActions from './offer.actions';
import * as AppActions from '@oper-client/shared/app/data-access-app';
import { IOfferService, OFFER_SERVICE } from '@oper-client/shared/data-access';

@Injectable()
export class OfferEffects {
	loadOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActions.loadOffer),
			mergeMap(({ loanRequestId, offerId, validation }) =>
				this.offerService.getOfferById(loanRequestId, offerId, validation).pipe(
					map((offer: Offer) => OfferActions.loadOfferSuccess({ offer: offer })),
					takeUntil(this.actions$.pipe(ofType(AppActions.cancelPendingHttpRequests))),
					catchError((error) => of(OfferActions.loadOfferFailure({ error: error, offerId: offerId })))
				)
			)
		)
	);

	loadOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActions.loadOffers),
			switchMap(({ loanRequestId }) => {
				return this.offerService.getAll(loanRequestId).pipe(
					mergeMap((offers: Offer[]) => [
						...offers.map((offer) =>
							!offer.isActive
								? OfferActions.loadOffer({ loanRequestId, offerId: offer.id, validation: true })
								: OfferActions.missingDataValidation({ loanRequestId, offerId: offer.id })
						),
						OfferActions.loadOffersSuccess({ offers: offers }),
					]),
					takeUntil(this.actions$.pipe(ofType(AppActions.cancelPendingHttpRequests))),
					catchError((error) => of(OfferActions.loadOffersFailure({ error: error })))
				);
			})
		)
	);

	createOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActions.createOffer),
			switchMap(({ loanRequestId, offer }) =>
				this.offerService.create(loanRequestId, offer).pipe(
					map((createdOffer: Offer) => OfferActions.createOfferSuccess({ offer: createdOffer })),
					catchError((error) => of(OfferActions.createOfferFailure({ error })))
				)
			)
		)
	);

	updateOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActions.updateOffer),
			switchMap(({ loanRequestId, offer }) =>
				this.offerService.update(loanRequestId, offer?.id, offer).pipe(
					map((updatedOffer: Offer) => OfferActions.updateOfferSuccess({ offer: { changes: updatedOffer, id: offer.id } })),
					catchError((error) => of(OfferActions.updateOfferFailure({ error, offer })))
				)
			)
		)
	);

	removeOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActions.removeOffer),
			switchMap(({ loanRequestId, offerId }) =>
				this.offerService.delete(loanRequestId, offerId).pipe(
					map(() => OfferActions.removeOfferSuccess({ offerId })),
					catchError((error) => of(OfferActions.removeOfferFailure({ error })))
				)
			)
		)
	);

	loadOfferMissingData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActions.missingDataValidation),
			mergeMap(({ loanRequestId, offerId }) =>
				this.offerService.getMissingData(loanRequestId, offerId).pipe(
					map((missingData) =>
						OfferActions.missingDataValidationSuccess({ offerId: offerId, missingDataValidation: missingData })
					),
					takeUntil(this.actions$.pipe(ofType(AppActions.cancelPendingHttpRequests))),
					catchError((error) => of(OfferActions.missingDataValidationFailure({ error: error, offerId: offerId })))
				)
			)
		)
	);

	predictFileCosts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActions.predictFileCosts),
			switchMap(({ loanRequestId, offer }) =>
				this.offerService.predictFileCosts(loanRequestId, offer).pipe(
					map((fileCostPrediction) => OfferActions.predictFileCostsSuccess({ fileCosts: fileCostPrediction })),
					catchError((error) => of(OfferActions.predictFileCostsFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		@Inject(OFFER_SERVICE) private offerService: IOfferService
	) {}
}
