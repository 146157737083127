import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { DATE_RESTRICTION_VALIDATION_CONSTANTS, DATE_RESTRICTION } from '../../services/validator-constants';
import { of } from 'rxjs';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MAX_AGE).getTime();
	const maxBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MIN_AGE).getTime();

	return [
		new InputSelect({
			key: 'title.id',
			label: 'ç.question.title.label',
			value: formData?.title?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CLIENT_TITLE] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || '',
			type: 'text',
			required: true,
			class: 'span3',
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || '',
			type: 'text',
			required: true,
			class: 'span3',
		}),
		new InputField({
			key: 'birthDate',
			label: 'ç.question.birthDate.label',
			value: formData?.birthDate,
			type: 'date',
			required: false,
			validators: [
				ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE),
				ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST),
			],
			min: minBirthDate,
			max: maxBirthDate,
			class: 'span3',
		}),
		new InputSelect({
			key: 'sex.id',
			label: 'ç.question.sex.label',
			value: formData?.sex?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.SEX] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'nationality.id',
			label: 'ç.question.nationality.label',
			value: formData?.nationality?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.NATIONALITY] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'taxId',
			label: 'ç.question.taxId.label',
			value: formData?.taxId,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputSelect({
			key: 'civilStatus.id',
			label: 'ç.question.civilStatus.label',
			value: formData?.civilStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CIVIL_STATUS] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'dependentChildren',
			label: 'ç.question.dependentChildren.label',
			value: formData?.dependentChildren,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputSelect({
			key: 'residencePermit.id',
			label: 'ç.question.residencePermit.label',
			value: formData?.residencePermit?.id,
			required: false,
			options: resources?.[ResourceType.RESIDENCE_PERMIT] || [],
			class: 'span3',
			helpText: of('ç.question.residencePermit.helpTextAP'),
		}),
	];
}
