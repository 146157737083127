export enum SeverityEnum {
	SUCCESS = 'success',
	INFO = 'info',
	WARNING = 'warn',
	ERROR = 'error',
}

export interface Severity {
	definition: SeverityEnum;
}
