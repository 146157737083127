import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function babyLoanValidator(maxAmounts: { maxBabyLoan?: number; maxBabyLoanHome?: number }): ValidatorFn {
	return (control: FormGroup): ValidationErrors | null => {
		const babyFreePurposeLoanControl = control.get([`babyFreePurposeLoan`]);
		const babyLoanHomeControl = control.get([`babyLoanHome`]);

		const babyLoan = babyLoanHomeControl?.value + babyFreePurposeLoanControl?.value;
		if (babyLoanHomeControl?.value > maxAmounts.maxBabyLoanHome) {
			babyLoanHomeControl.setErrors({
				maxBabyLoanHome: { maxBabyLoanHomeAmount: maxAmounts.maxBabyLoanHome },
			});
			babyFreePurposeLoanControl.setErrors(null);
		} else if (babyLoan > maxAmounts.maxBabyLoan) {
			babyFreePurposeLoanControl.setErrors({
				maxBabyLoan: { maxBabyLoanAmount: maxAmounts.maxBabyLoan },
			});
			babyLoanHomeControl.setErrors({
				maxBabyLoan: { maxBabyLoanAmount: maxAmounts.maxBabyLoan },
			});
		} else {
			babyLoanHomeControl.setErrors(null);
			babyFreePurposeLoanControl.setErrors(null);
		}
		return null;
	};
}
