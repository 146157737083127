import { of } from 'rxjs';
import { ResourceType, PartialNormalizedResource, Realty } from '@oper-client/shared/data-model';
import { InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function(formData?: Realty, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'price',
			label: 'ç.question.price.label',
			helpText: of('ç.question.price.helpText'),
			value: formData?.price || null,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			helpText: of('ç.question.realtyType.helpText'),
			value: formData?.realtyType?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_TYPE],
		}),
		new InputSelect({
			key: 'usageTypes[0].id',
			label: 'ç.question.usageTypes.label',
			helpText: of('ç.question.usageTypes.helpText'),
			value: formData?.usageTypes?.[0]?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
	];
}
