import { CreditProvider, Funder } from './offer.model';
import { Resource } from './resource.model';
import { DocumentLibrary } from './document-library.model';
import { AnyObject } from './any-object.model';

export enum LoanType {
	PROGRESSIVE_AMORTIZATION = 'progressiveAmortization',
	INFINITE_CREDIT = 'infiniteCredit',
	SUBSIDY = 'subsidy',
}

export interface CustomTranslations {
	[key: string]: AnyObject<string>;
}

export interface BaseProduct {
	id: number;
	active: boolean;
	name: string;
	creditProvider: CreditProvider;
	funder: Funder;
	loanType: Resource;
	productType: Resource;
	logoUrl: string;
	isSimulatorReferenceProduct: boolean;
	hasFunderSpecificPricer: boolean;
	loanRequestFormDocuments?: Partial<DocumentLibrary>[];
	customTranslations?: CustomTranslations;
}
