import { InjectionToken } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Task } from '@oper-client/shared/data-model';

export const TASK_SERVICE = new InjectionToken<ITaskService>('TASK_SERVICE');

export interface ITaskService {
	getTasks(
		loanRequest?: string,
		taskType?: string,
		createdBy?: string,
		taskCategory?: string,
		statusNot?: number,
		assignedTo?: string,
		status?: string,
		ordering?: string,
		search?: string,
		page?: number,
		pageSize?: number,
		upsert?: boolean,
		observe?: any,
		reportProgress?: boolean
	): Observable<{ results: Task[]; count?: number }>;

	createTask(task: Task): Observable<Task>;

	updateTask(id: number, task: Task, params?: HttpParams): Observable<Task>;
}
