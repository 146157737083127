import { of } from 'rxjs';
import { yearOptions1500 } from '@oper-client/shared/util-formatting';
import { ResourceType, PartialNormalizedResource, Realty } from '@oper-client/shared/data-model';
import { InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { Validators } from '@angular/forms';

export default function (formData?: Realty, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'price',
			label: 'ç.question.price.label',
			helpText: of('ç.question.price.helpText'),
			value: formData?.price || null,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			helpText: of('ç.question.realtyType.helpText'),
			value: formData?.realtyType?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_TYPE],
		}),
		new InputSelect({
			key: 'usageTypes[0].id',
			label: 'ç.question.usageTypes.label',
			helpText: of('ç.question.usageTypes.helpText'),
			value: formData?.usageTypes?.[0]?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE],
		}),
		new InputSelect({
			key: 'yearOfBuilt',
			label: 'ç.question.yearOfBuilt.label',
			helpText: of('ç.question.yearOfBuilt.helpText'),
			value: formData?.yearOfBuilt || null,
			options: yearOptions1500,
			validators: [],
			alreadySorted: true,
			required: false,
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcScoreAfterRenovation.label',
			value: formData?.epcAfterRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: false,
			additionalDisclaimer: 'ç.misc.kWhM2PerYearOrLess',
			transform: (value) => +value,
			helpText: of('ç.question.epcScoreAfterRenovation.helpText'),
		}),
		new InputField({
			key: 'isMainFirstResidence',
			label: 'ç.question.mainFirstResidence.label',
			value: formData?.mainFirstResidence,
			type: 'checkbox',
			required: false,
		}),
	];
}
