import { Component, Input } from '@angular/core';
import { Periodicity } from '@oper-client/shared/data-model';
import { map } from 'rxjs';

import { PeriodicityService } from '@oper-client/shared/util-feature-flag';
import { DestroyableComponent } from '@shared/util-component';

@Component({
	selector: 'oper-client-duration-displayment',
	styleUrl: './duration-displayment.component.scss',
	template: `
		<span class="diration">{{ duration$ | async }}</span>
		<oper-client-periodicity />
	`,
})
export class DurationDisplaymentComponent extends DestroyableComponent {
	@Input() value: number;
	@Input() loading: boolean;

	readonly periodicity$ = this.periodicityService.getBrokeragePeriodicity();
	readonly duration$ = this.periodicity$.pipe(map((periodicity) => this.getDuration(periodicity)));

	constructor(private readonly periodicityService: PeriodicityService) {
		super();
	}

	getDuration(periodicity: Periodicity): number {
		switch (periodicity) {
			case Periodicity.YEARLY:
				return this.value / 12;
			case Periodicity.MONTHLY:
				return this.value;
			default:
				return this.value;
		}
	}
}
