import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProductPricingActions from './product-pricing.actions';
import { IProductPricingService, PRODUCT_PRICING_SERVICE } from '@oper-client/shared/data-access';
import {
	AllowedProductVariability,
	BaseRate,
	ProductPricingDetails,
	ProductPricingSheet,
	ProductPricingSheetDiscounts,
} from '@oper-client/shared/data-model';

@Injectable()
export class ProductPricingEffects {
	setCurrentProductPricingDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.setCurrentProductPricingDetails),
			map(({ pricingDetails }) => ProductPricingActions.loadProductPricingSheets({ productId: pricingDetails.id }))
		)
	);

	setCurrentProductPricingSheet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.setCurrentProductPricingSheet),
			switchMap(({ pricingSheet }) => {
				if (pricingSheet) {
					return [
						ProductPricingActions.loadBaseRates({
							pricingSheetId: pricingSheet.id,
							productId: pricingSheet.product.id,
						}),
						ProductPricingActions.loadProductPricingSheetDiscounts({
							productId: pricingSheet.product.id,
							pricingSheetId: pricingSheet.id,
						}),
					];
				} else {
					return [
						ProductPricingActions.loadBaseRatesSuccess({ baseRates: [] }),
						ProductPricingActions.loadProductPricingSheetDiscountsSuccess({ discounts: [] }),
					];
				}
			}),
			map((actions) => actions)
		)
	);

	loadProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadProducts),
			fetch({
				run: ({ searchTerm }) => {
					return this.productPricingService.getAllProducts().pipe(
						map((products: ProductPricingDetails[]) =>
							ProductPricingActions.loadProductsSuccess({
								products:
									searchTerm && searchTerm.trim().length > 0
										? products.filter((p) => p.name.toLowerCase().includes(searchTerm.trim().toLowerCase()))
										: products,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadProductsFailure({ error });
				},
			})
		)
	);

	loadProductPricingDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadProductPricingDetails),
			fetch({
				run: ({ productId }) => {
					return this.productPricingService.getProductPricingDetails(productId).pipe(
						map((pricingDetails: ProductPricingDetails) =>
							ProductPricingActions.loadProductPricingDetailsSuccess({
								pricingDetails,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadProductPricingDetailsFailure({ error });
				},
			})
		)
	);

	loadProductPricingSheets$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadProductPricingSheets),
			fetch({
				run: ({ productId }) => {
					return this.productPricingService.getProductPricingSheets(productId).pipe(
						map((pricingSheets: ProductPricingSheet[]) =>
							ProductPricingActions.loadProductPricingSheetsSuccess({
								pricingSheets,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadProductPricingSheetsFailure({ error });
				},
			})
		)
	);

	loadProductPricingSheetsSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadProductPricingSheetsSuccess),
			map(({ pricingSheets }) => {
				if (pricingSheets?.length > 0) {
					const activeSheet = pricingSheets.find((sheet) => sheet.isActive);
					return ProductPricingActions.setCurrentProductPricingSheet({ pricingSheet: activeSheet ?? pricingSheets[0] });
				} else {
					return ProductPricingActions.setCurrentProductPricingSheet({ pricingSheet: null });
				}
			})
		)
	);

	loadBaseRates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadBaseRates),
			fetch({
				run: ({ productId, pricingSheetId }) => {
					return this.productPricingService.getProductPricingSheetBaseRates(productId, pricingSheetId).pipe(
						map((baseRates: BaseRate[]) =>
							ProductPricingActions.loadBaseRatesSuccess({
								baseRates,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadBaseRatesFailure({ error });
				},
			})
		)
	);

	loadProductPricingSheetDiscounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadProductPricingSheetDiscounts),
			fetch({
				run: ({ productId, pricingSheetId }) => {
					return this.productPricingService.getProductPricingSheetDiscounts(productId, pricingSheetId).pipe(
						map((discounts: ProductPricingSheetDiscounts[]) =>
							discounts.map((discount, index) => ({
								...discount,
								id: `discount-${index}`,
							}))
						),
						map((discountsWithIds: ProductPricingSheetDiscounts[]) =>
							ProductPricingActions.loadProductPricingSheetDiscountsSuccess({
								discounts: discountsWithIds,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadProductPricingSheetDiscountsFailure({ error });
				},
			})
		)
	);

	loadProductPricingSheet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadProductPricingSheet),
			fetch({
				run: ({ productId, pricingSheetId }) => {
					return this.productPricingService.getProductPricingSheet(productId, pricingSheetId).pipe(
						map((pricingSheet: ProductPricingSheet) =>
							ProductPricingActions.loadProductPricingSheetSuccess({
								pricingSheet,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadProductPricingSheetFailure({ error });
				},
			})
		)
	);

	removePricingSheet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.removeProductPricingSheet),
			fetch({
				run: ({ productId, pricingSheetId }) => {
					return this.productPricingService.deletePricingSheet(productId, pricingSheetId).pipe(
						map(() =>
							ProductPricingActions.removeProductPricingSheetSuccess({
								productId,
								pricingSheetId,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.removeProductPricingSheetFailure({ error });
				},
			})
		)
	);

	removeProductPricingSheetSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.removeProductPricingSheetSuccess),
			map(({ productId }) => ProductPricingActions.loadProductPricingSheets({ productId }))
		)
	);

	createProductPricingSheet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.createProductPricingSheet),
			fetch({
				run: ({ productId, request }) => {
					return this.productPricingService.createPricingSheet(productId, request).pipe(
						switchMap((pricingSheet) => {
							return request?.discounts?.length > 0
								? this.productPricingService
										.updateProductPricingSheetDiscounts(productId, pricingSheet.id, request.discounts)
										.pipe(map(() => pricingSheet))
								: of(pricingSheet);
						}),
						map((pricingSheet) => ProductPricingActions.createProductPricingSheetSuccess({ pricingSheet }))
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.createProductPricingSheetFailure({ error });
				},
			})
		)
	);

	createProductPricingSheetSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.createProductPricingSheetSuccess),
			map(({ pricingSheet }) => {
				return ProductPricingActions.loadProductPricingSheets({ productId: pricingSheet.product.id });
			})
		)
	);

	updateProductPricingDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.updateProductPricingDetails),
			fetch({
				run: ({ productId, pricingDetails }) => {
					return this.productPricingService.updateProductPricingDetails(productId, pricingDetails.changes).pipe(
						map((updated) =>
							ProductPricingActions.updateProductPricingDetailsSuccess({
								pricingDetails: { id: updated.id, changes: updated },
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.updateProductPricingDetailsFailure({ error });
				},
			})
		)
	);

	updateProductPricingSheet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.updateProductPricingSheet),
			fetch({
				run: ({ productId, pricingSheet }) => {
					return this.productPricingService.updatePricingSheet(productId, +pricingSheet.id, pricingSheet.changes).pipe(
						map((updatedPricingSheet) =>
							ProductPricingActions.updateProductPricingSheetSuccess({
								pricingSheet: { id: updatedPricingSheet.id, changes: updatedPricingSheet },
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.updateProductPricingSheetFailure({ error });
				},
			})
		)
	);

	updateProductPricingSheetBaseRate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.updateProductPricingSheetBaseRate),
			fetch({
				run: ({ productId, pricingSheetId, baseRateId, baseRate }) => {
					return this.productPricingService
						.updatePricingSheetBaseRate(productId, pricingSheetId, baseRateId, baseRate.changes)
						.pipe(
							map((updatedBaseRate) =>
								ProductPricingActions.updateProductPricingSheetBaseRateSuccess({
									baseRate: { id: updatedBaseRate.id, changes: updatedBaseRate },
								})
							)
						);
				},

				onError: (action, error) => {
					return ProductPricingActions.updateProductPricingSheetFailure({ error });
				},
			})
		)
	);

	updateProductPricingSheetBaseRates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.updateProductPricingSheetBaseRates),
			fetch({
				run: ({ productId, pricingSheetId, baseRates }) => {
					return this.productPricingService
						.updatePricingSheetBaseRates(
							productId,
							pricingSheetId,
							baseRates.map((rate) => rate.changes)
						)
						.pipe(
							map((updatedBaseRates) =>
								ProductPricingActions.updateProductPricingSheetBaseRatesSuccess({
									baseRates: updatedBaseRates.map((rate) => {
										return { id: rate.id, changes: rate };
									}),
								})
							)
						);
				},

				onError: (action, error) => {
					return ProductPricingActions.updateProductPricingSheetFailure({ error });
				},
			})
		)
	);

	updateProductPricingSheetDiscounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.updateProductPricingSheetDiscounts),
			fetch({
				run: ({ productId, pricingSheetId, discounts }) => {
					return this.productPricingService
						.updateProductPricingSheetDiscounts(
							productId,
							pricingSheetId,
							discounts.map((discount) => discount.changes)
						)
						.pipe(
							map((updatedDiscounts) =>
								ProductPricingActions.updateProductPricingSheetDiscountsSuccess({
									discounts: updatedDiscounts.map((discount) => {
										return { id: discount.name, changes: discount };
									}),
								})
							)
						);
				},

				onError: (action, error) => {
					return ProductPricingActions.updateProductPricingSheetDiscountsFailure({ error });
				},
			})
		)
	);

	loadProductVariabilitiesAfterSettingPricingSheet$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.setCurrentProductPricingSheet),
			filter(({ pricingSheet }) => !!pricingSheet),
			map(({ pricingSheet }) => ProductPricingActions.loadAllowedVariabilities({ productId: pricingSheet.product.id }))
		)
	);

	loadAllowedVariabilities$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadAllowedVariabilities),
			fetch({
				run: ({ productId }) => {
					return this.productPricingService.getAllowedProductVariabilities(productId).pipe(
						map((allowedVariabilities: AllowedProductVariability[]) =>
							ProductPricingActions.loadAllowedVariabilitiesSuccess({
								allowedVariabilities,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadAllowedVariabilitiesFailure({ error });
				},
			})
		)
	);

	loadAllowedProductVariability$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.loadAllowedProductVariability),
			fetch({
				run: ({ productId, variabilityId }) => {
					return this.productPricingService.getAllowedProductVariability(productId, variabilityId).pipe(
						map((allowedVariability: AllowedProductVariability) =>
							ProductPricingActions.loadAllowedProductVariabilitySuccess({
								allowedVariability,
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.loadAllowedProductVariabilityFailure({ error });
				},
			})
		)
	);

	updateAllowedProductVariability$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProductPricingActions.updateAllowedProductVariability),
			fetch({
				run: ({ productId, variability }) => {
					return this.productPricingService.updateAllowedProductVariability(productId, +variability.id, variability.changes).pipe(
						map((allowedVariability) =>
							ProductPricingActions.updateAllowedProductVariabilitySuccess({
								allowedVariability: { id: allowedVariability.id, changes: allowedVariability },
							})
						)
					);
				},

				onError: (action, error) => {
					return ProductPricingActions.updateAllowedProductVariabilityFailure({ error });
				},
			})
		)
	);

	constructor(
		private actions$: Actions,
		@Inject(PRODUCT_PRICING_SERVICE) private productPricingService: IProductPricingService
	) {}
}
