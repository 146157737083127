import { FormGroup, Validators, ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { IconDefinition } from '@oper-client/shared/util-fontawesome';
import { FormConfiguration } from './dynamic-form.model';
import { UiColor } from '@oper-client/shared/data-model';

export type ControlType =
	| 'field'
	| 'select'
	| 'radio'
	| 'switch'
	| 'space'
	| 'section'
	| 'phone'
	| 'table'
	| 'percentage'
	| 'dashed'
	| 'card';

export interface LabelDefinition {
	label: string;
	helpText?: Observable<string>;
	class: string;
}

export class InputBase<T> {
	controlType: ControlType;
	value: T;
	key: string;
	label: string;
	labelPlacement: 'above' | 'inline' | 'slim';
	errorLabel?: string;
	helpText: Observable<string>;
	warningText: Observable<string>;
	class: string;
	order: number;
	disabled: boolean;
	required: boolean;
	hideRequiredAsterisk: boolean;
	forceExpand?: boolean;
	updateOn: 'change' | 'blur' | 'submit';
	validators: ValidatorFn[];
	asyncValidator?: AsyncValidatorFn;
	hidden?: (formGroup: FormGroup, destroy$?: Subject<void>) => Observable<boolean>;
	badgeLabel?: string;
	badgeColor?: UiColor;
	onLabelClick?: () => void | null;
	additionalFlagValue: Observable<any>;
	additionalFlagClickable: Observable<boolean>;
	onAdditionalFlagClicked: () => void | null;
	additionalFlagIcon: IconDefinition;
	updateValidityOnFormValueChanges: boolean;
	prefix?: string;
	suffix?: string;
	questionClass?: string;
	additionalDisclaimer?: string;
	additionalLabel?: string;
	withItemBorder: true;
	externalActionButtonIcon?: IconDefinition;
	externalActionButtonDisabled?: Observable<boolean>;
	transform?: (x: any) => any;
	transformField?: (formGroup: FormGroup, formConfiguration: FormConfiguration, key?: string) => Observable<any>;
	appendTo: string | null;
	secondaryAction: boolean;
	secondaryActionLabel: string;
	beta: boolean;
	disclaimer: string | null;

	protected constructor(args: Partial<InputBase<T>>) {
		this.controlType = args.controlType;
		this.value = args.value;
		this.key = args.key || '';
		this.label = args.label || '';
		this.errorLabel = args.errorLabel;
		this.helpText = args.helpText;
		this.class = args.class ?? 'span12';
		this.order = args.order ?? 1;
		this.disabled = args.disabled ?? false;
		this.required = args.required ?? true;
		this.validators = args.validators ?? [];
		if (this.required === true) {
			this.validators.push(Validators.required);
		}
		this.hideRequiredAsterisk = args.hideRequiredAsterisk ?? false;
		this.asyncValidator = args.asyncValidator;
		this.hidden = args.hidden;
		this.badgeLabel = args.badgeLabel;
		this.badgeColor = args.badgeColor;
		this.onLabelClick = args.onLabelClick;
		this.warningText = args.warningText;
		this.labelPlacement = args.labelPlacement || 'above';
		this.updateOn = args.updateOn ?? 'change';

		this.additionalFlagValue = args.additionalFlagValue || of(null);
		this.onAdditionalFlagClicked = args.onAdditionalFlagClicked;
		this.additionalFlagIcon = args.additionalFlagIcon;
		this.additionalFlagClickable = args.additionalFlagClickable || of(false);
		this.updateValidityOnFormValueChanges = !!args.updateValidityOnFormValueChanges;
		this.prefix = args.prefix;
		this.suffix = args.suffix;
		this.questionClass = args.questionClass;
		this.additionalDisclaimer = args.additionalDisclaimer;
		this.additionalLabel = args.additionalLabel;
		this.withItemBorder = args.withItemBorder;
		this.externalActionButtonIcon = args.externalActionButtonIcon;
		this.externalActionButtonDisabled = args.externalActionButtonDisabled || of(false);
		this.transform = args.transform;
		this.transformField = args.transformField;
		this.appendTo = args.appendTo !== undefined ? args.appendTo : 'body';
		this.secondaryAction = args.secondaryAction;
		this.secondaryActionLabel = args.secondaryActionLabel;
		this.beta = args.beta;
		this.disclaimer = args.disclaimer;
	}
}
