import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoanRequestPartialState } from '../loan-request.reducer';
import { loadProducts, loadProductVariations, clearProducts } from './product.actions';
import { getProducts } from './product.selectors';

import { parseActionState, ParsedActionState } from '@oper-client/shared/data-model';

import { ProductActionTypes } from './product.reducer';
import * as ProductSelectors from './product.selectors';

@Injectable()
export class ProductFacade {
	products$ = this.store.pipe(select(getProducts));
	loadProductsActionState$ = this.selectActionState('loadProducts');

	constructor(private store: Store<LoanRequestPartialState>) {}

	public loadProducts(loanRequestId: number, params: { [key: string]: string } = {}, body?: object) {
		this.store.dispatch(loadProducts({ loanRequestId, params, body }));
	}

	public loadProductVariations() {
		this.store.dispatch(loadProductVariations());
	}

	public clearProducts() {
		this.store.dispatch(clearProducts());
	}

	private selectActionState(actionType: ProductActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(ProductSelectors.getActionState(actionType)), map(parseActionState));
	}
}
