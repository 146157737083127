import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { ProductDurationConfig } from '@oper-client/shared/data-model';

export const PRODUCT_DURATION_CONFIG_SERVICE = new InjectionToken<IProductDurationConfigService>('PRODUCT_DURATION_CONFIG_SERVICE');

export interface IProductDurationConfigService {
	configuration$: Observable<ProductDurationConfig>;
	defaultMinMonths: number;
	defaultMaxMonths: number;
}
