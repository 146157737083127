import { Directive, ElementRef, Optional, Inject, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[outsideClick]',
})
export class OutsideClickDirective implements OnInit, OnDestroy {
	// eslint-disable-next-line @angular-eslint/no-output-rename
	@Output('outsideClick') outsideClick = new EventEmitter<MouseEvent>();

	private subscription: Subscription;

	constructor(private element: ElementRef, @Optional() @Inject(DOCUMENT) private document: any) {}

	ngOnInit() {
		setTimeout(() => {
			this.subscription = fromEvent<MouseEvent>(this.document, 'click')
				.pipe(
					filter(event => {
						const clickTarget = event.target as HTMLElement;
						return !this.isOrContainsClickTarget(this.element.nativeElement, clickTarget);
					})
				)
				.subscribe(() => this.outsideClick.emit());
		}, 0);
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}

	private isOrContainsClickTarget(element: HTMLElement, clickTarget: HTMLElement) {
		return element === clickTarget || element.contains(clickTarget);
	}
}
