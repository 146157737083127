import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromProductPricing from './+state/product-pricing.reducer';
import { ProductPricingEffects } from './+state/product-pricing.effects';
import { ProductPricingFacade } from './+state/product-pricing.facade';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProductPricing.PRODUCT_PRICING_FEATURE_KEY, fromProductPricing.productPricingReducerMap),
		EffectsModule.forFeature([ProductPricingEffects]),
	],
	providers: [ProductPricingFacade],
})
export class DataAccessProductPricingModule {}
