import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as AppActions from './app.actions';
import { DeploymentDetectionService } from '../services/deployment-detection.service';
import { ApplicationInsights, APP_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';

@Injectable()
export class AppEffects {
	constructor(
		private actions$: Actions,
		private deploymentDetectionService: DeploymentDetectionService,
		@Inject(APP_INSIGHTS_CONFIG) private applicationInsights: ApplicationInsights
	) {}

	deploymentDetection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AppActions.setAppVisible),
			switchMap(() => {
				return this.deploymentDetectionService.getCommitHashFile().pipe(
					map((version: Record<string, string>) => {
						if (version?.commitHash !== this.applicationInsights.version) {
							return AppActions.newDeploymentDetected();
						}
						return AppActions.newDeploymentNotDetected();
					}),
					catchError(() => of(AppActions.newDeploymentDetected()))
				);
			})
		)
	);
}
