import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AiValidationPartialState } from './ai-validation.reducer';
import * as AIValidationSelectors from './ai-validation.selectors';
import * as AIValidationActions from './ai-validation.actions';
import { AI_VALIDATION_SERVICE, IAiValidationServiceService } from '@oper-client/shared/data-access';

@Injectable()
export class AiValidationFacade {
	fieldMapping$ = this.store.pipe(select(AIValidationSelectors.getFieldMapping));

	validationResults$ = this.store.pipe(select(AIValidationSelectors.getValidationResults));

	constructor(
		private store: Store<AiValidationPartialState>,
		@Inject(AI_VALIDATION_SERVICE) private validationService: IAiValidationServiceService
	) {}

	public loadFieldMapping(): void {
		this.store.dispatch(AIValidationActions.loadFieldMapping());
	}

	public triggerDocVerification(
		loanRequestId: number,
		proofId: number,
		docId: number,
		docName: string,
		category: string,
		categoryEntityId: number
	): void {
		this.store.dispatch(
			AIValidationActions.triggerValidation({
				loanRequestId,
				proofId,
				docId,
				docName,
				category,
				categoryEntityId,
			})
		);
	}

	public clearValidationResults(): void {
		this.validationService.clearValidationResults();
		this.store.dispatch(AIValidationActions.clearValidationResults());
	}
	
	public enableValidationTrigger(): void {
		this.store.dispatch(AIValidationActions.enableValidationTrigger());
	}
}
