import { Component, HostBinding, Input } from '@angular/core';
import { UiColor } from '@oper-client/shared/data-model';

@Component({
	selector: 'oper-client-badge',
	template: '<p>{{ label }}</p>',
	styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
	@Input() label: string;
	@Input() color: UiColor = UiColor.DEFAULT;

	@HostBinding('class') get className() {
		return `badge--${this.color}`;
	}
}
