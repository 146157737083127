import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IncomingPayment, Payment } from '@oper-client/shared/data-model';

// Load Payments
export const loadPayments = createAction('[Payment] Load Payments', props<{ brokerId: number }>());
export const loadPaymentsSuccess = createAction('[Payment] Load Payments Success', props<{ payments: Payment[] }>());
export const loadPaymentsFailure = createAction('[Payment] Load Payments Failure', props<{ error: HttpErrorResponse }>());

// Create Payment
export const createPayment = createAction('[Payment] Create Payment', props<{ payment: Partial<IncomingPayment> }>());
export const createPaymentSuccess = createAction('[Payment] Create Payment Success', props<{ brokerId: number }>());
export const createPaymentFailure = createAction('[Payment] Create Payment Failure', props<{ error: HttpErrorResponse }>());
