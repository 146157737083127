import { InputField, InputSelect, InputSwitch, Section } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, distinctUntilChanged, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { FinancialData, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { FormConfiguration } from '../models/dynamic-form.model';

const SHOWN_PURPOSES = ['newbuild', 'renovation', 'purchase'];

export default function (formData?: Partial<FinancialData>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const purchaseId = resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((lp) => lp.definition === 'purchase')?.id;
	const newBuildId = resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((lp) => lp.definition === 'newBuild')?.id;
	const renovationId = resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.find((lp) => lp.definition === 'renovation')?.id;

	return [
		new InputSelect({
			key: 'loanPurpose.id',
			label: 'ç.feature.loanRequest.loanPurpose',
			value: formData?.loanPurpose?.id ?? resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.[0]?.id,
			required: true,
			class: 'span12 ',
			options:
				resources?.[ResourceType.LOAN_REQUEST_PURPOSE]?.filter((loanPurpose) =>
					SHOWN_PURPOSES.includes(loanPurpose.definition.toLowerCase())
				) || [],
		}),
		new Section({
			class: 'span12',
			title: 'ç.feature.selfService.steps.housingType.title',
		}),
		new InputSwitch({
			key: 'housingType',
			label: 'ç.question.housingType.label',
			value: !!formData?.housingType,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
			class: 'span12',
			helpText: of('ç.question.housingType.label'),
		}),
		new InputSelect({
			key: 'coLivingGroupType.id',
			label: 'ç.question.coLivingGroupType.label',
			helpText: of('ç.question.coLivingGroupType.helpText'),
			value: formData?.coLivingGroupType?.id ?? resources?.[ResourceType.CO_LIVING_GROUP_TYPE]?.[0]?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.CO_LIVING_GROUP_TYPE] || [],
			class: 'span12',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const subject = new BehaviorSubject(!formGroup.value['housingType']);

				formGroup.controls['housingType'].valueChanges.pipe(takeUntil(destroy$)).subscribe({
					complete: () => subject.complete(),
					error: (error) => subject.error(error),
					next: (value) => subject.next(!value),
				});

				return subject.asObservable();
			},
		}),

		// shown only when housingType is false and loan purpose is purchase
		new Section({
			class: 'span12',
			title: 'ç.feature.selfService.steps.mortgageRenovatedExistingRealty.description',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const isProjectCoLiving = !!formGroup.value['housingType'];
				const isPurchase = formGroup.value['loanPurpose.id'] === purchaseId;
				const isHidden = isProjectCoLiving || !isPurchase;
				const subject = new BehaviorSubject(isHidden);

				formGroup.valueChanges
					.pipe(
						map((value) => !!value['housingType'] || value['loanPurpose.id'] !== purchaseId),
						distinctUntilChanged(),
						takeUntil(destroy$)
					)
					.subscribe((isHidden) => subject.next(isHidden));

				return subject.asObservable();
			},
		}),
		// shown only when housingType is false and loan purpose is newbuild
		new Section({
			class: 'span12',
			title: 'ç.feature.selfService.steps.mortgageNewBuild.description',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const isProjectCoLiving = !!formGroup.value['housingType'];
				const isNewBuild = formGroup.value['loanPurpose.id'] === newBuildId;
				const isHidden = isProjectCoLiving || !isNewBuild;
				const subject = new BehaviorSubject(isHidden);

				formGroup.valueChanges
					.pipe(
						map((value) => !!value['housingType'] || value['loanPurpose.id'] !== newBuildId),
						distinctUntilChanged(),
						takeUntil(destroy$)
					)
					.subscribe((isHidden) => subject.next(isHidden));

				return subject.asObservable();
			},
		}),
		// shown only when housingType is false and loan purpose is renovation
		new Section({
			class: 'span12',
			title: 'ç.feature.selfService.steps.mortgageExistingRealtyToRenovate.description',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const isProjectCoLiving = !!formGroup.value['housingType'];
				const isRenovation = formGroup.value['loanPurpose.id'] === renovationId;
				const isHidden = isProjectCoLiving || !isRenovation;
				const subject = new BehaviorSubject(isHidden);

				formGroup.valueChanges
					.pipe(
						map((value) => !!value['housingType'] || value['loanPurpose.id'] !== renovationId),
						distinctUntilChanged(),
						takeUntil(destroy$)
					)
					.subscribe((isHidden) => subject.next(isHidden));

				return subject.asObservable();
			},
		}),

		// shown only when housingType is false and loan purpose is renovation
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcScoreBeforeRenovation.label',
			helpText: of('ç.question.epcScoreBeforeRenovation.helpText'),
			value: formData?.epcBeforeRenovations,
			required: true,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			additionalDisclaimer: 'ç.misc.kWhM2PerYearOrLess',
			class: 'span12',
			transform: (value) => +value,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const isProjectCoLiving = !!formGroup.value['housingType'];
				const isRenovation = formGroup.value['loanPurpose.id'] === renovationId;
				const isHidden = isProjectCoLiving || !isRenovation;
				const subject = new BehaviorSubject(isHidden);

				formGroup.valueChanges
					.pipe(
						map((value) => !!value['housingType'] || value['loanPurpose.id'] !== renovationId),
						distinctUntilChanged(),
						takeUntil(destroy$)
					)
					.subscribe((isHidden) => subject.next(isHidden));

				return subject.asObservable();
			},
		}),

		// shown only when housingType is false and loan purpose is not renovation
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcScore.label',
			helpText: of('ç.question.epcScore.helpText'),
			value: formData?.epcAfterRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			additionalDisclaimer: 'ç.misc.kWhM2PerYearOrLess',
			class: 'span12',
			transform: (value) => +value,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const key = 'epcAfterRenovations';
				const field = <InputField>formConfiguration.formControl.questions.find((question) => question.key === key);
				const formControl = <FormControl>formGroup.controls[key];

				return formGroup.valueChanges.pipe(
					map((formValue) => ({
						isProjectCoLiving: !!formValue['housingType'],
						isRenovation: formValue['loanPurpose.id'] === renovationId,
					})),
					distinctUntilChanged(
						(prev, curr) => prev.isProjectCoLiving === curr.isProjectCoLiving && prev.isRenovation === curr.isRenovation
					),
					map(({ isProjectCoLiving, isRenovation }) => {
						if (!isProjectCoLiving) {
							// show field and set label and help text based on loan purpose
							if (isRenovation) {
								field.label = 'ç.question.epcScoreAfterRenovation.label';
								field.helpText = of('ç.question.epcScoreAfterRenovation.helpText');
								field.required = false;
								formControl.hasValidator(Validators.required) && formControl.removeValidators(Validators.required);
							} else {
								field.label = 'ç.question.epcScore.label';
								field.helpText = of('ç.question.epcScore.helpText');
								field.required = true;
								formControl.setValidators([
									Validators.min(0),
									Validators.pattern('^[0-9]*$'),
									Validators.max(1000),
									Validators.required,
								]);
							}
							field.type = 'number';
							formControl.enable();
						} else {
							// hide field and disable it
							field.type = 'hidden';
							field.required = false;
							formControl.disable();
						}

						return field;
					})
				);
			},
		}),
		new Section({
			class: 'span12',
			title: 'ç.feature.mortgageSimulator.totalCostsOfProject',
		}),

		// shown only when loan purpose is not newBuild
		new InputField({
			key: 'realtyPrice',
			label: 'ç.question.propertyPriceIndication.label',
			helpText: of('ç.question.propertyPriceIndication.helpText'),
			value: formData?.realtyPrice || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const isNewBuild = formGroup.value['loanPurpose.id'] === newBuildId;
				const isHidden = isNewBuild;
				const subject = new BehaviorSubject(isHidden);

				formGroup.valueChanges
					.pipe(
						map((value) => value['loanPurpose.id'] === newBuildId),
						distinctUntilChanged(),
						takeUntil(destroy$)
					)
					.subscribe((isHidden) => subject.next(isHidden));

				return subject.asObservable();
			},
		}),

		// shown only when loan purpose is newBuild
		new InputField({
			key: 'priceOfLand',
			label: 'ç.question.priceOfTheLand.label',
			helpText: of('ç.question.priceOfTheLand.helpText'),
			value: formData?.priceOfLand || null,
			type: 'text',
			currency: true,
			required: false,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const isNewBuild = formGroup.value['loanPurpose.id'] === newBuildId;
				const isHidden = !isNewBuild;
				const subject = new BehaviorSubject(isHidden);

				formGroup.valueChanges
					.pipe(
						map((value) => value['loanPurpose.id'] !== newBuildId),
						distinctUntilChanged(),
						takeUntil(destroy$)
					)
					.subscribe((isHidden) => subject.next(isHidden));

				return subject.asObservable();
			},
		}),
		new InputField({
			key: 'buildingCosts',
			label: 'ç.question.buildingCosts.label',
			helpText: of('ç.question.buildingCosts.helpText'),
			value: formData?.buildingCosts || null,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const isNewBuild = formGroup.value['loanPurpose.id'] === newBuildId;
				const isHidden = !isNewBuild;
				const subject = new BehaviorSubject(isHidden);

				formGroup.valueChanges
					.pipe(
						map((value) => value['loanPurpose.id'] !== newBuildId),
						distinctUntilChanged(),
						takeUntil(destroy$)
					)
					.subscribe((isHidden) => subject.next(isHidden));

				return subject.asObservable();
			},
		}),
	];
}
