import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, OnDestroy, Optional, Output, Self } from '@angular/core';
import { BehaviorSubject, noop, Subject } from 'rxjs';
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors } from '@angular/forms';

import { faEye, faEyeSlash } from '@oper-client/shared/util-fontawesome';

@Component({
	selector: 'oper-client-input-password',
	templateUrl: './input-password.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./input-password.component.scss'],
})
export class InputPasswordComponent implements OnInit, OnDestroy, ControlValueAccessor {
	readonly showPassword$ = new BehaviorSubject<boolean>(false);
	readonly isValid$ = new BehaviorSubject<boolean>(false);
	readonly toggle$ = new Subject<void>();
	readonly iconShowPassword = faEye;
	readonly iconHidePassword = faEyeSlash;

	@Input() disableInput = false;
	@Input() value = null;
	@Input() required = false;
	@Input() key: string;
	@Input() name: string;
	@Input() revealPassword = true;
	@Input() showPasswordStrength = false;
	@Input() immediatePasswordCheck = false;

	@Output() valueChange = new EventEmitter<string>();
	@Output() blurEmit = new EventEmitter<void>();
	@Output() focusEmit = new EventEmitter<void>();

	private readonly destroy$ = new Subject<void>();

	onTouchedCallback: () => void = noop;
	onChangeCallback: (_: any) => void = noop;

	ngOnInit() {
		if (this.showPasswordStrength) {
			this.control.control?.addValidators([this.validate.bind(this)]);
		}

		this.isValid$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (this.showPasswordStrength) {
				this.control.control?.updateValueAndValidity();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	constructor(@Self() @Optional() public control: NgControl) {
		if (this.control) {
			this.control.valueAccessor = this;
		}
		this.handleToggle();
	}

	valueChanged(value): void {
		this.onTouchedCallback();
		this.onChangeCallback(value);
		this.valueChange.emit(value);
	}

	onBlur(): void {
		this.blurEmit.emit();
		this.onTouchedCallback();
	}

	writeValue(obj: any): void {
		if (this.showPasswordStrength && obj?.length > 0) {
			this.immediatePasswordCheck = true;
		}
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(disabled: boolean): void {
		this.disableInput = disabled;
	}

	validate({ value }: FormControl): ValidationErrors | null {
		const isValid = value && this.isValid$.value;
		return isValid ? null : { invalid: true };
	}

	private handleToggle(): void {
		this.toggle$
			.pipe(
				withLatestFrom(this.showPassword$),
				tap(([, showPassword]) => {
					this.showPassword$.next(!showPassword);
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}
}
