export const DATE_RESTRICTION_VALIDATION_CONSTANTS: { [key: string]: number } = {
	MIN_AGE: 18,
	MAX_AGE: 100,
	ZERO: 0,
};

export enum DATE_RESTRICTION {
	PAST,
	FUTURE,
	BOTH,
}

export const COMMON_REGEX_EXPRESSIONS: { [key: string]: string } = {
	ONLY_CAPITAL_NUMBERS_LETTERS: '^[A-Z0-9]*$',
	ONLY_CAPITAL_NUMBERS_LETTERS_DASHES: '^[A-Z0-9-]*$',
	ONLY_NUMBERS_LETTERS: '^[a-zA-Z0-9]*$',
	ONLY_NUMBERS_LETTERS_DASHES: '^[a-zA-Z0-9-]*$',
	ONLY_NUMBERS_LETTERS_WHITESPACE: '^[a-zA-Z0-9 ]*$',
	ONLY_NUMBERS: '^[0-9]*$',
	ONLY_LETTERS: '^[a-zA-Z]*$',
	ONLY_LETTERS_WHITESPACE: '^[a-zA-Z ]*$',
	// eslint-disable-next-line no-useless-escape
	ONLY_NUMBERS_LETTERS_SPECIAL_CHARACTERS: `^[\w'_-,.]*$`,
};
