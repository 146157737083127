import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as MilestoneActions from './milestone.actions';
import { IMilestoneService, MILESTONE_SERVICE } from '@oper-client/shared/data-access';

@Injectable()
export class MilestoneEffects {
	constructor(
		private actions$: Actions,
		@Inject(MILESTONE_SERVICE) private milestoneService: IMilestoneService
	) {}

	loadProducts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MilestoneActions.loadMilestones),
			switchMap(({ loanRequestId }) => {
				return this.milestoneService.getAll(loanRequestId).pipe(
					map((milestones) => MilestoneActions.loadMilestonesSuccess({ milestones })),
					catchError((error) => of(MilestoneActions.loadMilestonesFailure({ error: error })))
				);
			})
		)
	);

	updateMilestone$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MilestoneActions.updateMilestone),
			mergeMap(({ loanRequestId, milestoneId, milestone }) =>
				this.milestoneService.updateMilestone(loanRequestId, milestoneId, milestone).pipe(
					map((milestone) => MilestoneActions.updateMilestoneSuccess({ milestone })),
					catchError((error) => of(MilestoneActions.updateMilestoneFailure({ error })))
				)
			)
		)
	);
}
