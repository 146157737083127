import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { APP_CONFIG, ApplicationName, CORE_INFORMATION } from '@oper-client/shared/configuration';
import { gtmLoader } from '@oper-client/shared/tracking/gtm';

import { AppModule } from './app/app.module';
import { MsaSsoAppModule } from './msa-sso-app/msa-sso-app.module';
import { environment } from './environments/environment';
import { KcSsoAppModule } from './kc-app/kc-sso-app.module';

declare const SERVER_URL: string;
declare const COMMIT_HASH: string;

/**
 * TODO:
 *
 * This file is now a Promise hell, so it would be ideal to refactor it
 * to a sequence of promises. Plus we should handle cases, when custom.json or /configuration/version/
 * is impossible to load and indicate that on the user interface.
 */

fetch(`/assets/${COMMIT_HASH}/config/customer.json`)
	.then((response) => response.json())
	.then((config) => {
		const enhancedConfig = {
			applicationInsights: {
				appName: ApplicationName.BROKERAGE,
				...config.applicationInsights,
			},
			customerInsights: {
				fullNamePattern: '{{firstName}} {{lastName}}',
				...config.customerInsights,
				...(typeof SERVER_URL !== 'undefined' ? { serverUrl: SERVER_URL } : {}),
			},
			featureConfigurations: {
				...config.featureConfigurations,
			},
			aiValidationConfig: null,
		};

		if (environment.production) {
			enableProdMode();
		}

		gtmLoader(config.customerInsights.gtmContainerId || 'GTM-K2ZH2PT').then(() => {
			fetch(`${enhancedConfig.customerInsights.serverUrl}/configuration/version/`)
				.then((response) => response.json())
				.then((info) => {
					let appModule;
					switch (enhancedConfig.customerInsights.authType) {
						case 'azure-sso':
							appModule = MsaSsoAppModule;
							break;
						case 'keycloak-sso':
							appModule = KcSsoAppModule;
							break;
						case 'basic':
						default:
							appModule = AppModule;
							enhancedConfig.customerInsights.authType = 'basic';
					}
					
					Promise.all([
						fetch(`/assets/${COMMIT_HASH}/config/ai-validation.json`)
							.then((response) => response.json())
							.catch(() => null),
						fetch(`/assets/${COMMIT_HASH}/config/default-ai-validation.json`)
							.then((response) => response.json())
							.catch(() => null),
					]).then(([aiConfig, defaultConfig]) => {
						enhancedConfig.aiValidationConfig = { current: aiConfig, default: defaultConfig };
						platformBrowserDynamic([
							{ provide: CORE_INFORMATION, useValue: info },
							{ provide: APP_CONFIG, useValue: enhancedConfig },
						])
							.bootstrapModule(appModule)
							.catch((err) => console.error(err));
					});
				});
		});
	});
