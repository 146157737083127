import { Inject, InjectionToken } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AUTH_SERVICE, IAuthService } from '../services/auth.service';

export const ROLE_GUARD = new InjectionToken<CanActivate>('ROLE_GUARD');

export class RoleGuard implements CanActivate {
	constructor(
		@Inject(AUTH_SERVICE) private authService: IAuthService,
		private router: Router
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authService.getCurrentUser().pipe(
			map((user) => {
				if (!user) {
					return this.router.parseUrl('/auth/login');
				} else {
					const roleGroups = user.roleGroups ?? [];
					const expectedRoleGroups = next.data.expectedRoleGroups ?? [];

					if (roleGroups.some((roleGroup) => expectedRoleGroups.includes(roleGroup.name))) {
						return true;
					} else {
						return this.router.parseUrl('/auth/unauthorized');
					}
				}
			})
		);
	}
}
