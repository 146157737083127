import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
	selector: '[operClientOnlyLettersDirective]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OnlyLettersDirective),
			multi: true,
		},
	],
})
export class OnlyLettersDirective implements ControlValueAccessor {
	private readonly tagsRegExp = /<[^>]*>/gim;
	private readonly lettersRegExp = /[\p{L}]([-]{1}|[ ]{1}|[']{1})?/gimu;

	@Input() extraChars = [' ', '-', "'"];
	@Output() valueChanged = new EventEmitter<string>();

	onChange: any = () => {};
	onTouch: any = () => {};

	constructor(private el: ElementRef) {}

	@HostListener('input', ['$event'])
	onInput() {
		const value = this.cleanUp(this.el.nativeElement.value);
		this.el.nativeElement.value = value;
		this.onTouch(value);
		this.onChange(value);
		this.valueChanged.emit(value);
	}

	@HostListener('keypress', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		if (event.ctrlKey || event.metaKey) return;

		if (!this.extraChars) return;
		if (event.key == null) return;
		if (!this.lettersRegExp) return;

		if (!(event.key.match(this.lettersRegExp) || this.extraChars.includes(event.key))) {
			event.preventDefault();
		}
	}

	@HostListener('focusout', ['$event'])
	onBlur() {
		const originalValue = this.el.nativeElement.value;
		const finalValue = this.cleanUp(originalValue);
		this.el.nativeElement.value = finalValue || null;
		if (originalValue !== finalValue) {
			this.onTouch(finalValue);
			this.onChange(finalValue);
			this.valueChanged.emit(finalValue);
		}
	}

	writeValue(value: string): void {
		this.el.nativeElement.value = this.cleanUp(value);
	}

	private cleanUp(value: string): string {
		value = value?.replace(this.tagsRegExp, '');
		return value?.match(this.lettersRegExp)?.join('') || '';
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
}
