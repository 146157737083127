<div class="radio-group">
	<div class="radio-group__item" [ngClass]="{ 'radio-group__item--with-border': withItemBorder }" *ngFor="let option of options"
  [attr.data-test]="option.key">
		<input
			type="radio"
			[id]="prefixId + option.id "
			[value]="option.id"
			[checked]="(value$ | async)?.toString() === option.id?.toString()"
			(change)="onRadioSelectChange($event)"
			(blur)="onTouchedCallback()"
		/>
		<label class="label" [for]="prefixId + option.id">{{ option.key | translate }}</label>
	</div>
</div>
