import { Injectable, Inject } from '@angular/core';
import { getLocaleDirection } from '@angular/common';

import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { BehaviorSubject, Observable } from 'rxjs';

export enum LocaleDirection {
	RTL = 'rtl',
	LTR = 'ltr',
}

@Injectable({
	providedIn: 'root',
})
export class LocaleService {
	locale: string;
	language: string;
	region: string;
	localeDirection: LocaleDirection;
	localeDirection$ = new BehaviorSubject<LocaleDirection>(null);

	private static getLocaleDirection(locale): LocaleDirection {
		return getLocaleDirection(locale) as LocaleDirection;
	}

	constructor(@Inject(CUSTOMER_INSIGHTS_CONFIG) private readonly customerInsights: CustomerInsights) {
		this.setLocale(this.customerInsights.locale);
	}

	setLocale(locale): void {
		const localeDirection = LocaleService.getLocaleDirection(locale);
		this.locale = locale;
		this.language = locale.split('-')[0];
		this.region = locale.split('-')[1];
		this.localeDirection = localeDirection;
		this.localeDirection$.next(localeDirection);
	}

	getLocaleDirection$(): Observable<LocaleDirection> {
		return this.localeDirection$.asObservable();
	}
}
