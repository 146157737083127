import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function trimmedPatternValidator(pattern: string | RegExp, validationMessageKey = 'pattern'): ValidatorFn {
	let regex: RegExp;
	let regexStr: string;
	if (typeof pattern === 'string') {
		regexStr = '';

		if (pattern.charAt(0) !== '^') regexStr += '^';

		regexStr += pattern;

		if (pattern.charAt(pattern.length - 1) !== '$') regexStr += '$';

		regex = new RegExp(regexStr);
	} else {
		regexStr = pattern.toString();
		regex = pattern;
	}
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value == null || control.value.length === 0) {
			return null;
		}
		const value: string = control.value.trim();
		return regex.test(value) ? null : { [validationMessageKey]: { requiredPattern: regexStr, actualValue: value } };
	};
}
