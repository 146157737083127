import { createSelector } from '@ngrx/store';
import { getLoanRequestFeatureState } from '../loan-request.selectors';
import { MILESTONE_ENTITY_KEY, milestoneAdapter, MilestoneActionTypes } from './milestone.reducer';

const selectors = milestoneAdapter.getSelectors();

export const getMilestoneState = createSelector(getLoanRequestFeatureState, (state) => state[MILESTONE_ENTITY_KEY]);
export const getMilestones = createSelector(getMilestoneState, selectors.selectAll);
export const getActionStates = createSelector(getMilestoneState, (state) => state.actions);
export const getActionState = (actionType: MilestoneActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
