import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
	name: 'translateResource',
	pure: false, // otherwise translation doesn't happen when switching language
})
export class TranslateResourcePipe extends TranslatePipe implements PipeTransform {
	static mapDefinitionToKey(value?: string, resourceType?: string): string {
		return `ç.resource.${resourceType}.${value}`;
	}

	transform(value?: string, resourceType?: string): string {
		return value ? super.transform(TranslateResourcePipe.mapDefinitionToKey(value, resourceType)) : value;
	}
}
