import { Client, ClientRoleEnum, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS, DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../../services/validator-constants';
import { FormConfiguration } from '../../models/dynamic-form.model';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MAX_AGE).getTime();
	const maxBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MIN_AGE).getTime();
	const maxIDDate: number = ValidatorService.getDateByYearOffset(-100).getTime();
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();
    const taxIdRegex = /^8\d{9}$/;
	
	const maxAgeV = ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST);
	const pastDateV = ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE);
	const minAgeV =  ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE);
	
	const roleTypeChildId = resources?.[ResourceType.CLIENT_ROLE]?.find((item) => item.definition === ClientRoleEnum.CHILD)?.id;
	
	const getBirthDateValidators = (id: number) => {
		if (id === roleTypeChildId) {
			return [maxAgeV, pastDateV];
		} else {
			return [maxAgeV, minAgeV];
		}
	}
	
	const getBirthDateMax = (id: number) => {
		if (id === roleTypeChildId) {
			return currentDate;
		} else {
			return maxBirthDate;
		}
	}
	
	return [
		new InputSelect({
			key: 'title.id',
			label: 'ç.question.title.label',
			value: formData?.title?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CLIENT_TITLE] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || '',
			type: 'text',
			required: true,
			class: 'span3',
		}),
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || '',
			type: 'text',
			required: true,
			class: 'span3',
		}),
		new InputSelect({
			key: 'birthCountry.id',
			label: 'ç.question.birthCountry.label',
			value: formData?.birthCountry?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.COUNTRY] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'maidenName',
			label: 'ç.question.maidenName.label',
			value: formData?.maidenName || '',
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'motherMaidenName',
			label: 'ç.question.motherMaidenName.label',
			value: formData?.motherMaidenName || '',
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'birthDate',
			label: 'ç.question.birthDate.label',
			value: formData?.birthDate,
			type: 'date',
			required: false,
			validators: getBirthDateValidators(formData?.role?.id),
			min: minBirthDate,
			max: getBirthDateMax(formData?.role?.id),
			class: 'span3',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputIndex = formConfiguration.formControl.questions.findIndex((q) => q.key === 'birthDate');
				const formControl = <FormControl>formGroup.controls['birthDate'];
				formControl.markAsDirty({onlySelf: false, emitEvent: false});
				if(!formData){
					return of(null);
				}
				
				return formGroup.controls['role.id'].valueChanges.pipe(
					distinctUntilChanged(),
					map((id: number) => {
						const inputField = <InputField>formConfiguration.formControl.questions[inputIndex];
						const max = getBirthDateMax(id);

						if (inputField.max !== max) {
							inputField.validators =  getBirthDateValidators(id);
							formConfiguration.formControl.questions[inputIndex] = new InputField({ ...inputField, max: max });
						}
						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'birthCity',
			label: 'ç.question.birthCity.label',
			value: formData?.birthCity,
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'sex.id',
			label: 'ç.question.sex.label',
			value: formData?.sex?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.SEX] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'civilStatus.id',
			label: 'ç.question.civilStatus.label',
			value: formData?.civilStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CIVIL_STATUS] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'dependentChildren',
			label: 'ç.question.dependentChildren.label',
			value: formData?.dependentChildren,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputField({
			key: 'plannedChildren',
			label: 'ç.question.plannedChildren.label',
			value: formData?.plannedChildren,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputField({
			key: 'numberOfFetusesGteTwelveWeeks',
			label: 'ç.question.numberOfFetusesGteTwelveWeeks.label',
			value: formData?.numberOfFetusesGteTwelveWeeks,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputField({
			key: 'expectedFetusesBirthDate',
			label: 'ç.question.expectedFetusesBirthDate.label',
			value: formData?.expectedFetusesBirthDate,
			type: 'date',
			required: false,
			min: currentDate,
			validators: [
				ValidatorService.getDateRestrictionValidatior('notInPast', currentDate, DATE_RESTRICTION.PAST),
			],
			class: 'span3',
		}),
		new InputField({
			key: 'numberOfDependents',
			label: 'ç.question.numberOfDependents.label',
			value: formData?.numberOfDependents,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputSelect({
			key: 'nationality.id',
			label: 'ç.question.nationality.label',
			value: formData?.nationality?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.NATIONALITY] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'legalStatusType.id',
			label: 'ç.question.legalStatusType.label',
			value: formData?.legalStatusType?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.LEGAL_STATUS_TYPE] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'idDocumentType.id',
			label: 'ç.question.idDocumentType.label',
			value: formData?.idDocumentType?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.ID_DOCUMENT_TYPE] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'idCardNumber',
			label: 'ç.question.idCardNumber.label',
			value: formData?.idCardNumber,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_CAPITAL_NUMBERS_LETTERS_DASHES, 'onlyNumbersAndCapitalLetters'),
			],
			class: 'span3',
		}),
		new InputField({
			key: 'idExpirationDate',
			label: 'ç.question.idCardExpirationDate.label',
			value: formData?.idExpirationDate,
			type: 'date',
			required: false,
			min: currentDate,
			max: maxIDDate,
			validators: [
				ValidatorService.getDateRestrictionValidatior('notInPast', currentDate, DATE_RESTRICTION.PAST),
				ValidatorService.getDateRestrictionValidatior('futureXYears', maxIDDate, DATE_RESTRICTION.FUTURE, null, () => ({
					years: 100,
				})),
			],
			class: 'span3',
			helpText: of('ç.question.idCardExpirationDate.helpText'),
			
		}),
		new InputField({
			key: 'addressCardId',
			label: 'ç.question.addressCardId.label',
			value: formData?.addressCardId,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_CAPITAL_NUMBERS_LETTERS, 'onlyNumbersAndCapitalLetters'),
			],
			class: 'span3',
		}),
		new InputField({
			key: 'nationalNumber',
			label: 'ç.question.nationalNumber.label',
			value: formData?.nationalNumber,
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
		}),
		new InputField({
			key: 'taxId',
			label: 'ç.question.taxId.label',
			value: formData?.taxId,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(taxIdRegex, 'taxId'),
			],
			class: 'span3',
		}),
		new InputSelect({
			key: 'role.id',
			label: 'ç.question.clientRole.label',
			value: formData?.role?.id,
			required: true,
			options: resources?.[ResourceType.CLIENT_ROLE] || [],
			class: 'span3',
		}),
	];
}
