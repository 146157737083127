import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ActionState, setActionState, ActionTypes, FinancialAsset, initialActionState } from '@oper-client/shared/data-model';

import * as FinancialAssetActions from './financial-asset.actions';

export const FINANCIAL_ASSETS_ENTITY_KEY = 'financialAssets';

export interface FinancialAssetState extends EntityState<FinancialAsset> {
	// additional entities state properties
	actions: FinancialAssetActionsState;
}

export type FinancialAssetActionTypes =
	| 'loadFinancialAsset'
	| 'loadFinancialAssets'
	| 'loadLoanRequestFinancialAssets'
	| 'createFinancialAsset'
	| 'updateFinancialAsset'
	| 'removeFinancialAsset';
export type FinancialAssetActionsState = Record<FinancialAssetActionTypes, ActionState>;

export const financialAssetAdapter: EntityAdapter<FinancialAsset> = createEntityAdapter<FinancialAsset>();

export const initialState: FinancialAssetState = financialAssetAdapter.getInitialState({
	// additional entity state properties
	actions: {
		loadFinancialAsset: initialActionState,
		loadFinancialAssets: initialActionState,
		loadLoanRequestFinancialAssets: initialActionState,
		createFinancialAsset: initialActionState,
		updateFinancialAsset: initialActionState,
		removeFinancialAsset: initialActionState,
	},
});

function setActionStates(
	actionState: FinancialAssetActionsState,
	action: FinancialAssetActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): FinancialAssetActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,
	on(FinancialAssetActions.addFinancialAsset, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createFinancialAsset', ActionTypes.loading),
	})),
	on(FinancialAssetActions.addFinancialAssetSuccess, (state, { financialAsset }) =>
		financialAssetAdapter.addOne(financialAsset, {
			...state,
			actions: setActionStates(state.actions, 'createFinancialAsset', ActionTypes.success),
		})
	),
	on(FinancialAssetActions.addFinancialAssetFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createFinancialAsset', ActionTypes.failure, error),
	})),

	on(FinancialAssetActions.updateFinancialAsset, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateFinancialAsset', ActionTypes.loading),
	})),
	on(FinancialAssetActions.updateFinancialAssetSuccess, (state, { financialAsset }) =>
		financialAssetAdapter.updateOne(financialAsset, {
			...state,
			actions: setActionStates(state.actions, 'updateFinancialAsset', ActionTypes.success),
		})
	),
	on(FinancialAssetActions.updateFinancialAssetFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateFinancialAsset', ActionTypes.failure, error),
	})),

	on(FinancialAssetActions.deleteFinancialAsset, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'removeFinancialAsset', ActionTypes.loading),
	})),
	on(FinancialAssetActions.deleteFinancialAssetSuccess, (state, { id }) =>
		financialAssetAdapter.removeOne(id, {
			...state,
			actions: setActionStates(state.actions, 'removeFinancialAsset', ActionTypes.success),
		})
	),
	on(FinancialAssetActions.deleteFinancialAssetFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeFinancialAsset', ActionTypes.failure, error),
	})),

	on(FinancialAssetActions.loadFinancialAsset, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadFinancialAsset', ActionTypes.loading),
	})),
	on(FinancialAssetActions.loadFinancialAssetSuccess, (state, { financialAsset }) =>
		financialAssetAdapter.upsertOne(financialAsset, {
			...state,
			actions: setActionStates(state.actions, 'loadFinancialAsset', ActionTypes.success),
		})
	),
	on(FinancialAssetActions.loadFinancialAssetFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadFinancialAsset', ActionTypes.failure, error),
	})),

	on(FinancialAssetActions.loadFinancialAssets, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadFinancialAssets', ActionTypes.loading),
	})),
	on(FinancialAssetActions.loadFinancialAssetsSuccess, (state, { financialAssets }) =>
		financialAssetAdapter.upsertMany(financialAssets, {
			...state,
			actions: setActionStates(state.actions, 'loadFinancialAssets', ActionTypes.success),
		})
	),
	on(FinancialAssetActions.loadFinancialAssetsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadFinancialAssets', ActionTypes.failure, error),
	})),

	on(FinancialAssetActions.loadLoanRequestFinancialAssets, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestFinancialAssets', ActionTypes.loading),
	})),
	on(FinancialAssetActions.loadLoanRequestFinancialAssetsSuccess, (state, { financialAssets }) =>
		financialAssetAdapter.upsertMany(financialAssets, {
			...state,
			actions: setActionStates(state.actions, 'loadLoanRequestFinancialAssets', ActionTypes.success),
		})
	),
	on(FinancialAssetActions.loadLoanRequestFinancialAssetsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequestFinancialAssets', ActionTypes.failure, error),
	})),

	on(FinancialAssetActions.clearFinancialAssets, (state) =>
		financialAssetAdapter.removeAll({
			...state,
		})
	)
);
