import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentNumberFormatService } from '../services/environment-number-format.service';
import { EnvironmentCurrencyPipe } from './environment-currency.pipe';

@Pipe({
	name: 'customizableCurrency',
	pure: false,
})
export class CustomizableCurrencyPipe extends EnvironmentCurrencyPipe implements PipeTransform {
	/**
	 * shortenedInteger - if true, the pipe will cut off the fractional part if the number is integer
	 * bolderValue - if true, the value will look bolder than the currency symbol (uses font-weight:bolder)
	 * largerValue - if true, the value font size will be 1.5 larger than the currency symbol font size
	 */
	private static readonly defaultArgs: any[] = [true, true, false];

	constructor(
		protected readonly environmentNumberFormatService: EnvironmentNumberFormatService,
		private readonly sanitizer: DomSanitizer
	) {
		super(environmentNumberFormatService);
	}

	transform(
		value: number | string,
		currencyCode?: string,
		display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
		digitsInfo?: string,
		locale?: string
	): string | null;
	transform(
		value: null | undefined,
		currencyCode?: string,
		display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
		digitsInfo?: string,
		locale?: string
	): null;
	transform(value: number, ...args: any[]): string | null {
		if (typeof value !== 'number') {
			return value;
		}
		value = Number.parseFloat(value.toFixed(2));
		const [shortenedInteger] = args?.length ? args : CustomizableCurrencyPipe.defaultArgs;
		const digitsInfo = shortenedInteger ? '1.0-0' : '1.2-2';
		let result = super.transform(value, digitsInfo);
		const firstDigit = result.search(/\d/);
		result =
			'<span style="font-size: 75%;">' +
			result.substring(0, firstDigit) +
			'</span>' +
			'<span style="font-weight: bold;">' +
			result.substring(firstDigit, result.length) +
			'</span>';
		return this.sanitizer.bypassSecurityTrustHtml(result) as string;
	}
}
