import { FormGroup } from '@angular/forms';

const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

export const trimmedEmailValidator = (control: FormGroup) => {
	if (control.value == null || control.value.length === 0) {
		return null;
	}
	return EMAIL_REGEXP.test(control.value.trim()) ? null : { email: true };
};
export const emailLoginValidator = (domain: string) => {
	return (control: FormGroup) => {
		if (control.value == null || control.value.length === 0) {
			return null;
		}
		return EMAIL_REGEXP.test(control.value.trim() + domain) ? null : { email: true };
	};
};
