import {
	CreditProvider,
	PartialNormalizedResource,
	ProductSearchFilterParams,
	ProductSearchVariabilityFilter,
	ResourceType,
} from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { Validators } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';

const VARIABILITY_OPTIONS: { id: ProductSearchVariabilityFilter; key: string }[] = [
	{ id: 'variable', key: 'ç.resource.variability.variable' },
	{ id: 'fix', key: 'ç.resource.variability.fix' },
];

export default function (
	formData?: Partial<ProductSearchFilterParams>,
	resources?: PartialNormalizedResource,
	creditProviders: CreditProvider[] = []
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'creditProvider',
			value: formData?.creditProvider,
			defaultLabel: 'ç.feature.offer.product.lender',
			required: false,
			multiple: true,
			validators: [],
			options: orderBy(
				creditProviders?.map((provider) => ({ key: provider.name, id: provider.id, order: provider.order })) || [],
				'order'
			),
			alreadySorted: true,
			class: 'span3',
			beta: true,
		}),
		new InputSelect({
			key: 'loanType',
			defaultLabel: 'ç.feature.offer.product.loanType',
			value: formData?.loanType,
			bindValue: 'definition',
			required: false,
			validators: [],
			multiple: true,
			options: orderBy(resources?.[ResourceType.LOAN_TYPE] || [], 'order'),
			alreadySorted: true,
			class: 'span3',
			beta: true,
			clearable: true,
		}),
		new InputField({
			key: 'duration',
			placeholder: 'ç.feature.mortgageSimulator.productSearch.filter.duration',
			value: formData?.duration,
			required: false,
			suffix: 'ç.misc.duration.month.plural',
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.min(1),
				Validators.max(360),
			],
			class: 'span3',
		}),
		new InputSelect({
			key: 'variability',
			defaultLabel: 'ç.question.variability.label',
			value: formData?.variability,
			required: false,
			validators: [],
			options: VARIABILITY_OPTIONS,
			alreadySorted: true,
			class: 'span3',
			beta: true,
			clearable: true,
		}),
	];
}
