import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import ResizeObserver from 'resize-observer-polyfill';

import { AppFacade } from '../+state/app.facade';

@Injectable({
	providedIn: 'root',
})
export class DeviceDetectionService {
	private observer: any;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private appFacade: AppFacade,
		private deviceDetectorService: DeviceDetectorService
	) {}

	init(): void {
		this.observer = new ResizeObserver(() => this.handleDocumentResize());
		this.observer.observe(this.document.body);
	}

	stop(): void {
		this.observer.unobserve(this.document.body);
	}

	private handleDocumentResize(): void {
		// Manually set user agent on document resize
		this.deviceDetectorService.setDeviceInfo(window.navigator.userAgent);

		this.appFacade.setDeviceInfo(this.deviceDetectorService.getDeviceInfo());

		if (this.deviceDetectorService.isMobile()) {
			this.appFacade.setDeviceAsMobile();
		}

		if (this.deviceDetectorService.isTablet()) {
			this.appFacade.setDeviceAsTablet();
		}

		if (this.deviceDetectorService.isDesktop()) {
			this.appFacade.setDeviceAsDesktop();
		}
	}
}
