import { Inject } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AUTH_SERVICE, IAuthService } from './../services/auth.service';

export class IsAuthenticatedGuard implements CanActivate {
	constructor(@Inject(AUTH_SERVICE) private authService: IAuthService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authService.getCurrentUser().pipe(
			map((user) => {
				return user ? this.router.parseUrl('/app') : true;
			})
		);
	}
}
