import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CaseStyleService } from '../services/case-style.service';

@Injectable({
	providedIn: 'root',
})
export class CaseStyleInterceptor implements HttpInterceptor {
	constructor(private caseStyleService: CaseStyleService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let body;
		if (typeof request.body === 'string') {
			body = JSON.parse(request.body);
		} else {
			body = request.body;
		}
		if (!(body instanceof FormData) && body !== null) {
			// only files get sent with FormData, but they don't need their case style to be updated
			const snakeCaseBody = this.caseStyleService.convertKeysToSnakeCase(body);
			request = request.clone({ body: JSON.stringify(snakeCaseBody) });
		} else {
			request = request.clone({ body: body });
		}
		return next.handle(request).pipe(
			map(event => {
				if (event instanceof HttpResponse && !(event instanceof HttpErrorResponse)) {
					const camelCaseBody = this.caseStyleService.convertKeysToCamelCase(event.body);
					event = event.clone({ body: camelCaseBody });
				}
				return event;
			})
		);
	}
}
