import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as MortgageSimulatorReducer from './+state/mortgage-simulator.reducer';
import { MortgageSimulatorEffects } from './+state/mortgage-simulator.effects';
import { MortgageSimulatorFacade } from './+state/mortgage-simulator.facade';

import * as AdvisorSimulatorReducer from './+state/advisor-simulator/advisor-simulator.reducer';
import { AdvisorSimulatorEffects } from './+state/advisor-simulator/advisor-simulator.effects';
import { AdvisorSimulatorFacade } from './+state/advisor-simulator/advisor-simulator.facade';

import * as BorrowerSimulatorApplicationFlowReducer from './+state/borrower-simulator-application-flow/borrower-simulator-application-flow.reducer';
import { BorrowerSimulatorApplicationFlowEffects } from './+state/borrower-simulator-application-flow/borrower-simulator-application-flow.effects';
import { BorrowerSimulatorApplicationFlowFacade } from './+state/borrower-simulator-application-flow/borrower-simulator-application-flow.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(MortgageSimulatorReducer.MORTGAGE_SIMULATOR_KEY, MortgageSimulatorReducer.reducer),
		StoreModule.forFeature(AdvisorSimulatorReducer.ADVISOR_SIMULATOR_KEY, AdvisorSimulatorReducer.reducer),
		StoreModule.forFeature(
			BorrowerSimulatorApplicationFlowReducer.BORROWER_SIMULATOR_APPLICATION_FLOW_KEY,
			BorrowerSimulatorApplicationFlowReducer.reducer
		),
		EffectsModule.forFeature([MortgageSimulatorEffects, AdvisorSimulatorEffects, BorrowerSimulatorApplicationFlowEffects]),
	],
	providers: [MortgageSimulatorFacade, AdvisorSimulatorFacade, BorrowerSimulatorApplicationFlowFacade],
})
export class DataAccessMortgageSimulatorModule {}
