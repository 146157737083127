import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as RealtiesActions from './realty.actions';
import { Realty } from '@oper-client/shared/data-model';
import { LoanRequestEntityService, PROPERTY_SERVICE } from '@oper-client/shared/data-access';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class RealtiesEffects {
	loadRealties$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RealtiesActions.loadRealties),
			fetch({
				run: (action) => {
					return this.realtyService.getAll(action.loanRequestId).pipe(
						map((realties: Realty[]) =>
							RealtiesActions.loadRealtiesSuccess({
								realties: realties,
							})
						)
					);
				},

				onError: (action, error) => {
					return RealtiesActions.loadRealtiesFailure({ error });
				},
			})
		)
	);

	loadRealty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RealtiesActions.loadRealty),
			fetch({
				run: (action) => {
					return this.realtyService.get(action.loanRequestId, action.realtyId).pipe(
						map((realty: Realty) =>
							RealtiesActions.loadRealtySuccess({
								realty: realty,
							})
						)
					);
				},

				onError: (action, error) => {
					return RealtiesActions.loadRealtiesFailure({ error });
				},
			})
		)
	);

	createRealty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RealtiesActions.createRealty),
			fetch({
				run: (action) => {
					let params = new HttpParams();
					if (action.realtyMapping) {
						params = params.set('realty_mapping', true);
					}
					return this.realtyService
						.create(action.loanRequestId, action.realty, params)
						.pipe(map((realty) => RealtiesActions.createRealtySuccess({ realty: realty })));
				},

				onError: (action, error) => {
					return RealtiesActions.createRealtyFailure({ error });
				},
			})
		)
	);

	createRealtySuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RealtiesActions.createRealtySuccess),
			map(() => RealtiesActions.resetRealtyActionState())
		)
	);

	updateRealty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RealtiesActions.updateRealty),
			fetch({
				run: (action) => {
					let params = new HttpParams();
					if (action.realtyMapping) {
						params = params.set('realty_mapping', true);
					}
					return this.realtyService
						.update(action.loanRequestId, action.realtyId, action.realty, params)
						.pipe(map((realty) => RealtiesActions.updateRealtySuccess({ realty: realty })));
				},

				onError: (action, error) => {
					return RealtiesActions.updateRealtyFailure({ error });
				},
			})
		)
	);

	removeRealty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RealtiesActions.removeRealty),
			mergeMap(({ loanRequestId, realtyId }) =>
				this.realtyService.delete(loanRequestId, realtyId).pipe(
					map(() => RealtiesActions.removeRealtySuccess({ realtyId })),
					catchError((error) => of(RealtiesActions.removeRealtyFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		@Inject(PROPERTY_SERVICE) private realtyService: LoanRequestEntityService<Realty>
	) {}
}
