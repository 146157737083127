import { createSelector } from '@ngrx/store';
import { getLoanRequestFeatureState } from '../loan-request.selectors';
import { OFFER_ENTITY_KEY, offerAdapter, OfferActionTypes } from './offer.reducer';

export const getOfferState = createSelector(getLoanRequestFeatureState, (state) => state[OFFER_ENTITY_KEY]);

const selectors = offerAdapter.getSelectors();

export const getOffers = createSelector(getOfferState, selectors.selectAll);
export const getOfferEntities = createSelector(getOfferState, selectors.selectEntities);
export const getCurrentOfferId = createSelector(getOfferState, (state) => state?.currentOfferId);
export const getOpenedOffers = createSelector(getOfferState, (state) => state?.offersOpened);

export const getCurrentOffer = createSelector(getOfferState, ({ entities, currentOfferId }) =>
	Object.values(entities).find((offer) => offer.id === currentOfferId)
);

export const getActiveOffer = createSelector(getOfferState, ({ entities }) => Object.values(entities).find((offer) => offer.isActive));

export const getOfferById = (offerId: number) =>
	createSelector(getOfferState, ({ entities }) => Object.values(entities).find((offer) => offer.id === offerId));

export const getVisibleOnSelfServiceOffer = createSelector(getOfferState, ({ entities }) =>
	Object.values(entities).find((offer) => offer.visualizeInSelfService)
);

export const getPredictedFileCosts = createSelector(getOfferState, (state) => state.predictedFileCosts);

export const getActionStates = createSelector(getOfferState, (state) => state.actions);
export const getActionState = (actionType: OfferActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
