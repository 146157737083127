import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_SERVICE, IApiService, IClientService } from '@oper-client/shared/data-access';
import { Client } from '@oper-client/shared/data-model';

@Injectable()
export class ClientService implements IClientService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getAll(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<Client[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/clients/`, params);
	}

	get(loanRequestId: number, clientId: number, params: HttpParams = new HttpParams()): Observable<Client> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/clients/${clientId}/`, params);
	}

	create(loanRequestId: number, client: Partial<Client>, params: HttpParams = new HttpParams()): Observable<Client> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/clients/`, client, params);
	}

	update(loanRequestId: number, clientId: number, client: Partial<Client>, params: HttpParams = new HttpParams()): Observable<Client> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/clients/${clientId}/`, client, params);
	}

	invite(loanRequestId: number, clientId: number): Observable<void> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/clients/${clientId}/invite/`);
	}

	delete(loanRequestId: number, clientId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/clients/${clientId}/`);
	}
}
