import { Observable } from 'rxjs';
import {
	CommissionFile,
	PaymentFile,
	Commission,
	CommissionStatement,
	CommissionStatementFilterParams,
	Payment,
	IncomingPayment,
} from '@oper-client/shared/data-model';
import { InjectionToken } from '@angular/core';

export const COMMISSION_SERVICE = new InjectionToken<ICommissionService>('COMMISSION_SERVICE');

export interface ICommissionService {
	getCommissionFiles(): Observable<CommissionFile[]>;

	getPaymentFiles(): Observable<PaymentFile[]>;

	regeneratePaymentFile(paymentFileid: number): Observable<PaymentFile>;

	downloadPaymentFile(paymentFileid: number): Observable<string>;

	processPaymentFile(paymentFileid: number): Observable<PaymentFile>;

	getCommissions(commissionFilterParams: CommissionStatementFilterParams): Observable<Commission[]>;

	loadCommissionStatement(commissionFilterParams: CommissionStatementFilterParams): Observable<CommissionStatement>;

	getCommissionFile(commissionFileId: number): Observable<CommissionFile>;

	recoverCommissionFile(commissionFileId: number): Observable<CommissionFile>;

	createCommissionFile(commissionFile: Partial<CommissionFile>): Observable<CommissionFile>;

	getCommissionStatements(brokerId: number): Observable<CommissionStatement[]>;

	getPayments(brokerId: number): Observable<Payment[]>;

	createPayment(payment: Partial<IncomingPayment>): Observable<IncomingPayment>;
}
