import { ValidatorFn, AsyncValidatorFn } from '@angular/forms';
import { InputBase } from '../models/input-base.model';

export class FormConfiguration {
	formControl: { questions: InputBase<any>[] };
	formGroup: { validators: Array<ValidatorFn>; asyncValidators: Array<AsyncValidatorFn> };
	name: string;

	constructor(data?: any) {
		this.formControl = { questions: [] };
		this.formGroup = { validators: [], asyncValidators: [] };
	}

	setName(name) {
		this.name = name
	}

	getName() {
		return this.name
	}

	setValidators(validators: Array<ValidatorFn>, asyncValidators: Array<AsyncValidatorFn> = []) {
		this.formGroup.validators = validators;
		this.formGroup.asyncValidators = asyncValidators || [];
	}
}
