import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, LoanRequestEntityService } from '@oper-client/shared/data-access';
import { ContactDetails } from '@oper-client/shared/data-model';

@Injectable()
export class ContactDetailsService implements LoanRequestEntityService<ContactDetails> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	public get(loanRequestId: number, id: number, params: HttpParams = new HttpParams()): Observable<ContactDetails> {
		const apiEndpoint = typeof loanRequestId === 'undefined' ? `/api/contact/` : `/api/loan-requests/${loanRequestId}/contact-details/`;
		return this.apiService.get(apiEndpoint, params);
	}
}
