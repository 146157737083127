import { FireInsurance, InsuranceProvider, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData?: Partial<FireInsurance>,
	resources?: PartialNormalizedResource,
	insuranceProviders?: InsuranceProvider[]
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'provider.id',
			label: 'ç.question.insurer.label',
			options: insuranceProviders.map((insuranceProvider) => ({
				key: insuranceProvider.name,
				id: insuranceProvider.id,
			})),
			value: (formData?.provider && formData?.provider.id) || null,
			class: 'span12',
			required: true,
			searchable: true,
			order: 1,
		}),
		new InputSelect({
			key: 'periodicity.id',
			label: 'ç.question.periodicity.label',
			options: resources?.[ResourceType.PERIODICITY],
			value: formData?.periodicity && formData.periodicity.id,
			class: 'span12',
			required: true,
			searchable: true,
			order: 1,
		}),
		new InputField({
			key: 'insuredAmount',
			label: 'ç.question.insuredAmount.label',
			type: 'number',
			value: formData?.insuredAmount,
			class: 'span12',
			required: true,
			order: 1,
			currency: true,
		}),
		new InputField({
			key: 'premium',
			label: 'ç.question.premium.label',
			type: 'number',
			value: formData?.premium,
			class: 'span12',
			required: true,
			order: 1,
			currency: true,
		}),
		new InputField({
			key: 'policyNumber',
			label: 'ç.question.policyNumber.label',
			type: 'text',
			value: formData?.policyNumber || '',
			class: 'span12',
			required: false,
			order: 1,
		}),
		new InputField({
			key: 'apartmentComplexPolicy',
			label: 'ç.question.apartmentComplexPolicy.label',
			value: formData?.apartmentComplexPolicy || false,
			type: 'checkbox',
			class: 'span12',
			required: false,
		}),
	];
}
