interface PieChartTextSettings extends CanvasTextDrawingStyles {
	innerText: string;
}

interface PieChartDimensionSettings
	extends Pick<HTMLCanvasElement, 'width' | 'height'>,
		Pick<CanvasPathDrawingStyles, 'lineWidth' | 'lineCap'> {
	radius: number;
}

export interface PieChartSlice {
	value: number;
	color: string;
	label?: string;
}

export interface PieChartSettings extends Partial<PieChartTextSettings>, Partial<PieChartDimensionSettings> {
	slices: PieChartSlice[];
	ratio?: number;
}

export const DEFAULT_PIE_CHART_SETTINGS: PieChartSettings = {
	slices: [],
	height: 200,
	width: 200,
	lineWidth: 20,
	innerText: '',
	font: '22px sans-serif',
	textAlign: 'center',
	ratio: window.devicePixelRatio,
};
