import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { AdditionalFinancingNeed, ContactDetails, DataConnection, LiabilityInsurance, Realty } from '@oper-client/shared/data-model';

export const ADDITIONAL_FINANCIAL_NEED_SERVICE = new InjectionToken<LoanRequestEntityService<AdditionalFinancingNeed>>(
	'ADDITIONAL_FINANCIAL_NEED_SERVICE'
);
export const DATA_CONNECTION_SERVICE = new InjectionToken<LoanRequestEntityService<DataConnection>>('DATA_CONNECTION_SERVICE');
export const LIABILITY_INSURANCE_SERVICE = new InjectionToken<LoanRequestEntityService<LiabilityInsurance>>('LIABILITY_INSURANCE_SERVICE');
export const PROPERTY_SERVICE = new InjectionToken<LoanRequestEntityService<Realty>>('PROPERTY_SERVICE');
export const CONTACT_DETAILS_SERVICE = new InjectionToken<LoanRequestEntityService<ContactDetails>>('CONTACT_DETAILS_SERVICE');

export interface LoanRequestEntityService<Entity> {
	getAll?(loanRequestId: number, params?: any): Observable<Entity[]>;
	get?(loanRequestId: number, id?: number, params?: HttpParams): Observable<Entity>;
	create?(loanRequestId: number, entity: Partial<Entity>, params?: HttpParams): Observable<Entity>;
	update?(loanRequestId: number, id: number, entity: Partial<Entity>, params?: HttpParams): Observable<Entity>;
	delete?(loanRequestId: number, id: number): Observable<void>;
}
