import { createSelector } from '@ngrx/store';

import { getLoanRequestFeatureState } from '../loan-request.selectors';
import {
	CREDIT_PROVIDER_ENTITY_KEY,
	creditProviderAdapter,
	brokerCreditProviderAdapter,
	CreditProviderFeatureActionTypes,
} from './credit-provider.reducer';

const selectors = creditProviderAdapter.getSelectors();

export const getCreditProviderState = createSelector(getLoanRequestFeatureState, state => state[CREDIT_PROVIDER_ENTITY_KEY]?.creditProviders);

export const getCreditProviders = createSelector(getCreditProviderState, selectors.selectAll);

export const getActionStates = createSelector(getCreditProviderState, state => state.actions);

export const getActionState = (actionType: CreditProviderFeatureActionTypes) => createSelector(getActionStates, state => state[actionType]);


const brokerSelectors = brokerCreditProviderAdapter.getSelectors();

export const getBrokerCreditProviderState = createSelector(getLoanRequestFeatureState, state => state[CREDIT_PROVIDER_ENTITY_KEY]?.brokerCreditProviders);

export const getBrokerCreditProviders = createSelector(getBrokerCreditProviderState, brokerSelectors.selectAll);

