import { GtmEventNameEnum } from './enums/gtm-event-name.enum';
import { GtmDispatchPayload } from './interfaces/gtm-event-payload';

const actionGroupWhitelist: string[] = ['@tracking'];

export const eventsMap = {
	/**
	 *
	 * @param {GtmDispatchPayload} action { type: 'ACTION-TYPE', ... }
	 * @returns Event object or null
	 *
	 * This method is being invoked on each action is dispatched into @ngrx store but send only whitelisted events to GTM
	 */
	'*': (action: GtmDispatchPayload) => {
		return actionGroupWhiteListed(action.type)
			? {
					event: getEvent(action.type as GtmEventNameEnum),
					feature: getFeature(action.type as GtmEventNameEnum),
					payload: action.payload as GtmDispatchPayload,
			  }
			: null;
	},

	/**
	 *
	 * @param {GtmDispatchPayload} action
	 * @returns Event object
	 *
	 * This method is being invoked when router dispatches '@ngrx/router-store/navigated' action into @ngrx store
	 */
	'@ngrx/router-store/navigated': () => {
		return {
			event: 'page_view',
			feature: 'router',
			page_location: window.location.href,
		};
	},
};

/**
 *
 * @param {string} actionType  Type of the dispatched action
 * @returns {boolean}
 *
 * Return true if the dispathed action's "@"" prefix is on the whitelist
 */
function actionGroupWhiteListed(actionType: string): boolean {
	return actionGroupWhitelist.some((eventGroupName) => actionType.includes(eventGroupName));
}

/**
 *
 * @param {GtmEventNameEnum} actionType
 * @returns {string}
 *
 * Get event name without the action group name
 */
function getEvent(actionType: GtmEventNameEnum): GtmEventNameEnum {
	return actionType
		.split('/')
		.filter((_, index) => index > 0)
		.join('/') as GtmEventNameEnum;
}

/**
 *
 * @param {GtmEventNameEnum} actionType
 * @returns {string}
 *
 * Get the feature name section of the dispatched action
 * Action naming convention: '@tracking/feature/sub-feature/action'
 */
function getFeature(actionType: GtmEventNameEnum): string {
	return actionType.split('/').reduce((acc, segment, index) => (index === 1 ? segment : acc), 'no-feature');
}
