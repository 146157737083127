import { HttpErrorResponse } from '@angular/common/http';
import { ActionState, ParsedActionState } from './action-state.model';

export abstract class OperHttpError {
	messages: string[];
	kind = 'UnknownError';
	genericErrorMessage = 'ç.userFeedback.unknown.error';

	readonly rawError: HttpErrorResponse;

	constructor(readonly response: HttpErrorResponse) {
		this.rawError = response;
		this.messages = ((response.error || {}).errors || []).map((e: any) => e.message);
		if (!(this.messages?.length > 0)) {
			if (this.rawError?.error?.non_field_errors?.length) {
				this.messages = this.rawError.error.non_field_errors;
			} else if (Array.isArray(this.rawError?.error) && this.rawError?.error.length) {
				this.messages = this.rawError.error;
			}
		}
	}

	get message(): string {
		return this.messages?.[0] || this.genericErrorMessage;
	}
}

export class UnknownError extends OperHttpError {
	constructor(readonly response: HttpErrorResponse) {
		super(response);
	}
}

export class AccessDenied extends OperHttpError {
	constructor(readonly response: HttpErrorResponse) {
		super(response);
		this.kind = 'AccessDenied';
		this.genericErrorMessage = 'ç.userFeedback.accessDenied.error';
		if (!(this.messages?.length > 0) && this.rawError?.error?.detail) {
			const errorDetails = this.rawError.error.detail.toLowerCase();
			this.messages = [
				`ç.userFeedback.${errorDetails.replace(/((_|\s|-)+\w)/g, function (m) {
					return m[1].toUpperCase();
				})}.error`,
			];
		}
	}
}

export class BadRequest extends OperHttpError {
	constructor(readonly response: HttpErrorResponse) {
		super(response);
		this.kind = 'BadRequest';
		this.genericErrorMessage = 'ç.userFeedback.badRequest.error';
	}
}

export const parseError = (error: HttpErrorResponse): OperHttpError | undefined => {
	if (!error) {
		return undefined;
	}
	if (error.error instanceof ErrorEvent) {
		return new UnknownError(error);
	} else {
		switch (error.status) {
			case 400:
				return new BadRequest(error);
			case 401:
				return new AccessDenied(error);
			case 403:
				return new AccessDenied(error);
			default:
				return new UnknownError(error);
		}
	}
};

export function parseActionState(actionState: ActionState): ParsedActionState {
	return { ...actionState, httpError: parseError(actionState.httpError) };
}

export const getErrorMessages = (error: HttpErrorResponse): string[] => {
	return Array.prototype.concat(...Object.values(error?.error));
};
