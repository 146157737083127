import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { ActionState, initialActionState, setActionState, ActionTypes, Realty } from '@oper-client/shared/data-model';
import * as RealtiesActions from './realty.actions';

export const REALTIES_FEATURE_KEY = 'realties';

export interface State extends EntityState<Realty> {
	currentRealtyId: number | null;
	createNewPropertyModalShown: boolean;
	actions: RealtyActionsState;
}
export type RealtyActionTypes = 'loadRealties' | 'loadRealty' | 'createRealty' | 'updateRealty' | 'removeRealty';
export type RealtyActionsState = Record<RealtyActionTypes, ActionState>;

export interface RealtiesPartialState {
	readonly [REALTIES_FEATURE_KEY]: State;
}

export const propertiesAdapter: EntityAdapter<Realty> = createEntityAdapter<Realty>({});

export const initialState: State = propertiesAdapter.getInitialState({
	// set initial required properties
	currentRealtyId: null,
	createNewPropertyModalShown: false,
	actions: {
		loadRealties: initialActionState,
		loadRealty: initialActionState,
		createRealty: initialActionState,
		updateRealty: initialActionState,
		removeRealty: initialActionState,
	},
});

function setActionStates(
	actionState: RealtyActionsState,
	action: RealtyActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): RealtyActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const realtiesReducer = createReducer(
	initialState,
	on(RealtiesActions.setCurrentRealtyId, (state, { realtyId }) => ({ ...state, currentRealtyId: realtyId })),
	on(RealtiesActions.clearCurrentRealtyId, (state) => ({ ...state, currentRealtyId: null })),
	on(RealtiesActions.loadRealties, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadRealties', ActionTypes.loading),
	})),

	on(RealtiesActions.loadRealtiesSuccess, (state, { realties }) =>
		propertiesAdapter.setAll(realties, { ...state, actions: setActionStates(state.actions, 'loadRealties', ActionTypes.success) })
	),

	on(RealtiesActions.loadRealtiesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadRealties', ActionTypes.failure, error),
	})),

	on(RealtiesActions.loadRealty, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadRealty', ActionTypes.loading),
	})),

	on(RealtiesActions.loadRealtySuccess, (state, { realty }) =>
		propertiesAdapter.upsertOne(realty, {
			...state,
			actions: setActionStates(state.actions, 'loadRealty', ActionTypes.success),
		})
	),

	on(RealtiesActions.loadRealtyFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadRealty', ActionTypes.failure, error),
	})),

	on(RealtiesActions.createRealty, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createRealty', ActionTypes.loading),
	})),

	on(RealtiesActions.createRealtySuccess, (state, { realty }) =>
		propertiesAdapter.addOne(realty, {
			...state,
			actions: setActionStates(state.actions, 'createRealty', ActionTypes.success),
			currentRealtyId: realty.id,
		})
	),
	on(RealtiesActions.createRealtyFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createRealty', ActionTypes.failure, error),
	})),

	on(RealtiesActions.updateRealty, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateRealty', ActionTypes.loading),
	})),

	on(RealtiesActions.updateRealtySuccess, (state, { realty }) =>
		propertiesAdapter.upsertOne(realty, {
			...state,
			actions: setActionStates(state.actions, 'updateRealty', ActionTypes.success),
		})
	),

	on(RealtiesActions.updateRealtyFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateRealty', ActionTypes.failure, error),
	})),

	on(RealtiesActions.resetRealtyState, () => initialState),

	on(RealtiesActions.removeRealty, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'removeRealty', ActionTypes.loading),
	})),

	on(RealtiesActions.removeRealtySuccess, (state, { realtyId }) =>
		propertiesAdapter.removeOne(realtyId, {
			...state,
			actions: setActionStates(state.actions, 'removeRealty', ActionTypes.success),
			currentRealtyId: 0,
		})
	),

	on(RealtiesActions.removeRealtyFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeRealty', ActionTypes.failure, error),
	})),

	on(RealtiesActions.resetRealtyActionState, (state) => ({
		...state,
		actions: initialState.actions,
	})),
	on(RealtiesActions.showCreateNewPropertyModal, (state) => ({ ...state, createNewPropertyModalShown: true })),
	on(RealtiesActions.hideCreateNewPropertyModal, (state) => ({ ...state, createNewPropertyModalShown: false }))
);

export function reducer(state: State | undefined, action: Action): State {
	return realtiesReducer(state, action);
}
