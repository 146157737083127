import { HttpErrorResponse } from '@angular/common/http';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as PaymentActions from './payment.actions';
import { Payment } from '@oper-client/shared/data-model';

export const PAYMENT_FEATURE_KEY = 'Payment';

export interface PaymentState extends EntityState<Payment> {
	error: HttpErrorResponse | undefined;
}

export interface PaymentPartialState {
	readonly [PAYMENT_FEATURE_KEY]: PaymentState;
}

export const PaymentAdapter: EntityAdapter<Payment> = createEntityAdapter<Payment>();

export const PaymentInitialState: PaymentState = PaymentAdapter.getInitialState({
	error: undefined,
});

const paymentReducerInternal = createReducer(
	PaymentInitialState,
	// Load Payments
	on(PaymentActions.loadPayments, state => ({ ...state, error: undefined })),
	on(PaymentActions.loadPaymentsSuccess, (state, { payments }) => PaymentAdapter.setAll(payments, { ...state })),
	on(PaymentActions.loadPaymentsFailure, (state, { error }) => ({ ...state, error })),
	// Create Payment
	on(PaymentActions.createPayment, state => ({ ...state, error: undefined })),
	on(PaymentActions.createPaymentSuccess, state => ({ ...state })),
	on(PaymentActions.createPaymentFailure, (state, { error }) => ({ ...state, error: error }))
);

export function paymentReducer(state: PaymentState | undefined, action: Action) {
	return paymentReducerInternal(state, action);
}
