export enum ErrorType {
	FileType,
	FileSize,
	FilesLimit,
	HttpError,
	Discarded,
	FileInfected,
}

interface FileProgressInit<T> {
	file: File;
	type: T;
}

interface FileProgressSuccess extends FileProgressInit<'success'> {
	fileSize?: number;
}
interface FileProgressError extends FileProgressInit<'error'> {
	errorType: ErrorType;
	context?: {
		fileTypes?: string;
		fileSize?: string;
		filesLimit?: number;
		message?: string;
	};
}
interface FileProgressUploading extends FileProgressInit<'uploading'> {
	total: number;
}

interface FileProgressPending extends FileProgressInit<'pending'> {
	total: number;
}

export type FileProgressType = 'success' | 'error' | 'uploading' | 'pending';
export type FileProgressState = FileProgressSuccess | FileProgressError | FileProgressUploading | FileProgressPending;
