import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { FileCostPrediction, MissingDataValidation, Offer } from '@oper-client/shared/data-model';
import { LoanRequestEntityService } from './loan-request-entity.service';

export const OFFER_SERVICE = new InjectionToken<IOfferService>('OFFER_SERVICE');

export interface IOfferService extends LoanRequestEntityService<Offer> {
	getOffer(loanRequestId: number): Observable<Offer>;

	getAllowedOffers(loanRequestId: number): Observable<Offer[]>;

	predictFileCosts(loanRequestId: number, offer: Partial<Offer>): Observable<FileCostPrediction>;

	getOfferById(loanRequestId: number, offerId: number, validation: boolean): Observable<Offer>;

	getMissingData(loanRequestId: number, offerId: number): Observable<MissingDataValidation>;
}
