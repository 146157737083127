export enum ModelReferenceEnum {
	ADDITIONAL_FINANCING_NEED = 'additional-financing-need',
	CLIENT_PROFILE = 'client-profile',
	CREDIT_LIABILITY = 'credit-liability',
	EMPLOYMENT_INCOME = 'employment-income',
	INCOME = 'income',
	LOAN_REQUEST = 'loan-request',
	LOAN_REQUEST_DEED = 'loan-request-deed',
	REALTY = 'realty',
	LIABILITY_INSURANCES = 'liability-insurances',
}

export interface ModelReference {
	model: ModelReferenceEnum;
	id: number;
}
