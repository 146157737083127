import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { FinancialAsset } from '@oper-client/shared/data-model';
import * as FinancialAssetActions from './financial-asset.actions';
import { LoanRequestSubentityService, FINANCIAL_ASSET_SERVICE } from '@oper-client/shared/data-access';

@Injectable()
export class FinancialAssetEffects {
	loadFinancialAssets$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FinancialAssetActions.loadFinancialAssets),
			concatMap(({ type, loanRequestId, clientId }) =>
				this.financialAssetService.getAll(loanRequestId, clientId).pipe(
					map(financialAssets =>
						FinancialAssetActions.loadFinancialAssetsSuccess({
							financialAssets: financialAssets,
						})
					),
					catchError(error => of(FinancialAssetActions.loadFinancialAssetsFailure({ error })))
				)
			)
		);
	});

	loadLoanRequestFinancialAssets$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FinancialAssetActions.loadLoanRequestFinancialAssets),
			concatMap(({ type, loanRequestId }) =>
				this.financialAssetService.getAllByLoanRequestId(loanRequestId).pipe(
					map(financialAssets =>
						FinancialAssetActions.loadLoanRequestFinancialAssetsSuccess({
							financialAssets: financialAssets,
						})
					),
					catchError(error => of(FinancialAssetActions.loadLoanRequestFinancialAssetsFailure({ error })))
				)
			)
		);
	});

	loadFinancialAsset$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FinancialAssetActions.loadFinancialAsset),
			concatMap(({ type, loanRequestId, clientId, financialAssetId }) =>
				this.financialAssetService.get(loanRequestId, clientId, financialAssetId).pipe(
					map(financialAsset =>
						FinancialAssetActions.loadFinancialAssetSuccess({
							financialAsset: financialAsset,
						})
					),
					catchError(error => of(FinancialAssetActions.loadFinancialAssetFailure({ error })))
				)
			)
		);
	});

	addFinancialAsset$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FinancialAssetActions.addFinancialAsset),
			concatMap(({ type, loanRequestId, clientId, financialAsset }) =>
				this.financialAssetService.create(loanRequestId, clientId, financialAsset).pipe(
					map(createdFinancialAsset =>
						FinancialAssetActions.addFinancialAssetSuccess({
							financialAsset: createdFinancialAsset,
						})
					),
					catchError(error => of(FinancialAssetActions.addFinancialAssetFailure({ error })))
				)
			)
		);
	});

	updateFinancialAsset$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FinancialAssetActions.updateFinancialAsset),
			concatMap(({ type, loanRequestId, clientId, financialAsset }) =>
				this.financialAssetService.update(loanRequestId, clientId, +financialAsset.id, financialAsset.changes).pipe(
					map(updatedFinancialAsset =>
						FinancialAssetActions.updateFinancialAssetSuccess({
							financialAsset: {
								id: updatedFinancialAsset.id,
								changes: updatedFinancialAsset,
							},
						})
					),
					catchError(error => of(FinancialAssetActions.updateFinancialAssetFailure({ error })))
				)
			)
		);
	});

	deleteFinancialAsset$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(FinancialAssetActions.deleteFinancialAsset),
			concatMap(({ type, loanRequestId, clientId, id }) =>
				this.financialAssetService.delete(loanRequestId, clientId, id).pipe(
					map(() => FinancialAssetActions.deleteFinancialAssetSuccess({ id })),
					catchError(error => of(FinancialAssetActions.deleteFinancialAssetFailure({ error })))
				)
			)
		);
	});

	constructor(
		private readonly actions$: Actions,
		@Inject(FINANCIAL_ASSET_SERVICE) private readonly financialAssetService: LoanRequestSubentityService<FinancialAsset>
	) {}
}
