import { Pipe, PipeTransform } from '@angular/core';
import { AppDate } from '../models/app-date';
import * as moment from 'moment';
import { LanguageService } from '@oper-client/shared/util-language';

@Pipe({
	name: 'dateFormat',
	pure: false,
})
export class DateFormatPipe implements PipeTransform {
	constructor(private languageService: LanguageService) {}

	transform(value: Date | string): any {
		const momentDate = moment(value);
		return momentDate.isValid() ? momentDate.format(AppDate.getDateFormat(this.languageService.getCurrentLanguage())) : '';
	}
}
