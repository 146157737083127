import { PartialNormalizedResource, Relationship, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { FormControl, FormGroup } from '@angular/forms';
import { FormConfiguration } from '../../models/dynamic-form.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export default function (formData?: Partial<Relationship>, resources?: PartialNormalizedResource, clientName?: string): InputBase<any>[] {
	return [
		new InputField({
			key: 'id',
			value: formData?.id,
			type: 'hidden',
			required: false,
		}),
		new InputField({
			key: 'clientFrom.id',
			required: false,
			value: formData?.clientFrom?.id,
			type: 'hidden',
		}),
		new InputField({
			key: 'clientTo.id',
			required: false,
			value: formData?.clientTo?.id,
			type: 'hidden',
		}),
		new InputField({
			key: 'clientTo.name',
			label: 'ç.question.client.label',
			required: false,
			class: 'span12',
			value: clientName,
			disabled: true,
		}),
		new InputSelect({
			key: 'relationship.id',
			label: 'ç.feature.client.relationshipToClient',
			required: false,
			options: resources[ResourceType.RELATIONSHIP],
			class: 'span12',
			value: formData?.relationship?.id,
			clearable: true,
		}),
		new InputField({
			key: 'liveInTheSameHousehold',
			label: 'ç.question.liveInTheSameHousehold.label',
			value: formData?.relationship?.id ? formData?.liveInTheSameHousehold : false,
			type: 'checkbox',
			class: 'span12',
			required: false,
			disabled: !formData?.relationship?.id,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'liveInTheSameHousehold');
				const formControl = <FormControl>formGroup.controls['liveInTheSameHousehold'];
				return formGroup.controls['relationship.id'].valueChanges.pipe(
					map((value) => {
						if (value) {
							formControl.enable();
						} else {
							formControl.disable();
							formControl.setValue(false);
						}

						return inputField;
					})
				);
			},
		}),
	];
}
