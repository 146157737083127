import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Document, ActionState, initialActionState, setActionState, ActionTypes } from '@oper-client/shared/data-model';

import * as CommissionStatementActions from './commission-statement.actions';

export const COMMISSION_STATEMENT_DOCUMENT_ENTITY_KEY = 'commissionStatement';

export interface CommissionStatementDocumentState extends EntityState<Document> {
	actions: CommissionStatementActionState;
}
type CommissionStatementActionsTypes = 'loadCommissionStatementDocuments';
export type CommissionStatementActionState = Record<CommissionStatementActionsTypes, ActionState>;

export const commissionStatementDocumentAdapter: EntityAdapter<Document> = createEntityAdapter<Document>();

export const initialState: CommissionStatementDocumentState = commissionStatementDocumentAdapter.getInitialState({
	allowedDocuments: undefined,
	actions: {
		loadCommissionStatementDocuments: initialActionState,
	},
});

function setActionStates(
	actionState: CommissionStatementActionState,
	action: CommissionStatementActionsTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): CommissionStatementActionState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const documentReducer = createReducer(
	initialState,
	on(CommissionStatementActions.loadCommissionStatementDocuments, state => ({
		...state,
		actions: setActionStates(state.actions, 'loadCommissionStatementDocuments', ActionTypes.loading),
	})),
	on(CommissionStatementActions.loadCommissionStatementDocumentsSuccess, (state, { documents }) =>
		commissionStatementDocumentAdapter.setAll(documents, {
			...state,
			actions: setActionStates(state.actions, 'loadCommissionStatementDocuments', ActionTypes.success),
		})
	),
	on(CommissionStatementActions.loadCommissionStatementDocumentsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadCommissionStatementDocuments', ActionTypes.failure, error),
	}))
);

export function reducer(state: CommissionStatementDocumentState | undefined, action: Action) {
	return documentReducer(state, action);
}
