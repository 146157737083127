import { Input, Output, EventEmitter, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { generateGuid } from '@oper-client/shared/util-object';
import { noop } from 'rxjs';

enum SwitchType {
	BUTTON = 'button',
	TOGGLE = 'toggle',
}

@Directive()
export abstract class BaseSwitch implements ControlValueAccessor {
	@Input() type: SwitchType; // 'button' | 'toggle'
	@Input() formId: string;
	@Input() disabled: boolean;
	@Input() labelA: string;
	@Input() labelB: string;
	@Input() valueA: any;
	@Input() valueB: any;

	@Input() set value(value: any) {
		if (value === this.value) {
			return;
		}

		if (value !== this.valueA && value !== this.valueB) {
			throw new Error(`value=${value} must be either valueA=${this.valueA} or valueB=${this.valueB}`);
		}

		this._value = value;
		this.ngModelValue = value !== this.valueA;
		this._onChange(value);
	}

	get value(): any {
		return this._value;
	}

	@Input() set formControlName(value: string) {
		this._formControlName = value;
		this.inputId = `${this.formId}-${this.type}-switch-${this.formControlName}`;
	}

	get formControlName() {
		return this._formControlName;
	}

	@Output() valueChanged = new EventEmitter<any>();

	ngModelValue: boolean;
	inputId: string = generateGuid();

	private _formControlName: string;
	private _value: any;

	_onChange: (_: any) => void = noop;
	_onTouched: () => void = noop;

	onChange(value) {
		this.value = value;
		this.valueChanged.emit(value);
	}

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	writeValue(value: any): void {
		this.value = value;
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}
