import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState, CommissionFile } from '@oper-client/shared/data-model';
import * as CommissionFileActions from './commission-file.actions';
import { CommissionFilePartialState, CommissionFileActionTypes } from './commission-file.reducer';
import * as CommissionFileSelectors from './commission-file.selectors';

@Injectable()
export class CommissionFileFacade {
	commissionFiles$: Observable<CommissionFile[]> = this.store.pipe(select(CommissionFileSelectors.getCommissionFiles));

	loadCommissionFileActionState$ = this.selectActionState(CommissionFileActionTypes.LOAD_COMMISSION_FILE);
	loadCommissionFilesActionState$ = this.selectActionState(CommissionFileActionTypes.LOAD_COMMISSION_FILES);
	recoverCommissionFileActionState$ = this.selectActionState(CommissionFileActionTypes.RECOVER_COMMISSION_FILE);
	createCommissionFileActionState$ = this.selectActionState(CommissionFileActionTypes.CREATE_COMMISSION_FILE);

	constructor(private store: Store<CommissionFilePartialState>) {}

	loadCommissionFile(commissionFileId: number): void {
		this.store.dispatch(CommissionFileActions.loadCommissionFile({ commissionFileId }));
	}

	loadCommissionFiles(): void {
		this.store.dispatch(CommissionFileActions.loadCommissionFiles());
	}

	createCommissionFile(commissionFile: Partial<CommissionFile>): void {
		this.store.dispatch(CommissionFileActions.createCommissionFile({ commissionFile }));
	}

	recoverCommissionFile(commissionFileId: number): void {
		this.store.dispatch(CommissionFileActions.recoverCommissionFile({ commissionFileId }));
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: CommissionFileActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(CommissionFileSelectors.getActionState(actionType)), map(parseActionState));
	}
}
