import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'username',
			label: 'ç.question.username.label',
			value: null,
			type: 'email',
			required: true,
			class: 'span12',
			validators: [Validators.email],
		}),
		new InputField({
			key: 'password',
			label: 'ç.question.password.label',
			value: null,
			type: 'password',
			required: true,
			class: 'span12',
			revealPassword: false,
		}),
	];
}
