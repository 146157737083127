<div class="validation-container" *ngIf="(validationResults$ | async).length > 0">
	<span
		*ngFor="let validationResult of validationResults$ | async"
		[class.validation-result--success]="validationResult.validationResult"
		[class.validation-result--fail]="!validationResult.validationResult"
		class="validation-result"
	>
		<oper-client-fontawesome-icon class="icon" [icon]="validationResult.icon" [fixedWidth]="true"></oper-client-fontawesome-icon>
		<span
			[class.validation-result--success-text]="validationResult.validationResult"
			[class.validation-result--fail-text]="!validationResult.validationResult"
		>
			{{ validationResult.label | translate }}
		</span>
	</span>
</div>
