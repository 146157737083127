import { of } from 'rxjs';

import { DynamicInputTable, InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { debounceTimes } from '@oper-client/shared/configuration';
import { LoanApplicationDto, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';

export default function (formData?: LoanApplicationDto, resources?: PartialNormalizedResource): InputBase<any>[] {
	const additionalFormFields: InputBase<any>[] = [];
	if (formData?.coBorrowerIncomes?.length > 0) {
		additionalFormFields.push(
			new DynamicInputTable({
				key: 'coBorrowerLiabilities',
				value: formData?.coBorrowerLiabilities,
				debounceTime: debounceTimes.xxs,
				showAsCard: true,
				cardTitle: 'ç.misc.coBorrower',
				addRowLabel: 'ç.misc.action.moreExpenses',
				required: false,
				secondaryAction: true,
				showDeleteButton: true,
				secondaryActionLabel: 'ç.misc.action.addCoBorrowerExpense',
				columns: [
					new InputSelect({
						key: 'liabilityType.id',
						required: false,
						clearable: true,
						label: 'ç.feature.mortgageSimulator.liabilityType.label',
						helpText: of('ç.feature.mortgageSimulator.liabilityType.tooltip'),
						validators: [],
						options: orderBy(resources?.[ResourceType.LIABILITY_TYPE] || [], 'order'),
						alreadySorted: true,
						class: 'span6',
					}),
					new InputField({
						key: 'monthlyAmount',
						label: 'ç.feature.mortgageSimulator.monthly',
						type: 'number',
						required: false,
						currency: true,
						class: 'span6',
					}),
				],
			})
		);
	}
	return [
		new DynamicInputTable({
			key: 'mainBorrowerLiabilities',
			value: formData?.mainBorrowerLiabilities,
			debounceTime: debounceTimes.xxs,
			showAsCard: true,
			cardTitle: 'ç.misc.mainBorrower',
			addRowLabel: 'ç.misc.action.moreExpenses',
			required: false,
			secondaryAction: true,
			secondaryActionLabel: 'ç.misc.action.addExpense',
			showDeleteButton: true,
			columns: [
				new InputSelect({
					key: 'liabilityType.id',
					required: false,
					clearable: true,
					label: 'ç.feature.mortgageSimulator.liabilityType.label',
					helpText: of('ç.feature.mortgageSimulator.liabilityType.tooltip'),
					validators: [],
					options: orderBy(resources?.[ResourceType.LIABILITY_TYPE] || [], 'order'),
					alreadySorted: true,
					class: 'span6',
				}),
				new InputField({
					key: 'monthlyAmount',
					label: 'ç.feature.mortgageSimulator.monthly',
					type: 'number',
					required: false,
					currency: true,
					class: 'span6',
				}),
			],
		}),
		...additionalFormFields,
	];
}
