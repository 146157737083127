import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const IDENTITY_PROVIDER_SERVICE = new InjectionToken<IIdentityProviderService>('IDENTITY_PROVIDER_SERVICE');

export interface IIdentityProviderService {
	getUserInfo(queryParams: any): Observable<any>;

	getLoginUrl(identityProvider: string): Observable<any>;
}
