import { createAction, props } from '@ngrx/store';

import { DeviceInfo } from '@oper-client/shared/data-model';

export const setAppVisible = createAction('[App] Set app visible');
export const setAppHidden = createAction('[App] Set app hidden');

export const setDeviceAsMobile = createAction('[App] Set device as mobile');
export const setDeviceAsTablet = createAction('[App] Set device as tablet');
export const setDeviceAsDesktop = createAction('[App] Set device as desktop');

export const setDeviceInfo = createAction('[App] Set device type', props<{ deviceInfo: DeviceInfo }>());

export const setAppOnline = createAction('[App] Set app online');
export const setAppOffline = createAction('[App] Set app offline');

export const newDeploymentDetected = createAction('[App] New deployment detected');
export const newDeploymentNotDetected = createAction('[App] New deployment was not detected');

export const cancelPendingHttpRequests = createAction('[App] Cancel Pending HTTP requests');
