import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { DATE_RESTRICTION_VALIDATION_CONSTANTS, DATE_RESTRICTION, COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();
	return [
		new InputSelect({
			key: 'sex.id',
			label: 'ç.question.sex.label',
			value: formData?.sex?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.SEX] || [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'birthCountry.id',
			label: 'ç.question.birthCountry.label',
			value: formData?.birthCountry?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.COUNTRY] || [],
			class: 'span6',
		}),
		new InputField({
			key: 'birthCity',
			label: 'ç.question.birthCity.label',
			value: formData?.birthCity,
			type: 'text',
			required: false,
			validators: [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'nationality.id',
			label: 'ç.question.nationality.label',
			value: formData?.nationality?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.NATIONALITY] || [],
			class: 'span6',
		}),
		new InputField({
			key: 'nationalNumber',
			label: 'ç.question.nationalNumber.label',
			value: formData?.nationalNumber,
			type: 'text',
			required: false,
			validators: [],
			class: 'span6',
		}),
		new InputField({
			key: 'idCardNumber',
			label: 'ç.question.idCardNumber.label',
			value: formData?.idCardNumber,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS_LETTERS_DASHES, 'onlyNumbersAndLetters'),
			],
			class: 'span6',
		}),
		new InputField({
			key: 'idExpirationDate',
			label: 'ç.question.idCardExpirationDate.label',
			value: formData?.idExpirationDate,
			type: 'date',
			required: false,
			min: currentDate,
			validators: [ValidatorService.getDateRestrictionValidatior('notInPast', currentDate, DATE_RESTRICTION.PAST)],
			class: 'span6',
		}),
		new InputField({
			key: 'taxId',
			label: 'ç.question.taxId.label',
			value: formData?.taxId,
			type: 'text',
			required: false,
			class: 'span6',
		}),
		new InputSelect({
			key: 'civilStatus.id',
			label: 'ç.question.civilStatus.label',
			value: formData?.civilStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CIVIL_STATUS] || [],
			class: 'span6',
		}),
		
		new InputField({
			key: 'dependentChildren',
			label: 'ç.question.dependentChildren.label',
			value: formData?.dependentChildren,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span6',
		}),
	];
}
