import { ResourceType, PartialNormalizedResource, Client } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { ValidatorService } from '../services/validator.service';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../services/validator-constants';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MAX_AGE).getTime();
	const maxBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MIN_AGE).getTime();

	return [
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || null,
			type: 'text',
			required: true,
			onlyLetters: true,
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || null,
			type: 'text',
			required: true,
			onlyLetters: true,
		}),
		new InputField({
			key: 'birthDate',
			type: 'date',
			label: 'ç.question.birthDate.label',
			value: formData?.birthDate || null,
			required: true,
			validators: [
				ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE),
				ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST),
			],
			min: minBirthDate,
			max: maxBirthDate,
		}),
		new InputSelect({
			key: 'livingStatus.id',
			label: 'ç.question.livingStatus.label',
			value: formData?.livingStatus?.id,
			required: true,
			options: resources?.[ResourceType.LIVING_STATUS],
		}),
	];
}
