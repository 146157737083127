import { InputSelect, InputSwitch } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSwitch({
			key: 'housingType',
			label: 'ç.question.housingType.label',
			value: !!formData?.housingType,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
			class: 'span12',
			helpText: of('ç.question.housingType.label'),
		}),
		new InputSelect({
			key: 'coLivingGroupType.id',
			label: 'ç.question.coLivingGroupType.label',
			value: formData?.coLivingGroupType?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.CO_LIVING_GROUP_TYPE] || [],
			class: 'span12',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const subject = new BehaviorSubject(!formGroup.value['housingType']);

				formGroup.controls['housingType'].valueChanges.pipe(takeUntil(destroy$)).subscribe({
					complete: () => subject.complete(),
					error: (error) => subject.error(error),
					next: (value) => subject.next(!value),
				});

				return subject.asObservable();
			},
			helpText: of('ç.question.coLivingGroupType.helpText'),
		}),
	];
}
