import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Application, Periodicity, FeatureFlag } from '@oper-client/shared/data-model';
import { FeatureFlagService } from './feature-flag.service';

@Injectable({
	providedIn: 'root',
})
export class PeriodicityService {
	constructor(private readonly featureFlagService: FeatureFlagService) {}

	public getBrokeragePeriodicity(): Observable<Periodicity> {
		return this.getPeriodicity(Application.BROKERAGE);
	}

	public getSelfServicePeriodicity(): Observable<Periodicity> {
		return this.getPeriodicity(Application.SELF_SERVICE);
	}

	/*
	Returns income/liability periodicity resource definition ('monthly' or 'yearly') basing on feature flags.

	In Belgium and Austria (and a lot of other countries), the income and liabilities of the borrower are always reviewed
	on a monthly basis. So when an agent enters the salary of the client of 2000 euros it means it's 2000 euros per month.

	However, in Switzerland people work based on yearly salaries etc. This means the periodicity of the incomes/liabilities
	is different.

	We have this notion already in the back-end and the periodicity is set to 'monthly' by default if it's not passed as input.
	However, the feature flags are added to switch between 'yearly' and 'monthly' for different clients. When the flag is set
	to true, each time an income or liability is created in the FE we provide 'periodicity : yearly' as input as well, except
	for credit liabilities managed via Brokerage where we always use 'monthly' periodicity.
	*/
	private getPeriodicity(app: Application): Observable<Periodicity> {
		const featureName = (app.toString() + 'YearlyIncomeLiabilityPeriodicity') as FeatureFlag;
		return this.featureFlagService
			.hasFeatureFlags(featureName)
			.pipe(map((featureFlags) => (featureFlags[featureName] ? Periodicity.YEARLY : Periodicity.MONTHLY)));
	}
}
