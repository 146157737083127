import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, IApiService, IMilestoneService } from '@oper-client/shared/data-access';
import { Milestone } from '@oper-client/shared/data-model';

@Injectable()
export class MilestoneService implements IMilestoneService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getAll(loanRequestId: number): Observable<Milestone[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/milestones/`);
	}

	public updateMilestone(loanRequestId: number, milestoneId: number, milestone: Partial<Milestone>): Observable<Milestone> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/milestones/${milestoneId}/`, milestone);
	}
}
