import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { FinancialData, MortgageReportRequest, Resource, Step, StepData, StepNames } from '@oper-client/shared/data-model';

export const setConfiguration = createAction(
	'[Mortgage Simulator] Set configuration',
	props<{ configuration: Step[]; activeStep?: Step; simulationFlowType: Resource; fromQueryParam: boolean }>()
);

export const setActiveStep = createAction('[Mortgage Simulator] Set Active Step', props<{ step: Step }>());
export const nextStep = createAction('[Mortgage Simulator] Go to next Step');
export const prevStep = createAction('[Mortgage Simulator] Go to previous Step');

export const showLoadingIndicator = createAction('[Mortgage Simulator] Show loading indicator');
export const hideLoadingIndicator = createAction('[Mortgage Simulator] Hide loading indicator');

export const loadConfig = createAction('[Mortgage Simulator] Load Config');
export const loadConfigSuccess = createAction('[Mortgage Simulator] Load Config Success', props<{ config: any }>());
export const loadConfigFailure = createAction(
	'[Mortgage Simulator] Load Config Failure',
	props<{
		error: HttpErrorResponse;
	}>()
);

export const reset = createAction('[Mortgage Simulator] Reset');

export const clear = createAction('[Mortgage Simulator] Clear');

export const clearFinancialData = createAction('[Mortgage Simulator] Clear Financial Data');

export const setFinancialData = createAction(
	'[Mortgage Simulator] Set Financial Data',
	props<{ financialData: Partial<FinancialData>; resetPrevious?: boolean }>()
);

export const loadMortgageReport = createAction(
	'[Mortgage Simulator] Load Mortgage Report',
	props<{ mortgageReportRequest: MortgageReportRequest }>()
);
export const loadMortgageReportSuccess = createAction(
	'[Mortgage Simulator] Load Mortgage Report Success',
	props<{ mortgageReport: any }>()
);
export const loadMortgageReportFailure = createAction(
	'[Mortgage Simulator] Get Mortgage Report Failure',
	props<{ error: HttpErrorResponse }>()
);

export const showError = createAction('[Mortgage Simulator] Show error', props<{ message: string }>());
export const hideError = createAction('[Mortgage Simulator] Hide error', props<{ message: string }>());

export const markStepForSkip = createAction('[Mortgage Simulator] Mark Step for SKip', props<{ step: StepNames; skip: boolean }>());

export const setDataForStep = createAction('[Mortgage Simulator] Set Data for Step', props<{ stepData: StepData<any> }>());
export const syncSimulation = createAction('[Mortgage Simulator] Sync Simulation', props<{ simulationId: number }>());
export const syncSimulationSuccess = createAction('[Mortgage Simulator] Sync Simulation Success');
export const syncSimulationFailure = createAction('[Mortgage Simulator] Sync Simulation Failure', props<{ error: HttpErrorResponse }>());
