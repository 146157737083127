<div class="dropdown" outsideClick (outsideClick)="handleClickOutside()" [attr.data-test]="testLabel">
	@if (multiselect && selectedOptions.length > 0) {
		<div class="counter">{{ selectedOptions.length }}</div>
	}

	@if (label) {
		<div class="label">{{ multiselect ? label : (selectedLabel | translate) }}</div>
	}

	<div class="actions">
		@if (clearable && selectedOptions.length > 0) {
			<oper-client-fontawesome-icon class="clear" [icon]="'faXmark'" (click)="clearOptions()" />
		}

		@if (!disabled) {
			<oper-client-fontawesome-icon [ngClass]="{ 'toggle': true, 'toggle--open': open }" [icon]="'faChevronDown'" />
		}
	</div>
</div>

@if (open) {
	<div [ngClass]="{ 'options': true, 'options--with-scroll': completeOptions.length > 5 }" [@fadeInOut]>
		@for (option of completeOptions; track $index) {
			<div
				[ngClass]="{ 'option': true, 'option--selected': !multiselect && optionSelected(option) }"
				(click)="optionClicked($event, option)"
				[attr.data-test-option]="option.testLabel"
			>
				@if (multiselect) {
					<oper-client-checkbox class="checkbox" [value]="optionSelected(option)" />
				}

				@if (option.key) {
					<div class="label">{{ option.key | translate }}</div>
				} @else {
					<div class="label label--no-label">No key is defined on the option.</div>
				}
			</div>
		}
	</div>
}
