import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';

@Injectable({
	providedIn: 'root',
})
export class AuthRedirectionService {
	private static REDIRECTION_URL_KEY = 'loginRedirectionUrl';

	constructor(private readonly localStorageService: LocalStorageService, private readonly router: Router) {}

	saveRedirectionUrl(url: string): void {
		if (!url) {
			this.localStorageService.unset(AuthRedirectionService.REDIRECTION_URL_KEY);
			return;
		}
		this.localStorageService.set(AuthRedirectionService.REDIRECTION_URL_KEY, url);
	}

	redirectToRedirectionUrl(): void {
		const redirectionUrl: string = this.localStorageService.get(AuthRedirectionService.REDIRECTION_URL_KEY);
		if (redirectionUrl) {
			this.router.navigateByUrl(redirectionUrl);
			this.localStorageService.unset(AuthRedirectionService.REDIRECTION_URL_KEY);
		}
	}
}
