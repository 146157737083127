import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'role.id',
			label: 'ç.question.clientRole.label',
			value: formData?.role?.id,
			required: true,
			options: resources?.[ResourceType.CLIENT_ROLE] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'employmentStatus.id',
			label: 'ç.question.employmentStatus.label',
			value: formData?.employmentStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.EMPLOYMENT_TYPE] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'iban',
			label: 'ç.question.iban.label',
			value: formData?.iban,
			type: 'text',
			required: false,
			validators: [ValidatorService.getIbanValidator()],
			class: 'span3',
		}),
		new InputField({
			key: 'bic',
			label: 'ç.question.bic.label',
			value: formData?.bic,
			type: 'text',
			required: false,
			validators: [ValidatorService.getBicValidator()],
			class: 'span3',
		}),
		new InputSelect({
			key: 'livingStatus.id',
			label: 'ç.question.livingStatus.label',
			value: formData?.livingStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.LIVING_STATUS] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'futureLivingStatus.id',
			label: 'ç.question.futureLivingStatus.label',
			value: formData?.futureLivingStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.LIVING_STATUS] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'countryArrivalDate',
			label: 'ç.question.arrivalDate.label',
			value: formData?.countryArrivalDate,
			type: 'date',
			required: false,
			validators: [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'residencePermit.id',
			label: 'ç.question.residencePermit.label',
			value: formData?.residencePermit?.id,
			required: false,
			options: resources?.[ResourceType.RESIDENCE_PERMIT] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'compliancePersonalizedInformation.id',
			label: 'ç.question.compliancePersonalizedInformation.label',
			value: formData?.compliancePersonalizedInformation?.id,
			required: false,
			options: resources?.[ResourceType.PERSONALIZED_INFORMATION] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'externalReference',
			label: 'ç.question.externalReference.label',
			value: formData?.externalReference,
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
		}),
		new InputField({
			key: 'isTaxResidenceInSwiss',
			label: 'ç.question.isTaxResidenceInSwiss.label',
			value: formData?.isTaxResidenceInSwiss || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new InputField({
			key: 'isTaxResidenceInUsa',
			label: 'ç.question.isTaxResidenceInUsa.label',
			value: formData?.isTaxResidenceInUsa || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new InputField({
			key: 'isDebtCollection',
			label: 'ç.question.isDebtCollection.label',
			value: formData?.isDebtCollection || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new InputField({
			key: 'hasPoliticallyExposedPersonStatus',
			label: 'ç.question.hasPoliticallyExposedPersonStatus.label',
			value: formData?.hasPoliticallyExposedPersonStatus || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
			badgeLabel: 'PEP',
		}),
	];
}
