import { PartialNormalizedResource, Liability, Periodicity, CreditProvider, ProductDurationConfig } from '@oper-client/shared/data-model';

import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData?: Partial<Liability>,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[],
	durationConfig?: Partial<ProductDurationConfig & {periodicity?: Periodicity}>
): InputBase<any>[] {
	return [
		...(durationConfig.periodicity === Periodicity.YEARLY
			? [
					new InputField({
						key: 'amount',
						label: 'ç.question.amountYearly.label',
						value: formData?.amount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
					}),
			  ]
			: [
					new InputField({
						key: 'monthlyAmount',
						label: 'ç.question.amountMonthly.label',
						value: formData?.monthlyAmount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
					}),
			  ]),
	];
}
