import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DATE_RESTRICTION } from '../services/validator-constants';
import {isMoment} from "moment";

export function dateRestrictionValidatior(
	validationMessageKey: string,
	firstOffset: number,
	dateRestriction: DATE_RESTRICTION,
	secondOffset?: number,
	transform?: (firstOffset, secondOffset) => any
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
    if (isMoment(control.value)) {
      return null;
    }
		const date: Date = new Date(control.value);
		if (!(date && date.getTime())) {
			return null;
		}
		const dateMS: number = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();

		const returnValue = {
			[validationMessageKey]: transform ? transform(firstOffset, secondOffset) : firstOffset,
		};
		switch (dateRestriction) {
			case DATE_RESTRICTION.PAST:
				if (dateMS < firstOffset) {
					return returnValue;
				}
				break;
			case DATE_RESTRICTION.FUTURE:
				if (dateMS > firstOffset) {
					return returnValue;
				}
				break;
			case DATE_RESTRICTION.BOTH:
				if (dateMS < firstOffset || dateMS > secondOffset) {
					return returnValue;
				}
				break;
			default:
				return null;
		}
	};
}
