import { PartialNormalizedResource, Liability, ResourceType } from '@oper-client/shared/data-model';

import { InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData?: Partial<Liability>,
	resources?: PartialNormalizedResource,
	liabilityTypeDefinitions: string[] = []
): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'liabilityType.id',
			label: 'ç.question.liabilityType.label',
			value: '',
			required: true,
			options: resources?.[ResourceType.LIABILITY_TYPE]?.filter((item) => liabilityTypeDefinitions.includes(item.definition)),
			class: 'span12',
		}),
	];
}
