import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState } from '@oper-client/shared/data-model';
import { LoanRequestPartialState } from '../loan-request.reducer';
import { CreditProviderFeatureActionTypes } from './credit-provider.reducer';
import { loadBrokerCreditProviders, loadCreditProviders } from './credit-provider.actions';
import * as CreditProviderSelectors from './credit-provider.selectors';

@Injectable()
export class CreditProviderFacade {
	creditProviders$ = this.store.pipe(select(CreditProviderSelectors.getCreditProviders));
	liabilityProviders$ = this.creditProviders$.pipe(
		map((creditProviders) => creditProviders.filter((creditProvider) => creditProvider.isLiabilityProvider))
	);
	activeCreditProviders$ = this.creditProviders$.pipe(
		map((creditProviders) => creditProviders.filter((creditProvider) => creditProvider.active))
	);
	loadCreditProviderActionState$ = this.selectActionState('loadCreditProviders');
	brokerCreditProviders$ = this.store.pipe(select(CreditProviderSelectors.getBrokerCreditProviders));

	constructor(private store: Store<LoanRequestPartialState>) {}

	public loadCreditProviders(params?: { active?: boolean; isLiabilityProvider?: boolean }) {
		this.store.dispatch(loadCreditProviders(params || {}));
	}

	private selectActionState(actionType: CreditProviderFeatureActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(CreditProviderSelectors.getActionState(actionType)), map(parseActionState));
	}
	
	public loadBrokerCreditProviders(params?: { active?: boolean; isLiabilityProvider?: boolean; }) {
		this.store.dispatch(loadBrokerCreditProviders(params || {}));
	}
}
