import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect, InputSwitch } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { of } from 'rxjs';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'landRegister',
			label: 'ç.question.landRegister.label',
			value: formData?.landRegister,
			type: 'text',
			required: false,
			class: 'span3',
			order: 1,
		}),
		new InputField({
			key: 'propertyNumber',
			label: 'ç.question.propertyNumber.label',
			value: formData?.propertyNumber,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputSelect({
			key: 'features',
			label: 'ç.question.propertyFeatures.label',
			value: formData?.features,
			bindValue: 'id',
			multiple: true,
			required: false,
			options: resources?.[ResourceType.REALTY_FEATURE] || [],
			class: 'span3',
			beta: false,
			clearable: true,
			alreadySorted: true,
		}),
		new InputSwitch({
			key: 'hasApplicableLandCharges',
			label: 'ç.question.landCharges.label',
			helpText: of('ç.question.landCharges.helpText'),
			value: formData?.hasApplicableLandCharges,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
			class: 'span3',
		}),
	];
}
