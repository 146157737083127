import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, filter, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

import { DocumentService } from '../../services/document.service';
import { Document, DocumentTypes, Proof } from '@oper-client/shared/data-model';
import * as ProofActions from './proof.actions';
import { HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';

@Injectable()
export class ProofEffects {
	// Loads both the proof as the documents within the proof
	loadProof$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(ProofActions.loadProof),
				switchMap((payload) =>
					this.documentService.getProofs(payload.loanRequestId).pipe(
						mergeMap((proofs: Proof[]) => from(proofs)),
						mergeMap((proof: Proof) =>
							this.documentService
								.getProofDocuments(payload.loanRequestId, proof.id)
								.pipe(map((proofDocuments: Document[]) => ({ ...proof, documents: proofDocuments })))
						),
						toArray(),
						map((proof: Proof[]) => ProofActions.loadProofSuccess({ proof: proof })),
						catchError((error) => of(ProofActions.loadProofFailure({ error: error })))
					)
				)
			)
	);

	createProofDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.createProofDocument),
			mergeMap(({ loanRequestId, categoryId, document }) =>
				this.documentService.addProofDocument(loanRequestId, categoryId, document).pipe(
					map((newDocument) => ProofActions.createProofDocumentSuccess({ categoryId, document: newDocument })),
					catchError((error) => of(ProofActions.createProofDocumentFailure({ error })))
				)
			)
		)
	);

	uploadDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.uploadDocument),
			mergeMap(({ loanRequestId, proofId, document, file, kind }) => {
				const body = {
					...document,
					toBeSignedDocument: kind === DocumentTypes.toBeSigned,
				};
				if (kind === DocumentTypes.informative) {
					body.externalId = document.externalId;
					body.isInformativeDocument = true;
				}
				return this.documentService.addProofDocument(loanRequestId, proofId, body).pipe(
					mergeMap((newDocument) => {
						const documentId =
							kind === DocumentTypes.informative
								? Number(newDocument.informativeDocument.id)
								: Number(newDocument.unsignedDocument.id);
						return this.documentService.uploadProofFile(loanRequestId, proofId, documentId, file).pipe(
							filter(
								(event) =>
									event instanceof HttpResponse || (event as HttpProgressEvent).type === HttpEventType.UploadProgress
							),
							mergeMap((event) => {
								if (event instanceof HttpResponse) {
									return from([
										ProofActions.createProofDocumentSuccess({ categoryId: proofId, document: newDocument }),
										ProofActions.loadProof({ loanRequestId }),
										ProofActions.uploadDocumentSuccess(),
									]);
								} else if ((event as HttpProgressEvent).type === HttpEventType.UploadProgress) {
									const progressEvent = event as HttpProgressEvent;
									return [
										ProofActions.uploadFileProgress({
											loanRequestId,
											documentId,
											file,
											loaded: progressEvent.loaded,
											total: progressEvent.total,
										}),
									];
								}
							})
						);
					}),
					catchError((error) => of(ProofActions.createProofDocumentFailure({ error })))
				);
			})
		)
	);

	updateProofDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.updateProofDocument),
			mergeMap(({ loanRequestId, categoryId, document }) =>
				this.documentService.updateProofDocument(loanRequestId, categoryId, +document.id, document.changes).pipe(
					map((updatedDocument) =>
						ProofActions.updateProofDocumentSuccess({
							categoryId,
							document: {
								id: updatedDocument.id,
								changes: { ...updatedDocument, fileName: updatedDocument.fileName || null },
							},
						})
					),
					catchError((error) => of(ProofActions.updateProofDocumentFailure({ error })))
				)
			)
		)
	);

	uploadFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.uploadFile),
			mergeMap(({ loanRequestId, categoryId, documentId, file }) => {
				return this.documentService.uploadProofFile(loanRequestId, categoryId, documentId, file).pipe(
					filter((event) => event instanceof HttpResponse || (event as HttpProgressEvent).type === HttpEventType.UploadProgress),
					map((event) => {
						if (event instanceof HttpResponse) {
							return ProofActions.uploadFileSuccess({ categoryId, document: { id: documentId, changes: event.body } });
						} else if ((event as HttpProgressEvent).type === HttpEventType.UploadProgress) {
							const progressEvent = event as HttpProgressEvent;
							return ProofActions.uploadFileProgress({
								loanRequestId,
								documentId,
								file,
								loaded: progressEvent.loaded,
								total: progressEvent.total,
							});
						}
					}),
					catchError((error) => of(ProofActions.uploadFileFailure({ categoryId, documentId, error })))
				);
			})
		)
	);

	deleteProofDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.deleteProofDocument),
			mergeMap(({ loanRequestId, categoryId, documentId }) =>
				this.documentService.deleteProofDocument(loanRequestId, categoryId, documentId).pipe(
					map(() => ProofActions.deleteProofDocumentSuccess({ categoryId, documentId })),
					catchError((error) => of(ProofActions.updateProofDocumentFailure({ error })))
				)
			)
		)
	);

	deleteProofDocumentFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.deleteProofDocumentFile),
			mergeMap(({ loanRequestId, categoryId, documentId }) =>
				this.documentService.deleteProofDocumentFile(loanRequestId, categoryId, documentId).pipe(
					map(() =>
						ProofActions.updateProofDocument({
							loanRequestId: loanRequestId,
							categoryId: categoryId,
							document: { id: documentId, changes: {} },
						})
					),
					catchError((error) => of(ProofActions.deleteProofDocumentFailure({ error })))
				)
			)
		)
	);

	loadAllowedProofDocuments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.loadAllowedProofDocuments),
			switchMap(({ kind, loanRequestId, categoryId }) =>
				this.documentService.getAllowedProofDocuments(kind, loanRequestId, categoryId).pipe(
					map((allowedDocuments) => ProofActions.loadAllowedProofDocumentsSuccess({ allowedDocuments })),
					catchError((error) => of(ProofActions.loadAllowedProofDocumentsFailure({ error })))
				)
			)
		)
	);

	downloadZippedProofDocuments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.downloadZippedProofDocuments),
			switchMap(({ loanRequestId, language }) =>
				this.documentService.downloadProofZip(loanRequestId, { language }).pipe(
					map((zipFiles) => ProofActions.downloadZippedProofDocumentsSuccess({ zipFiles })),
					catchError((error) => of(ProofActions.downloadZippedProofDocumentsFailure({ error })))
				)
			)
		)
	);

	generateDocument$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ProofActions.generateDocument),
			switchMap(({ loanRequestId, documentOptions, data }) =>
				this.documentService
					.generateDocument(loanRequestId, {
						...(documentOptions?.documentType && { documentType: { definition: documentOptions.documentType } }),
						...(documentOptions?.libraryDocument && { libraryDocument: { id: documentOptions.libraryDocument.id } }),
						...(documentOptions?.loanOfferIds && {
							loanOffers: documentOptions.loanOfferIds.map((loanOfferId) => ({ id: loanOfferId })),
						}),
						...(documentOptions?.productItem && { productItem: { id: documentOptions.productItem.id } }),
						...(documentOptions?.language && { language: documentOptions.language }),
						...(documentOptions?.currency && { currency: documentOptions?.currency }),
						...(data && { _json_data_data: data }),
					})
					.pipe(
						map((document) => ProofActions.generateDocumentSuccess({ document })),
						catchError((error) => of(ProofActions.generateDocumentFailure({ error })))
					)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private documentService: DocumentService
	) {}
}
