import { PartialNormalizedResource, ResourceType, Realty } from '@oper-client/shared/data-model';

import { InputField, InputSelect, PercentageInputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { roundPercentage } from '@oper-client/shared/util-formatting';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: false,
			class: 'span3',
			updateValidityOnFormValueChanges: true,
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span3',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.address?.box,
			type: 'text',
			required: false,
			validators: [],
			updateValidityOnFormValueChanges: true,
			class: 'span3',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			updateValidityOnFormValueChanges: true,
			class: 'span3',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span3',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span3',
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			value: formData?.realtyType?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_TYPE] || [],
			validators: [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'purposes[0].id',
			label: 'ç.question.mainPurpose.label',
			value: formData?.purposes?.[0]?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_PURPOSE] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'usageTypes[0].id',
			label: 'ç.question.usageType.label',
			value: formData?.usageTypes?.[0]?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE] || [],
			class: 'span3',
		}),
		new InputField({
			key: 'mainFirstResidence',
			label: 'ç.question.mainFirstResidence.label',
			value: formData?.mainFirstResidence || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new PercentageInputField({
			key: 'livingPercentage',
			label: 'ç.question.livingPercentage.label',
			value: roundPercentage(formData?.livingPercentage),
			type: 'number',
			validators: [Validators.min(0), Validators.max(100)],
			hidden: (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
				const livingUsageTypeId = resources?.[ResourceType.REALTY_USAGE_TYPE]?.find((item) => item.definition === 'living')?.id;
				const subject = new BehaviorSubject(
					!formGroup.value['usageTypes[0].id'] || formGroup.value['usageTypes[0].id'] === livingUsageTypeId
				);

				formGroup.controls['usageTypes[0].id'].valueChanges
					.pipe(
						map((value) => !value || value === livingUsageTypeId),
						takeUntil(destroy$)
					)
					.subscribe({
						complete: () => subject.complete(),
						error: (error) => subject.error(error),
						next: (value) => subject.next(value),
					});

				return subject.asObservable();
			},
			required: true,
			class: 'span3',
		}),
	];
}
