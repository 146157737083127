import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { asyncScheduler, combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { IAM } from '@oper-client/shared/data-model';
import { AUTH_SERVICE, IAuthService, IDENTITY_PROVIDER_SERVICE, IIdentityProviderService } from '@oper-client/shared/data-access';
import * as iamActions from './iam.actions';
import { PermissionService } from './../services/permission.service';

const REQUEST_DELAY = 400;

@Injectable()
export class IamEffects {
	login$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(iamActions.login),
					mergeMap((action) => {
						const { ...props } = action;

						return combineLatest([
							timer(requestDelay, scheduler),
							(<Observable<IAM.JwtTokens>>this.authService.login(props.credentials)).pipe(
								map((tokens) => {
									this.authService.setTokens(tokens);
									return tokens;
								}),
								switchMap((tokens) =>
									of(
										iamActions.loginSuccess({
											tokens: tokens,
											loginSuccessRedirectRoute: props?.loginSuccessRedirectRoute,
										})
									)
								),
								catchError((httpError) => of(iamActions.loginFailure({ httpError })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	loadCurrentUser$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.loadCurrentUser),
			switchMap(() => {
				return this.authService.getCurrentUser().pipe(
					map((user: IAM.User) => {
						if (user) {
							return iamActions.loadCurrentUserSuccess({ user: { ...user } });
						}
						return iamActions.loadCurrentUserFailure({ httpError: null });
					}),
					catchError((httpError) => of(iamActions.loadCurrentUserFailure({ httpError })))
				);
			})
		)
	);

	updateCurrentUser$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.updateCurrentUser),
			switchMap(({ user }) => {
				return this.authService.updateCurrentUser(user.changes).pipe(
					map((user: IAM.User) => {
						if (user) {
							return iamActions.updateCurrentUserSuccess({ user: { id: user.id, changes: user } });
						}
						return iamActions.updateCurrentUserFailure({ httpError: null });
					}),
					catchError((httpError) => of(iamActions.updateCurrentUserFailure({ httpError })))
				);
			})
		)
	);

	loadCurrentUserOnLoginSuccess$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.loginSuccess),
			map((action) => {
				const { ...props } = action;
				this.router.navigate(props?.loginSuccessRedirectRoute ?? ['/app']);
				return iamActions.loadCurrentUser();
			})
		)
	);

	loadPermissionsOnLoadCurrentUserSuccess$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.loadCurrentUserSuccess),
			map(() => {
				return iamActions.loadPermissions();
			})
		)
	);

	logout$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(iamActions.logout),
				switchMap(() => this.authService.logout().pipe(tap(() => this.router.navigate(['/auth/login']))))
			),
		{ dispatch: false }
	);

	signInToIdentityProvider$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(iamActions.signInToIdentityProvider),
				tap((action) => (window.location.href = action.authRequest.authUrl))
			),
		{ dispatch: false }
	);

	loadIdentity$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.loadIdentity),
			switchMap((action) => {
				const { identityProvider, queryParams, loanRequestId } = action;
				if (queryParams) {
					return this.identityProviderService
						.getUserInfo({
							...queryParams,
							loanRequestId: loanRequestId,
						})
						.pipe(
							map((identity: any) => {
								return iamActions.loadIdentitySuccess({
									identity: identity,
									identityProvider: identityProvider,
								});
							}),
							catchError((httpError) => of(iamActions.loadIdentityFailure({ httpError })))
						);
				} else {
					return this.identityProviderService.getLoginUrl(identityProvider).pipe(
						map((url: string) => {
							return iamActions.signInToIdentityProvider({
								authRequest: {
									state: new URL(url).searchParams.get('state'),
									url: this.router.url,
									identityProvider,
									authUrl: url,
								},
							});
						}),
						catchError((httpError) => of(iamActions.loadIdentityFailure({ httpError })))
					);
				}
			})
		)
	);

	forgotPassword$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.forgotPassword),
			switchMap((action) => {
				const { email } = action;
				return this.authService.forgotPassword(email).pipe(
					mergeMap(() => of(iamActions.forgotPasswordSuccess())),
					catchError((httpError) => of(iamActions.forgotPasswordFailure({ httpError })))
				);
			})
		)
	);

	resetPassword$: Observable<Action> = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.resetPassword),
			switchMap((action) => {
				return this.authService.resetPassword(action.payload).pipe(
					mergeMap(() => of(iamActions.resetPasswordSuccess())),
					catchError((httpError) => of(iamActions.resetPasswordFailure({ httpError })))
				);
			})
		)
	);

	resetPassword2Fa$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(iamActions.resetPassword2Fa),
					mergeMap((action) => {
						return combineLatest([
							timer(requestDelay, scheduler),
							(<Observable<IAM.JwtTokens>>this.authService.resetPassword2Fa(action.payload)).pipe(
								map((tokens) => {
									this.authService.setTokens(tokens);
									return tokens;
								}),
								switchMap((tokens) =>
									of(
										iamActions.loginSuccess({
											tokens: tokens,
										})
									)
								),
								catchError((httpError) => of(iamActions.resetPassword2FaFailure({ httpError })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	activateUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.activateUser),
			switchMap(({ token }) =>
				this.authService.activateUser(token).pipe(
					map(() => iamActions.activateUserSuccess()),
					catchError((httpError) => of(iamActions.activateUserFailure({ httpError })))
				)
			)
		)
	);

	verifyUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.verifyUser),
			switchMap(({ code, token }) =>
				this.authService.verifyUser(code, token).pipe(
					map(() => iamActions.verifyUserSuccess()),
					catchError((httpError) => of(iamActions.verifyUserFailure({ httpError })))
				)
			)
		)
	);

	validateCredentials$ = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.validateCredentials),
			switchMap(({ username, password }) =>
				this.authService.validateCredentials(username, password).pipe(
					map(({ email, token, verified }) => iamActions.validateCredentialsSuccess({ email, token, verified })),
					catchError((httpError) => of(iamActions.validateCredentialsFailure({ httpError })))
				)
			)
		)
	);

	activateOTP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.activateOTP),
			switchMap(({ token }) =>
				this.authService.activateOTP(token).pipe(
					map(() => iamActions.activateOTPSuccess()),
					catchError((httpError) => of(iamActions.activateOTPFailure({ httpError })))
				)
			)
		)
	);

	verifyOTP$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(iamActions.verifyOTP),
					mergeMap(({ token, code, loginSuccessRedirectRoute }) => {
						return combineLatest([
							timer(requestDelay, scheduler),
							this.authService.verifyOTP(token, code).pipe(
								map((tokens) => {
									this.authService.setTokens(tokens);
									return tokens;
								}),
								switchMap((tokens) =>
									of(
										iamActions.loginSuccess({
											tokens: tokens,
											loginSuccessRedirectRoute: loginSuccessRedirectRoute,
										})
									)
								),
								catchError((httpError) => of(iamActions.verifyOTPFailure({ httpError })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	loadPermissions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.loadPermissions),
			switchMap(() =>
				this.permissionService.getPermissions().pipe(
					map((permissions: string[]) =>
						iamActions.loadPermissionsSuccess({
							permissions: permissions,
						})
					),
					catchError((httpError) => of(iamActions.loadPermissionsFailure({ httpError })))
				)
			)
		)
	);

	createUser$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(iamActions.createUser),
					mergeMap(({ userData }) => {
						return combineLatest([
							timer(requestDelay, scheduler),
							this.authService.createUser(userData).pipe(
								map((tokens) => {
									this.authService.setTokens(tokens);
									return tokens;
								}),
								switchMap((tokens) =>
									of(
										iamActions.loginSuccess({
											tokens,
											loginSuccessRedirectRoute: ['/auth/sign-up'],
										})
									)
								),
								catchError((httpError) => of(iamActions.createUserFailure({ httpError })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	updateProfile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(iamActions.updateProfile),
			switchMap(({ profileData }) =>
				this.authService.updateProfile(profileData).pipe(
					map(({ token }) => iamActions.updateProfileSuccess({ token })),
					catchError((httpError) => of(iamActions.updateProfileFailure({ httpError })))
				)
			)
		)
	);

	inviteUser$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(iamActions.inviteUser),
					mergeMap(({ userData }) => {
						return combineLatest([
							timer(requestDelay, scheduler),
							this.authService.inviteUser(userData).pipe(
								map((tokens) => {
									this.authService.setTokens(tokens);
									return tokens;
								}),
								switchMap((tokens) =>
									of(
										iamActions.loginSuccess({
											tokens,
											loginSuccessRedirectRoute: ['/auth/sign-up'],
										})
									)
								),
								catchError((httpError) => of(iamActions.inviteUserFailure({ httpError })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	constructor(
		private actions$: Actions,
		@Inject(AUTH_SERVICE) private authService: IAuthService,
		@Inject(IDENTITY_PROVIDER_SERVICE) private readonly identityProviderService: IIdentityProviderService,
		private permissionService: PermissionService,
		private router: Router
	) {}
}
