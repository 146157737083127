import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IamFacade } from '../+state/iam.facade';
import { UserPermissions } from '../enums/user-permissions.enum';

@Injectable({ providedIn: 'root' })
export class UserPermissionsGuard implements CanActivate, CanLoad {
	constructor(private iamFacade: IamFacade, private readonly router: Router) {}

	canLoad(route: Route): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.hasPermissions(route.data.permissions).pipe(
			tap((result) => {
				if (!result && route.data.redirectTo) {
					this.router.navigate([route.data.redirectTo]);
				}
			})
		);
	}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.hasPermissions(route.data.permissions).pipe(
			tap((result) => {
				if (!result && route.data.redirectTo) {
					this.router.navigate([route.data.redirectTo]);
				}
			})
		);
	}

	private hasPermissions(permissions: UserPermissions[]): Observable<boolean> {
		if (!permissions || !Array.isArray(permissions)) {
			return of(true);
		}

		return this.iamFacade.permissions$.pipe(map((list) => !!list && permissions.every((permission) => list.indexOf(permission) > -1)));
	}
}
