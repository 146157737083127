import { Component } from '@angular/core';
import { Periodicity } from '@oper-client/shared/data-model';
import { PeriodicityService } from '@oper-client/shared/util-feature-flag';
import { DestroyableComponent } from '@shared/util-component';
import { map, takeUntil } from 'rxjs';

@Component({
	selector: 'oper-client-periodicity',
	styleUrls: ['./periodicity.component.scss'],
	template: `{{ label$ | async | translate }}`,
})
export class PeriodicityComponent extends DestroyableComponent {
	periodicityEnum: Periodicity;

	readonly periodicity$ = this.periodicityService.getBrokeragePeriodicity();
	readonly label$ = this.periodicity$.pipe(
		map((periodicity) => this.getLabel(periodicity)),
		takeUntil(this.destroy$)
	);

	constructor(private readonly periodicityService: PeriodicityService) {
		super();
	}

	getLabel(periodicity: Periodicity): string {
		switch (periodicity) {
			case Periodicity.YEARLY:
				return 'ç.misc.duration.year.plural';
			case Periodicity.MONTHLY:
				return 'ç.misc.duration.month.plural';
			default:
				return 'ç.misc.duration.month.plural';
		}
	}
}
