import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of, iif } from 'rxjs';
import { retryWhen, delay, concatMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ConnectionLostInterceptor implements HttpInterceptor {
	private MAX_RETRIES = 2;

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			// Inspiration from https://stackoverflow.com/questions/44911251/how-to-create-an-rxjs-retrywhen-with-delay-and-limit-on-tri
			retryWhen((errors) =>
				errors.pipe(
					// Use concat map to keep the errors in order and make sure they
					// aren't executed in parallel
					concatMap((error, i) =>
						// Executes a conditional Observable depending on the result
						// of the first argument
						iif(
							() => error?.status === 0 && i < this.MAX_RETRIES,
							// If the condition is true we pipe this back into our stream and delay the retry
							of(error).pipe(delay(500)),
							// Otherwise we throw the error (the last error)
							throwError(error)
						)
					)
				)
			)
		);
	}
}
