import { BehaviorSubject } from 'rxjs';

export type StepModalEventData = any;

export interface ModalSettings {
	size?: 'small' | 'medium' | 'large' | 'max';
	disableTransitionEffect?: boolean;
	wrapperClasses?: string[];
	overlayClasses?: string[];
	title?: string;
	description?: string;
	classPrimaryPostfix?: string;
	classSecondaryPostfix?: string;
	classThirdaryPostfix?: string;
	actionPrimary?: string;
	actionSecondary?: string;
	actionThirdary?: string;
	enablePrimary?: boolean;
	enableSecondary?: boolean;
	enableThirdary?: boolean;
	closeButton?: boolean;
	headerBackButton?: string;
	headerBorder?: boolean;
	footerBorder?: boolean;
	hideFooter?: boolean;
	closeOnClickOutside?: boolean;
	noContentPadding?: boolean;
	centerCta?: boolean;
}

export const DEFAULT_MODAL_SETTINGS: ModalSettings = {
	size: 'small',
	disableTransitionEffect: false,
	wrapperClasses: [],
	overlayClasses: [],
	enablePrimary: true,
	enableSecondary: true,
	enableThirdary: true,
	closeButton: true,
	headerBorder: true,
	footerBorder: true,
	hideFooter: false,
	closeOnClickOutside: false,
	noContentPadding: false,
	centerCta: false,
};

export interface StepModalSettings extends ModalSettings {
	step: number;
	handleNextStep?(event?: StepModalEventData): void;
	handlePreviousStep?(event?: StepModalEventData): void;
	handleClose?(event?: StepModalEventData): void;
}

export abstract class StepModalAbstract {
	private readonly _currentStepSetting$ = new BehaviorSubject<StepModalSettings>(null);
	readonly currentStep$ = this._currentStepSetting$.asObservable();
	protected steps: StepModalSettings[];

	constructor() {
		this.initializeSteps();
	}

	protected abstract getStepModalSettings(): StepModalSettings[];

	setToNextStep(nextStep?: number): void {
		const next = this.steps?.find((s) => s.step === (nextStep !== undefined ? nextStep : this._currentStepSetting$.value?.step + 1));
		if (next) {
			this._currentStepSetting$.next(next);
		}
	}

	setToPreviousStep(previousStep?: number): void {
		const previous = this.steps?.find(
			(s) => s.step === (previousStep !== undefined ? previousStep : this._currentStepSetting$.value?.step - 1)
		);
		if (previous) {
			this._currentStepSetting$.next(previous);
		}
	}

	setCurrentStep(step: number): void {
		const current = this.steps?.find((s) => s.step === step);
		if (current) {
			this._currentStepSetting$.next(current);
		}
	}

	updateStepSettings(stepNumber: number, settings: Partial<StepModalSettings>) {
		const stepIndex = this.steps?.findIndex((s) => s.step === stepNumber);
		if (stepIndex > -1) {
			this.steps[stepIndex] = Object.assign(this.steps[stepIndex], { ...settings });
		}
	}

	resetSteps() {
		this.initializeSteps();
	}

	initializeSteps(): void {
		this.steps = this.getStepModalSettings();
		this.setCurrentStep(0);
	}
}
