import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidatorFn } from '@angular/forms';
import { AbstractControlWithWarning } from '../models/form-warning.model';

@Injectable({
	providedIn: 'root',
})
export class FormWarningService {
	/**
	 * FormWarningSerivce are like validations. However, they will never
	 * actually provide errors.
	 * The service should always set the .warnings property on a Control field.
	 *
	 * NOTE: Make sure to also clear your values, as this will not be done automatically
	 *
	 * NOTE: unless sure, always add the validators on the FormGroups, not on
	 * independent item. The warnings won't propagate
	 */
	constructor(private readonly translate: TranslateService) {}

	/**
	 * Compares the startDate in the formControl to today. (now - startDate)
	 * If that time frame is less than input months, it sets the
	 * 'nowMinusStartDateLessThanMonths' warning field.
	 *
	 * Dates in the future will have negative date differences as it's today-startDate
	 * @param months
	 */
	public nowMinusStartDateLessThanMonths(months: number): ValidatorFn {
		return (control: AbstractControlWithWarning): null => {
			const startDate = new Date(control.value.startDate);
			if (!startDate) {
				if (control.warnings) {
					delete control.warnings.nowMinusStartDateLessThanMonths;
				}
				return null;
			}
			const now = new Date();

			const yearDiff = now.getUTCFullYear() - startDate.getUTCFullYear();
			const monthDiff = now.getUTCMonth() - startDate.getUTCMonth();
			const dayDiff = now.getUTCDate() - startDate.getUTCDate();
			const difference = yearDiff * 12 + monthDiff + (dayDiff > 0 ? 0 : -1);

			if (difference < months && difference > 0) {
				if (!control.warnings) {
					control.warnings = {};
				}
				control.warnings.nowMinusStartDateLessThanMonths = { minMonths: months };
			} else {
				if (control.warnings) {
					delete control.warnings.nowMinusStartDateLessThanMonths;
				}
			}
			return null;
		};
	}

	public formatWarning(key, options = null) {
		switch (key) {
			case 'nowMinusStartDateLessThanMonths':
				return this.translate.instant(`ç.question.warning.nowMinusStartDateLessThanMonths`, options);
			case 'countryNotBelgium':
				return `ç.question.warning.countryNotBelgium`;
			default:
				return `ç.question.warning.default`;
		}
	}

	public formatWarnings(warnings) {
		return Object.keys(warnings).map(key => {
			return this.translate.instant(this.formatWarning(key, warnings[key]));
		});
	}
}
