import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ActionState, setActionState, ActionTypes, initialActionState, Income } from '@oper-client/shared/data-model';
import * as IncomeActions from './income.actions';

export const INCOME_ENTITY_KEY = 'incomes';

export interface IncomeState extends EntityState<Income> {
	// additional entities state properties
	actions: IncomeActionsState;
}

export type IncomeActionTypes = 'loadIncome' | 'loadIncomes' | 'createIncome' | 'updateIncome' | 'removeIncome';
export type IncomeActionsState = Record<IncomeActionTypes, ActionState>;

export const incomeAdapter: EntityAdapter<Income> = createEntityAdapter<Income>();

export const initialState: IncomeState = incomeAdapter.getInitialState({
	// additional entity state properties
	actions: {
		loadIncome: initialActionState,
		loadIncomes: initialActionState,
		createIncome: initialActionState,
		updateIncome: initialActionState,
		removeIncome: initialActionState,
	},
});

function setActionStates(
	actionState: IncomeActionsState,
	action: IncomeActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): IncomeActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,

	on(IncomeActions.addIncome, state => ({
		...state,
		actions: setActionStates(state.actions, 'createIncome', ActionTypes.loading),
	})),
	on(IncomeActions.addIncomeSuccess, (state, { income }) =>
		incomeAdapter.addOne(income, { ...state, actions: setActionStates(state.actions, 'createIncome', ActionTypes.success) })
	),
	on(IncomeActions.addIncomeFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createIncome', ActionTypes.failure, error),
	})),

	on(IncomeActions.updateIncome, state => ({
		...state,
		actions: setActionStates(state.actions, 'updateIncome', ActionTypes.loading),
	})),
	on(IncomeActions.updateIncomeSuccess, (state, { income }) =>
		incomeAdapter.updateOne(income, { ...state, actions: setActionStates(state.actions, 'updateIncome', ActionTypes.success) })
	),
	on(IncomeActions.updateIncomeFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateIncome', ActionTypes.failure, error),
	})),

	on(IncomeActions.deleteIncome, state => ({
		...state,
		actions: setActionStates(state.actions, 'removeIncome', ActionTypes.loading),
	})),
	on(IncomeActions.deleteIncomeSuccess, (state, { id }) =>
		incomeAdapter.removeOne(id, { ...state, actions: setActionStates(state.actions, 'removeIncome', ActionTypes.success) })
	),
	on(IncomeActions.deleteIncomeFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeIncome', ActionTypes.failure, error),
	})),

	on(IncomeActions.loadIncome, state => ({
		...state,
		actions: setActionStates(state.actions, 'loadIncome', ActionTypes.loading),
	})),
	on(IncomeActions.loadIncomeSuccess, (state, { income }) =>
		incomeAdapter.upsertOne(income, { ...state, actions: setActionStates(state.actions, 'loadIncome', ActionTypes.success) })
	),
	on(IncomeActions.loadIncomeFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadIncome', ActionTypes.failure, error),
	})),

	on(IncomeActions.loadIncomes, state => ({
		...state,
		actions: setActionStates(state.actions, 'loadIncomes', ActionTypes.loading),
	})),
	on(IncomeActions.loadIncomesSuccess, (state, { incomes }) =>
		incomeAdapter.upsertMany(incomes, { ...state, actions: setActionStates(state.actions, 'loadIncomes', ActionTypes.success) })
	),
	on(IncomeActions.loadIncomesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadIncomes', ActionTypes.failure, error),
	})),

	on(IncomeActions.clearIncomes, state => incomeAdapter.removeAll(state))
);
