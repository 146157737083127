import { createSelector } from '@ngrx/store';

import { COMMISSION_FEATURE_KEY, commissionAdapter, CommissionActionTypes } from './commission.reducer';
import { getCommissionFeatureState } from '../commission.selectors';

export const getCommissionState = createSelector(getCommissionFeatureState, state => state[COMMISSION_FEATURE_KEY]);

const selectors = commissionAdapter.getSelectors();

export const getCommissionStatement = createSelector(getCommissionState, state => state.commissionStatement);
export const getCommissions = createSelector(getCommissionState, selectors.selectAll);
export const getCommissionEntities = createSelector(getCommissionState, selectors.selectEntities);

export const getActionStates = createSelector(getCommissionState, state => state.actions);
export const getActionState = (actionType: CommissionActionTypes) => createSelector(getActionStates, state => state[actionType]);
