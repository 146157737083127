import { ResourceType, PartialNormalizedResource, Client } from '@oper-client/shared/data-model';
import { ValidatorService } from '../services/validator.service';

import { InputField, InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { of } from 'rxjs';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../services/validator-constants';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MAX_AGE).getTime();
	const maxBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MIN_AGE).getTime();

	return [
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || null,
			type: 'text',
			required: true,
			onlyLetters: true,
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || null,
			type: 'text',
			required: true,
			onlyLetters: true,
		}),
		new InputField({
			key: 'birthDate',
			type: 'date',
			label: 'ç.question.birthDate.label',
			value: formData?.birthDate || null,
			required: true,
			validators: [
				ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE),
				ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST),
			],
			min: minBirthDate,
			max: maxBirthDate,
			class: 'span8',
		}),
		new InputSelect({
			key: 'sex.id',
			label: 'ç.question.sex.label',
			value: formData?.sex?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.SEX] || [],
			class: 'span4',
		}),
		new InputSelect({
			key: 'civilStatus.id',
			label: 'ç.question.civilStatus.label',
			value: formData?.civilStatus?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.CIVIL_STATUS] || [],
		}),
		new InputSelect({
			key: 'employmentStatus.id',
			label: 'ç.question.employmentStatus.label',
			helpText: of('ç.question.employmentStatus.helpText'),
			value: formData?.employmentStatus?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.EMPLOYMENT_TYPE] || [],
		}),
		new InputSelect({
			key: 'nationality.id',
			label: 'ç.question.nationality.label',
			value: formData?.nationality?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.NATIONALITY] || [],
		}),
		new InputSelect({
			key: 'residencePermit.id',
			label: 'ç.question.residencePermit.label',
			value: formData?.residencePermit?.id,
			helpText: of('ç.question.residencePermit.helpText'),
			required: false,
			options: resources?.[ResourceType.RESIDENCE_PERMIT] || [],
		}),
	];
}
