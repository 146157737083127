import { createSelector } from '@ngrx/store';

import { getThirdPartyState } from '../third-party.selectors';
import { Broker } from '@oper-client/shared/data-model';
import { BrokerState, BrokerPartialState, BrokerActionTypes, BROKER_FEATURE_KEY, selectAll } from './broker.reducer';

export const getBrokerState = createSelector(getThirdPartyState, (state: BrokerPartialState) => state[BROKER_FEATURE_KEY]);

export const getBrokers = createSelector(getBrokerState, (state: BrokerState) => selectAll(state));

export const getCurrentBrokerId = createSelector(getBrokerState, (state: BrokerState) => state.currentBrokerId);

export const getCurrentBroker = createSelector(getBrokerState, (state: BrokerState) => {
	if (state.currentBrokerId === null) {
		return null;
	} else if (state.currentBrokerId === 0) {
		return <Broker>{};
	}
	return state.entities[state.currentBrokerId];
});

export const getActionStates = createSelector(getBrokerState, state => state.actions);
export const getActionState = (actionType: BrokerActionTypes) => createSelector(getActionStates, state => state[actionType]);
