import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Broker } from '@oper-client/shared/data-model';

import { BrokerService } from '../../services/broker.service';
import * as BrokerActions from './broker.actions';

@Injectable()
export class BrokerEffects {
	constructor(private actions$: Actions, private translateService: TranslateService, private brokerService: BrokerService) {}

	loadBrokers$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(BrokerActions.loadBrokers),
			switchMap(() => {
				return this.brokerService.getBrokers().pipe(
					map((brokers) => BrokerActions.loadBrokersSuccess({ brokers })),
					catchError((error) => of(BrokerActions.loadBrokersFailure({ error })))
				);
			})
		);
	});

	loadCurrentBroker$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BrokerActions.setCurrentBrokerId),
			switchMap(({ brokerId }) =>
				this.brokerService.getBroker(brokerId).pipe(
					map((broker: Broker) => BrokerActions.loadBrokerSuccess({ broker })),
					catchError((error) => of(BrokerActions.loadBrokerFailure({ error })))
				)
			)
		)
	);

	transferPortfolio$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BrokerActions.transferPortfolio),
			switchMap(({ brokerId, beneficiaryBroker }) =>
				this.brokerService.transferPortfolio(brokerId, beneficiaryBroker).pipe(
					map(() => BrokerActions.transferPortfolioSuccess()),
					catchError((error) => of(BrokerActions.transferPortfolioFailure({ error })))
				)
			)
		)
	);

	updateCommissionConfig$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BrokerActions.updateCommissionConfig),
			switchMap(({ config }) =>
				this.brokerService.updateCommissionConfig(config).pipe(
					map(() => BrokerActions.updateCommissionConfigSuccess({ brokerId: config.broker.id })),
					catchError((error) => of(BrokerActions.updateCommissionConfigFailure({ error })))
				)
			)
		)
	);

	updateCommissionConfigSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BrokerActions.updateCommissionConfigSuccess),
			map((payload) => BrokerActions.setCurrentBrokerId(payload))
		)
	);

	updateBroker$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BrokerActions.updateBroker),
			switchMap(({ broker }) =>
				this.brokerService.updateBroker(+broker.id, broker.changes).pipe(
					map((updatedBroker) =>
						BrokerActions.updateBrokerSuccess({
							broker: { id: updatedBroker.id, changes: updatedBroker },
						})
					),
					catchError((error) => of(BrokerActions.updateBrokerFailure({ error })))
				)
			)
		)
	);
}
