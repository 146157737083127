import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Client, ActionState, setActionState, ActionTypes, initialActionState } from '@oper-client/shared/data-model';

import * as ClientActions from './client.actions';

export const CLIENT_ENTITY_KEY = 'clients';

export interface ClientState extends EntityState<Client> {
	// additional entities state properties
	actions: ClientActionsState;
	currentClientId: number | null;
	createNewClientModalShown: boolean;
}

export type ClientActionTypes = 'loadClient' | 'loadClients' | 'createClient' | 'updateClient' | 'inviteClient' | 'removeClient';
export type ClientActionsState = Record<ClientActionTypes, ActionState>;

export const clientAdapter: EntityAdapter<Client> = createEntityAdapter<Client>();

export const initialState: ClientState = clientAdapter.getInitialState({
	// additional entity state properties
	currentClientId: null,
	createNewClientModalShown: false,
	actions: {
		loadClient: initialActionState,
		loadClients: initialActionState,
		createClient: initialActionState,
		updateClient: initialActionState,
		inviteClient: initialActionState,
		removeClient: initialActionState,
	},
});

function setActionStates(
	actionState: ClientActionsState,
	action: ClientActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): ClientActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,
	on(ClientActions.resetClientState, () => initialState),
	on(ClientActions.loadClient, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClient', ActionTypes.loading),
	})),
	on(ClientActions.loadClientSuccess, (state, { client }) =>
		clientAdapter.upsertOne(client, { ...state, actions: setActionStates(state.actions, 'loadClient', ActionTypes.success) })
	),
	on(ClientActions.loadClientFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClient', ActionTypes.failure, error),
	})),

	on(ClientActions.loadClients, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClients', ActionTypes.loading),
	})),
	on(ClientActions.loadClientsSuccess, (state, { clients }) =>
		clientAdapter.upsertMany(clients, { ...state, actions: setActionStates(state.actions, 'loadClients', ActionTypes.success) })
	),
	on(ClientActions.loadClientsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClients', ActionTypes.failure, error),
	})),

	on(ClientActions.addClient, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createClient', ActionTypes.loading),
	})),
	on(ClientActions.addClientSuccess, (state, { client }) =>
		clientAdapter.addOne(client, {
			...state,
			actions: setActionStates(state.actions, 'createClient', ActionTypes.success),
			currentClientId: client.id,
		})
	),
	on(ClientActions.addClientFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createClient', ActionTypes.failure, error),
	})),

	on(ClientActions.updateClient, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateClient', ActionTypes.loading),
	})),
	on(ClientActions.updateClientSuccess, (state, { client }) =>
		clientAdapter.updateOne(client, { ...state, actions: setActionStates(state.actions, 'updateClient', ActionTypes.success) })
	),
	on(ClientActions.updateClientFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateClient', ActionTypes.failure, error),
	})),

	on(ClientActions.inviteClient, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'inviteClient', ActionTypes.loading),
	})),
	on(ClientActions.inviteClientSuccess, (state, { client }) =>
		clientAdapter.updateOne(client, { ...state, actions: setActionStates(state.actions, 'inviteClient', ActionTypes.success) })
	),
	on(ClientActions.inviteClientFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'inviteClient', ActionTypes.failure, error),
	})),

	on(ClientActions.removeClient, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'removeClient', ActionTypes.loading),
	})),
	on(ClientActions.removeClientSuccess, (state, { clientId }) =>
		clientAdapter.removeOne(clientId, {
			...state,
			actions: setActionStates(state.actions, 'removeClient', ActionTypes.success),
			currentClientId: null,
		})
	),
	on(ClientActions.removeClientFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeClient', ActionTypes.failure, error),
	})),

	on(ClientActions.clearClients, (state) => clientAdapter.removeAll({ ...state, currentClientId: null })),

	on(ClientActions.clearClientActionStates, (state) => ({
		...state,
		actions: initialState.actions,
	})),
	on(ClientActions.clearCurrentClientId, (state) => ({
		...state,
		currentClientId: null,
	})),
	on(ClientActions.setCurrentClientId, (state, { clientId }) => ({ ...state, currentClientId: clientId })),
	on(ClientActions.showCreateNewClientModal, (state) => ({ ...state, createNewClientModalShown: true })),
	on(ClientActions.hideCreateNewClientModal, (state) => ({ ...state, createNewClientModalShown: false }))
);
