import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState, Realty } from '@oper-client/shared/data-model';
import { RealtiesPartialState, RealtyActionTypes } from './realty.reducer';
import {
	loadRealties,
	createRealty,
	updateRealty,
	setCurrentRealtyId,
	clearCurrentRealtyId,
	showCreateNewPropertyModal,
	hideCreateNewPropertyModal,
	resetRealtyState,
} from './realty.actions';
import * as RealtiesSelectors from './realty.selectors';

@Injectable()
export class RealtyFacade {
	properties$ = this.store.pipe(select(RealtiesSelectors.getRealties));
	currentRealty$ = this.store.pipe(select(RealtiesSelectors.getCurrentRealty));
	currentRealtyId$ = this.store.pipe(select(RealtiesSelectors.getCurrentRealtyId));
	showCreateNewPropertyModal$ = this.store.pipe(select(RealtiesSelectors.getCreateNewPropertyModalShown));
	loadRealtiesActionState$ = this.selectActionState('loadRealties');
	createRealtyActionState$ = this.selectActionState('createRealty');
	updateRealtyActionState$ = this.selectActionState('updateRealty');
	public removeRealtyActionState$ = this.selectActionState('removeRealty');

	constructor(private store: Store<RealtiesPartialState>) {}

	public loadRealties(loanRequestId: number): void {
		this.store.dispatch(loadRealties({ loanRequestId }));
	}

	public createRealty(loanRequestId: number, realty: Partial<Realty>, realtyMapping?: boolean): void {
		this.store.dispatch(createRealty({ loanRequestId, realty, realtyMapping }));
	}

	public updateRealty(loanRequestId: number, realtyId: number, realty: Partial<Realty>, realtyMapping?: boolean): void {
		this.store.dispatch(updateRealty({ loanRequestId, realtyId, realty, realtyMapping }));
	}

	public setCurrentRealty(realtyId: number): void {
		this.store.dispatch(setCurrentRealtyId({ realtyId: realtyId }));
	}

	public resetRealtyState(): void {
		this.dispatch(resetRealtyState());
	}

	public clearCurrentRealtyId() {
		this.store.dispatch(clearCurrentRealtyId());
	}

	public showCreateNewPropertyModal(): void {
		this.store.dispatch(showCreateNewPropertyModal());
	}

	public hideCreateNewPropertyModal(): void {
		this.store.dispatch(hideCreateNewPropertyModal());
	}

	public dispatch(action: Action) {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: RealtyActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(RealtiesSelectors.getActionState(actionType)), map(parseActionState));
	}
}
