import { FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputRadio, InputSelect, InputSwitch } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: Realty, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSwitch({
			key: 'housingType',
			label: 'ç.question.housingType.label',
			value: !!formData?.housingType,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
			class: 'span12',
			helpText: of('ç.question.housingType.label'),
		}),
		new InputSelect({
			key: 'coLivingGroupType.id',
			label: 'ç.question.coLivingGroupType.label',
			value: formData?.coLivingGroupType?.id,
			validators: [],
			options: resources?.[ResourceType.CO_LIVING_GROUP_TYPE] || [],
			class: 'span12',
			hidden: (formGroup: FormGroup): Observable<boolean> => {
				const subject = new BehaviorSubject(!formGroup.value['housingType']);

				formGroup.controls['housingType'].valueChanges.subscribe({
					complete: () => subject.complete(),
					error: (error) => subject.error(error),
					next: (value) => {
						if (value === false) {
							formGroup.controls['coLivingGroupType.id'].setValidators([]);
						} else {
							formGroup.controls['coLivingGroupType.id'].setValidators([Validators.required]);
						}

						formGroup.controls['coLivingGroupType.id'].updateValueAndValidity();
						return subject.next(!value);
					},
				});

				return subject.asObservable();
			},
			helpText: of('ç.question.coLivingGroupType.helpText'),
		}),
		new InputRadio({
			key: 'loanPurpose.id',
			label: 'ç.question.homeType.label',
			helpText: of('ç.misc.purpose.singular'),
			value: formData?.loanPurpose?.id,
			required: true,
			options: resources?.[ResourceType.LOAN_REQUEST_PURPOSE].filter((loanPurpose) => loanPurpose.isSimulatorEnabled),
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			value: formData?.realtyType?.id,
			helpText: of('ç.question.realtyType.helpText'),
			required: true,
			validators: [],
			options: resources?.[ResourceType.REALTY_TYPE] || [],
		}),
	];
}
