import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DoCheck,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	Self,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';

import { FormGroupDirective, NgControl, NgForm, NG_VALIDATORS } from '@angular/forms';
import {
	AsYouType,
	CountryCode as CC,
	getExampleNumber,
	NationalNumber,
	NumberType,
	parsePhoneNumberFromString,
	PhoneNumber,
} from 'libphonenumber-js/max';
import { CountryCode, Examples } from './data/country-code';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState, _AbstractConstructor, _Constructor } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { Resource, I18nPhoneNumber, PhoneCountry } from '@oper-client/shared/data-model';
import { deepEquals } from '@oper-client/shared/util-object';
import { phoneNumberValidator } from './phone-number.validator';

type PhoneNumberFormat = 'default' | 'national' | 'international' | 'noCountry';

class NgxMatIntlTelInputBase {
	readonly stateChanges = new Subject<void>();

	constructor(
		public _defaultErrorStateMatcher: ErrorStateMatcher,
		public _parentForm: NgForm,
		public _parentFormGroup: FormGroupDirective,
		public ngControl: NgControl
	) {}
}

declare type CanUpdateErrorStateCtor = _Constructor<CanUpdateErrorState> & _AbstractConstructor<CanUpdateErrorState>;

const _NgxMatIntlTelInputMixinBase: CanUpdateErrorStateCtor & typeof NgxMatIntlTelInputBase = mixinErrorState(NgxMatIntlTelInputBase);

@Component({
	selector: 'oper-client-phone-input',
	templateUrl: './phone-input.component.html',
	styleUrls: ['./phone-input.component.scss'],
	providers: [
		CountryCode,
		{ provide: MatFormFieldControl, useExisting: PhoneInputComponent },
		{
			provide: NG_VALIDATORS,
			useValue: phoneNumberValidator,
			multi: true,
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent
	extends _NgxMatIntlTelInputMixinBase
	implements OnInit, OnDestroy, DoCheck, CanUpdateErrorState, MatFormFieldControl<any>
{
	static nextId = 0;

	@Input() enablePlaceholder = true;
	@Input() inputPlaceholder: string | undefined;
	@Input() cssClass: string | undefined;
	@Input() name: string | undefined;
	@Input() errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();
	@Input() enableSearch = true;
	@Input() searchPlaceholder: string | undefined;
	@Input() describedBy = '';
	@Input() allowedTypes: Array<NumberType> = ['FIXED_LINE_OR_MOBILE', 'FIXED_LINE', 'MOBILE'];
	@Input() validatePhoneType = true;

	@Input()
	set value(phone: I18nPhoneNumber) {
		if (!deepEquals(this._phone, phone)) {
			this._phone = phone;
			this.setPhone(`${phone.countryCode?.definition?.replace('-', '') || ''}${phone.value || ''}`);
			this._changeDetectorRef.markForCheck();
			this.stateChanges.next();
		}
	}

	get value(): I18nPhoneNumber {
		return {
			countryCode: {
				id: this._phone?.countryCode?.id,
				definition: this._phone?.countryCode?.definition,
			},
			value: this._phone?.value,
		};
	}

	@Input()
	set allowedCountries(value: Array<Resource>) {
		this._allowedCountries = value;
		this.populateCountryData();
	}

	@Input()
	get format(): PhoneNumberFormat {
		return this._format;
	}

	set format(value: PhoneNumberFormat) {
		this._format = value;
		this.phoneNumber = this.formattedPhoneNumber;
		this.stateChanges.next(undefined);
	}

	@Output() countryChanged = new EventEmitter<PhoneCountry>();
	@Output() phoneChanged = new EventEmitter<I18nPhoneNumber>();
	@Output() valueChanged = new EventEmitter<I18nPhoneNumber>();

	private _placeholder: string | undefined;
	private _required = false;
	private _disabled = false;
	private _phone = new I18nPhoneNumber();
	private previousFormattedNumber: string | undefined;
	private _format: PhoneNumberFormat = 'default';
	private _allowedCountries: Array<Resource> = [];
	private numberInstance: PhoneNumber | undefined;

	stateChanges = new Subject<void>();
	focused = false;
	phoneNumber: NationalNumber | undefined;
	allCountries: Array<PhoneCountry> = [];
	filteredCountries: Array<PhoneCountry> = [];
	selectedCountry: PhoneCountry | undefined;
	searchCriteria: string | undefined;

	@HostBinding()
	id = `phone-input-${PhoneInputComponent.nextId++}`;

	static getPhoneNumberPlaceHolder(countryISOCode: CC): string | undefined {
		const result = getExampleNumber(countryISOCode, Examples);
		return !result ? undefined : result.number.toString();
	}

	onTouched = () => {};
	onChangeCallback: any = () => {};

	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private countryCodeData: CountryCode,
		private fm: FocusMonitor,
		private elRef: ElementRef<HTMLElement>,
		@Optional() @Self() public ngControl: NgControl,
		@Optional() _parentForm: NgForm,
		@Optional() _parentFormGroup: FormGroupDirective,
		_defaultErrorStateMatcher: ErrorStateMatcher
	) {
		super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
		fm.monitor(elRef, true).subscribe((origin) => {
			if (this.focused && !origin) {
				this.onTouched();
			}
			this.focused = !!origin;
			this.stateChanges.next(undefined);
		});
		this.populateCountryData();
		if (this.ngControl != null) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		if (!this.searchPlaceholder) {
			this.searchPlaceholder = 'ç.misc.action.search';
		}

		if (this?.numberInstance?.country) {
			// If an existing number is present, we use it to determine selectedCountry
			this.selectedCountry = this.getCountry(this.numberInstance.country);
		} else if (!this.selectedCountry) {
			this.selectedCountry = this.allCountries[0];
			if (this.selectedCountry && this.enablePlaceholder) this.generatePlaceholder(this.selectedCountry);
		}

		if (this.validatePhoneType) {
			this.ngControl.control?.addValidators([this.validateType.bind(this)]);
			this.ngControl.control?.updateValueAndValidity();
		}

		this.countryChanged.emit(this.selectedCountry);
		this._changeDetectorRef.markForCheck();
		this.stateChanges.next(undefined);
	}

	ngDoCheck(): void {
		if (this.ngControl) {
			this.updateErrorState();
		}
	}

	public onPhoneNumberChange(): void {
		try {
			this.numberInstance = parsePhoneNumberFromString(
				this.phoneNumber?.toString() || '',
				this.selectedCountry?.iso2.toUpperCase() as CC
			);

			this.formatAsYouTypeIfEnabled();
			if (this?.numberInstance?.isValid()) {
				if (this.phoneNumber !== this.formattedPhoneNumber) {
					this.phoneNumber = this.formattedPhoneNumber;
				}

				const i18nValue = this.phoneNumberToI18NPhoneNumber(this.numberInstance);
				this._phone = { ...this._phone, ...i18nValue };
			} else {
				this._phone = new I18nPhoneNumber();
				this._phone = { ...this._phone, value: this.phoneNumber?.toString() };
			}
		} catch (e) {
			// if no possible numbers are there,
			// then the full number is passed so that validator could be triggered and proper error could be shown
			this._phone = { ...this._phone, value: this.phoneNumber?.toString() };
		}
		this.valueChanged.emit(this.value);
		this.onChangeCallback(this.value);
		this.onTouched();
		this.stateChanges.next();
		this._changeDetectorRef.markForCheck();
	}

	private phoneNumberToI18NPhoneNumber(number: PhoneNumber): I18nPhoneNumber {
		return {
			value: number.number.replace(this.selectedCountry.dialCode, ''),
			countryCode: {
				country: this.selectedCountry.iso2.toUpperCase(),
				definition: this.selectedCountry.definition,
				id: this.selectedCountry.id,
			},
		};
	}

	public onCountrySelect(country: PhoneCountry, el: MatInput): void {
		this.selectedCountry = country;
		if (this.enablePlaceholder) {
			this.generatePlaceholder(country);
		}
		this.countryChanged.emit(this.selectedCountry);
		this.onPhoneNumberChange();
		el.focus();
	}

	public getCountry(code: string): PhoneCountry {
		return (
			this.allCountries.find((c) => c.iso2 === code.toLowerCase()) || {
				name: 'UN',
				iso2: 'UN',
				dialCode: '',
				priority: 0,
				areaCodes: undefined,
				flagClass: 'UN',
				placeHolder: '',
				id: null,
				definition: null,
			}
		);
	}

	public onKeyPress(event: KeyboardEvent): void {
		const pattern = /[0-9]/;
		if (!pattern.test(event.key)) {
			event.preventDefault();
		}
	}

	protected populateCountryData(): void {
		this.allCountries = this._allowedCountries.map((c) => {
			return {
				name: '',
				iso2: c['country'].toLowerCase(),
				dialCode: c['definition'].replace('-', ''),
				priority: 0,
				areaCodes: undefined,
				flagClass: c['country'],
				placeHolder: this.enablePlaceholder ? PhoneInputComponent.getPhoneNumberPlaceHolder(c['country'] as CC) : '',
				id: c['id'],
				definition: c['definition'],
			};
		});
		this.filteredCountries = this.allCountries;
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this._changeDetectorRef.markForCheck();
		this.stateChanges.next(undefined);
	}

	writeValue(phoneNumber: I18nPhoneNumber): void {
		if (!phoneNumber) return;
		this.value = phoneNumber;
	}

	private setPhone(value) {
		if (value) {
			this.numberInstance = parsePhoneNumberFromString(value);
			if (this.numberInstance) {
				const countryCode = this.numberInstance.country;
				if (!countryCode) {
					return;
				}
				this.selectedCountry = this.getCountry(countryCode);
				this.phoneNumber = this.formattedPhoneNumber;
				this.countryChanged.emit(this.selectedCountry);
				// Initial value is set
				this._changeDetectorRef.markForCheck();
				this.stateChanges.next(undefined);
			} else {
				this.phoneNumber = value;
			}
		}
	}

	get empty(): boolean {
		return !this.phoneNumber;
	}

	@HostBinding('class.ngx-floating')
	get shouldLabelFloat(): boolean {
		return true; // this.focused || !this.empty;
	}

	@Input()
	get placeholder(): string {
		return this._placeholder || '';
	}

	set placeholder(value: string) {
		this._placeholder = value;
		this.stateChanges.next(undefined);
	}

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(value: boolean) {
		this._required = coerceBooleanProperty(value);
		this.stateChanges.next(undefined);
	}

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: boolean) {
		this._disabled = coerceBooleanProperty(value);
		this.stateChanges.next(undefined);
	}

	setDescribedByIds(ids: string[]) {
		this.describedBy = ids.join(' ');
	}

	onContainerClick(event: MouseEvent): void {
		if ((event.target as Element).tagName.toLowerCase() !== 'input') {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.elRef.nativeElement.querySelector('input')!.focus();
		}
	}

	reset(): void {
		this.phoneNumber = '';
		this._phone = new I18nPhoneNumber();
		this.onChangeCallback(null);

		this._changeDetectorRef.markForCheck();
		this.stateChanges.next(undefined);
	}

	ngOnDestroy(): void {
		this.stateChanges.complete();
		this.fm.stopMonitoring(this.elRef);
	}

	private get formattedPhoneNumber(): string {
		if (!this.numberInstance) {
			return this.phoneNumber?.toString() || '';
		}
		return this.getFormattedNumber(this.numberInstance);
	}

	private generatePlaceholder(country: PhoneCountry) {
		const countryCode = country.iso2.toUpperCase() as CC;
		const example = PhoneInputComponent.getPhoneNumberPlaceHolder(countryCode);
		this.inputPlaceholder = this.formatPlaceholder(example, countryCode);
	}

	private formatPlaceholder(placeholder: string, country: CC): string {
		const placeholderNumber = parsePhoneNumberFromString(placeholder, country);
		if (!placeholderNumber?.isValid()) {
			return placeholderNumber?.toString() || '';
		}
		return this.getFormattedNumber(placeholderNumber);
	}

	private getFormattedNumber(number: PhoneNumber): string {
		const countryPrefix = this.selectedCountry?.definition?.replace('-', ' ');
		switch (this.format) {
			case 'national':
				return number.formatNational();
			case 'international':
				return number.formatInternational();
			case 'noCountry':
				return number.formatInternational().replace(`${countryPrefix}`, '').trim();
			default:
				return number.nationalNumber.toString();
		}
	}

	private formatAsYouTypeIfEnabled(): void {
		if (this.format === 'default') return;
		if (this.phoneNumber?.toString().length < 1) return;

		// To avoid caret positioning we apply formatting only if the caret is at the end:
		if (this.phoneNumber?.toString().startsWith(this.previousFormattedNumber || '')) {
			const trim = `${this.selectedCountry.dialCode}`;
			const asYouType = new AsYouType().input(trim + this.phoneNumber.toString());
			this.phoneNumber = asYouType.replace(`${trim}`, '').trim();
			this.phoneNumber = asYouType.replace(this.selectedCountry.definition.replace('-', ' '), '').trim();
		}
		this.previousFormattedNumber = this.phoneNumber?.toString();
	}

	validateType() {
		if (!this.value?.value || this.value?.value?.length === 0) return null;
		try {
			const numberInstance = parsePhoneNumberFromString(`${this.value?.countryCode?.definition || ''}${this.value?.value}`);
			if (!this.allowedTypes.includes(numberInstance.getType())) {
				return { invalidPhoneType: true };
			}
		} catch (e) {
			return { invalidPhoneNumber: true };
		}
		return null;
	}

	onBtnKeyPress(event: KeyboardEvent) {
		event.preventDefault();
		event.target?.dispatchEvent(new Event('click'));
	}

	countrySearchKeydown($event: KeyboardEvent) {
		$event.stopPropagation();
		window.dispatchEvent(new Event('resize'));
	}

	searchChanged() {
		if (this.searchCriteria.length > 0) {
			this.filteredCountries = this.allCountries.filter((country) =>
				` ${country.iso2} ${country.dialCode} `.toLowerCase().includes(this.searchCriteria.toLowerCase())
			);
		} else {
			this.filteredCountries = this.allCountries;
		}
	}
}
