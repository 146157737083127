import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { User } from '@oper-client/shared/data-model';
import { HttpParams } from '@angular/common/http';

export const USER_SERVICE = new InjectionToken<IUserService>('USER_SERVICE');

export interface IUserService {
	getUsers(params?: HttpParams): Observable<User[]>;
}
