import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { Observable, combineLatest, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { FormConfiguration } from '../../models/dynamic-form.model';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const purposeOptions = (resources?.[ResourceType.REALTY_PURPOSE] || []).filter(({ definition }) =>
		['buy', 'build', 'refinance', 'renovate'].includes(definition)
	);
	const realtyPurposeToBuy = resources['realty-purpose'].find((resource) => resource.definition === 'buy');
	const realtyPurposeToBuild = resources['realty-purpose'].find((resource) => resource.definition === 'build');
	const realtyPurposeToRenovate = resources['realty-purpose'].find((resource) => resource.definition === 'renovate');

	return [
		new InputSelect({
			key: 'purposes[0].id',
			label: 'ç.question.mainPurpose.label',
			value: formData?.purposes?.[0]?.id ?? realtyPurposeToBuy.id,
			required: true,
			options: purposeOptions,
			class: 'span12',
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			value: formData?.realtyType?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_TYPE] || [],
			validators: [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'usageTypes[0].id',
			label: 'ç.question.usageType.label',
			value: formData?.usageTypes?.[0]?.id,
			helpText: of('ç.feature.property.usageType.helpText'),
			required: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'price',
			label: 'ç.question.price.label',
			value: formData?.price,
			type: 'number',
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'price');
				const formControl = <FormControl>formGroup.controls['price'];
				return formGroup.controls['purposes[0].id'].valueChanges.pipe(
					filter((value) => !!value),
					map((value) => {
						if (value === realtyPurposeToBuild?.id || value === realtyPurposeToRenovate.id) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.venalValueBeforeRenovation.label',
			value: formData?.venalValueBefore,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'venalValueBefore');
				const formControl = <FormControl>formGroup.controls['venalValueBefore'];
				return formGroup.controls['purposes[0].id'].valueChanges.pipe(
					filter((value) => !!value),
					map((value) => {
						if (value != realtyPurposeToRenovate.id) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'priceLand',
			label: 'ç.question.priceLand.label',
			value: formData?.priceLand,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'priceLand');
				const formControl = <FormControl>formGroup.controls['priceLand'];
				return formGroup.controls['purposes[0].id'].valueChanges.pipe(
					filter((value) => !!value),
					map((value) => {
						if (value !== realtyPurposeToBuild?.id) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'priceBuilding',
			label: 'ç.question.priceBuilding.label',
			value: formData?.priceBuilding,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'priceBuilding');
				const formControl = <FormControl>formGroup.controls['priceBuilding'];
				return formGroup.controls['purposes[0].id'].valueChanges.pipe(
					filter((value) => !!value),
					map((value) => {
						if (value !== realtyPurposeToBuild?.id) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'venalValueAfter',
			label: 'ç.question.venalValueAfterRenovation.label',
			value: formData?.venalValueAfter,
			type: 'hidden',
			required: false,
			disabled: true,
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'venalValueAfter');
				const formControl = <FormControl>formGroup.controls['venalValueAfter'];
				return combineLatest([
					formGroup.controls['purposes[0].id'].valueChanges,
					formGroup.controls['priceBuilding'].valueChanges,
					formGroup.controls['priceLand'].valueChanges,
				]).pipe(
					filter(([, priceBuilding, priceLand]) => !!priceBuilding && !!priceLand),
					map(([loanPurposeId, priceBuilding, priceLand]) => {
						if (loanPurposeId === realtyPurposeToBuild?.id || loanPurposeId === realtyPurposeToRenovate.id) {
							inputField.value = priceBuilding + priceLand;
							inputField.disabled = false;
							formControl.enable();
							formControl.setValue(inputField.value);
						} else {
							inputField.disabled = true;
							formControl.disable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			updateValidityOnFormValueChanges: true,
			class: 'span12',
		}),
	];
}
