<ng-template #tpl let-close="close">
	<div
		class="inner"
		[ngClass]="{
			'oper-client-modal--shown': shown,
			'inner--small': size === 'small',
			'inner--medium': size === 'medium',
			'inner--large': size === 'large',
			'inner--xlarge': size === 'xlarge',
			'inner--max': size === 'max',
			'disable-transition-effect': disableTransitionEffect
		}"
	>
		<header *ngIf="closeButton || !!title" class="header" [class.border]="headerBorder">
			<div class="header--title">
				<button *ngIf="headerBackButton" class="back-navigation-button" (click)="back.emit()">
					<fa-icon class="icon" [icon]="iconAngleLeft"></fa-icon>
					<h3 class="header-title">{{ headerBackButton | translate }}</h3>
				</button>
				<label class="header-title">{{ title | translate }}</label>
				<button *ngIf="closeButton" class="header-buttonClose" data-test="button--closeModal" (click)="closed.emit()">
					<oper-client-fontawesome-icon class="header-buttonClose-icon" icon="faXmark" [fixedWidth]="true"></oper-client-fontawesome-icon>
				</button>
			</div>
			<div *ngIf="titleDescription" class="header--title-description">
				<label class="header-description">{{ titleDescription | translate }}</label>
			</div>
		</header>

		<section
			class="content"
			[ngClass]="{
				'content--no-padding': noContentPadding
			}"
			[class.content--dynamic-form]="enableDynamicForm"
		>
			<ng-content></ng-content>

			<ng-container *ngIf="actionPosition === 'body'">
				<ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
			</ng-container>
		</section>

		<ng-container *ngIf="!hideFooter && actionPosition !== 'body'">
			<ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
		</ng-container>
	</div>

</ng-template>

<ng-template #actionsTemplate>
	<section class="actions" [class.auto-height]="actionsAutoHeight" [class.border]="footerBorder && actionPosition !== 'body'">
		<ng-container *ngIf="!!actionPrimary || !!actionSecondary || !!actionThirdary">
			<div class="col col--left">
				<button
					*ngIf="!!actionThirdary"
					[disabled]="!enableThirdary"
					[class]="getButtonClass('action--thirdary', classThirdaryPostfix)"
					(click)="clickedThirdary.emit()"
				>
					<fa-icon *ngIf="!!iconThirdary" class="icon" [icon]="iconThirdary"></fa-icon>
					<span>{{ actionThirdary | translate }}</span>
				</button>
			</div>
			<div class="col col--right" [ngClass]="{ center: this.centerCta }">
				<button
					*ngIf="!!actionSecondary"
					[disabled]="!enableSecondary"
					[class]="getButtonClass('action--secondary', classSecondaryPostfix)"
					(click)="clickedSecondary.emit()"
					data-test="button--cancel"
				>
					{{ actionSecondary | translate }}
				</button>
				<button
					*ngIf="!!actionPrimary"
					[disabled]="!enablePrimary"
					[class]="getButtonClass('action--primary', classPrimaryPostfix)"
					(click)="clickedPrimary.emit()"
					data-test="button--saveOverviewInformations"
				>
					<fa-icon *ngIf="primaryActionIcon" class="action--primary-icon" [icon]="primaryActionIcon"></fa-icon>
					{{ actionPrimary | translate }}
				</button>
			</div>
		</ng-container>
		<ng-content select="[modal-footer]" *ngIf="!actionPrimary && !actionSecondary"></ng-content>
	</section>
</ng-template>
