import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { AppFacade } from '../+state/app.facade';

@Injectable({
	providedIn: 'root',
})
export class OnlineDetectionService {
	constructor(@Inject(DOCUMENT) private document: Document, private appFacade: AppFacade) {}

	init(): void {
		this.document.defaultView.addEventListener('online', () => this.handleOnline());
		this.document.defaultView.addEventListener('offline', () => this.handleOffline());
	}

	stop(): void {
		this.document.defaultView.removeEventListener('online', () => this.handleOnline());
		this.document.defaultView.removeEventListener('offline', () => this.handleOffline());
	}

	private handleOnline(): void {
		this.appFacade.appOnline();
	}

	private handleOffline(): void {
		this.appFacade.appOffline();
	}
}
