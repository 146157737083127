import { InputField, InputPhone } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { PartialNormalizedResource, ResourceType, I18nPhoneNumber } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';
import { ValidatorService } from '../services/validator.service';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	const phoneValue = {
		value: formData?.phoneNumber?.value,
		countryCode: formData?.phoneNumber?.countryCode,
	};

	return [
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName,
			type: 'text',
			required: true,
			class: 'span12',
			onlyLetters: true,
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName,
			type: 'text',
			required: true,
			class: 'span12',
			onlyLetters: true,
		}),
		new InputField({
			key: 'email',
			label: 'ç.question.email.label',
			value: formData?.email,
			type: 'email',
			required: true,
			validators: [Validators.email],
			class: 'span12',
		}),
		new InputPhone({
			key: 'phoneNumber',
			label: 'ç.question.phoneNumber.label',
			value: phoneValue as I18nPhoneNumber,
			allowedCountries: resources?.[ResourceType.PHONE_COUNTRY_CODE],
			required: true,
			class: 'span12',
			validators: [ValidatorService.phoneNumberRequiredValidator()],
			allowedTypes: ['MOBILE'],
		}),
	];
}
