import { Injectable } from '@angular/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { MissingTranslationHandlerParams } from '@ngx-translate/core/lib/missing-translation-handler';
import { Inject } from '@angular/core';
import { API_SERVICE } from '@oper-client/shared/data-access';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

declare const COMMIT_HASH: string;

/**
 * MissingCustomTranslationHandler
 *
 * This class is responsible to handle custom translations.
 * Custom traslations are special translation keys which stars with 'çustom.'
 *
 * TODO: This solution is suboptimal, because to display a product name where customTranslations is being used
 * We have to fetch base products upfront, when customTranslations can be simply part of the API response
 * This class will be deprecated soon.
 *
 * @deprecated
 */

@Injectable({
	providedIn: 'root',
})
export class MissingCustomTranslationHandler implements MissingTranslationHandler {
	customTranslations = {};
	customTranslationsLoading: Observable<any> | null;

	constructor(@Inject(API_SERVICE) private readonly apiService) {}

	handle(params: MissingTranslationHandlerParams): any {
		const lang = params.translateService.currentLang;
		const defaultLang = params.translateService.defaultLang;
		const translations = params.translateService.translations;
		if (params.key.startsWith('çustom.')) {
			if (!this.customTranslations[lang]) {
				return this.loadCustomTranslations(translations, defaultLang).pipe(
					map(() => {
						return params.translateService.getParsedResult(translations[lang], params.key, params.interpolateParams);
					})
				);
			} else if (!translations[lang]['çustom']) {
				translations[lang]['çustom'] = this.customTranslations[lang]['çustom'];
			}
			const res = params.translateService.parser.interpolate(
				params.translateService.parser.getValue(translations[lang], params.key),
				params.interpolateParams
			);
			if (res !== undefined) {
				return res;
			}
		}
		const res =
			lang !== defaultLang
				? params.translateService.parser.interpolate(
						params.translateService.parser.getValue(translations[defaultLang], params.key),
						params.interpolateParams
				  )
				: undefined;
		return typeof res !== 'undefined' ? res : params.key;
	}

	loadCustomTranslations(translations: any, defaultLang: string): Observable<void> {
		if (!this.customTranslationsLoading) {
			this.customTranslationsLoading = this.loadBaseProductsTranslations(defaultLang).pipe(
				map(() => {
					for (const [key, value] of Object.entries(this.customTranslations)) {
						if (translations[key]) {
							translations[key]['çustom'] = value['çustom'];
						}
					}
					this.customTranslationsLoading = null;
				}),
				shareReplay(1)
			);
		}
		return this.customTranslationsLoading;
	}

	loadBaseProductsTranslations(defaultLang: string): Observable<void> {
		return this.apiService.get(`/api/base-products/`).pipe(
			map((baseProducts: { id; name; customTranslations }[]) => {
				baseProducts.forEach(({ id, name, customTranslations }) => {
					if (typeof customTranslations === 'object' && customTranslations !== null) {
						for (const [key, value] of Object.entries(customTranslations)) {
							for (const [lang, translation] of Object.entries(value)) {
								this.updateCustomTranslation(lang, `baseProduct${id}-${key}`, translation);
							}
						}
					}
					if (!this.customTranslations?.[defaultLang]?.['çustom']?.[`baseProduct${id}-name`]) {
						this.updateCustomTranslation(defaultLang, `baseProduct${id}-name`, name);
					}
				});
			})
		);
	}

	updateCustomTranslation(lang: string, key: string, value: string) {
		if (!this.customTranslations[lang]) {
			this.customTranslations[lang] = { çustom: {} };
		}
		this.customTranslations[lang]['çustom'][key] = value;
	}
}
