import { FinancialAsset, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function(formData?: Partial<FinancialAsset>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const matchedResource = resources?.[ResourceType.FINANCIAL_ASSET_TYPE]?.find(item => item.id === +(formData?.assetType?.id ?? 0));
	switch (matchedResource?.definition) {
		case 'cash':
			return buildForm(formData, resources, false);
		case 'pensionPillar2':
		case 'pensionPillar3A':
		case 'investmentPortfolio':
		case 'other':
			return buildForm(formData, resources, true);
		default:
			return buildForm(formData, resources);
	}
}

function buildForm(formData?: Partial<FinancialAsset>, resources?: PartialNormalizedResource, showUsage?: boolean): InputBase<any>[] {
	return [
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			value: formData?.amount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span6',
		}),
		...(showUsage
			? [
					new InputSelect({
						key: 'usage.id',
						label: 'ç.question.financialAssetUsageType.label',
						value: formData?.usage?.id,
						required: true,
						options: resources?.[ResourceType.FINANCIAL_ASSET_USAGE_TYPE] || [],
						class: 'span6',
					}),
			  ]
			: []),
	];
}
