import { PartialNormalizedResource, Renovation, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (formData?: Partial<Renovation>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'renovationType.id',
			label: 'ç.question.renovationType.label',
			required: true,
			options: resources[ResourceType.RENOVATION_TYPE] || [],
			class: 'span12',
			bindLabel: 'name',
			value: formData?.renovationType?.id,
		}),
		new InputField({
			key: 'amountContractor',
			label: 'ç.question.amount.label',
			currency: true,
			class: 'span12',
			value: formData?.amountContractor,
		}),
	];
}
