import { PartialNormalizedResource, ResourceType, Simulator } from '@oper-client/shared/data-model';
import { orderBy } from '@oper-client/shared/util-array';
import { InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { SelectOption } from '@oper-client/shared/util-data-model-transform';

export default function (
	formData?: Partial<Simulator.SearchSimulationQueryParams>,
	resources?: PartialNormalizedResource,
	analystOptions: SelectOption[] = [],
	showClientSimulationsFlagEnabled = false
): InputBase<any>[] {
	const showStatuses = resources?.[ResourceType.MORTGAGE_SIMULAION_STATUS]?.length > 1;
	const showSources = resources?.[ResourceType.MORTGAGE_SIMULAION_SOURCE]?.length > 1;
	const showAnalysts = analystOptions.length > 1;

	const questions = [];

	if (showStatuses) {
		questions.push(
			new InputSelect({
				key: 'status',
				label: 'ç.misc.status',
				value: formData?.status,
				required: false,
				validators: [],
				searchable: true,
				bindValue: 'definition',
				options: orderBy(resources?.[ResourceType.MORTGAGE_SIMULAION_STATUS] || [], 'order'),
				alreadySorted: true,
				class: 'span2',
			})
		);
	}

	if (showSources && showClientSimulationsFlagEnabled) {
		questions.push(
			new InputSelect({
				key: 'source',
				label: 'ç.misc.source',
				value: formData?.source,
				required: false,
				searchable: true,
				bindValue: 'definition',
				validators: [],
				options: orderBy(resources?.[ResourceType.MORTGAGE_SIMULAION_SOURCE] || [], 'order'),
				alreadySorted: true,
				class: 'span2',
			})
		);
	}

	if (showAnalysts && showClientSimulationsFlagEnabled) {
		questions.push(
			new InputSelect({
				key: 'analyst',
				label: 'ç.misc.assignedTo',
				value: formData?.analyst,
				options: analystOptions,
				searchable: true,
				required: false,
				class: 'span2',
			})
		);
	}

	return questions;
}
