import { of } from 'rxjs';
import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function(formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'salaryEmployed',
			label: formData?.yearlyPeriodicity ? 'ç.question.yearlySalaryEmployed.label' : 'ç.question.monthlySalaryEmployed.label',
			helpText: of(formData?.yearlyPeriodicity
				? 'ç.question.yearlySalaryEmployed.helpText'
				: 'ç.question.monthlySalaryEmployed.helpText'),
			value: formData?.salaryEmployed || null,
			type: 'number',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'other',
			label: formData?.yearlyPeriodicity ? 'ç.question.yearlyOtherIncome.label' : 'ç.question.monthlyOtherIncome.label',
			helpText: of(formData?.yearlyPeriodicity ? 'ç.question.yearlyOtherIncome.helpText' : 'ç.question.monthlyOtherIncome.helpText'),
			value: formData?.other || null,
			type: 'number',
			currency: true,
			required: false,
		}),
	];
}
