import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, convertObjectToHttpParams, IApiService, IProductService } from '@oper-client/shared/data-access';
import {
	AcceptanceRule,
	ProductAllocationItem,
	ProductWithAcceptanceRules,
	SelectedBaseProductDiscounts,
	TweakedProductItemsResponse,
} from '@oper-client/shared/data-model';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductService implements IProductService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getAll(loanRequestId: number, params: { [key: string]: string } = {}, body?: object): Observable<any> {
		const url = loanRequestId === null ? `/api/products/` : `/api/loan-requests/${loanRequestId}/search/products/`;
		return this.apiService.post(url, body, convertObjectToHttpParams(params));
	}

	public getProductVersions(): Observable<any> {
		return this.apiService.get(`/api/products/`);
	}

	// TODO: refactor
	public getProductsForOfferAllocation(
		loanRequestId: number,
		params: { items: ProductAllocationItem[]; discounts: SelectedBaseProductDiscounts[] }
	): Observable<TweakedProductItemsResponse> {
		params.items.forEach((item) => {
			if (item.product.baseProductId) {
				const foundIndex = params.discounts?.findIndex((p) => p.baseProductId === item.product.baseProductId);
				if (foundIndex > -1) {
					item.discounts = params.discounts[foundIndex].discounts;
				} else {
					item.discounts = [];
				}
			}
		});
		delete params.discounts;
		const url = `/api/loan-requests/${loanRequestId}/tweak/products/`;
		return this.apiService.post(url, { ...params });
	}

	public getProductAcceptanceRules(
		loanRequestId: number,
		productId: number,
		params: { [key: string]: string } = {}
	): Observable<AcceptanceRule[]> {
		const url = `/api/loan-requests/${loanRequestId}/search/products/${productId}/`;
		return this.apiService.post(url, undefined, convertObjectToHttpParams(params)).pipe(
			map((response: ProductWithAcceptanceRules[]) => {
				if (response.length > 1) {
					throw new Error(`Expected only 1 list item in response but found more (${response.length}) than expected`);
				}

				return response.length === 1 ? response[0].acceptanceRules : [];
			})
		);
	}
}
