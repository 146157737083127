import { Injectable } from '@angular/core';

enum Target {
	SELF = '_self',
	BLANK = '_blank',
	PARENT = '_parent',
	TOP = '_top',
}

@Injectable({
	providedIn: 'root',
})
export class WindowService {
	open(url: string, target: Target = Target.BLANK, features?: string): void {
		window.open(url, target, target === Target.BLANK ? `noopened noreferrer` : features);
	}
}
