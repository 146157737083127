import { PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { InputBase } from '../../models/input-base.model';
import { InputField, InputSelect } from '../../models/input-types.model';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { ValidatorService } from '../../services/validator.service';
import { Validators } from '@angular/forms';

export default function (address: any, resources: PartialNormalizedResource): InputBase<any>[] {
	const prefix = address.prefix ?? '';
	return [
		new InputField({
			key: `${prefix}street`,
			label: 'ç.question.street.label',
			value: address?.street || '',
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: `${prefix}houseNumber`,
			label: 'ç.question.houseNumber.label',
			value: address?.houseNumber || '',
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: `${prefix}box`,
			label: 'ç.question.box.label',
			value: address?.box || '',
			required: false,
			type: 'text',
			class: 'span3',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: `${prefix}zipCode`,
			label: 'ç.question.zipCode.label',
			value: address?.zipCode || '',
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			class: 'span3',
			updateValidityOnFormValueChanges: true,
		}),
		new InputField({
			key: `${prefix}city`,
			label: 'ç.question.city.label',
			value: address?.city || '',
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
			updateValidityOnFormValueChanges: true,
		}),
		new InputSelect({
			key: `${prefix}country.id`,
			label: 'ç.question.country.label',
			value: address?.country?.id || '',
			options: resources?.[ResourceType.COUNTRY] || [],
			required: false,
			validators: [],
			class: 'span3',
			updateValidityOnFormValueChanges: true,
		}),
	];
}
