import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';

import * as AppActions from './app.actions';
import * as AppSelectors from './app.selectors';

import { DeviceInfo } from '@oper-client/shared/data-model';

@Injectable()
export class AppFacade {
	constructor(private store: Store<any>) {}

	public appVisible$ = this.store.pipe(select(AppSelectors.getVisible));
	public appOnline$ = this.store.pipe(select(AppSelectors.getOnline));
	public newDeploymentDetected$ = this.store.pipe(select(AppSelectors.getNewDeploymentDetected));

	public appVisible(): void {
		this.store.dispatch(AppActions.setAppVisible());
	}

	public appHidden(): void {
		this.store.dispatch(AppActions.setAppHidden());
	}

	public setDeviceInfo(deviceInfo: DeviceInfo): void {
		this.store.dispatch(AppActions.setDeviceInfo({ deviceInfo }));
	}

	public setDeviceAsMobile(): void {
		this.store.dispatch(AppActions.setDeviceAsMobile());
	}

	public setDeviceAsTablet(): void {
		this.store.dispatch(AppActions.setDeviceAsTablet());
	}

	public setDeviceAsDesktop(): void {
		this.store.dispatch(AppActions.setDeviceAsDesktop());
	}

	public appOnline(): void {
		this.store.dispatch(AppActions.setAppOnline());
	}

	public appOffline(): void {
		this.store.dispatch(AppActions.setAppOffline());
	}

	public dispatch(action: Action): void {
		this.store.dispatch(action);
	}

	public cancelPendingHttpRequests(): void {
		this.store.dispatch(AppActions.cancelPendingHttpRequests());
	}
}
