import { Injectable } from '@angular/core';
import { ThirdPartyService } from './third-party.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InsuranceProvider } from '@oper-client/shared/data-model';

@Injectable({
	providedIn: 'root',
})
export class InsuranceProviderService {
	readonly thirdPartyName = 'insurance-providers';
	constructor(private thirdPartyService: ThirdPartyService) {}

	public getInsuranceProviders(params: HttpParams = new HttpParams()): Observable<InsuranceProvider[]> {
		return this.thirdPartyService.getThirdParties(this.thirdPartyName, params);
	}
}
