export interface GtmEventPayload {
	userId?: number;
	userRole?: string;
	office?: string;
	description?: string;
	language?: string;
	simulationId: number;
	tenant?: string;
}

export interface GtmDispatchPayload {
	type: string;
	payload: Partial<GtmEventPayload>;
}

export const cleanGtmEventPayload: GtmEventPayload = {
	userId: null,
	userRole: null,
	office: null,
	description: null,
	language: null,
	simulationId: null,
	tenant: null,
};
