import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { BaseProductDiscounts, DiscountsQueryParams } from '@oper-client/shared/data-model';

export const loadSelectedDiscountsFromOffer = createAction(
	'[LoanRequest/Discounts] Load Selected Discounts From Offer',
	props<{ loanRequestId: number; offerId: number }>()
);

export const loadSelectedDiscountsFromOfferSuccess = createAction(
	'[LoanRequest/Discounts] Load Selected Discounts From Offer Success',
	props<{ discounts: BaseProductDiscounts[] }>()
);

export const loadSelectedDiscountsFromOfferFailure = createAction(
	'[LoanRequest/Discounts] Load Selected Discounts From Offer Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadProductDiscounts = createAction(
	'[LoanRequest/Discounts] Load Product Discounts',
	props<{ loanRequestId: number; queryParams: DiscountsQueryParams }>()
);
export const loadProductDiscountsSuccess = createAction(
	'[LoanRequest/Discounts] Load Product Discounts Success',
	props<{ discounts: BaseProductDiscounts[] }>()
);
export const loadProductDiscountsFailure = createAction(
	'[LoanRequest/Discounts] Load Product Discounts Failure',
	props<{ error: HttpErrorResponse }>()
);

export const setSelectedDiscounts = createAction(
	'[LoanRequest/Discounts] Set Selected Discounts',
	props<{ discounts: BaseProductDiscounts[] }>()
);

export const applyDiscounts = createAction(
	'[LoanRequest/Discounts] Apply Discounts Configuration',
	props<{ discounts: BaseProductDiscounts[] }>()
);

export const clearProductDiscounts = createAction('[LoanRequest/Discounts] Clear All Discounts');
export const clearSelectedDiscounts = createAction('[LoanRequest/Discounts] Clear Selected Discounts');
export const resetDiscountsState = createAction('[LoanRequest/Discounts] Reset Discounts State');
