import { InputSelect } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { of } from 'rxjs';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'coLivingGroupType.id',
			label: 'ç.question.coLivingGroupType.label',
			value: formData?.coLivingGroupType?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.CO_LIVING_GROUP_TYPE] || [],
			class: 'span12',
			helpText: of('ç.question.coLivingGroupType.helpText'),
		}),
		new InputSelect({
			key: 'coLivingPurpose.id',
			label: 'ç.question.coLivingPurpose.label',
			value: formData?.coLivingPurpose?.id,
			required: true,
			validators: [],
			options: resources?.[ResourceType.CO_LIVING_PURPOSE] || [],
			class: 'span12',
			helpText: of('ç.question.propertyType.helpText'),
		}),
	];
}
