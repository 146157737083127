import { Component, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCheckCircle, IconDefinition } from '@oper-client/shared/util-fontawesome';

import { BaseSwitch } from '../base-switch/base-switch.component';

@Component({
	selector: 'oper-client-button-switch',
	templateUrl: './button-switch.component.html',
	styleUrls: ['./button-switch.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => ButtonSwitch),
		},
	],
})
export class ButtonSwitch extends BaseSwitch {
	readonly iconActive: IconDefinition = faCheckCircle;
}
