import { Component, EventEmitter, HostBinding, HostListener, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
	selector: 'oper-client-checkbox',
	styleUrls: ['./checkbox.component.scss'],
	template: `
		<oper-client-fontawesome-icon
			class="icon"
			[ngStyle]="{ visibility: checked || indeterminate ? 'visible' : 'hidden' }"
			[size]="size"
			[icon]="indeterminate ? 'faMinus' : 'faCheck'"
			[fixedWidth]="true"
		></oper-client-fontawesome-icon>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CheckboxComponent),
			multi: true,
		},
	],
})
export class CheckboxComponent implements ControlValueAccessor {
	@Input() @HostBinding('class') size: SizeProp;
	@Input() @HostBinding('class.disabled') disabled = false;
	@Input() indeterminate = false;
	@Output() clicked = new EventEmitter<boolean>();
	@Input() set value(value: boolean) {
		this.writeValue(value);
	}
	get value(): boolean {
		return this.checked;
	}

	@HostBinding('class.checked') checked;

	@HostBinding('class.indeterminate') get indeterminateClass() {
		return this.indeterminate;
	}

	@HostListener('click', ['$event']) handleTouch() {
		this.onChange(this.checked);
		this.clicked.emit(this.checked);
	}

	// Function to call when the checked status has changed
	onChange = (_: boolean) => {};

	// Function to call when the input is touched
	onTouched = () => {
		this.writeValue(this.checked);
	};

	// Allows Angular to update the model.
	// Update the model and changes needed for the view here.
	writeValue(checked: boolean): void {
		this.checked = checked;
	}

	// Allows Angular to register a function to call when the model changes.
	// Save the function as a property to call later here.
	registerOnChange(fn: (checked: boolean) => void): void {
		this.onChange = fn;
	}

	// Allows Angular to register a function to call when the input has been touched.
	// Save the function as a property to call later here.
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
