import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Directive({
	selector: 'input[operClientOnlyNumbers]',
})
export class OnlyNumbersDirective {
	@Input() min: number;
	@Input() max: number;
	@Input() required = false;
	@Input() errorDescriptions: ValidationErrors = {};
	@Input() apply = true;

	@Output() validationErrorsEvent: EventEmitter<ValidationErrors>;

	constructor(private _el: ElementRef) {
		this.validationErrorsEvent = new EventEmitter();
	}

	@HostListener('input', ['$event']) onInputChange(event: Event) {
		if (!this.apply) return;
		const initalValue = this._el.nativeElement.value;
		this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
		this.validateInput(this._el.nativeElement.value);
		if (initalValue !== this._el.nativeElement.value) {
			event.stopPropagation();
		}
	}

	private validateInput(input: string): void {
		const errors: ValidationErrors = {};
		if (!input && this.required) {
			errors.required = this.errorDescriptions['required'];
		}

		if (input) {
			const number = +input;
			if (number < this.min) {
				errors.min = this.errorDescriptions['min'];
			}

			if (number > this.max) {
				errors.max = this.errorDescriptions['max'];
			}
		}

		this.validationErrorsEvent.emit(errors);
	}
}
