import { createSelector } from '@ngrx/store';

import { getLoanRequestFeatureState } from '../loan-request.selectors';
import { DISCOUNT_ENTITY_KEY, discountAdapter, DiscountActionTypes } from './discount.reducer';

const selectors = discountAdapter.getSelectors();

export const getDiscountState = createSelector(getLoanRequestFeatureState, (state) => state[DISCOUNT_ENTITY_KEY]);
export const getAllDiscounts = createSelector(getDiscountState, selectors.selectAll);
export const getSelectedDiscounts = createSelector(getDiscountState, (state) => state.selectedDiscounts);
export const getActionStates = createSelector(getDiscountState, (state) => state.actions);
export const getActionState = (actionType: DiscountActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
