import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, ILinkService } from '@oper-client/shared/data-access';
import { Link } from '@oper-client/shared/data-model';

@Injectable()
export class LinkService implements ILinkService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getAll(params: HttpParams = new HttpParams()): Observable<Link[]> {
		return this.apiService.get(`/api/legal-links`, params);
	}
}
