import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { NotaryService } from '../../services/notary.service';
import * as NotaryActions from './notary.actions';

@Injectable()
export class NotaryEffects {
	loadNotaries$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(NotaryActions.loadNotaries),
			switchMap(() => {
				return this.notaryService.getNotaries().pipe(
					map(notaries => NotaryActions.loadNotariesSuccess({ notaries })),
					catchError(error => of(NotaryActions.loadNotariesFailure({ error })))
				);
			})
		);
	});

	constructor(private actions$: Actions, private notaryService: NotaryService) {}
}
