import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Discount, BaseProductDiscounts, DiscountsQueryParams } from '@oper-client/shared/data-model';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class DiscountService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getDiscounts(params: HttpParams = new HttpParams()): Observable<Discount[]> {
		return this.apiService.get(`/api/discounts/`, params);
	}

	public getProductDiscounts(loanRequestId: number, queryParams: DiscountsQueryParams): Observable<BaseProductDiscounts[]> {
		let params = new HttpParams();

		if (queryParams.offerId) {
			params = params.append('loan_offer_id', queryParams.offerId.toString());
		}
		if (queryParams.baseProductIds?.length > 0) {
			params = params.append('base_product_ids', queryParams.baseProductIds.join(','));
		}

		if (queryParams.selectDefaultDiscounts) {
			params = params.append('select_default_discounts', queryParams.selectDefaultDiscounts);
		}

		return this.apiService.get(`/api/loan-requests/${loanRequestId}/discounts/`, params);
	}
}
