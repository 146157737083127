import { Validators } from '@angular/forms';
import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { IAM } from '@oper-client/shared/data-model';

export default function (formData: IAM.User): InputBase<any>[] {
	const questions = [];

	if (!formData.firstName || !formData.lastName) {
		questions.push(
			new InputField({
				key: 'firstName',
				label: 'ç.question.firstName.label',
				value: formData?.firstName,
				required: true,
				type: 'text',
				class: 'span12',
			}),
			new InputField({
				key: 'lastName',
				label: 'ç.question.lastName.label',
				value: formData?.lastName,
				required: true,
				type: 'text',
				class: 'span12',
			})
		);
	}

	questions.push(
		new InputField({
			key: 'comment',
			label: 'ç.question.message.label',
			value: null,
			type: 'text',
			multiline: true,
			class: 'span12',
			required: false,
			validators: [Validators.maxLength(5000)],
		})
	);

	return questions;
}
