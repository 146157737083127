import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as deepmerge from 'deepmerge';

import { IAM_FEATURE_KEY, iamInitialState } from '@oper-client/shared/iam/data-access-iam';
import { gtmMetaReducer } from '@oper-client/shared/tracking/gtm';

import { MatDateFormats } from 'saturn-datepicker';
import { CustomerInsights, DEFAULT_NUMBER_FORMAT_OPTIONS, NumberFormatOptions } from '@oper-client/shared/configuration';
import { AppDate } from '@oper-client/shared/util-formatting';

export function tokenGetter() {
	return localStorage.getItem('accessToken');
}

function reviver(key, value) {
	return key === 'actions' ? iamInitialState.actions : value;
}

const INIT_ACTION = '@ngrx/store/init';

const mergeReducer = (state: any, rehydratedState: any, action: any) => {
	if (action.type === INIT_ACTION && rehydratedState) {
		const overwriteMerge = (destinationArray: any, sourceArray: any) => sourceArray;
		const options: deepmerge.Options = {
			arrayMerge: overwriteMerge,
		};
		state = deepmerge(state, rehydratedState, options);
	}
	return state;
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({
		keys: [{ [IAM_FEATURE_KEY]: { reviver } }],
		storageKeySerializer: (key) => `oper_${key}`,
		rehydrate: true,
		storage: sessionStorage,
		mergeReducer,
	})(reducer);
}

export const metaReducers: MetaReducer<any>[] = [gtmMetaReducer, localStorageSyncReducer];

export function matDateFormatsFactory(locale: string): MatDateFormats {
	return {
		parse: {
			dateInput: AppDate.getDateFormat(locale),
		},
		display: {
			dateInput: AppDate.getDateFormat(locale),
			monthYearLabel: AppDate.getDateFormat(locale),
			// Default, there is no requirement for accessibility labels
			dateA11yLabel: 'LL',
			monthYearA11yLabel: 'MMMM YYYY',
		},
	};
}

export function numberFormatFactory(customerInsights: CustomerInsights): NumberFormatOptions {
	return {
		...DEFAULT_NUMBER_FORMAT_OPTIONS,
		currencyDigitsInfo: customerInsights?.currency?.digitsInfo || DEFAULT_NUMBER_FORMAT_OPTIONS.currencyDigitsInfo,
	};
}

export * from './lib/util-bootstrap.module';
