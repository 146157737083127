import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';
import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { Observable, of } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { FormConfiguration } from '../../models/dynamic-form.model';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const purposeOptions = (resources?.[ResourceType.REALTY_PURPOSE] || []).filter(({ definition }) =>
		['buy', 'build', 'refinance', 'renovate', 'extension', 'modernization', 'collateral'].includes(definition)
	);
	const realtyPurposeToBuy = resources['realty-purpose'].find((resource) => resource.definition === 'buy');
	const realtyPurposeToBuild = resources['realty-purpose'].find((resource) => resource.definition === 'build');

	return [
		new InputSelect({
			key: 'purposes[0].id',
			label: 'ç.question.mainPurpose.label',
			value: formData?.purposes?.[0]?.id ?? realtyPurposeToBuy.id,
			required: true,
			options: purposeOptions,
			class: 'span12',
		}),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			value: formData?.realtyType?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_TYPE] || [],
			validators: [],
			class: 'span12',
		}),
		new InputField({
			key: 'price',
			label: 'ç.question.fairMarketValue.label',
			value: formData?.price,
			type: 'number',
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'price');
				const formControl = <FormControl>formGroup.controls['price'];
				return formGroup.controls['purposes[0].id'].valueChanges.pipe(
					filter((value) => !!value),
					map((value) => {
						if (value === realtyPurposeToBuild.id) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'priceLand',
			label: 'ç.question.priceLand.label',
			value: formData?.priceLand,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'priceLand');
				const formControl = <FormControl>formGroup.controls['priceLand'];
				return formGroup.controls['purposes[0].id'].valueChanges.pipe(
					filter((value) => !!value),
					map((value) => {
						if (value !== realtyPurposeToBuild.id) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'priceBuilding',
			label: 'ç.question.priceBuilding.label',
			value: formData?.priceBuilding,
			type: 'hidden',
			disabled: true,
			currency: true,
			required: true,
			class: 'span12',
			transformField: (formGroup: FormGroup, formConfiguration: FormConfiguration): Observable<InputField> => {
				const inputField = <InputField>formConfiguration.formControl.questions.find((q) => q.key === 'priceBuilding');
				const formControl = <FormControl>formGroup.controls['priceBuilding'];
				return formGroup.controls['purposes[0].id'].valueChanges.pipe(
					filter((value) => !!value),
					map((value) => {
						if (value !== realtyPurposeToBuild.id) {
							inputField.type = 'hidden';
							inputField.disabled = true;
							formControl.disable();
						} else {
							inputField.type = 'number';
							inputField.disabled = false;
							formControl.enable();
						}

						return inputField;
					})
				);
			},
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			updateValidityOnFormValueChanges: true,
			class: 'span12',
		}),
		new InputField({
			key: 'mainFirstResidence',
			label: 'ç.question.mainFirstResidence.label',
			value: formData?.mainFirstResidence || false,
			type: 'checkbox',
			class: 'span12',
			helpText: of('ç.feature.property.mainFirstResidence.helpText'),
			required: false,
		}),
	];
}
