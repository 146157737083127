export function roundNumber(number: number, decimalPlaces = 2): number {
	const y = decimalPlaces > 1 ? 10 ** decimalPlaces : 1;
	return Math.round((number + Number.EPSILON) * y) / y;
}

export function getPercentageFromAmount(amount: number, totalAmount: number, decimalPlaces = 2): number {
	return (amount / totalAmount) * (decimalPlaces > 1 ? 10 ** decimalPlaces : 1);
}

export function roundPercentage(number: number): number {
	return number ? Math.round((number * 100 + Number.EPSILON) * 100) / 100 : number;
}
