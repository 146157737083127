import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseActionState, ParsedActionState } from '@oper-client/shared/data-model';
import * as PaymentFileActions from './payment-file.actions';
import { PaymentFilePartialState, PaymentFileActionTypes } from './payment-file.reducer';
import * as PaymentFileSelectors from './payment-file.selectors';

@Injectable()
export class PaymentFileFacade {
	paymentFiles$ = this.store.pipe(select(PaymentFileSelectors.getPaymentFiles));
	downloadPaymentFile$ = this.store.pipe(select(PaymentFileSelectors.getDownloadPaymentFile));

	loadPaymentFilesActionState$ = this.selectActionState(PaymentFileActionTypes.LOAD_PAYMENT_FILES);
	regeneratePaymentFileActionState$ = this.selectActionState(PaymentFileActionTypes.REGENERATE_PAYMENT_FILE);
	downloadPaymentFileActionState$ = this.selectActionState(PaymentFileActionTypes.DOWNLOAD_PAYMENT_FILE);
	processPaymentFileActionState$ = this.selectActionState(PaymentFileActionTypes.PROCESS_PAYMENT_FILE);

	constructor(private store: Store<PaymentFilePartialState>) {}

	clearPaymentFiles(): void {
		this.store.dispatch(PaymentFileActions.clearPaymentFiles());
	}

	loadPaymentFiles(): void {
		this.store.dispatch(PaymentFileActions.loadPaymentFiles());
	}

	processPaymentFile(paymentFileId: number): void {
		this.store.dispatch(PaymentFileActions.processPaymentFile({ paymentFileId }));
	}

	regeneratePaymentFile(paymentFileId: number): void {
		this.store.dispatch(PaymentFileActions.regeneratePaymentFile({ paymentFileId }));
	}

	downloadPaymentFile(paymentFileId: number): void {
		this.store.dispatch(PaymentFileActions.downloadPaymentFile({ paymentFileId }));
	}

	clearDownloadPaymentFileContent(): void {
		this.store.dispatch(PaymentFileActions.clearDownloadPaymentFileContent());
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}

	private selectActionState(actionType: PaymentFileActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(PaymentFileSelectors.getActionState(actionType)), map(parseActionState));
	}
}
