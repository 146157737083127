import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS, DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../../services/validator-constants';

import { of } from 'rxjs';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const maxIDDate: number = ValidatorService.getDateByYearOffset(-100).getTime();
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();
	const taxIdRegex = /^8\d{9}$/;

	return [
		new InputSelect({
			key: 'birthCountry.id',
			label: 'ç.question.birthCountry.label',
			value: formData?.birthCountry?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.COUNTRY] || [],
			class: 'span6',
		}),
		new InputField({
			key: 'maidenName',
			label: 'ç.question.maidenName.label',
			value: formData?.maidenName || '',
			type: 'text',
			required: false,
			class: 'span6',
		}),
		new InputField({
			key: 'motherMaidenName',
			label: 'ç.question.motherMaidenName.label',
			value: formData?.motherMaidenName || '',
			type: 'text',
			required: false,
			class: 'span6',
		}),
		new InputField({
			key: 'birthCity',
			label: 'ç.question.birthCity.label',
			value: formData?.birthCity,
			type: 'text',
			required: false,
			validators: [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'sex.id',
			label: 'ç.question.sex.label',
			value: formData?.sex?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.SEX] || [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'civilStatus.id',
			label: 'ç.question.civilStatus.label',
			value: formData?.civilStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CIVIL_STATUS] || [],
			class: 'span6',
		}),
		new InputField({
			key: 'dependentChildren',
			label: 'ç.question.dependentChildren.label',
			value: formData?.dependentChildren,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span6',
		}),
		new InputField({
			key: 'plannedChildren',
			label: 'ç.question.plannedChildren.label',
			value: formData?.plannedChildren,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span6',
		}),
		new InputField({
			key: 'numberOfFetusesGteTwelveWeeks',
			label: 'ç.question.numberOfFetusesGteTwelveWeeks.label',
			value: formData?.numberOfFetusesGteTwelveWeeks,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span6',
		}),
		new InputField({
			key: 'expectedFetusesBirthDate',
			label: 'ç.question.expectedFetusesBirthDate.label',
			value: formData?.expectedFetusesBirthDate,
			type: 'date',
			required: false,
			min: currentDate,
			validators: [
				ValidatorService.getDateRestrictionValidatior('notInPast', currentDate, DATE_RESTRICTION.PAST),
			],
			class: 'span6',
		}),
		new InputField({
			key: 'numberOfDependents',
			label: 'ç.question.numberOfDependents.label',
			value: formData?.numberOfDependents,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span6',
		}),
		new InputSelect({
			key: 'nationality.id',
			label: 'ç.question.nationality.label',
			value: formData?.nationality?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.NATIONALITY] || [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'legalStatusType.id',
			label: 'ç.question.legalStatusType.label',
			value: formData?.legalStatusType?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.LEGAL_STATUS_TYPE] || [],
			class: 'span6',
		}),
		new InputSelect({
			key: 'idDocumentType.id',
			label: 'ç.question.idDocumentType.label',
			value: formData?.idDocumentType?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.ID_DOCUMENT_TYPE] || [],
			class: 'span6',
		}),
		new InputField({
			key: 'idCardNumber',
			label: 'ç.question.idCardNumber.label',
			value: formData?.idCardNumber,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_CAPITAL_NUMBERS_LETTERS_DASHES, 'onlyNumbersAndCapitalLetters'),
			],
			class: 'span6',
		}),
		new InputField({
			key: 'idExpirationDate',
			label: 'ç.question.idCardExpirationDate.label',
			value: formData?.idExpirationDate,
			type: 'date',
			required: false,
			min: currentDate,
			max: maxIDDate,
			validators: [
				ValidatorService.getDateRestrictionValidatior('notInPast', currentDate, DATE_RESTRICTION.PAST),
				ValidatorService.getDateRestrictionValidatior('futureXYears', maxIDDate, DATE_RESTRICTION.FUTURE, null, () => ({
					years: 100,
				})),
			],
			class: 'span6',
			helpText: of('ç.question.idCardExpirationDate.helpText'),
			
		}),
		new InputField({
			key: 'addressCardId',
			label: 'ç.question.addressCardId.label',
			value: formData?.addressCardId,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_CAPITAL_NUMBERS_LETTERS, 'onlyNumbersAndCapitalLetters'),
			],
			class: 'span6',
		}),
		new InputField({
			key: 'nationalNumber',
			label: 'ç.question.nationalNumber.label',
			value: formData?.nationalNumber,
			type: 'text',
			required: false,
			validators: [],
			class: 'span6',
		}),
		new InputField({
			key: 'taxId',
			label: 'ç.question.taxId.label',
			value: formData?.taxId,
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(taxIdRegex, 'taxId'),
			],
			class: 'span6',
		})
	];
}
