import { Inject, Injectable } from '@angular/core';
import { API_SERVICE, IApiService, IProductDurationConfigService } from '@oper-client/shared/data-access';
import { ProductDurationConfig } from '@oper-client/shared/data-model';
import { Observable } from 'rxjs';

import { map, shareReplay } from 'rxjs/operators';

interface ProductDurationConfigurationResponse {
	id: number;
	durationFormat: {
		definition: string;
		id: string;
	};
	minMonths: number;
	maxMonths: number;
}

const CACHE_SIZE = 1; // used to reply the most recent value that is cached
const MIN_MONTHS = 1;
const MAX_MONTHS = 420;

@Injectable()
export class ProductDurationConfigService implements IProductDurationConfigService {
	private readonly _minMonths = MIN_MONTHS;
	private readonly _maxMonths = MAX_MONTHS;

	private cache$: Observable<ProductDurationConfig>;

	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	get configuration$(): Observable<ProductDurationConfig> {
		if (!this.cache$) {
			this.cache$ = this.getProductDurationConfig().pipe(shareReplay(CACHE_SIZE));
		}

		return this.cache$;
	}

	get defaultMaxMonths(): number {
		return this._maxMonths;
	}

	get defaultMinMonths(): number {
		return this._minMonths;
	}

	getProductDurationConfig(): Observable<ProductDurationConfig> {
		return this.apiService.get(`/configuration/product-duration/`).pipe(
			map((response: ProductDurationConfigurationResponse) => {
				if (response) {
					const { minMonths, maxMonths, durationFormat } = response;
					return {
						minMonths: minMonths ?? this._minMonths,
						maxMonths: maxMonths ?? this._maxMonths,
						formatAsYear: durationFormat?.definition === 'year',
					};
				}

				return null;
			})
		);
	}
}
