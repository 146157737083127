import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, LoanRequestSubentityService, IApiService } from '@oper-client/shared/data-access';
import { Income } from '@oper-client/shared/data-model';

@Injectable()
export class IncomeService implements LoanRequestSubentityService<Income> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	getAll(loanRequestId: number, clientId: number, params: HttpParams = new HttpParams()): Observable<Income[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/clients/${clientId}/incomes/`, params);
	}

	get(loanRequestId: number, clientId: number, id: number, params: HttpParams = new HttpParams()): Observable<Income> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/clients/${clientId}/incomes/${id}/`, params);
	}

	create(loanRequestId: number, clientId: number, income: Partial<Income>, params: HttpParams = new HttpParams()): Observable<Income> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/clients/${clientId}/incomes/`, income, params);
	}

	update(
		loanRequestId: number,
		clientId: number,
		incomeId: number,
		income: Partial<Income>,
		params: HttpParams = new HttpParams()
	): Observable<Income> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/clients/${clientId}/incomes/${incomeId}/`, income, params);
	}

	delete(loanRequestId: number, clientId: number, incomeId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/clients/${clientId}/incomes/${incomeId}/`);
	}
}
