import { of } from 'rxjs';
import { InputField } from '../../../models/input-types.model';
import { InputBase } from '../../../models/input-base.model';

export default function (formData?: { babyFreePurposeLoan: number; babyLoanHome: number }): InputBase<any>[] {
	return [
		new InputField({
			key: `babyFreePurposeLoan`,
			label: `ç.question.babyFreePurposeLoan.label`,
			helpText: of(`ç.question.babyFreePurposeLoan.helpText`),
			value: formData?.babyFreePurposeLoan,
			type: 'number',
			currency: true,
			validators: [],
			required: false,
			class: 'span12',
		}),

		new InputField({
			key: `babyLoanHome`,
			label: `ç.question.babyLoanHome.label`,
			helpText: of(`ç.question.babyLoanHome.helpText`),
			value: formData?.babyLoanHome,
			type: 'number',
			currency: true,
			validators: [],
			required: false,
			class: 'span12',
		}),
	];
}
