import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { Validators } from '@angular/forms';
import { of } from 'rxjs';
import { yearOptions1500 } from '@oper-client/shared/util-formatting';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputSelect({
			key: 'conditionOfProperty.id',
			label: 'ç.question.conditionOfProperty.label',
			value: formData?.conditionOfProperty?.id,
			required: false,
			options: resources?.[ResourceType.CONDITION_OF_PROPERTY] || [],
			class: 'span3',
		}),
		new InputSelect({
			key: 'yearOfBuilt',
			label: 'ç.question.yearOfBuilt.label',
			value: formData?.yearOfBuilt || null,
			options: yearOptions1500,
			validators: [],
			required: false,
			class: 'span3',
			alreadySorted: true,
		}),
		new InputField({
			key: 'landRegister',
			label: 'ç.question.landRegister.label',
			value: formData?.landRegister,
			type: 'text',
			required: false,
			class: 'span3',
			order: 1,
		}),
		new InputField({
			key: 'districtCourt',
			label: 'ç.question.districtCourt.label',
			value: formData?.districtCourt,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'plotNumber',
			label: 'ç.question.plotNumber.label',
			value: formData?.plotNumber,
			type: 'text',
			required: false,
			validators: [],
			class: 'span3',
		}),
		new InputField({
			key: 'propertyNumber',
			label: 'ç.question.propertyNumber.label',
			value: formData?.propertyNumber,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'cadastralLocation',
			label: 'ç.question.cadastralLocation.label',
			value: formData?.cadastralLocation,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'surface',
			label: 'ç.question.surface.label',
			value: formData?.surface,
			type: 'number',
			required: false,
			class: 'span3',
			digitsInfo: '1.2-4',
		}),
		new InputField({
			key: 'rooms',
			label: 'ç.question.rooms.label',
			value: formData?.rooms,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'epcBeforeRenovations',
			label: 'ç.question.epcBeforeRenovations.label',
			value: formData?.epcBeforeRenovations,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcAfterRenovations.label',
			value: formData?.epcAfterRenovations,
			type: 'number',
			required: false,
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$')],
			class: 'span3',
		}),
		new InputField({
			key: 'epcContractNumber',
			label: 'ç.question.epcContractNumber.label',
			value: formData?.epcContractNumber,
			type: 'text',
			required: false,
			class: 'span3',
		}),
		new InputField({
			key: 'epcDate',
			label: 'ç.question.epcDate.label',
			value: formData?.epcDate,
			type: 'date',
			required: false,
			validators: [],
			class: 'span3',
		}),
		new InputField({
			key: 'eLevel',
			label: 'ç.question.eLevel.label',
			value: formData?.eLevel,
			type: 'number',
			class: 'span3',
			helpText: of('ç.question.eLevel.helpText'),
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(100)],
			required: false,
			prefix: 'E',
		}),
		new InputField({
			key: 'energeticRenovations',
			label: 'ç.question.energeticRenovations.label',
			value: formData?.energeticRenovations || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new InputField({
			key: 'kleinBeschrijf',
			label: 'ç.question.kleinBeschrijf.label',
			value: formData?.kleinBeschrijf || false,
			type: 'checkbox',
			class: 'span3',
			required: false,
		}),
		new InputSelect({
			key: 'features',
			label: 'ç.question.propertyFeatures.label',
			value: formData?.features,
			bindValue: 'id',
			multiple: true,
			required: false,
			options: resources?.[ResourceType.REALTY_FEATURE] || [],
			class: 'span3',
			beta: false,
			clearable: true,
			alreadySorted: true,
		}),
	];
}
