<div class="input-group" [ngClass]="{ 'input-group--disabled': disableInput }">
	<input
		class="input-group__form-item-input"
		inputmode="password"
		[id]="key"
		[name]="name"
		[required]="required"
		[attr.disabled]="disableInput"
		[(ngModel)]="value"
		(ngModelChange)="valueChanged($event)"
		[type]="(showPassword$ | async) ? 'text' : 'password'"
		(blur)="onBlur()"
		(focus)="focusEmit.emit()"
		[class.hide-password]="(showPassword$ | async) === false"
	/>

	<button
		class="input-group-addition input-group-addition--append toggleShowPassword"
		*ngIf="revealPassword"
		type="button"
		(click)="toggle$.next(null)"
	>
		<fa-icon [icon]="iconShowPassword" [fixedWidth]="true" *ngIf="(showPassword$ | async) === false; else hidePassword"></fa-icon>
		<ng-template #hidePassword>
			<fa-icon [icon]="iconHidePassword" [fixedWidth]="true" *ngIf="showPassword$ | async"></fa-icon>
		</ng-template>
	</button>
</div>

@if (showPasswordStrength) {
	<oper-client-password-validation
		[ngClass]="{ 'password-validation': !!value }"
		[password]="value"
		[immediate]="immediatePasswordCheck"
		(validationResult)="isValid$.next($event)"
	/>
}
