import { of } from 'rxjs';
import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'additionalLiabilities',
			label: formData?.yearlyPeriodicity
				? 'ç.question.yearlyAdditionalLiabilities.label'
				: 'ç.question.monthlyAdditionalLiabilities.label',
			helpText: of(
				formData?.yearlyPeriodicity
					? 'ç.question.yearlyAdditionalLiabilities.helpText'
					: 'ç.question.monthlyAdditionalLiabilities.helpText'
			),
			value: formData?.additionalLiabilities,
			type: 'number',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'alimony',
			label: formData?.yearlyPeriodicity ? 'ç.question.yearlyAlimony.label' : 'ç.question.monthlyAlimony.label',
			helpText: of(formData?.yearlyPeriodicity ? 'ç.question.yearlyAlimony.helpText' : 'ç.question.monthlyAlimony.helpText'),
			value: formData?.alimony,
			type: 'number',
			currency: true,
			required: false,
		}),
	];
}
