import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { IAM, ActionState, initialActionState, setActionState, ActionTypes, IdentityProvider } from '@oper-client/shared/data-model';

import * as IamActions from './iam.actions';

export const IAM_FEATURE_KEY = 'iam';

export interface IamPartialState {
	readonly [IAM_FEATURE_KEY]: IamState;
}

export const iamAdapter: EntityAdapter<IAM.User> = createEntityAdapter<IAM.User>();

export type IAMActionTypes =
	| 'login'
	| 'forgotPassword'
	| 'resetPassword'
	| 'resetPassword2Fa'
	| 'activateUser'
	| 'verifyUser'
	| 'validateCredentials'
	| 'activateOTP'
	| 'verifyOTP'
	| 'loadPermissions'
	| 'loadCurrentUser'
	| 'updateCurrentUser'
	| 'loadIdentity'
	| 'createUser'
	| 'updateProfile'
	| 'inviteUser';

export type IamActionsState = Record<IAMActionTypes, ActionState>;

export interface IamState extends EntityState<IAM.User> {
	selectedId: number;
	permissions: string[];
	authRequest: {
		state: string;
		identityProvider: IdentityProvider;
		url: string;
	};
	actions: IamActionsState;
}

export const initialState: IamState = iamAdapter.getInitialState({
	selectedId: null,
	permissions: null,
	authRequest: null,
	actions: {
		login: initialActionState,
		resetPassword: initialActionState,
		resetPassword2Fa: initialActionState,
		forgotPassword: initialActionState,
		activateUser: initialActionState,
		verifyUser: initialActionState,
		validateCredentials: initialActionState,
		activateOTP: initialActionState,
		verifyOTP: initialActionState,
		loadPermissions: initialActionState,
		loadCurrentUser: initialActionState,
		updateCurrentUser: initialActionState,
		loadIdentity: initialActionState,
		createUser: initialActionState,
		updateProfile: initialActionState,
		inviteUser: initialActionState,
	},
});

function setActionStates(
	actionState: IamActionsState,
	action: IAMActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null,
	ids: any = null
): IamActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error, ids),
	};
}

const iamReducer = createReducer(
	initialState,
	// Load Current User
	on(IamActions.loadCurrentUser, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadCurrentUser', ActionTypes.loading),
	})),
	on(IamActions.loadCurrentUserSuccess, (state, { user }) =>
		iamAdapter.addOne(user, {
			...state,
			actions: setActionStates(state.actions, 'loadCurrentUser', ActionTypes.success),
			selectedId: user.id,
		})
	),
	on(IamActions.loadCurrentUserFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadCurrentUser', ActionTypes.failure, httpError),
	})),
	// Update Current User
	on(IamActions.updateCurrentUser, (state, { user }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateCurrentUser', ActionTypes.loading),
	})),
	on(IamActions.updateCurrentUserSuccess, (state, { user }) =>
		iamAdapter.updateOne(user, {
			...state,
			actions: setActionStates(state.actions, 'updateCurrentUser', ActionTypes.success),
		})
	),
	on(IamActions.updateCurrentUserFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateCurrentUser', ActionTypes.failure, httpError),
	})),
	// Login
	on(IamActions.login, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'login', ActionTypes.loading),
	})),
	on(IamActions.loginSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'login', ActionTypes.success),
	})),
	on(IamActions.loginFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'login', ActionTypes.failure, httpError),
	})),
	// Logout
	on(IamActions.logout, (state) =>
		iamAdapter.removeOne(state.selectedId, {
			...state,
			selectedId: initialState.selectedId,
			permissions: initialState.permissions,
			authRequest: initialState.authRequest,
			actions: { ...initialState.actions },
		})
	),
	// Forgot Password
	on(IamActions.forgotPassword, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'forgotPassword', ActionTypes.loading),
	})),
	on(IamActions.forgotPasswordSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'forgotPassword', ActionTypes.success),
	})),
	on(IamActions.forgotPasswordFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'forgotPassword', ActionTypes.failure, httpError),
	})),
	// Reset Password
	on(IamActions.resetPassword, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'resetPassword', ActionTypes.loading),
	})),
	on(IamActions.resetPasswordSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'resetPassword', ActionTypes.success),
	})),
	on(IamActions.resetPasswordFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'resetPassword', ActionTypes.failure, httpError),
	})),
	// Reset Password 2FA
	on(IamActions.resetPassword2Fa, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'resetPassword2Fa', ActionTypes.loading),
	})),
	on(IamActions.resetPassword2FaSuccess, (state, { tokens }) => ({
		...state,
		actions: setActionStates(state.actions, 'resetPassword2Fa', ActionTypes.success),
	})),
	on(IamActions.resetPassword2FaFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'resetPassword2Fa', ActionTypes.failure, httpError),
	})),
	// Activate User
	on(IamActions.activateUser, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'activateUser', ActionTypes.loading),
	})),
	on(IamActions.activateUserSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'activateUser', ActionTypes.success),
	})),
	on(IamActions.activateUserFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'activateUser', ActionTypes.failure, httpError),
	})),
	// Verify User
	on(IamActions.verifyUser, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'verifyUser', ActionTypes.loading),
	})),
	on(IamActions.verifyUserSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'verifyUser', ActionTypes.success),
	})),
	on(IamActions.verifyUserFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'verifyUser', ActionTypes.failure, httpError),
	})),
	// validate credentials
	on(IamActions.validateCredentials, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'validateCredentials', ActionTypes.loading),
	})),
	on(IamActions.validateCredentialsSuccess, (state, { email, token, verified }) => ({
		...state,
		actions: setActionStates(state.actions, 'validateCredentials', ActionTypes.success, null, {
			email,
			token,
			verified,
		}),
	})),
	on(IamActions.validateCredentialsFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'validateCredentials', ActionTypes.failure, httpError),
	})),
	//activate OTP
	on(IamActions.activateOTP, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'activateOTP', ActionTypes.loading),
	})),
	on(IamActions.activateOTPSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'activateOTP', ActionTypes.success),
	})),
	on(IamActions.activateOTPFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'activateOTP', ActionTypes.failure, httpError),
	})),
	//verify OTP
	on(IamActions.verifyOTP, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'verifyOTP', ActionTypes.loading),
	})),
	on(IamActions.verifyOTPSuccess, (state, { access, refresh }) => ({
		...state,
		actions: setActionStates(state.actions, 'verifyOTP', ActionTypes.success, null, {
			access,
			refresh,
		}),
	})),
	on(IamActions.verifyOTPFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'verifyOTP', ActionTypes.failure, httpError),
	})),
	// Identity Password
	on(IamActions.loadIdentity, (state, { identityProvider }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadIdentity', ActionTypes.loading, null, identityProvider),
	})),
	on(IamActions.loadIdentitySuccess, (state, { identityProvider }) => ({
		...state,
		authRequest: undefined,
		actions: setActionStates(state.actions, 'loadIdentity', ActionTypes.success, null, identityProvider),
	})),
	on(IamActions.loadIdentityFailure, (state, { httpError }) => ({
		...state,
		authRequest: undefined,
		actions: setActionStates(state.actions, 'loadIdentity', ActionTypes.failure, httpError),
	})),
	//Release IAM ActionsState
	on(IamActions.releaseIamActionState, (state, { kind }) => ({
		...state,
		actions: initialState.actions,
	})),
	on(IamActions.clearPermissions, (state) => ({
		...state,
		permissions: undefined,
	})),
	on(IamActions.loadPermissionsSuccess, (state, { permissions }) => ({
		...state,
		permissions: permissions,
		actions: setActionStates(state.actions, 'loadPermissions', ActionTypes.success),
	})),
	on(IamActions.loadPermissionsFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadPermissions', ActionTypes.failure, httpError),
	})),
	on(IamActions.resetLoadIdentityState, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadIdentity', ActionTypes.init),
	})),
	on(IamActions.signInToIdentityProvider, (state, { authRequest }) => ({
		...state,
		authRequest: {
			state: authRequest.state,
			url: authRequest.url,
			identityProvider: authRequest.identityProvider,
		},
	})),

	//create user
	on(IamActions.createUser, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createUser', ActionTypes.loading),
	})),
	on(IamActions.createUserSuccess, (state, { tokens }) => ({
		...state,
		actions: setActionStates(state.actions, 'createUser', ActionTypes.success, null, { tokens }),
	})),
	on(IamActions.createUserFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'createUser', ActionTypes.failure, httpError),
	})),

	//update profile
	on(IamActions.updateProfile, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateProfile', ActionTypes.loading),
	})),
	on(IamActions.updateProfileSuccess, (state, { token: string }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateProfile', ActionTypes.success, null, { token: string }),
	})),
	on(IamActions.updateProfileFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateProfile', ActionTypes.failure, httpError),
	})),

	//invite user
	on(IamActions.inviteUser, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'inviteUser', ActionTypes.loading),
	})),
	on(IamActions.inviteUserSuccess, (state, { tokens }) => ({
		...state,
		actions: setActionStates(state.actions, 'inviteUser', ActionTypes.success, null, { tokens }),
	})),
	on(IamActions.inviteUserFailure, (state, { httpError }) => ({
		...state,
		actions: setActionStates(state.actions, 'inviteUser', ActionTypes.failure, httpError),
	}))
);

export function reducer(state: IamState | undefined, action: Action) {
	return iamReducer(state, action);
}
