import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ADVISOR_SIMULATOR_KEY, AdvisorSimulatorState, simulationAdapter, SimulatorActionTypes } from './advisor-simulator.reducer';

const selectors = simulationAdapter.getSelectors();

export const selectSimulatorState = createFeatureSelector<AdvisorSimulatorState>(ADVISOR_SIMULATOR_KEY);
export const getSimulations = createSelector(selectSimulatorState, selectors.selectAll);
export const getSimulationResultEntities = createSelector(selectSimulatorState, selectors.selectEntities);
export const getCurrentSimulation = createSelector(selectSimulatorState, (state) => state.currentSimulation);
export const getActiveSimulatorStep = createSelector(selectSimulatorState, (state) => state.activeSimulatorStep);
export const getActionStates = createSelector(selectSimulatorState, (state) => state.actions);
export const getSimulatorInformationBox = createSelector(selectSimulatorState, (state) => state.simulatorInformationBox);
export const getProducts = createSelector(selectSimulatorState, (state) => state.products);
export const getProductsSortOrder = createSelector(selectSimulatorState, (state) => state.productsSortOrder);
export const getSimulationsSortOrder = createSelector(selectSimulatorState, (state) => state.simulationsSortOrder);
export const getOffers = createSelector(selectSimulatorState, (state) => state.offers);
export const getSelectedOfferIds = createSelector(selectSimulatorState, (state) => state.selectedOfferIds);
export const getDiscounts = createSelector(selectSimulatorState, (state) => state.discounts);
export const getProductSearchFilters = createSelector(selectSimulatorState, (state) => state.productSearchFilters);
export const getConvertToLoanRequestResponse = createSelector(selectSimulatorState, (state) => state.convertToLoanRequestResponse);
export const getConfiguration = createSelector(selectSimulatorState, (state) => state.configuration);
export const getBorrowerDocumentTypes = createSelector(selectSimulatorState, (state) => state.documents);
export const getSearchSimulationResponse = createSelector(selectSimulatorState, (state) => state.searchResponse);
export const getAssignAnalystDialogVisibility = createSelector(selectSimulatorState, (state) => state.assignAnalystDialogVisibility);
export const getViewMode = createSelector(selectSimulatorState, (state) => state.viewMode);
export const getActionState = (actionType: SimulatorActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
