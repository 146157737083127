export class I18nPhoneNumber {
	value: string;
	countryCode: {
		country?: string;
		definition?: string;
		id: number;
	};
	phoneType?: {
		definition?: string;
		id?: number;
	};
}

export interface PhoneCountry {
	name: string;
	iso2: string;
	dialCode: string;
	priority: number;
	areaCodes?: string[];
	flagClass: string;
	placeHolder?: string;
	id?: number;
	definition?: string;
}
