import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Task } from '@oper-client/shared/data-model';
import { API_SERVICE, CustomHttpUrlEncodingCodec, IApiService, ITaskService } from '@oper-client/shared/data-access';

@Injectable()
export class TaskService implements ITaskService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getTasks(
		loanRequest?: string,
		taskType?: string,
		createdBy?: string,
		taskCategory?: string,
		statusNot?: number,
		assignedTo?: string,
		status?: string,
		ordering?: string,
		search?: string,
		page?: number,
		pageSize?: number,
		upsert?: boolean,
		observe: any = 'body',
		reportProgress = false
	): Observable<{ results: Task[]; count?: number }> {
		let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
		if (loanRequest !== undefined && loanRequest !== null) {
			queryParameters = queryParameters.set('loan_request', <any>loanRequest);
		}
		if (taskType !== undefined && taskType !== null) {
			queryParameters = queryParameters.set('task_type', <any>taskType);
		}
		if (createdBy !== undefined && createdBy !== null) {
			queryParameters = queryParameters.set('created_by', <any>createdBy);
		}
		if (taskCategory !== undefined && taskCategory !== null) {
			queryParameters = queryParameters.set('task_category', <any>taskCategory);
		}
		if (statusNot !== undefined && statusNot !== null) {
			queryParameters = queryParameters.set('status__not', <any>statusNot);
		}
		if (assignedTo !== undefined && assignedTo !== null) {
			queryParameters = queryParameters.set('assigned_to', <any>assignedTo);
		}
		if (status !== undefined && status !== null) {
			queryParameters = queryParameters.set('status', <any>status);
		}
		if (ordering !== undefined && ordering !== null) {
			queryParameters = queryParameters.set('ordering', <any>ordering);
		}
		if (search !== undefined && search !== null) {
			queryParameters = queryParameters.set('search', <any>search);
		}
		if (page !== undefined && page !== null) {
			queryParameters = queryParameters.set('page', <any>page);
		}
		if (pageSize !== undefined && pageSize !== null) {
			queryParameters = queryParameters.set('page_size', <any>pageSize);
		} else {
			queryParameters = queryParameters.set('page_size', '15');
		}

		return this.apiService.get(`/api/tasks/`, queryParameters).pipe(map((paginatedResult) => paginatedResult));
	}

	public createTask(task: Task): Observable<Task> {
		return this.apiService.post(`/api/tasks/`, task);
	}

	public updateTask(id: number, task: Task, params: HttpParams = new HttpParams()): Observable<Task> {
		return this.apiService.patch(`/api/tasks/${id}/`, task, params);
	}
}
