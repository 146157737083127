import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CUSTOMER_INSIGHTS_CONFIG, CustomerInsights } from '@oper-client/shared/configuration';
import { API_SERVICE, IApiService, IIdentityProviderService } from '@oper-client/shared/data-access';

@Injectable()
export class IdentityProviderService implements IIdentityProviderService {
	private readonly redirectUri = `${location.protocol}//${location.host}/app/auth-id`;
	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(CUSTOMER_INSIGHTS_CONFIG) private customerInsights: CustomerInsights
	) {}

	getUserInfo(queryParams: any): Observable<any> {
		return this.apiService.get(
			`/api/connective/auth-id/`,
			new HttpParams({ fromObject: { ...queryParams, redirectUri: this.redirectUri } })
		);
	}

	getLoginUrl(identityProvider: string): Observable<any> {
		return this.apiService.get(
			`/api/connective/login-url/`,
			new HttpParams({ fromObject: { redirectUri: this.redirectUri, identityProvider: identityProvider } })
		);
	}
}
