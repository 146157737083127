import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AllowedProductVariability,
	BaseRate,
	CreateProductPricingSheetRequest,
	ProductPricingDetails,
	ProductPricingSheet,
	ProductPricingSheetDiscounts,
} from '@oper-client/shared/data-model';

export const setCurrentProductPricingDetailsId = createAction(
	'[Product Pricing] Set Current Product Pricing Details Id',
	props<{ productId: number }>()
);
export const setCurrentProductPricingSheetId = createAction(
	'[Product Pricing] Set Current Product Pricing Sheet Id',
	props<{ pricingSheetId: number }>()
);

export const setCurrentProductPricingDetails = createAction(
	'[Product Pricing] Set Current Product Pricing Details',
	props<{ pricingDetails: ProductPricingDetails }>()
);
export const setCurrentProductPricingSheet = createAction(
	'[Product Pricing] Set Current Product Pricing Sheet',
	props<{ pricingSheet: ProductPricingSheet }>()
);

export const loadProducts = createAction('[Product Pricing] Load Products', props<{ searchTerm?: string }>());
export const loadProductsSuccess = createAction('[Product Pricing] Load Products Success', props<{ products: ProductPricingDetails[] }>());
export const loadProductsFailure = createAction('[Product Pricing] Load Products Failure', props<{ error: any }>());

export const loadProductPricingDetails = createAction('[Product Pricing] Load Product Pricing Details', props<{ productId: number }>());
export const loadProductPricingDetailsSuccess = createAction(
	'[Product Pricing] Load Product Pricing Details Success',
	props<{ pricingDetails: ProductPricingDetails }>()
);
export const loadProductPricingDetailsFailure = createAction(
	'[Product Pricing] Load Product Pricing Details Failure',
	props<{ error: any }>()
);

export const loadProductPricingSheets = createAction('[Product Pricing] Load Product Pricing Sheets', props<{ productId: number }>());
export const loadProductPricingSheetsSuccess = createAction(
	'[Product Pricing] Load Product Pricing Sheets Success',
	props<{ pricingSheets: ProductPricingSheet[] }>()
);
export const loadProductPricingSheetsFailure = createAction(
	'[Product Pricing] Load Product Pricing Sheets Failure',
	props<{ error: any }>()
);

export const loadValidProductPricingSheets = createAction(
	'[Product Pricing] Load Valid Product Pricing Sheets',
	props<{ productId: number }>()
);
export const loadValidProductPricingSheetsSuccess = createAction(
	'[Product Pricing] Load Valid Product Pricing Sheets Success',
	props<{ pricingSheets: ProductPricingSheet[] }>()
);
export const loadValidProductPricingSheetsFailure = createAction(
	'[Product Pricing] Load Valid Product Pricing Sheets Failure',
	props<{ error: any }>()
);

export const loadBaseRates = createAction(
	'[Product Pricing] Load Product Pricing Sheet Base Rates',
	props<{ productId: number; pricingSheetId: number }>()
);
export const loadBaseRatesSuccess = createAction(
	'[Product Pricing] Load Product Pricing Sheet Base Rates Success',
	props<{ baseRates: BaseRate[] }>()
);
export const loadBaseRatesFailure = createAction(
	'[Product Pricing] Load Product Pricing Sheet Base Rates Failure',
	props<{ error: any }>()
);

export const loadProductPricingSheet = createAction(
	'[Product Pricing] Load Product Pricing Sheet',
	props<{ productId: number; pricingSheetId: number }>()
);
export const loadProductPricingSheetSuccess = createAction(
	'[Product Pricing] Load Product Pricing Sheet Success',
	props<{ pricingSheet: ProductPricingSheet }>()
);
export const loadProductPricingSheetFailure = createAction('[Product Pricing] Load Product Pricing Sheet Failure', props<{ error: any }>());

export const loadAllowedVariabilities = createAction(
	'[Product Pricing] Load Allowed Product Variabilities',
	props<{ productId: number }>()
);
export const loadAllowedVariabilitiesSuccess = createAction(
	'[Product Pricing] Load Allowed Product Variabilities Success',
	props<{ allowedVariabilities: AllowedProductVariability[] }>()
);
export const loadAllowedVariabilitiesFailure = createAction(
	'[Product Pricing] Load Allowed Product Variabilities Failure',
	props<{ error: any }>()
);

export const loadAllowedProductVariability = createAction(
	'[Product Pricing] Load Allowed Product Variability',
	props<{ productId: number; variabilityId: number }>()
);
export const loadAllowedProductVariabilitySuccess = createAction(
	'[Product Pricing] Load Allowed Product Variability Success',
	props<{ allowedVariability: AllowedProductVariability }>()
);
export const loadAllowedProductVariabilityFailure = createAction(
	'[Product Pricing] Load Allowed Product Variability Failure',
	props<{ error: any }>()
);

export const updateProductPricingDetails = createAction(
	'[Product Pricing] Update Product Pricing Details',
	props<{ productId: number; pricingDetails: Update<ProductPricingDetails> }>()
);
export const updateProductPricingDetailsSuccess = createAction(
	'[Product Pricing] Update Product Pricing Details Success',
	props<{ pricingDetails: Update<ProductPricingDetails> }>()
);
export const updateProductPricingDetailsFailure = createAction(
	'[Product Pricing] Update Product Pricing Details Failure',
	props<{ error: any }>()
);

export const updateProductPricingSheet = createAction(
	'[Product Pricing] Update Product Pricing Sheet',
	props<{ productId: number; pricingSheet: Update<ProductPricingSheet> }>()
);
export const updateProductPricingSheetSuccess = createAction(
	'[Product Pricing] Update Product Pricing Sheet Success',
	props<{ pricingSheet: Update<ProductPricingSheet> }>()
);
export const updateProductPricingSheetFailure = createAction(
	'[Product Pricing] Update Product Pricing Sheet Failure',
	props<{ error: any }>()
);

export const createProductPricingSheet = createAction(
	'[Product Pricing] Create Product Pricing Sheet',
	props<{ productId: number; request: CreateProductPricingSheetRequest }>()
);
export const createProductPricingSheetSuccess = createAction(
	'[Product Pricing] Create Product Pricing Sheet Success',
	props<{ pricingSheet: ProductPricingSheet }>()
);
export const createProductPricingSheetFailure = createAction(
	'[Product Pricing] Create Product Pricing Sheet Failure',
	props<{ error: any }>()
);

export const removeProductPricingSheet = createAction(
	'[Product Pricing] Remove Pricing Sheet',
	props<{ productId: number; pricingSheetId: number }>()
);
export const removeProductPricingSheetSuccess = createAction(
	'[Product Pricing] Remove Pricing Sheet Success',
	props<{ productId: number; pricingSheetId: number }>()
);
export const removeProductPricingSheetFailure = createAction(
	'[Product Pricing]] Remove Pricing Sheet Failure',
	props<{ error: HttpErrorResponse }>()
);

export const updateProductPricingSheetBaseRate = createAction(
	'[Product Pricing] Update Product Pricing Sheet Base Rate',
	props<{ productId: number; pricingSheetId: number; baseRateId: number; baseRate: Update<BaseRate> }>()
);
export const updateProductPricingSheetBaseRateSuccess = createAction(
	'[Product Pricing] Update Product Pricing Sheet Base Rate Success',
	props<{ baseRate: Update<BaseRate> }>()
);
export const updateProductPricingSheetBaseRateFailure = createAction(
	'[Product Pricing] Update Product Pricing Sheet Base Rate Failure',
	props<{ error: any }>()
);

export const updateProductPricingSheetBaseRates = createAction(
	'[Product Pricing] Update Product Pricing Sheet Base Rates',
	props<{ productId: number; pricingSheetId: number; baseRates: Update<BaseRate>[] }>()
);
export const updateProductPricingSheetBaseRatesSuccess = createAction(
	'[Product Pricing] Update Product Pricing Sheet Base Rates Success',
	props<{ baseRates: Update<BaseRate>[] }>()
);
export const updateProductPricingSheetBaseRatesFailure = createAction(
	'[Product Pricing] Update Product Pricing Sheet Base Rates Failure',
	props<{ error: any }>()
);

export const updateAllowedProductVariability = createAction(
	'[Product Pricing] Update Allowed Product Variability',
	props<{ productId: number; variability: Update<AllowedProductVariability> }>()
);
export const updateAllowedProductVariabilitySuccess = createAction(
	'[Product Pricing] Update Allowed Product Variability Success',
	props<{ allowedVariability: Update<AllowedProductVariability> }>()
);
export const updateAllowedProductVariabilityFailure = createAction(
	'[Product Pricing] Update Allowed Product Variability Failure',
	props<{ error: any }>()
);

export const resetProductPricingDetailsState = createAction('[Product Pricing] Reset Product Pricing Details State');
export const resetProductPricingDetailsStateActionState = createAction('[Product Pricing] Reset Product Pricing Details Action State');

export const resetProductPricingSheetsState = createAction('[Product Pricing] Reset Product Pricing Sheets State');
export const resetProductPricingSheetsStateActionState = createAction('[Product Pricing] Reset Product Pricing Sheets Action State');

export const resetProductPricingSheetBaseRatesState = createAction('[Product Pricing] Reset Product Pricing Sheet Base Rates State');
export const resetProductPricingSheetBaseRatesStateActionState = createAction(
	'[Product Pricing] Reset Product Pricing Sheet Base Rates Action State'
);
export const resetProductPricingSheetDiscountsActionState = createAction(
	'[Product Pricing] Reset Product Pricing Sheet Discounts Action State'
);

export const resetAllowedProductVariabilityState = createAction('[Product Pricing] Reset Product Pricing Sheets State');
export const resetAllowedProductVariabilityStateActionState = createAction('[Product Pricing] Reset Product Pricing Sheets Action State');

export const loadProductPricingSheetDiscounts = createAction(
	'[Product Pricing] Load Product Pricing Sheet Discounts',
	props<{ productId: number; pricingSheetId: number }>()
);
export const loadProductPricingSheetDiscountsSuccess = createAction(
	'[Product Pricing] Load Product Pricing Sheet Discounts Success',
	props<{ discounts: ProductPricingSheetDiscounts[] }>()
);
export const loadProductPricingSheetDiscountsFailure = createAction(
	'[Product Pricing] Load Product Pricing Sheet Discounts Failure',
	props<{ error: any }>()
);

export const updateProductPricingSheetDiscounts = createAction(
	'[Product Pricing] Update Product Pricing Sheet Discounts',
	props<{ productId: number; pricingSheetId: number; discounts: Update<ProductPricingSheetDiscounts>[] }>()
);
export const updateProductPricingSheetDiscountsSuccess = createAction(
	'[Product Pricing] Update Product Pricing Sheet Discounts Success',
	props<{ discounts: Update<ProductPricingSheetDiscounts>[] }>()
);
export const updateProductPricingSheetDiscountsFailure = createAction(
	'[Product Pricing] Update Product Pricing Sheet Discounts Failure',
	props<{ error: any }>()
);
