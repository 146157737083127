import { createSelector } from '@ngrx/store';

import { getLoanRequestFeatureState } from '../loan-request.selectors';
import * as fromProductExplorer from './product-explorer.reducer';

export const selectProductExplorerState = createSelector(
	getLoanRequestFeatureState,
	(state) => state[fromProductExplorer.PRODUCT_EXPLORER_ENTITY_KEY]
);

export const getActiveStep = createSelector(selectProductExplorerState, (state) => state.activeStep);
export const getOfferToUpdate = createSelector(selectProductExplorerState, (state) => state.offerToUpdate);
export const getSelectedProducts = createSelector(selectProductExplorerState, (state) => state.selectedProducts);
export const getSearchFilterParams = createSelector(selectProductExplorerState, (state) => state.searchFilterParams);
