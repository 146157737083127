import { of } from 'rxjs';
import { PartialNormalizedResource, Realty } from '@oper-client/shared/data-model';
import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: Realty, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'price',
			label: 'ç.question.price.label',
			helpText: of('ç.question.price.helpText'),
			value: formData?.price || null,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'renovations[0].amountContractor',
			label: 'ç.question.renovationPrice.label',
			helpText: of('ç.question.renovationPrice.helpText'),
			value: formData.renovations?.find((renovation) => renovation.renovationType.definition === 'other')?.amountContractor || null,
			type: 'text',
			currency: true,
			required: false,
		}),
	];
}
