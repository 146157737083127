import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as PaymentFileActions from './payment-file.actions';
import { ActionState, setActionState, ActionTypes, PaymentFile, initialActionState } from '@oper-client/shared/data-model';

export const PAYMENT_FILE_FEATURE_KEY = 'paymentFile';

export interface PaymentFilePartialState {
	readonly [PAYMENT_FILE_FEATURE_KEY]: PaymentFileState;
}

export enum PaymentFileActionTypes {
	LOAD_PAYMENT_FILES = 'loadPaymentFiles',
	REGENERATE_PAYMENT_FILE = 'regeneratePaymentFile',
	DOWNLOAD_PAYMENT_FILE = 'downloadPaymentFile',
	PROCESS_PAYMENT_FILE = 'processPaymentFile',
}

export type PaymentFileActionsState = Record<PaymentFileActionTypes, ActionState>;

export interface PaymentFileState extends EntityState<PaymentFile> {
	error: HttpErrorResponse | undefined;
	downloadPaymentFile: {
		fileName: string;
		content: string;
	} | null;
	actions: PaymentFileActionsState;
}

export const paymentFileAdapter: EntityAdapter<PaymentFile> = createEntityAdapter<PaymentFile>();

export const paymentFileInitialState: PaymentFileState = paymentFileAdapter.getInitialState({
	error: null,
	downloadPaymentFile: null,
	actions: {
		loadPaymentFiles: initialActionState,
		regeneratePaymentFile: initialActionState,
		downloadPaymentFile: initialActionState,
		processPaymentFile: initialActionState,
	},
});

function setActionStates(
	actionState: PaymentFileActionsState,
	action: PaymentFileActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): PaymentFileActionsState {
	return {
		...paymentFileInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	paymentFileInitialState,

	on(PaymentFileActions.clearPaymentFiles, state =>
		paymentFileAdapter.removeAll({
			...state,
			actions: setActionStates(state.actions, PaymentFileActionTypes.LOAD_PAYMENT_FILES, ActionTypes.success),
		})
	),

	on(PaymentFileActions.loadPaymentFiles, state => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.LOAD_PAYMENT_FILES, ActionTypes.loading),
	})),

	on(PaymentFileActions.loadPaymentFilesSuccess, (state, { paymentFiles }) =>
		paymentFileAdapter.setAll(paymentFiles, {
			...state,
			actions: setActionStates(state.actions, PaymentFileActionTypes.LOAD_PAYMENT_FILES, ActionTypes.success),
		})
	),

	on(PaymentFileActions.loadPaymentFilesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.LOAD_PAYMENT_FILES, ActionTypes.failure, error),
	})),

	on(PaymentFileActions.regeneratePaymentFile, state => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.REGENERATE_PAYMENT_FILE, ActionTypes.loading),
	})),

	on(PaymentFileActions.regeneratePaymentFileSuccess, (state, { paymentFile }) =>
		paymentFileAdapter.setOne(paymentFile, {
			...state,
			actions: setActionStates(state.actions, PaymentFileActionTypes.REGENERATE_PAYMENT_FILE, ActionTypes.success),
		})
	),

	on(PaymentFileActions.regeneratePaymentFileFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.REGENERATE_PAYMENT_FILE, ActionTypes.failure, error),
	})),

	on(PaymentFileActions.downloadPaymentFile, state => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.DOWNLOAD_PAYMENT_FILE, ActionTypes.loading),
	})),

	on(PaymentFileActions.downloadPaymentFileSuccess, (state, { downloadPaymentFile }) => ({
		...state,
		downloadPaymentFile,
		actions: setActionStates(state.actions, PaymentFileActionTypes.DOWNLOAD_PAYMENT_FILE, ActionTypes.success),
	})),

	on(PaymentFileActions.clearDownloadPaymentFileContent, state => ({
		...state,
		downloadPaymentFile: null,
	})),

	on(PaymentFileActions.downloadPaymentFileFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.DOWNLOAD_PAYMENT_FILE, ActionTypes.failure, error),
	})),

	on(PaymentFileActions.processPaymentFile, state => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.PROCESS_PAYMENT_FILE, ActionTypes.loading),
	})),

	on(PaymentFileActions.processPaymentFileSuccess, (state, { paymentFile }) =>
		paymentFileAdapter.setOne(paymentFile, {
			...state,
			actions: setActionStates(state.actions, PaymentFileActionTypes.PROCESS_PAYMENT_FILE, ActionTypes.success),
		})
	),

	on(PaymentFileActions.processPaymentFileFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, PaymentFileActionTypes.PROCESS_PAYMENT_FILE, ActionTypes.failure, error),
	}))
);
