export class AppDate {
	static readonly DAY_FORMAT = 'EEEE';
	static readonly TIME_FORMAT = 'HH:mm';
	static readonly DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

	public static getDateFormat(locale: string): string {
		switch (locale) {
			case 'hu-HU':
				return 'YYYY.MM.DD.';
			case 'en-UK':
				return 'DD/MM/YYYY';
			case 'it-CH':
			case 'de-CH':
			case 'fr-CH':
				return 'DD.MM.YYYY';
			case 'de-AT':
			case 'de-DE':
				return 'DD.MM.YYYY';
			// add other locales as needed
			default:
				// nl-BE, fr-BE, fr-FR
				return AppDate.DEFAULT_DATE_FORMAT;
		}
	}

	public static getTimeFormat(region: string): string {
		switch (region) {
			case 'HU':
				return 'HH:mm:ss';
			case 'UK':
			case 'CH':
			case 'AT':
			case 'DE':
      case 'FR':
			default:
				return AppDate.TIME_FORMAT;
		}
	}
}
