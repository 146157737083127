import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { asyncScheduler, combineLatest, forkJoin, Observable, of, takeUntil, timer } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Environment, ENVIRONMENT } from '@oper-client/shared/configuration';
import { getResources } from '@oper-client/shared/resources/data-access-resource';
import { CustomHttpParameterCodec } from '@oper-client/shared/util-custom-http-parameter-codec';
import * as LoanRequestActions from './loan-request.actions';
import {
	LoanRequest,
	LoanRequestComment,
	LoanRequestDecision,
	LoanRequestDecisionStatistics,
	LoanRequestHistory,
	PreapprovalResponse,
	Resource,
} from '@oper-client/shared/data-model';
import { ILoanRequestService, LOAN_REQUEST_SERVICE } from '@oper-client/shared/data-access';
import * as AppActions from '@oper-client/shared/app/data-access-app';

const REQUEST_DELAY = 400;

@Injectable()
export class LoanRequestEffects {
	loadLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.loadLoanRequest),
			mergeMap(({ loanRequestId }) =>
				this.loanRequestService.getLoanRequest(loanRequestId).pipe(
					map((loanRequest: any) => LoanRequestActions.loadLoanRequestSuccess({ loanRequest: loanRequest })),
					catchError((error) => of(LoanRequestActions.loadLoanRequestFailure({ error: error })))
				)
			)
		)
	);

	loadLoanRequests$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.loadLoanRequests),
					map((_) => _.parameters),
					mergeMap(({ search, filters, page_size }) => {
						let params = new HttpParams({ encoder: new CustomHttpParameterCodec() })
							.set('serializer', 'overview')
							.set('page_size', page_size ? Number(page_size).toString() : '15');
						if (search) {
							params = params.set('search', search);
						}
						if (filters) {
							Object.keys(filters).forEach((key) => (params = params.set(key, filters[key])));
						}
						return combineLatest([
							timer(requestDelay, scheduler),
							this.loanRequestService.getLoanRequests(params).pipe(
								map((response) =>
									LoanRequestActions.loadLoanRequestsSuccess({
										loanRequestTotalCount: response.count,
										loanRequests: response.results,
									})
								),
								catchError((error) => of(LoanRequestActions.loadLoanRequestsFailure({ error: error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	loadLoanRequestsPerStatus$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.loadLoanRequestsPerStatus),
					mergeMap((action) => {
						const { search, filters, page_size } = action.parameters;
						const statuses = action.statuses;

						let params = new HttpParams({ encoder: new CustomHttpParameterCodec() })
							.set('serializer', 'overview')
							.set('page_size', page_size ? Number(page_size).toString() : '15');
						if (search) {
							params = params.set('search', search);
						}
						if (filters) {
							Object.keys(filters)
								.filter((key) => key !== 'status')
								.forEach((key) => (params = params.set(key, filters[key])));
						}
						const requests = statuses.map((status) =>
							this.loanRequestService
								.getLoanRequests(params.set('status', status))
								.pipe(map((x) => ({ loanRequestTotalCount: x.count, loanRequests: x.results, status })))
						);
						return combineLatest([
							timer(requestDelay, scheduler),

							forkJoin(requests).pipe(
								map((response) => LoanRequestActions.loadLoanRequestsPerStatusSuccess({ loanRequestsPerStatus: response })),
								catchError((error) => of(LoanRequestActions.loadLoanRequestsPerStatusFailure({ error: error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	loadLoanRequestsTotalAmount$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.loadLoanRequests),
					filter((_) => _.loadTotalAmount),
					map((_) => _.parameters),
					mergeMap(({ search, filters }) => {
						let params = new HttpParams({ encoder: new CustomHttpParameterCodec() });
						if (filters) {
							Object.keys(filters).forEach((key) => (params = params.set(key, filters[key])));
						}
						if (search) {
							params = params.set('search', search);
						}
						return combineLatest([
							timer(requestDelay, scheduler),
							this.loanRequestService.getLoanRequestsTotalAmount(params).pipe(
								map((response) =>
									LoanRequestActions.loadLoanRequestsTotalAmountSuccess({
										loanRequestTotalAmount: response,
									})
								),
								catchError((error) => of(LoanRequestActions.loadLoanRequestsTotalAmountFailed({ error: error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	loadLoanRequestsPerStatusTotalAmount$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.loadLoanRequestsTotalAmountPerStatus),
					filter((_) => _.loadTotalAmount),
					mergeMap((action) => {
						const { search, filters } = action.parameters;
						const statuses = action.statuses;
						let params = new HttpParams({ encoder: new CustomHttpParameterCodec() });
						if (filters) {
							Object.keys(filters)
								.filter((key) => key !== 'status')
								.forEach((key) => (params = params.set(key, filters[key])));
						}
						if (search) {
							params = params.set('search', search);
						}
						const requests = statuses.map((status) =>
							this.loanRequestService
								.getLoanRequestsTotalAmount(params.set('status', status))
								.pipe(map((response) => ({ [status]: response })))
						);

						return combineLatest([
							timer(requestDelay, scheduler),
							forkJoin(requests).pipe(
								map((response) =>
									LoanRequestActions.loadLoanRequestsTotalAmountPerStatusSuccess({
										loanRequestsTotalAmountPerStatus: response,
									})
								),
								catchError((error) => of(LoanRequestActions.loadLoanRequestsTotalAmountPerStatusFailure({ error: error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	loadMoreLoanRequests$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.loadMoreLoanRequests),
					mergeMap(({ search, filters, page }) => {
						let params = new HttpParams({ encoder: new CustomHttpParameterCodec() })
							.set('serializer', 'overview')
							.set('page_size', '15')
							.set('page', page);
						if (search) {
							params = params.set('search', search);
						}
						let loanRequestCountPerStatus = null;
						if (filters) {
							const keys: string[] = Object.keys(filters);
							keys.forEach((key) => (params = params.set(key, filters[key])));
							if (keys.length === 1 && filters['status']) {
								loanRequestCountPerStatus = { [filters['status']]: 0 };
							}
						}
						return combineLatest([
							timer(requestDelay, scheduler),
							this.loanRequestService.getLoanRequests(params).pipe(
								map((response) => {
									if (loanRequestCountPerStatus) {
										loanRequestCountPerStatus[filters['status']] = response.count;
									}
									return LoanRequestActions.loadMoreLoanRequestsSuccess({
										loanRequests: response.results,
										loanRequestCountPerStatus: loanRequestCountPerStatus ?? {},
									});
								}),
								catchError((error) => of(LoanRequestActions.loadMoreLoanRequestsFailure({ error: error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	loadLoanRequestStatus$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.loadLoanRequestStatuses),
					withLatestFrom(this.store.pipe(select(getResources))),
					mergeMap(([{ loanRequestId }, resourceCollection]) => {
						return combineLatest([
							timer(requestDelay, scheduler),
							this.loanRequestService.getLoanRequestStatuses(loanRequestId).pipe(
								map((loanRequestStatuses: Resource[]) => {
									loanRequestStatuses = loanRequestStatuses.map((status) => {
										const tempResource = resourceCollection.status.find((r) => r.definition === status.definition);
										return { ...status, key: tempResource.key };
									});
									return LoanRequestActions.loadLoanRequestStatusesSuccess({ loanRequestStatuses });
								}),
								catchError((error) => of(LoanRequestActions.loadLoanRequestStatusesFailure({ error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	resetLoanRequestStatus$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.resetLoanRequestStatus),
					mergeMap(({ loanRequestId, loanRequestUpdate }) => {
						return combineLatest([
							timer(requestDelay, scheduler),
							this.loanRequestService.resetLoanRequestStatus(loanRequestId, loanRequestUpdate).pipe(
								map((loanRequest: LoanRequest) => {
									return LoanRequestActions.loadLoanRequestSuccess({
										loanRequest: loanRequest,
									});
								}),
								catchError((error) => of(LoanRequestActions.loadLoanRequestFailure({ error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	resetCurrentLoanRequestId$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.resetCurrentLoanRequestId),
			map(() => LoanRequestActions.resetCurrentLoanRequestStatusId())
		)
	);

	resetCurrentLoanRequestStatusId = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.resetCurrentLoanRequestStatusId),
			map(() => LoanRequestActions.resetLoanRequestPreapproval())
		)
	);

	createLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.createLoanRequest),
			concatMap(({ loanRequest }) =>
				this.loanRequestService.createLoanRequest(loanRequest).pipe(
					map((createdLoanRequest: LoanRequest) =>
						LoanRequestActions.createLoanRequestSuccess({
							loanRequest: createdLoanRequest,
						})
					),
					catchError((error) => of(LoanRequestActions.createLoanRequestFailure({ error: error })))
				)
			)
		)
	);

	updateLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.updateLoanRequest),
			concatMap(({ loanRequest }) =>
				this.loanRequestService.updateLoanRequest(+loanRequest.id, loanRequest.changes).pipe(
					map((updatedLoanRequest: LoanRequest) =>
						LoanRequestActions.updateLoanRequestSuccess({
							loanRequest: {
								id: updatedLoanRequest.id,
								changes: updatedLoanRequest,
							},
						})
					),
					catchError((error) => of(LoanRequestActions.updateLoanRequestFailure({ error: error })))
				)
			)
		)
	);

	updateLoanRequestStatus$ = createEffect(
		() =>
			({ requestDelay = REQUEST_DELAY, scheduler = asyncScheduler } = {}): Observable<Action> =>
				this.actions$.pipe(
					ofType(LoanRequestActions.updateLoanRequestStatus),
					mergeMap(({ loanRequest }) => {
						return combineLatest([
							timer(requestDelay, scheduler),
							this.loanRequestService.updateLoanRequestStatus(+loanRequest.id, loanRequest.changes).pipe(
								map((updatedLoanRequest: LoanRequest) => {
									return LoanRequestActions.updateLoanRequestStatusSuccess({
										loanRequest: {
											id: +loanRequest.id,
											changes: updatedLoanRequest,
										},
									});
								}),
								catchError((error) => of(LoanRequestActions.updateLoanRequestStatusFailure({ error })))
							),
						]).pipe(map((result) => result[1]));
					})
				)
	);

	assignAnalystToLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.assignAnalystToLoanRequest),
			switchMap(({ analystId, loanRequestId }) =>
				this.loanRequestService.assignAnalystToLoanRequest(loanRequestId, analystId).pipe(
					map((loanRequest: LoanRequest) =>
						LoanRequestActions.assignAnalystToLoanRequestSuccess({
							loanRequest: loanRequest,
						})
					),
					catchError((error) => of(LoanRequestActions.assignAnalystToLoanRequestFailure({ error: error })))
				)
			)
		)
	);

	assignBrokerToLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.assignBrokerToLoanRequest),
			switchMap(({ brokerId, loanRequestId }) =>
				this.loanRequestService.assignBrokerToLoanRequest(loanRequestId, brokerId).pipe(
					map((loanRequest: LoanRequest) =>
						LoanRequestActions.assignBrokerToLoanRequestSuccess({
							loanRequest: loanRequest,
						})
					),
					catchError((error) => of(LoanRequestActions.assignAnalystToLoanRequestFailure({ error: error })))
				)
			)
		)
	);

	updateAcquisitionSource$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.updateAcquisitionSourceOfLoanRequest),
			switchMap(({ acquisitionSourceId, loanRequestId }) =>
				this.loanRequestService.updateAcquisitionSource(loanRequestId, acquisitionSourceId).pipe(
					map((loanRequest: LoanRequest) =>
						LoanRequestActions.updateAcquisitionSourceOfLoanRequestSuccess({
							loanRequest: loanRequest,
						})
					),
					catchError((error) => of(LoanRequestActions.updateAcquisitionSourceOfLoanRequestFailure({ error: error })))
				)
			)
		)
	);

	createDecision$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.createDecision),
			switchMap(({ decision, loanRequestId }) =>
				this.loanRequestService.createDecision(loanRequestId, decision).pipe(
					map((loanRequestDecision: LoanRequestDecision) =>
						LoanRequestActions.createDecisionSuccess({ decision: loanRequestDecision })
					),
					catchError((error) => of(LoanRequestActions.createDecisionFailure({ error })))
				)
			)
		)
	);

	createComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.createComment),
			switchMap(({ comment, loanRequestId }) =>
				this.loanRequestService.createComment(loanRequestId, comment).pipe(
					map((loanRequestComment: LoanRequestComment) =>
						LoanRequestActions.createCommentSuccess({ comment: loanRequestComment })
					),
					catchError((error) => of(LoanRequestActions.createCommentFailure({ error })))
				)
			)
		)
	);

	loadDecisions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.loadDecisions),
			mergeMap(({ loanRequestId }) =>
				this.loanRequestService.getDecisions(loanRequestId).pipe(
					map((decisions: LoanRequestDecision[]) =>
						LoanRequestActions.loadDecisionsSuccess({
							decisions: decisions,
						})
					),
					catchError((error) => of(LoanRequestActions.loadDecisionsFailure({ error: error })))
				)
			)
		)
	);

	loadDecisionsStatistics$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.loadDecisionsStatistics),
			mergeMap(({ loanRequestId }) =>
				this.loanRequestService.getDecisionsStatistics(loanRequestId).pipe(
					map((decisionsStatistics: LoanRequestDecisionStatistics) =>
						LoanRequestActions.loadDecisionsStatisticsSuccess({
							decisionsStatistics,
						})
					),
					takeUntil(this.actions$.pipe(ofType(AppActions.cancelPendingHttpRequests))),
					catchError((error) => of(LoanRequestActions.loadDecisionsStatisticsFailure({ error: error })))
				)
			)
		)
	);

	loadComments$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.loadComments),
			mergeMap(({ loanRequestId }) =>
				this.loanRequestService.getComments(loanRequestId).pipe(
					map((comments: LoanRequestComment[]) =>
						LoanRequestActions.loadCommentsSuccess({
							comments: comments,
						})
					),
					catchError((error) => of(LoanRequestActions.loadCommentsFailure({ error: error })))
				)
			)
		)
	);

	loadHistories$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.loadHistories),
			mergeMap(({ loanRequestId, params }) =>
				this.loanRequestService.getHistories(loanRequestId, params).pipe(
					map((histories: LoanRequestHistory[]) =>
						LoanRequestActions.loadHistoriesSuccess({
							histories: histories,
						})
					),
					catchError((error) => of(LoanRequestActions.loadHistoriesFailure({ error: error })))
				)
			)
		)
	);

	getPreapproval$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.getPreapproval),
			mergeMap(({ loanRequestId, borrowerMode }) =>
				this.loanRequestService.getPreapproval(loanRequestId, borrowerMode).pipe(
					map((preapproval: PreapprovalResponse) =>
						LoanRequestActions.getPreapprovalSuccess({
							preapproval,
						})
					),
					takeUntil(this.actions$.pipe(ofType(AppActions.cancelPendingHttpRequests))),
					catchError((error) => of(LoanRequestActions.getPreapprovalFailure({ error: error })))
				)
			)
		)
	);

	loadPreapproval$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.loadPreapproval),
			mergeMap(({ loanRequestId }) =>
				this.loanRequestService.loadPreapproval(loanRequestId).pipe(
					map((preapproval: PreapprovalResponse) =>
						LoanRequestActions.loadPreapprovalSuccess({
							preapproval,
						})
					),
					takeUntil(this.actions$.pipe(ofType(AppActions.cancelPendingHttpRequests))),
					catchError((error) => of(LoanRequestActions.loadPreapprovalFailure({ error: error })))
				)
			)
		)
	);

	toggleLoanRequestCancellation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanRequestActions.toggleLoanRequestCancellation),
			switchMap(({ loanRequestId }) =>
				this.loanRequestService.toggleLoanRequestCancellation(loanRequestId).pipe(
					map((loanRequest: LoanRequest) =>
						LoanRequestActions.toggleLoanRequestCancellationSuccess({
							loanRequest: loanRequest,
						})
					),
					catchError((error) => of(LoanRequestActions.toggleLoanRequestCancellationFailure({ error: error })))
				)
			)
		)
	);

	constructor(
		@Inject(ENVIRONMENT) private env: Environment,
		private actions$: Actions,
		private store: Store,
		@Inject(LOAN_REQUEST_SERVICE) private loanRequestService: ILoanRequestService
	) {}
}
