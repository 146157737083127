import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { Validators } from '@angular/forms';

export default function (formData?: any): InputBase<any>[] {
	const emailDisabled = !!formData?.emailDisabled;
	return [
		new InputField({
			key: 'email',
			label: 'ç.question.username.label',
			value: formData?.email,
			type: 'email',
			required: true,
			class: 'span12',
			disabled: emailDisabled,
			validators: [Validators.email],
		}),
		new InputField({
			key: 'password',
			label: 'ç.question.password.label',
			value: formData?.password,
			type: 'password',
			required: true,
			class: 'span12',
			showPasswordStrength: true,
		}),
	];
}
