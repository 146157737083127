import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, LoanRequestSubentityService } from '@oper-client/shared/data-access';
import { CalculateAmortization, Client, Liability, LiabilityConsultation } from '@oper-client/shared/data-model';

@Injectable()
export class LiabilityService implements LoanRequestSubentityService<Liability> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	getAll(loanRequestId: number, clientId: number, params: HttpParams = new HttpParams()): Observable<Liability[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/clients/${clientId}/liabilities/`, params);
	}

	get(loanRequestId: number, clientId: number, liabilityId: number, params: HttpParams = new HttpParams()): Observable<Liability> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/clients/${clientId}/liabilities/${liabilityId}/`, params);
	}

	create(
		loanRequestId: number,
		clientId: number,
		liability: Partial<Liability>,
		params: HttpParams = new HttpParams()
	): Observable<Liability> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/clients/${clientId}/liabilities/`, liability, params);
	}

	update(
		loanRequestId: number,
		clientId: number,
		liabilityId: number,
		liability: Partial<Liability>,
		params: HttpParams = new HttpParams()
	): Observable<Liability> {
		return this.apiService.patch(
			`/api/loan-requests/${loanRequestId}/clients/${clientId}/liabilities/${liabilityId}/`,
			liability,
			params
		);
	}

	delete(loanRequestId: number, clientId: number, liabilityId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/clients/${clientId}/liabilities/${liabilityId}/`);
	}

	getConsultations(loanRequestId: number, clientId: number): Observable<LiabilityConsultation[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/consultations?client_id=${clientId}&only_last=true`);
	}

	triggerConsultations(loanRequestId: number, client: Client): Observable<LiabilityConsultation[]> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/consultations/`, { client });
	}

	calculateAmortization(calculateAmortization: CalculateAmortization) {
		return this.apiService.post(`/api/calculate/credit/`, calculateAmortization);
	}
}
