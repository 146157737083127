import { inject, Injectable } from '@angular/core';
import { APP_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	private applicationInsights = inject(APP_INSIGHTS_CONFIG);
	private readonly customer: string;
	private readonly customerSeparator: string = '-';

	constructor() {
		this.customer = this.setCustomer();
	}

	getCustomer(): string {
		return this.customer;
	}

	private setCustomer(): string | null {
		const name = this.applicationInsights?.name;
		return !name.includes(this.customerSeparator) || name.split(this.customerSeparator).length === 0
			? null
			: name.split(this.customerSeparator)[0];
	}
}
