import { INCOME_ENTITY_KEY, IncomeState } from './income/income.reducer';
import { CLIENT_ENTITY_KEY, ClientState } from './client/client.reducer';
import { LIABILITIES_ENTITY_KEY, LiabilityState } from './liability/liability.reducer';
import { FINANCIAL_ASSETS_ENTITY_KEY, FinancialAssetState } from './financial-asset/financial-asset.reducer';

export const CLIENT_FEATURE_KEY = 'clients';

export interface ClientEntityState {
	readonly [CLIENT_ENTITY_KEY]: ClientState;
	readonly [INCOME_ENTITY_KEY]: IncomeState;
	readonly [LIABILITIES_ENTITY_KEY]: LiabilityState;
	readonly [FINANCIAL_ASSETS_ENTITY_KEY]: FinancialAssetState;
}

export interface ClientPartialFeatureState {
	readonly [CLIENT_FEATURE_KEY]: ClientEntityState;
}
