import { createReducer, on } from '@ngrx/store';

import { DeviceInfo } from '@oper-client/shared/data-model';
import * as AppActions from './app.actions';

export const APP_KEY = 'app';

export interface AppState {
	visible: boolean;
	online: boolean;
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	deviceType: DeviceInfo;
	newDeploymentDetected: boolean;
}

export const initialState: AppState = {
	visible: true,
	online: true,
	newDeploymentDetected: false,
	isMobile: null,
	isTablet: null,
	isDesktop: null,
	deviceType: null,
};

const deviceInitialState = {
	isMobile: null,
	isTablet: null,
	isDesktop: null,
};

export const reducer = createReducer(
	initialState,

	on(AppActions.setAppVisible, (state) => {
		return {
			...state,
			visible: true,
		};
	}),

	on(AppActions.setAppHidden, (state) => {
		return {
			...state,
			visible: false,
		};
	}),

	on(AppActions.setAppOnline, (state) => {
		return {
			...state,
			online: true,
		};
	}),

	on(AppActions.setAppOffline, (state) => {
		return {
			...state,
			online: false,
		};
	}),

	on(AppActions.setDeviceAsMobile, (state) => {
		return {
			...state,
			...deviceInitialState,
			isMobile: true,
		};
	}),

	on(AppActions.setDeviceAsTablet, (state) => {
		return {
			...state,
			...deviceInitialState,
			isTablet: true,
		};
	}),

	on(AppActions.setDeviceAsDesktop, (state) => {
		return {
			...state,
			...deviceInitialState,
			isDesktop: true,
		};
	}),

	on(AppActions.setDeviceInfo, (state, { deviceInfo }) => {
		return {
			...state,
			deviceInfo,
		};
	}),

	on(AppActions.newDeploymentDetected, (state) => {
		return {
			...state,
			newDeploymentDetected: true,
		};
	})
);
