import { of } from 'rxjs';
import { InputField } from '../../../models/input-types.model';
import { InputBase } from '../../../models/input-base.model';

export default function (): InputBase<any>[] {
	return [
		new InputField({
			key: `csokMortgageLoan`,
			label: `ç.calculator.question.csokMortgageLoan.label`,
			helpText: of(`ç.calculator.question.csokMortgageLoan.helpText`),
			disabled: true,
			value: this.result,
			type: 'number',
			currency: true,
			validators: [],
			required: false,
			class: 'span12',
		}),
	];
}
