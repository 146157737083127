import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { ValidatorService } from '../../lib/services/validator.service';
import { DATE_RESTRICTION } from '../../lib/services/validator-constants';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';

import { InputRadio, InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minYear: number = ValidatorService.getDateByYearOffset(50).getTime();
	const maxYear: number = ValidatorService.getDateByYearOffset(0).getTime();

	const minEndDate: number = ValidatorService.getDateByYearOffset(0).getTime();
	const maxEndDate: number = ValidatorService.getDateByYearOffset(-50).getTime();

	function toggleRequiredValdiator(refinance: boolean, formGroupControl: AbstractControl) {
		if (refinance) {
			formGroupControl.addValidators(Validators.required);
			return;
		}
		formGroupControl.removeValidators(Validators.required);
	}

	const hidden =
		(controlKey: string) =>
		(formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
			const subject = new BehaviorSubject(!formGroup.value['refinance']);

			formGroup.controls['refinance'].valueChanges
				.pipe(
					tap((refinance) => toggleRequiredValdiator(!!refinance, formGroup.controls[controlKey])),
					map((refinance) => !refinance),
					takeUntil(destroy$)
				)
				.subscribe({
					complete: () => subject.complete(),
					error: (error) => subject.error(error),
					next: (value) => subject.next(value),
				});

			return subject.asObservable();
		};

	const refinance = !!formData.purposes?.find((x) => x.definition === 'refinance');
	return [
		new InputRadio({
			key: 'refinance',
			label: '',
			helpText: of('ç.question.homeType.helpText'),
			value: refinance,
			required: true,
			options: [
				{ id: false, key: 'ç.question.applyForNewMortgage.label' },
				{ id: true, key: 'ç.question.applyForExistingMortgage.label' },
			],
			transform: (x) => x === 'true',
		}),
		new InputField({
			key: 'startDate',
			type: 'date',
			label: 'ç.question.originalMortgageDate.label',
			value: formData?.startDate,
			hidden: hidden('startDate'),
			required: true,
			validators: [
				ValidatorService.getDateRestrictionValidatior('past', maxYear, DATE_RESTRICTION.FUTURE),
				ValidatorService.getDateRestrictionValidatior('pastXYears', minYear, DATE_RESTRICTION.PAST, null, () => ({
					years: 50,
				})),
			],
			min: minYear,
			max: maxYear,
		}),
		new InputField({
			key: 'endDate',
			type: 'date',
			label: 'ç.question.newMortgageDate.label',
			value: formData.endDate,
			hidden: hidden('endDate'),
			required: true,
			validators: [
				ValidatorService.getDateRestrictionValidatior('future', minEndDate, DATE_RESTRICTION.PAST),
				ValidatorService.getDateRestrictionValidatior('futureXYears', maxEndDate, DATE_RESTRICTION.FUTURE, null, () => ({
					years: 50,
				})),
			],
			min: minEndDate,
			max: maxEndDate,
		}),
		new InputField({
			key: 'balance',
			label: 'ç.question.newLoanAmount.label',
			helpText: of(null),
			value: formData.balance,
			type: 'number',
			hidden: hidden('balance'),
			currency: true,
			required: true,
		}),
		new InputRadio({
			key: 'isMax2Tranches18MonthsApart',
			label: 'ç.question.onlyOneTranche.label',
			questionClass: 'multiline-label',
			value: formData.isMax2Tranches18MonthsApart,
			required: false,
			hidden: hidden('isMax2Tranches18MonthsApart'),
			options: [
				{ id: false, key: 'ç.misc.no' },
				{ id: true, key: 'ç.misc.yes' },
			],
			transform: (x) => x === 'true',
		}),
	];
}
