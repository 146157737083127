import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, IContactFormService } from '@oper-client/shared/data-access';

@Injectable()
export class ContactFormService implements IContactFormService{
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	send(contactFormPayload: any): Observable<any> {
		return this.apiService.post(`/api/contact-advisor/`, contactFormPayload);
	}
}
