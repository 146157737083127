import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CommissionFile } from '@oper-client/shared/data-model';

// Load CommissionFiles
export const loadCommissionFiles = createAction('[CommissionFile] Load CommissionFiles');
export const loadCommissionFilesSuccess = createAction(
	'[CommissionFile] Load CommissionFiles Success',
	props<{ commissionFiles: CommissionFile[] }>()
);
export const loadCommissionFilesFailure = createAction(
	'[CommissionFile] Load CommissionFiles Failure',
	props<{ error: HttpErrorResponse }>()
);

// Load CommissionFile
export const loadCommissionFile = createAction('[CommissionFile] Load CommissionFile', props<{ commissionFileId: number }>());
export const loadCommissionFileSuccess = createAction(
	'[CommissionFile] Load CommissionFile Success',
	props<{ commissionFile: CommissionFile }>()
);
export const loadCommissionFileFailure = createAction(
	'[CommissionFile] Load CommissionFile Failure',
	props<{ error: HttpErrorResponse }>()
);

// Recover CommissionFile
export const recoverCommissionFile = createAction('[CommissionFile] Recover CommissionFile', props<{ commissionFileId: number }>());
export const recoverCommissionFileSuccess = createAction(
	'[CommissionFile] Recover CommissionFile Success',
	props<{ commissionFile: CommissionFile }>()
);
export const recoverCommissionFileFailure = createAction(
	'[CommissionFile] Recover CommissionFile Failure',
	props<{ error: HttpErrorResponse }>()
);

// Create CommissionFiles
export const createCommissionFile = createAction(
	'[CommissionFile] Create CommissionFile',
	props<{ commissionFile: Partial<CommissionFile> }>()
);
export const createCommissionFileSuccess = createAction(
	'[CommissionFile] Create CommissionFile Success',
	props<{ commissionFile: CommissionFile }>()
);
export const createCommissionFileFailure = createAction(
	'[CommissionFile] Create CommissionFiles Failure',
	props<{ error: HttpErrorResponse }>()
);
