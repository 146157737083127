import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import {
	AcceptanceRule,
	Product,
	ProductAllocationItem,
	SelectedBaseProductDiscounts,
	TweakedProductItemsResponse,
} from '@oper-client/shared/data-model';
import { LoanRequestEntityService } from './loan-request-entity.service';

export const PRODUCT_SERVICE = new InjectionToken<IProductService>('PRODUCT_SERVICE');

export interface IProductService extends LoanRequestEntityService<Product> {
	getAll(loanRequestId: number, params?: { [key: string]: string }, body?: object): Observable<any>;

	getProductVersions(): Observable<{ products: Product[] }[]>;

	getProductsForOfferAllocation(
		loanRequestId: number,
		params: { items: ProductAllocationItem[]; discounts: SelectedBaseProductDiscounts[] }
	): Observable<TweakedProductItemsResponse>;

	getProductAcceptanceRules(loanRequestId: number, productId: number, params: { [key: string]: string }): Observable<AcceptanceRule[]>;
}
