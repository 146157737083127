export interface Hyperlink {
	link?: string | string[];
	text: string;
}

/**
 * Represents a special string used as delimiter in order to identify hyperlink text inside a string.
 */
export const HYPERLINK_TEXT_DELIMITER = '__';

/**
 * Function which is used to transform the text into list of {@link Hyperlink} objects
 * @param text - text containing the hyperlink phrases that are identified using {@link HYPERLINK_TEXT_DELIMITER}
 * @example
 * '__Sign in__ if you already have an account.'
 * 'If you do not have an account then please __sign up__'
 *
 * @param links - a list of links that is used to assign one link to every {@link Hyperlink} object from text in order.
 * These links could be passed into angular ___RouterLink___ directive.
 * @example
 * ['auth/login', ['../', id]]
 *
 * @returns List of {@link Hyperlink} objects
 */
export function transformTextToHyperlinks(text: string, links: (string[] | string)[]): Hyperlink[] {
	const hyperlinks: Hyperlink[] = [];
	const linksCopy = [...links];
	let hyperlinkFlag = false;
	let word = '';
	for (let i = 0; i < text.length; i++) {
		const character = text[i];

		if ((character === ' ' || i === text.length - 1) && !hyperlinkFlag) {
			hyperlinks.push({ text: word + character });
			word = '';
			continue;
		}

		word += character;
		if (word === HYPERLINK_TEXT_DELIMITER && !hyperlinkFlag) {
			hyperlinkFlag = true;
			word = '';
			continue;
		}

		if (word.includes(HYPERLINK_TEXT_DELIMITER) && hyperlinkFlag) {
			hyperlinkFlag = false;
			hyperlinks.push({ text: word.replace(HYPERLINK_TEXT_DELIMITER, ''), link: linksCopy?.shift() });
			word = '';
		}
	}

	return hyperlinks;
}
