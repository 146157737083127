import { CreditProvider, MortgageRank, PartialNormalizedResource } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	formData?: Partial<MortgageRank>,
	resources?: PartialNormalizedResource,
	liabilityProviders?: CreditProvider[],
	rankToSet?: number
): InputBase<any>[] {
	return [
		new InputField({
			key: 'id',
			value: formData?.id,
			type: 'hidden',
			required: false,
		}),
		new InputSelect({
			key: 'creditProvider.id',
			label: 'ç.question.creditProvider.label',
			required: true,
			options: liabilityProviders,
			class: 'span12',
			bindLabel: 'name',
			value: formData?.creditProvider?.id,
		}),
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			currency: true,
			class: 'span12',
			value: formData?.amount,
		}),
		new InputField({
			key: 'rank',
			value: formData?.rank || rankToSet,
			type: 'hidden',
			required: false,
		}),
	];
}
