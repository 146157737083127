import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromRealties from './+state/realty.reducer';
import { RealtiesEffects } from './+state/realty.effects';
import { RealtyFacade } from './+state/realty.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromRealties.REALTIES_FEATURE_KEY, fromRealties.reducer),
		EffectsModule.forFeature([RealtiesEffects]),
	],
	providers: [RealtyFacade],
})
export class DataAccessRealtyModule {}
