import { Income, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (income: Partial<Income>, resources: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			value: income?.amount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span12',
		}),
		new InputField({
			key: 'payer',
			label: 'ç.question.payer.label',
			value: income?.payer,
			required: true,
			type: 'text',
			class: 'span12',
		}),
	];
}
