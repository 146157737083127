import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {KcAuthGuard} from "@oper-client/shared/data-access";

const routes: Routes = [
	{ path: '', redirectTo: 'auth', pathMatch: 'full' },
	{
		path: 'auth',
		loadChildren: () => import('@oper-client/shared/iam/feature-iam').then((m) => m.FeatureIamModule)
	},
	{
		path: 'app',
		loadChildren: () => import('@oper-client/brokerage/feature-shell').then((m) => m.FeatureShellModule),
		canActivate: [KcAuthGuard],
		canActivateChild: [KcAuthGuard],
	},
	{
		path: '**',
		redirectTo: 'app',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule],
	providers: [],
})
export class KcSsoAppRoutingModule {}
