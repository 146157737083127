import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AI_VALIDATION_FEATURE_KEY, State, validationsAdapter } from './ai-validation.reducer';

const selectors = validationsAdapter.getSelectors();
export const getAiValidationState = createFeatureSelector<State>(AI_VALIDATION_FEATURE_KEY);

export const getFieldMapping = createSelector(getAiValidationState, (state) => state.config);

export const getValidationResults = createSelector(getAiValidationState, selectors.selectAll);
