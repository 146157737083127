import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@oper-client/shared/util-fontawesome';
import { IconDefinition } from '@oper-client/shared/util-fontawesome';
import { UiColor } from '@oper-client/shared/data-model';

@Component({
	selector: 'oper-client-fontawesome-icon',
	template: `
		<fa-icon
			*ngIf="iconDefinition"
			[class.not-found]="notFound"
			[class.centered]="centered"
			[icon]="iconDefinition"
			[size]="size"
			[fixedWidth]="fixedWidth"
			[classes]="classes"
			[spin]="spin"
		></fa-icon>
	`,
	styleUrls: ['./fontawesome-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontawesomeIconComponent {
	@Input() set icon(value: string | IconDefinition) {
		if (typeof value === 'undefined') {
			this.iconDefinition = null;
		} else if (typeof value === 'string') {
			if (!Icons[value]) {
				this.iconDefinition = Icons['faMinusCircle'];
				this.notFound = true;
			} else {
				this.iconDefinition = Icons[value];
			}
		} else {
			this.iconDefinition = value;
		}
	}
	@Input() size: SizeProp = 'sm';
	@Input() fixedWidth = true;
	@Input() classes: string[] = [];
	@Input() spin = false;
	@Input() color: UiColor = UiColor.DEFAULT;
	@Input() centered = false;

	@HostBinding('class') get className() {
		return `fontawesome-icon--${this.color}`;
	}

	iconDefinition: IconDefinition;
	notFound = false;
}
