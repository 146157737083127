import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { ActionState, ActionTypes, setActionState } from '@oper-client/shared/data-model';
import * as FeatureFlagActions from './feature-flag.actions';

export const featureFlagFeatureKey = 'featureFlag';

export type FeatureFlagActionTypes = 'loadFeatureFlags';
export type FeatureFlagActionsState = Record<FeatureFlagActionTypes, ActionState>;

export interface State {
	featureFlags: Record<string, boolean>;
	actions: FeatureFlagActionsState;
}

export const initialState: State = {
	featureFlags: null,
	actions: {
		loadFeatureFlags: null,
	},
};

function setActionStates(
	actionState: FeatureFlagActionsState,
	action: FeatureFlagActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): FeatureFlagActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,
	on(FeatureFlagActions.loadFeatureFlags, (state, action) => ({
		...state,
		actions: setActionStates(state.actions, 'loadFeatureFlags', ActionTypes.loading),
	})),
	on(FeatureFlagActions.loadFeatureFlagsSuccess, (state, { featureFlags }) => ({
		...state,
		featureFlags,
		actions: setActionStates(state.actions, 'loadFeatureFlags', ActionTypes.success),
	})),
	on(FeatureFlagActions.loadFeatureFlagsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadFeatureFlags', ActionTypes.failure),
	}))
);
