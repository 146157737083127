import { Inject, Injectable } from '@angular/core';
import { CORE_RESOURCES_VERSION } from '@oper-client/shared/configuration';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagApiService {
	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(CORE_RESOURCES_VERSION) private readonly coreResourcesVersion: string
	) {}

	loadFeatureFlags() {
		return this.apiService.get(`/resources/features/?cache_version=${this.coreResourcesVersion}`);
	}
}
