import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Document } from '@oper-client/shared/data-model';

export const loadCommissionStatementDocuments = createAction(
	'[Document] Load Commission Statement Documents',
	props<{ brokerId: number }>()
);
export const loadCommissionStatementDocumentsSuccess = createAction(
	'[Document] Load Commission Statement Documents Success',
	props<{ documents: Document[] }>()
);
export const loadCommissionStatementDocumentsFailure = createAction(
	'[Document] Load Commission Statement Documents Failure',
	props<{ error: HttpErrorResponse }>()
);
