import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslateContent } from '@oper-client/shared/data-model';
import { extractLocalDateAndTime } from '../datetime/util.datetime';

@Pipe({
	name: 'relativeDate',
	pure: false, // otherwise translation doesn't happen when switching language
})
export class RelativeDatePipe extends TranslatePipe implements PipeTransform {
	static convertToRelativeDate(value: Date): Array<TranslateContent> {
		const diffSeconds = (new Date().getTime() - value.getTime()) / 1000;
		const diffDays = Math.floor(diffSeconds / 86400);

		if (diffDays >= 7) {
			const localDatetime = extractLocalDateAndTime(value);
			return [{ plainText: `${localDatetime.date} ${localDatetime.time}` }];
		} else if (diffDays === 0) {
			if (diffSeconds < 60) {
				return [{ translateKey: 'ç.misc.relativeDate.now' }];
			} else if (diffSeconds < 120) {
				return [{ translateKey: 'ç.misc.relativeDate.minuteAgo.singular' }];
			} else if (diffSeconds < 3600) {
				return [{ plainText: Math.floor(diffSeconds / 60).toString() }, { translateKey: 'ç.misc.relativeDate.minuteAgo.plural' }];
			} else if (diffSeconds < 7200) {
				return [{ translateKey: 'ç.misc.relativeDate.hourAgo.singular' }];
			} else if (diffSeconds < 86400) {
				return [{ plainText: Math.floor(diffSeconds / 3600).toString() }, { translateKey: 'ç.misc.relativeDate.hourAgo.plural' }];
			}
		} else if (diffDays === 1) {
			return [{ translateKey: 'ç.misc.relativeDate.yesterday' }];
		} else if (diffDays < 7) {
			return [{ plainText: diffDays.toString() }, { translateKey: 'ç.misc.relativeDate.dayAgo.plural' }];
		}
	}

	transform(value: Date | string): string {
		if (typeof value === 'string') {
			value = new Date(value);
		}
		const contents = RelativeDatePipe.convertToRelativeDate(value);
		let result = '';
		contents.forEach((content, index) => {
			if (content.plainText) {
				result += content.plainText;
			} else if (content.translateKey) {
				result += super.transform(content.translateKey);
			}
			if (index < contents.length - 1) {
				result += ' ';
			}
		});
		return result;
	}
}
