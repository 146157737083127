import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	public set(key: string, value: string) {
		localStorage.setItem(key, value);
	}

	public get(key: string) {
		return localStorage.getItem(key);
	}

	public unset(key: string) {
		localStorage.removeItem(key);
	}

	public clear() {
		localStorage.clear();
	}
}
