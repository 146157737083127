import { of } from 'rxjs';
import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'generalCreditExpense',
			label: formData?.yearlyPeriodicity
				? 'ç.question.yearlyGeneralCreditExpense.label'
				: 'ç.question.monthlyGeneralCreditExpense.label',
			helpText: of(
				formData?.yearlyPeriodicity
					? 'ç.question.yearlyGeneralCreditExpense.helpText'
					: 'ç.question.monthlyGeneralCreditExpense.helpText'
			),
			value: formData?.generalCreditExpense,
			type: 'number',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'additionalLiabilities',
			label: formData?.yearlyPeriodicity
				? 'ç.question.yearlyAdditionalLiabilities.label'
				: 'ç.question.monthlyAdditionalLiabilities.label',
			helpText: of(
				formData?.yearlyPeriodicity
					? 'ç.question.yearlyAdditionalLiabilities.helpText'
					: 'ç.question.monthlyAdditionalLiabilities.helpText'
			),
			value: formData?.additionalLiabilities,
			type: 'number',
			currency: true,
			required: true,
		}),
	];
}
