import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, combineReducers } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DataAccessLoanRequestModule } from '@oper-client/shared/loan-request/data-access-loan-request';

import * as fromClientFeature from './+state/client.reducer';
import { ClientFacade } from './+state/client/client.facade';
import { LiabilityFacade } from './+state/liability/liability.facade';
import { IncomeFacade } from './+state/income/income.facade';

import * as fromClientEntity from './+state/client/client.reducer';
import { ClientEffects } from './+state/client/client.effects';
import * as fromIncomeEntity from './+state/income/income.reducer';
import { IncomeEffects } from './+state/income/income.effects';
import * as fromLiabilityEntity from './+state/liability/liability.reducer';
import * as fromFinancialAssetEntity from './+state/financial-asset/financial-asset.reducer';
import { LiabilityEffects } from './+state/liability/liability.effects';
import { FinancialAssetEffects } from './+state/financial-asset/financial-asset.effects';
import { FinancialAssetFacade } from './+state/financial-asset/financial-asset.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromClientFeature.CLIENT_FEATURE_KEY,
			combineReducers({
				[fromClientEntity.CLIENT_ENTITY_KEY]: fromClientEntity.reducer,
				[fromIncomeEntity.INCOME_ENTITY_KEY]: fromIncomeEntity.reducer,
				[fromLiabilityEntity.LIABILITIES_ENTITY_KEY]: fromLiabilityEntity.reducer,
				[fromFinancialAssetEntity.FINANCIAL_ASSETS_ENTITY_KEY]: fromFinancialAssetEntity.reducer,
			})
		),
		EffectsModule.forFeature([ClientEffects, IncomeEffects, LiabilityEffects, FinancialAssetEffects]),
		DataAccessLoanRequestModule,
	],
	providers: [ClientFacade, LiabilityFacade, IncomeFacade, FinancialAssetFacade],
})
export class DataAccessClientModule {}
