import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { User } from '@oper-client/shared/data-model';
import * as UserActions from './user.actions';
import { IUserService, USER_SERVICE } from '@oper-client/shared/data-access';

@Injectable()
export class UserEffects {
	loadUsers$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(UserActions.loadUsers),
			switchMap(() =>
				this.userService.getUsers().pipe(
					map((users: User[]) => UserActions.loadUsersSuccess({ users: users })),
					catchError(error => of(UserActions.loadUsersFailure({ error: error })))
				)
			)
		);
	});

	constructor(private actions$: Actions, @Inject(USER_SERVICE) private userService: IUserService) {}
}
