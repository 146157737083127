import { PartialNormalizedResource, Realty, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { COMMON_REGEX_EXPRESSIONS } from '../../services/validator-constants';
import { Validators } from '@angular/forms';

export default function (formData?: Partial<Realty>, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'address.street',
			label: 'ç.question.street.label',
			value: formData?.address?.street || '',
			type: 'text',
			required: false,
			class: 'span12',
			updateValidityOnFormValueChanges: true,
			order: 1,
		}),
		new InputField({
			key: 'address.houseNumber',
			label: 'ç.question.houseNumber.label',
			value: formData?.address?.houseNumber || '',
			type: 'text',
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.box',
			label: 'ç.question.box.label',
			value: formData?.address?.box || '',
			type: 'text',
			required: false,
			validators: [],
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.zipCode',
			label: 'ç.question.zipCode.label',
			value: formData?.address?.zipCode || '',
			type: 'text',
			required: false,
			validators: [
				ValidatorService.getTrimmedPatternValidator(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS, 'onlyNumbers'),
				Validators.maxLength(8),
			],
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.city',
			label: 'ç.question.city.label',
			value: formData?.address?.city || '',
			type: 'text',
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputSelect({
			key: 'address.country.id',
			label: 'ç.question.country.label',
			value: formData?.address?.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: false,
			updateValidityOnFormValueChanges: true,
			class: 'span6',
		}),
		new InputField({
			key: 'address.mapConfig',
			value: formData?.address?.mapConfig || null,
			required: false,
			type: 'hidden',
		}),
	];
}
