import { Client, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../../services/validator-constants';

export default function (formData?: Partial<Client>, resources?: PartialNormalizedResource): InputBase<any>[] {
	const minBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MAX_AGE).getTime();
	const maxBirthDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.MIN_AGE).getTime();
	
	return [
		new InputSelect({
			key: 'title.id',
			label: 'ç.question.title.label',
			value: formData?.title?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.CLIENT_TITLE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'firstName',
			label: 'ç.question.firstName.label',
			value: formData?.firstName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'lastName',
			label: 'ç.question.lastName.label',
			value: formData?.lastName || '',
			type: 'text',
			required: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'role.id',
			label: 'ç.question.clientRole.label',
			value: formData?.role?.id,
			required: true,
			options: resources?.[ResourceType.CLIENT_ROLE] || [],
			class: 'span12',
		}),
		new InputSelect({
			key: 'language.id',
			label: 'ç.question.language.label',
			value: formData?.language?.id || '',
			options: resources?.[ResourceType.LANGUAGE],
			required: true,
			class: 'span12',
		}),
		new InputSelect({
			key: 'employmentStatus.id',
			label: 'ç.question.employmentStatus.label',
			value: formData?.employmentStatus?.id,
			required: false,
			validators: [],
			options: resources?.[ResourceType.EMPLOYMENT_TYPE] || [],
			class: 'span12',
		}),
		new InputField({
			key: 'birthDate',
			label: 'ç.question.birthDate.label',
			value: formData?.birthDate,
			type: 'date',
			required: false,
			validators: [
				ValidatorService.getDateRestrictionValidatior('ageRestrictionYounger', maxBirthDate, DATE_RESTRICTION.FUTURE),
				ValidatorService.getDateRestrictionValidatior('ageRestrictionOlder', minBirthDate, DATE_RESTRICTION.PAST),
			],
			min: minBirthDate,
			max: maxBirthDate,
			class: 'span12',
		}),
	];
}
