import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import {
	ActionState,
	initialActionState,
	setActionState,
	ActionTypes,
	ProductPricingDetails,
	BaseRate,
	ProductPricingSheet,
	AllowedProductVariability,
	ProductPricingSheetDiscounts,
} from '@oper-client/shared/data-model';
import * as ProductPricingActions from './product-pricing.actions';

export const PRODUCT_PRICING_FEATURE_KEY = 'productPricing';

export type ProductPricingDetailsActionTypes = 'loadProducts' | 'loadProductPricingDetails' | 'updateProductPricingDetails';
export type ProductPricingBaseRateActionTypes = 'loadBaseRates' | 'updateBaseRate' | 'updateBaseRates';
export type ProductPricingSheetDiscountsActionTypes = 'loadProductPricingSheetDiscounts' | 'updateProductPricingSheetDiscounts';
export type AllowedProductVariabilityActionTypes =
	| 'loadAllowedProductVariabilities'
	| 'loadAllowedProductVariability'
	| 'updateAllowedProductVariability';
export type ProductPricingSheetActionTypes =
	| 'loadProductPricingSheets'
	| 'loadProductPricingSheet'
	| 'createProductPricingSheet'
	| 'updateProductPricingSheet'
	| 'removeProductPricingSheet';

export type ProductPricingFeatueActionTypes =
	| ProductPricingDetailsActionTypes
	| ProductPricingBaseRateActionTypes
	| ProductPricingSheetActionTypes
	| ProductPricingSheetDiscountsActionTypes
	| AllowedProductVariabilityActionTypes;

export type ProductPricingActionsState = Record<ProductPricingDetailsActionTypes, ActionState>;
export type ProductPricingSheetActionsState = Record<ProductPricingSheetActionTypes, ActionState>;
export type ProductPricingSheetBaseRateActionsState = Record<ProductPricingBaseRateActionTypes, ActionState>;
export type AllowedProductVariabilityActionsState = Record<AllowedProductVariabilityActionTypes, ActionState>;
export type ProductPricingSheetDiscountsState = Record<ProductPricingSheetDiscountsActionTypes, ActionState>;

export interface ProductPricingDetailsEntityState extends EntityState<ProductPricingDetails> {
	currentProductPricingDetails: ProductPricingDetails | null;
	currentProductPricingDetailsId: number | null;
	actions: ProductPricingActionsState;
}

export interface ProductPricingSheetEntityState extends EntityState<ProductPricingSheet> {
	currentPricingSheetId: number | null;
	currentPricingSheet: ProductPricingSheet | null;
	actions: ProductPricingSheetActionsState;
}

export interface ProductPricingSheetBaseRateEntityState extends EntityState<BaseRate> {
	actions: ProductPricingSheetBaseRateActionsState;
}

export interface AllowedProductVariabilityEntityState extends EntityState<AllowedProductVariability> {
	actions: AllowedProductVariabilityActionsState;
}

export interface ProductPricingSheetDiscountsEntityState extends EntityState<ProductPricingSheetDiscounts> {
	actions: ProductPricingSheetDiscountsState;
}

export interface ProductPricingState {
	products: ProductPricingDetailsEntityState;
	pricingSheets: ProductPricingSheetEntityState;
	baseRates: ProductPricingSheetBaseRateEntityState;
	discounts: ProductPricingSheetDiscountsEntityState;
	allowedVariabilities: AllowedProductVariabilityEntityState;
}

export interface ProductPricingPartialState {
	readonly [PRODUCT_PRICING_FEATURE_KEY]: ProductPricingState;
}

export const productPricingDetailsAdapter = createEntityAdapter<ProductPricingDetails>({});
export const productPricingSheetsAdapter = createEntityAdapter<ProductPricingSheet>({});
export const pricingSheetBaseRatesAdapter = createEntityAdapter<BaseRate>({});
export const allowedProductVariabilitiesAdapter = createEntityAdapter<AllowedProductVariability>({});
export const productPricingSheetDiscountsAdapter = createEntityAdapter<ProductPricingSheetDiscounts>({});

export const productPricingDetailsInitialState: ProductPricingDetailsEntityState = productPricingDetailsAdapter.getInitialState({
	currentProductPricingDetails: null,
	currentProductPricingDetailsId: null,
	actions: {
		loadProductPricingDetails: initialActionState,
		loadProducts: initialActionState,
		updateProductPricingDetails: initialActionState,
	},
});

export const productPricingSheetsInitialState: ProductPricingSheetEntityState = productPricingSheetsAdapter.getInitialState({
	currentPricingSheet: null,
	currentPricingSheetId: null,
	actions: {
		createProductPricingSheet: initialActionState,
		loadProductPricingSheet: initialActionState,
		loadProductPricingSheets: initialActionState,
		updateProductPricingSheet: initialActionState,
		removeProductPricingSheet: initialActionState,
	},
});

export const productPricingSheetDiscountsInitialState: ProductPricingSheetDiscountsEntityState =
	productPricingSheetDiscountsAdapter.getInitialState({
		actions: {
			loadProductPricingSheetDiscounts: initialActionState,
			updateProductPricingSheetDiscounts: initialActionState,
		},
	});

export const productPricingSheetBaseRatesInitialState: ProductPricingSheetBaseRateEntityState =
	pricingSheetBaseRatesAdapter.getInitialState({
		actions: {
			loadBaseRates: initialActionState,
			updateBaseRate: initialActionState,
			updateBaseRates: initialActionState,
		},
	});

export const allowedProductVariabilitiesInitialState: AllowedProductVariabilityEntityState =
	allowedProductVariabilitiesAdapter.getInitialState({
		actions: {
			loadAllowedProductVariabilities: initialActionState,
			loadAllowedProductVariability: initialActionState,
			updateAllowedProductVariability: initialActionState,
		},
	});

export const initialState: ProductPricingState = {
	products: productPricingDetailsInitialState,
	pricingSheets: productPricingSheetsInitialState,
	baseRates: productPricingSheetBaseRatesInitialState,
	discounts: productPricingSheetDiscountsInitialState,
	allowedVariabilities: allowedProductVariabilitiesInitialState,
};

function setProductPricingDetailsActionStates(
	actionState: ProductPricingActionsState,
	action: ProductPricingDetailsActionTypes,
	actionType: ActionTypes,
	error?: HttpErrorResponse
): ProductPricingActionsState {
	return {
		...productPricingDetailsInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

function setProductPricingSheetActionStates(
	actionState: ProductPricingSheetActionsState,
	action: ProductPricingSheetActionTypes,
	actionType: ActionTypes,
	error?: HttpErrorResponse
): ProductPricingSheetActionsState {
	return {
		...productPricingSheetsInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

function setProductPricingSheetBaseRatesActionStates(
	actionState: ProductPricingSheetBaseRateActionsState,
	action: ProductPricingBaseRateActionTypes,
	actionType: ActionTypes,
	error?: HttpErrorResponse
): ProductPricingSheetBaseRateActionsState {
	return {
		...productPricingSheetBaseRatesInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

function setAllowedProductVariabilitiesActionStates(
	actionState: AllowedProductVariabilityActionsState,
	action: AllowedProductVariabilityActionTypes,
	actionType: ActionTypes,
	error?: HttpErrorResponse
): AllowedProductVariabilityActionsState {
	return {
		...allowedProductVariabilitiesInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

function setProductPricingSheetDiscountsActionStates(
	actionState: ProductPricingSheetDiscountsState,
	action: ProductPricingSheetDiscountsActionTypes,
	actionType: ActionTypes,
	error?: HttpErrorResponse
): ProductPricingSheetDiscountsState {
	return {
		...productPricingSheetDiscountsInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

const productPricingDetailsReducer = createReducer(
	productPricingDetailsInitialState,
	on(ProductPricingActions.resetProductPricingDetailsState, () => productPricingDetailsInitialState),
	on(ProductPricingActions.resetProductPricingDetailsStateActionState, (state) => ({
		...state,
		actions: productPricingDetailsInitialState.actions,
	})),
	on(ProductPricingActions.setCurrentProductPricingDetailsId, (state, { productId }) => ({
		...state,
		currentProductPricingDetailsId: productId,
	})),
	on(ProductPricingActions.setCurrentProductPricingDetails, (state, { pricingDetails }) => ({
		...state,
		currentProductPricingDetails: pricingDetails,
		currentProductPricingDetailsId: pricingDetails.id,
	})),
	on(ProductPricingActions.loadProducts, (state) => ({
		...state,
		actions: setProductPricingDetailsActionStates(state.actions, 'loadProducts', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadProductsSuccess, (state, { products }) =>
		productPricingDetailsAdapter.setAll(products, {
			...state,
			actions: setProductPricingDetailsActionStates(state.actions, 'loadProducts', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadProductsFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingDetailsActionStates(state.actions, 'loadProducts', ActionTypes.failure, error),
	})),

	on(ProductPricingActions.loadProductPricingDetails, (state) => ({
		...state,
		actions: setProductPricingDetailsActionStates(state.actions, 'loadProductPricingDetails', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadProductPricingDetailsSuccess, (state, { pricingDetails }) =>
		productPricingDetailsAdapter.upsertOne(pricingDetails, {
			...state,
			actions: setProductPricingDetailsActionStates(state.actions, 'loadProductPricingDetails', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadProductPricingDetailsFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingDetailsActionStates(state.actions, 'loadProductPricingDetails', ActionTypes.failure, error),
	})),

	on(ProductPricingActions.updateProductPricingDetails, (state) => ({
		...state,
		actions: setProductPricingDetailsActionStates(state.actions, 'updateProductPricingDetails', ActionTypes.loading),
	})),
	on(ProductPricingActions.updateProductPricingDetailsSuccess, (state, { pricingDetails }) =>
		productPricingDetailsAdapter.updateOne(pricingDetails, {
			...state,
			actions: setProductPricingDetailsActionStates(state.actions, 'updateProductPricingDetails', ActionTypes.success),
			currentProductPricingDetails: { ...state.currentProductPricingDetails, ...pricingDetails.changes },
			currentProductPricingDetailsId: pricingDetails.changes.id,
		})
	),
	on(ProductPricingActions.updateProductPricingDetailsFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingDetailsActionStates(state.actions, 'updateProductPricingDetails', ActionTypes.failure, error),
	}))
);

const productPricingSheetDiscountsReducer = createReducer(
	productPricingSheetDiscountsInitialState,
	on(ProductPricingActions.resetProductPricingSheetDiscountsActionState, (state) => ({
		...state,
		actions: productPricingSheetDiscountsInitialState.actions,
	})),
	on(ProductPricingActions.loadProductPricingSheetDiscounts, (state) => ({
		...state,
		actions: setProductPricingSheetDiscountsActionStates(state.actions, 'loadProductPricingSheetDiscounts', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadProductPricingSheetDiscountsSuccess, (state, { discounts }) =>
		productPricingSheetDiscountsAdapter.setAll(discounts, {
			...state,
			actions: setProductPricingSheetDiscountsActionStates(state.actions, 'loadProductPricingSheetDiscounts', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadProductPricingSheetsFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetDiscountsActionStates(state.actions, 'loadProductPricingSheetDiscounts', ActionTypes.failure, error),
	})),

	on(ProductPricingActions.updateProductPricingSheetDiscounts, (state) => ({
		...state,
		actions: setProductPricingSheetDiscountsActionStates(state.actions, 'updateProductPricingSheetDiscounts', ActionTypes.loading),
	})),
	on(ProductPricingActions.updateProductPricingSheetDiscountsSuccess, (state, { discounts }) =>
		productPricingSheetDiscountsAdapter.updateMany(discounts, {
			...state,
			actions: setProductPricingSheetDiscountsActionStates(state.actions, 'updateProductPricingSheetDiscounts', ActionTypes.success),
		})
	),
	on(ProductPricingActions.updateProductPricingSheetDiscountsFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetDiscountsActionStates(
			state.actions,
			'updateProductPricingSheetDiscounts',
			ActionTypes.failure,
			error
		),
	}))
);

const productPricingSheetsReducer = createReducer(
	productPricingSheetsInitialState,
	on(ProductPricingActions.resetProductPricingSheetsState, () => productPricingSheetsInitialState),
	on(ProductPricingActions.resetProductPricingSheetsStateActionState, (state) => ({
		...state,
		actions: productPricingSheetsInitialState.actions,
	})),
	on(ProductPricingActions.setCurrentProductPricingSheetId, (state, { pricingSheetId }) => ({
		...state,
		currentPricingSheetId: pricingSheetId,
	})),
	on(ProductPricingActions.setCurrentProductPricingSheet, (state, { pricingSheet }) => ({
		...state,
		currentPricingSheet: pricingSheet,
		currentPricingSheetId: pricingSheet?.id,
	})),
	on(ProductPricingActions.loadProductPricingSheets, (state) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'loadProductPricingSheets', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadProductPricingSheetsSuccess, (state, { pricingSheets }) =>
		productPricingSheetsAdapter.setAll(pricingSheets, {
			...state,
			actions: setProductPricingSheetActionStates(state.actions, 'loadProductPricingSheets', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadProductPricingSheetsFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'loadProductPricingSheets', ActionTypes.failure, error),
	})),

	on(ProductPricingActions.loadProductPricingSheet, (state) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'loadProductPricingSheet', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadProductPricingSheetSuccess, (state, { pricingSheet }) =>
		productPricingSheetsAdapter.upsertOne(pricingSheet, {
			...state,
			actions: setProductPricingSheetActionStates(state.actions, 'loadProductPricingSheet', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadProductPricingSheetFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'loadProductPricingSheet', ActionTypes.failure, error),
	})),

	on(ProductPricingActions.createProductPricingSheet, (state) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'createProductPricingSheet', ActionTypes.loading),
	})),
	on(ProductPricingActions.createProductPricingSheetSuccess, (state, { pricingSheet }) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'createProductPricingSheet', ActionTypes.success),
	})),
	on(ProductPricingActions.createProductPricingSheetFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'createProductPricingSheet', ActionTypes.failure, error),
	})),

	on(ProductPricingActions.updateProductPricingSheet, (state) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'updateProductPricingSheet', ActionTypes.loading),
	})),
	on(ProductPricingActions.updateProductPricingSheetSuccess, (state, { pricingSheet }) =>
		productPricingSheetsAdapter.updateOne(pricingSheet, {
			...state,
      currentPricingSheet: <ProductPricingSheet>pricingSheet.changes,
			actions: setProductPricingSheetActionStates(state.actions, 'updateProductPricingSheet', ActionTypes.success),
		})
	),
	on(ProductPricingActions.updateProductPricingSheetFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'updateProductPricingSheet', ActionTypes.failure, error),
	})),

	on(ProductPricingActions.removeProductPricingSheet, (state) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'removeProductPricingSheet', ActionTypes.loading),
	})),

	on(ProductPricingActions.removeProductPricingSheetSuccess, (state, { pricingSheetId }) =>
		productPricingSheetsAdapter.removeOne(pricingSheetId, {
			...state,
			actions: setProductPricingSheetActionStates(state.actions, 'removeProductPricingSheet', ActionTypes.success),
		})
	),

	on(ProductPricingActions.removeProductPricingSheetFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetActionStates(state.actions, 'removeProductPricingSheet', ActionTypes.failure, error),
	}))
);

const productPricingSheetBaseRatesReducer = createReducer(
	productPricingSheetBaseRatesInitialState,
	on(ProductPricingActions.resetProductPricingSheetBaseRatesState, () => productPricingSheetBaseRatesInitialState),
	on(ProductPricingActions.resetProductPricingSheetBaseRatesStateActionState, (state) => ({
		...state,
		actions: productPricingSheetBaseRatesInitialState.actions,
	})),
	on(ProductPricingActions.loadBaseRates, (state) => ({
		...state,
		actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'loadBaseRates', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadBaseRatesSuccess, (state, { baseRates }) =>
		pricingSheetBaseRatesAdapter.setAll(baseRates, {
			...state,
			actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'loadBaseRates', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadBaseRatesFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'loadBaseRates', ActionTypes.failure, error),
	})),
	on(ProductPricingActions.updateProductPricingSheetBaseRate, (state) => ({
		...state,
		actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'updateBaseRate', ActionTypes.loading),
	})),
	on(ProductPricingActions.updateProductPricingSheetBaseRateSuccess, (state, { baseRate }) =>
		pricingSheetBaseRatesAdapter.updateOne(baseRate, {
			...state,
			actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'updateBaseRate', ActionTypes.success),
		})
	),
	on(ProductPricingActions.updateProductPricingSheetBaseRateFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'updateBaseRate', ActionTypes.failure, error),
	})),
	on(ProductPricingActions.updateProductPricingSheetBaseRates, (state) => ({
		...state,
		actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'updateBaseRates', ActionTypes.loading),
	})),
	on(ProductPricingActions.updateProductPricingSheetBaseRatesSuccess, (state, { baseRates }) =>
		pricingSheetBaseRatesAdapter.updateMany(baseRates, {
			...state,
			actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'updateBaseRates', ActionTypes.success),
		})
	),
	on(ProductPricingActions.updateProductPricingSheetBaseRatesFailure, (state, { error }) => ({
		...state,
		actions: setProductPricingSheetBaseRatesActionStates(state.actions, 'updateBaseRates', ActionTypes.failure, error),
	}))
);

const allowedProductVariabilitiesReducer = createReducer(
	allowedProductVariabilitiesInitialState,
	on(ProductPricingActions.resetAllowedProductVariabilityState, () => allowedProductVariabilitiesInitialState),
	on(ProductPricingActions.resetAllowedProductVariabilityStateActionState, (state) => ({
		...state,
		actions: allowedProductVariabilitiesInitialState.actions,
	})),
	on(ProductPricingActions.loadAllowedVariabilities, (state) => ({
		...state,
		actions: setAllowedProductVariabilitiesActionStates(state.actions, 'loadAllowedProductVariabilities', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadAllowedVariabilitiesSuccess, (state, { allowedVariabilities }) =>
		allowedProductVariabilitiesAdapter.setAll(allowedVariabilities, {
			...state,
			actions: setAllowedProductVariabilitiesActionStates(state.actions, 'loadAllowedProductVariabilities', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadAllowedVariabilitiesFailure, (state, { error }) => ({
		...state,
		actions: setAllowedProductVariabilitiesActionStates(state.actions, 'loadAllowedProductVariabilities', ActionTypes.failure, error),
	})),
	on(ProductPricingActions.loadAllowedProductVariability, (state) => ({
		...state,
		actions: setAllowedProductVariabilitiesActionStates(state.actions, 'loadAllowedProductVariability', ActionTypes.loading),
	})),
	on(ProductPricingActions.loadAllowedProductVariabilitySuccess, (state, { allowedVariability }) =>
		allowedProductVariabilitiesAdapter.upsertOne(allowedVariability, {
			...state,
			actions: setAllowedProductVariabilitiesActionStates(state.actions, 'loadAllowedProductVariability', ActionTypes.success),
		})
	),
	on(ProductPricingActions.loadAllowedProductVariabilityFailure, (state, { error }) => ({
		...state,
		actions: setAllowedProductVariabilitiesActionStates(state.actions, 'loadAllowedProductVariability', ActionTypes.failure, error),
	})),
	on(ProductPricingActions.updateAllowedProductVariability, (state) => ({
		...state,
		actions: setAllowedProductVariabilitiesActionStates(state.actions, 'updateAllowedProductVariability', ActionTypes.loading),
	})),
	on(ProductPricingActions.updateAllowedProductVariabilitySuccess, (state, { allowedVariability }) =>
		allowedProductVariabilitiesAdapter.updateOne(allowedVariability, {
			...state,
			actions: setAllowedProductVariabilitiesActionStates(state.actions, 'updateAllowedProductVariability', ActionTypes.success),
		})
	),
	on(ProductPricingActions.updateAllowedProductVariabilityFailure, (state, { error }) => ({
		...state,
		actions: setAllowedProductVariabilitiesActionStates(state.actions, 'updateAllowedProductVariability', ActionTypes.failure, error),
	}))
);

export const productPricingReducerMap = {
	discounts: productPricingSheetDiscountsReducer,
	products: productPricingDetailsReducer,
	pricingSheets: productPricingSheetsReducer,
	baseRates: productPricingSheetBaseRatesReducer,
	allowedVariabilities: allowedProductVariabilitiesReducer,
};
