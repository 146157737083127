import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { User } from '@oper-client/shared/data-model';

export const loadUsers = createAction('[User/API] Load Users');
export const loadUsersSuccess = createAction('[User/API] Load Users Success', props<{ users: User[] }>());
export const loadUsersFailure = createAction('[User/API] Load Users Failure', props<{ error: HttpErrorResponse }>());


export const clearUsers = createAction('[User/API] Clear Users');
