import { createSelector } from '@ngrx/store';

import { COMMISSION_FILE_FEATURE_KEY, commissionFileAdapter, CommissionFileActionTypes } from './commission-file.reducer';
import { getCommissionFeatureState } from '../commission.selectors';

export const getCommissionFileState = createSelector(getCommissionFeatureState, state => state[COMMISSION_FILE_FEATURE_KEY]);

const selectors = commissionFileAdapter.getSelectors();

export const getCommissionFiles = createSelector(getCommissionFileState, selectors.selectAll);
export const getCommissionFileEntities = createSelector(getCommissionFileState, selectors.selectEntities);

export const getActionStates = createSelector(getCommissionFileState, state => state.actions);
export const getActionState = (actionType: CommissionFileActionTypes) => createSelector(getActionStates, state => state[actionType]);
