import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { StoreModule, combineReducers } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import * as fromLoanRequestFeature from './+state/loan-request.reducer';
import { LoanRequestFacade } from './+state/loan-request/loan-request.facade';

import * as fromLoanRequestEntity from './+state/loan-request/loan-request.reducer';
import { LoanRequestEffects } from './+state/loan-request/loan-request.effects';
import * as fromOfferEntity from './+state/offer/offer.reducer';
import { OfferEffects } from './+state/offer/offer.effects';
import { OfferFacade } from './+state/offer/offer.facade';

import * as fromCreditProviderEntity from './+state/credit-provider/credit-provider.reducer';
import { CreditProviderFacade } from './+state/credit-provider/credit-provider.facade';
import { CreditProviderEffects } from './+state/credit-provider/credit-provider.effects';

import { ProductFacade } from './+state/product/product.facade';
import { ProductEffects } from './+state/product/product.effects';
import * as fromProductEntity from './+state/product/product.reducer';
import * as fromDiscountEntity from './+state/discount/discount.reducer';
import { DiscountEffects } from './+state/discount/discount.effects';
import { DiscountFacade } from './+state/discount/discount.facade';

import { ProductExplorerFacade } from './+state/product-explorer/product-explorer.facade';
import { ProductExplorerEffects } from './+state/product-explorer/product-explorer.effects';
import * as fromProductExplorerEntity from './+state/product-explorer/product-explorer.reducer';

import { MilestoneFacade } from './+state/milestone/milestone.facade';
import { MilestoneEffects } from './+state/milestone/milestone.effects';
import * as fromMilestoneEntity from './+state/milestone/milestone.reducer';

export const dataAccessLoanRequestRoutes: Route[] = [];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		StoreModule.forFeature(
			fromLoanRequestFeature.LOAN_REQUEST_FEATURE_KEY,
			combineReducers({
				[fromLoanRequestEntity.LOAN_REQUEST_ENTITY_KEY]: fromLoanRequestEntity.reducer,
				[fromOfferEntity.OFFER_ENTITY_KEY]: fromOfferEntity.reducer,
				[fromCreditProviderEntity.CREDIT_PROVIDER_ENTITY_KEY]: fromCreditProviderEntity.reducer,
				[fromProductEntity.PRODUCT_ENTITY_KEY]: fromProductEntity.reducer,
				[fromDiscountEntity.DISCOUNT_ENTITY_KEY]: fromDiscountEntity.reducer,
				[fromProductExplorerEntity.PRODUCT_EXPLORER_ENTITY_KEY]: fromProductExplorerEntity.reducer,
				[fromMilestoneEntity.MILESTONE_ENTITY_KEY]: fromMilestoneEntity.reducer,
			})
		),
		EffectsModule.forFeature([
			LoanRequestEffects,
			OfferEffects,
			CreditProviderEffects,
			ProductEffects,
			DiscountEffects,
			ProductExplorerEffects,
			MilestoneEffects,
		]),
	],
	providers: [
		LoanRequestFacade,
		OfferFacade,
		CreditProviderFacade,
		ProductFacade,
		DiscountFacade,
		ProductExplorerFacade,
		MilestoneFacade,
	],
})
export class DataAccessLoanRequestModule {}
