import { createSelector } from '@ngrx/store';
import { getClientFeatureState } from '../client.selectors';
import { FINANCIAL_ASSETS_ENTITY_KEY, financialAssetAdapter, FinancialAssetActionTypes } from './financial-asset.reducer';

export const getFinancialAssetState = createSelector(getClientFeatureState, state => state[FINANCIAL_ASSETS_ENTITY_KEY]);

const selectors = financialAssetAdapter.getSelectors();

export const getFinancialAssets = createSelector(getFinancialAssetState, selectors.selectAll);
export const getFinancialAssetMap = createSelector(getFinancialAssetState, selectors.selectEntities);

export const getActionStates = createSelector(getFinancialAssetState, state => state.actions);
export const getActionState = (actionType: FinancialAssetActionTypes) => createSelector(getActionStates, state => state[actionType]);
