import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PAYMENT_FEATURE_KEY, PaymentState, PaymentAdapter } from './payment.reducer';

// Lookup the 'Payment' feature state managed by NgRx
export const getPaymentState = createFeatureSelector<PaymentState>(PAYMENT_FEATURE_KEY);

const { selectAll, selectEntities } = PaymentAdapter.getSelectors();

export const getPayments = createSelector(getPaymentState, (state: PaymentState) => selectAll(state));
export const getPaymentEntities = createSelector(getPaymentState, (state: PaymentState) => selectEntities(state));
export const getPaymentError = createSelector(getPaymentState, (state: PaymentState) => state.error);
