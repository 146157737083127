export interface CollapsibleEvent {
	element: Element;
	collapsedClassName: string;
	expandedClassName: string;
}

export function toggleElementExpansion(event: CollapsibleEvent): void {
	const { element, collapsedClassName, expandedClassName } = event;
	const elements = element.getElementsByClassName(collapsedClassName);
	for (let i = 0; i < elements.length; i++) {
		const collapsedElement = elements[i] as HTMLElement;
		collapsedElement.classList.toggle(expandedClassName);
		collapsedElement.style.maxHeight = collapsedElement.style.maxHeight
			? null
			: element.scrollHeight + collapsedElement.scrollHeight + 'px';
	}
}
