import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as InsuranceProviderActions from './insurance-provider.actions';
import { InsuranceProviderService } from '../../services/insurance-provider.service';

@Injectable()
export class InsuranceProviderEffects {
	loadInsuranceProviders$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(InsuranceProviderActions.loadInsuranceProviders),
			switchMap(() => {
				return this.insuranceProviderService.getInsuranceProviders().pipe(
					map(insuranceProviders => InsuranceProviderActions.loadInsuranceProvidersSuccess({ insuranceProviders })),
					catchError(error => of(InsuranceProviderActions.loadInsuranceProvidersFailure({ error })))
				);
			})
		);
	});

	constructor(private actions$: Actions, private insuranceProviderService: InsuranceProviderService) {}
}
