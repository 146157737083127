import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { LoanApplicationDto, PartialNormalizedResource } from '@oper-client/shared/data-model';
import { of, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { hideWhenFieldIsFalse, showOnlyWhenAllConditionsAreMet } from '../utils/dynamic-form.utils';

export default function (formData?: LoanApplicationDto, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'ownFunds',
			label: 'ç.question.ownFunds.label',
			helpText: of('ç.question.ownFunds.helpText'),
			value: formData?.ownFunds || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'isMarkedForBridgeLoan',
			label: 'ç.question.isMarkedForBridgeLoan.label',
			helpText: of('ç.question.isMarkedForBridgeLoan.helpText'),
			value: formData?.isMarkedForBridgeLoan || null,
			updateOn: 'change',
			type: 'checkbox',
		}),
		new InputField({
			key: 'bridgeLoanAmount',
			label: 'ç.question.bridgeLoanAmount.label',
			helpText: of('ç.question.bridgeLoanAmount.helpText'),
			value: formData?.bridgeLoanAmount || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isMarkedForBridgeLoan'),
		}),
		new InputField({
			key: 'sellingPrice',
			label: 'ç.question.sellingPrice.label',
			helpText: of('ç.question.sellingPrice.helpText'),
			value: formData?.sellingPrice || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isMarkedForBridgeLoan'),
		}),
		new InputField({
			key: 'hasExistingLoan',
			label: 'ç.question.hasExistingLoan.label',
			helpText: of('ç.question.hasExistingLoan.helpText'),
			value: formData?.hasExistingLoan || null,
			updateOn: 'change',
			type: 'checkbox',
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'isMarkedForBridgeLoan'),
		}),
		new InputField({
			key: 'outstandingLoanAmount',
			label: 'ç.question.outstandingLoanAmount.label',
			value: formData?.outstandingLoanAmount || null,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) =>
				showOnlyWhenAllConditionsAreMet(formGroup, destroy$, { hasExistingLoan: true, isMarkedForBridgeLoan: true }),
		}),
		new InputField({
			key: 'hasAdditionalCollateral',
			label: 'ç.question.hasAdditionalCollateral.label',
			helpText: of('ç.question.hasAdditionalCollateral.helpText'),
			value: formData?.hasAdditionalCollateral || null,
			updateOn: 'change',
			type: 'checkbox',
		}),
		new InputField({
			key: 'venalValueBefore',
			label: 'ç.question.fairValueOfTheProperty.label',
			helpText: of('ç.question.fairValueOfTheProperty.helpText'),
			value: formData?.venalValueBefore || null,
			type: 'text',
			currency: true,
			required: true,
			hidden: (formGroup: FormGroup, destroy$: Subject<void>) => hideWhenFieldIsFalse(formGroup, destroy$, 'hasAdditionalCollateral'),
		}),
	];
}
