import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberRequiredValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const error = { required: true };
		const value = control?.value?.value;

		if (!value || value.length === 0) {
			return error;
		}
		return null;
	};
}

export function uniquePhoneNumberValidator(mobilKey: string, landlineKey: string): ValidatorFn {
	return (group: FormGroup): ValidationErrors | null => {
		const error = { notUniquePhoneNumber: true };
		const mobile = group.get(mobilKey);
		const landline = group.get(landlineKey);

		const mobileCountry = mobile?.value?.countryCode?.definition;
		const landlineCountry = landline?.value?.countryCode?.definition;
		const mobileValue = mobile?.value?.value;
		const landlineValue = landline?.value?.value;

		if (!mobileValue || mobileValue.length < 1) {
			landline?.setErrors(null);
			return null;
		}

		if (!landlineValue || landlineValue.length < 1) {
			mobile?.setErrors(null);
			return null;
		}

		if (mobileValue === landlineValue && mobileCountry === landlineCountry) {
			mobile.setErrors(error);
			landline.setErrors(error);
		} else {
			mobile.setErrors(null);
			landline.setErrors(null);
		}
		return null;
	};
}
