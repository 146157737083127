import { createSelector } from '@ngrx/store';
import { getDocumentFeatureState } from '../document.selectors';
import { COMMISSION_STATEMENT_DOCUMENT_ENTITY_KEY, commissionStatementDocumentAdapter } from './commission-statement.reducer';

export const getCommissionStatementDocumentState = createSelector(
	getDocumentFeatureState,
	state => state[COMMISSION_STATEMENT_DOCUMENT_ENTITY_KEY]
);

const commissionStatementDocumentSelectors = commissionStatementDocumentAdapter.getSelectors();
export const getCommissionStatementDocuments = createSelector(
	getCommissionStatementDocumentState,
	commissionStatementDocumentSelectors.selectAll
);
