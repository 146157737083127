import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppFacade } from '../+state/app.facade';

type VisibilityEvent = 'visibilitychange' | 'msvisibilitychange' | 'webkitvisibilitychange';
type VisibilityDocumentKey = 'hidden' | 'msHidden' | 'webkitHidden';

interface DocumentExtendedWithVisibility extends Document {
	msHidden: boolean;
	webkitHidden: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class VisibilityDetectionService {
	constructor(@Inject(DOCUMENT) private document: DocumentExtendedWithVisibility, private appFacade: AppFacade) {}

	init(): void {
		this.document.addEventListener(this.getBrowserVisibilityEventName(), () => this.handleVisibilityChange(), false);
	}

	stop(): void {
		this.document.removeEventListener(this.getBrowserVisibilityEventName(), () => this.handleVisibilityChange());
	}

	private getBrowserVisibilityEventName(): VisibilityEvent {
		if (typeof this.document.hidden !== 'undefined') {
			return 'visibilitychange';
		} else if (typeof this.document.msHidden !== 'undefined') {
			return 'msvisibilitychange';
		} else if (typeof this.document.webkitHidden !== 'undefined') {
			return 'webkitvisibilitychange';
		}
	}

	private getVisibilityDocumentKey(): VisibilityDocumentKey {
		if (typeof this.document.hidden !== 'undefined') {
			return 'hidden';
		} else if (typeof this.document.msHidden !== 'undefined') {
			return 'msHidden';
		} else if (typeof this.document.webkitHidden !== 'undefined') {
			return 'webkitHidden';
		}
	}

	private handleVisibilityChange() {
		const visibilityKey = this.getVisibilityDocumentKey();
		if (document[visibilityKey]) {
			this.appFacade.appHidden();
		} else {
			this.appFacade.appVisible();
		}
	}
}
