import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';

export enum CountdownStatus {
	START,
	RESET,
	FINISHED,
}

@Component({
	selector: 'oper-client-countdown',
	templateUrl: './countdown.component.html',
	styleUrls: ['./countdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnChanges, OnDestroy {
	min$ = new BehaviorSubject<number>(0);
	sec$ = new BehaviorSubject<number>(0);

	@Input() seconds: number;
	@Input() countdownStatus: CountdownStatus = CountdownStatus.FINISHED;
	@Input() description: string;

	@Output() finish = new EventEmitter<void>();

	private _timerSubscription: Subscription;

	private _seconds = 0;

	ngOnDestroy(): void {
		this.unsubscribeFromTimer();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['seconds']) {
			this._seconds = this.seconds;
			this.updateTime();
			this.countdownStatus = CountdownStatus.START;
			this.handleCountdownStatus();
		}

		if (changes['countdownStatus']) {
			this.handleCountdownStatus();
		}
	}

	private updateTime(): void {
		const min: number = Math.floor(this._seconds / 60);
		const sec: number = this._seconds - min * 60;
		this.min$.next(min);
		this.sec$.next(sec);
	}

	private initializeTimer(): void {
		this.updateTime();
		this._timerSubscription = interval(1000).subscribe(() => {
			if (this._seconds > 0) {
				this._seconds -= 1;
				this.updateTime();
				return;
			}
			this.updateTime();
			this.countdownStatus = CountdownStatus.FINISHED;
			this.handleCountdownStatus();
		});
	}

	private handleCountdownStatus(): void {
		switch (this.countdownStatus) {
			case CountdownStatus.START:
				this._seconds = this.seconds;
				this.unsubscribeFromTimer();
				this.initializeTimer();
				break;
			case CountdownStatus.RESET:
				this._seconds = this.seconds;
				this.unsubscribeFromTimer();
				this.updateTime();
				break;
			case CountdownStatus.FINISHED:
			default:
				this.finish.emit();
				this.unsubscribeFromTimer();
		}
	}

	private unsubscribeFromTimer(): void {
		if (this._timerSubscription) {
			this._timerSubscription.unsubscribe();
			this._timerSubscription = null;
		}
	}
}
