import { HttpErrorResponse } from '@angular/common/http';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ActionState, ActionTypes, initialActionState, setActionState, Milestone } from '@oper-client/shared/data-model';

import * as MilestoneActions from './milestone.actions';

export const MILESTONE_ENTITY_KEY = 'milestone';

export interface MilestoneState extends EntityState<Milestone> {
	actions: MilestoneActionsState;
	error: HttpErrorResponse | undefined;
}
export type MilestoneActionTypes = 'loadMilestones' | 'updateMilestone';
export type MilestoneActionsState = Record<MilestoneActionTypes, ActionState>;
export const milestoneAdapter: EntityAdapter<Milestone> = createEntityAdapter<Milestone>({});

export const initialState: MilestoneState = milestoneAdapter.getInitialState({
	actions: {
		loadMilestones: initialActionState,
		updateMilestone: initialActionState,
	},
	error: undefined,
});

function setActionStates(
	actionState: MilestoneActionsState,
	action: MilestoneActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): MilestoneActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,

	on(MilestoneActions.loadMilestones, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMilestones', ActionTypes.loading),
	})),
	on(MilestoneActions.loadMilestonesSuccess, (state, { milestones }) =>
		milestoneAdapter.setAll(milestones, {
			...state,
			actions: setActionStates(state.actions, 'loadMilestones', ActionTypes.success),
		})
	),
	on(MilestoneActions.loadMilestonesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMilestones', ActionTypes.failure, error),
	})),
	on(MilestoneActions.updateMilestone, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateMilestone', ActionTypes.loading),
	})),
	on(MilestoneActions.updateMilestoneSuccess, (state, { milestone }) =>
		milestoneAdapter.upsertOne(milestone, {
			...state,
			actions: setActionStates(state.actions, 'updateMilestone', ActionTypes.success),
		})
	),
	on(MilestoneActions.updateMilestoneFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateMilestone', ActionTypes.failure, error),
	})),
);
