import { of } from 'rxjs';
import { Realty } from '@oper-client/shared/data-model';

import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function(formData?: Realty): InputBase<any>[] {
	return [
		new InputField({
			key: 'surface',
			label: 'ç.question.surface.label',
			helpText: of('ç.question.surface.helpText'),
			value: formData?.surface || null,
			type: 'number',
			required: true,
		}),
		new InputField({
			key: 'rooms',
			label: 'ç.question.rooms.label',
			helpText: of('ç.question.rooms.helpText'),
			value: formData?.rooms || null,
			type: 'text',
			required: false,
		}),
	];
}
