import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CreditProvider } from '@oper-client/shared/data-model';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class CreditProviderService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getCreditProviders(params: { active?: boolean; isLiabilityProvider?: boolean;  excludeBlacklisted?: boolean}): Observable<CreditProvider[]> {
		const { active, isLiabilityProvider, excludeBlacklisted } = params;
		let queryParams = new HttpParams();
		if (typeof active !== 'undefined') {
			queryParams = queryParams.set('active', active.toString());
		}
		if (typeof isLiabilityProvider !== 'undefined') {
			queryParams = queryParams.set('is_liability_provider', isLiabilityProvider.toString());
		}
		if (typeof excludeBlacklisted !== 'undefined') {
			queryParams = queryParams.set('exclude_blacklisted', excludeBlacklisted.toString());
		}
		return this.apiService.get(`/api/credit-providers/`, queryParams);
	}
}
