import { createAction, props } from '@ngrx/store';

import { Offer, Product, ProductExplorerStep, FilterParams } from '@oper-client/shared/data-model';

export const setActiveStep = createAction('[ProductExplorer] Set active step', props<{ activeStep: ProductExplorerStep }>());
export const setOfferToUpdate = createAction('[ProductExplorer] Set offer to update', props<{ offerToUpdate: Partial<Offer> }>());
export const setSelectedProducts = createAction('[ProductExplorer] Set selected products', props<{ selectedProducts: Product[] }>());
export const setSearchFilterParams = createAction(
	'[ProductExplorer] Set search filter params',
	props<{ searchFilterParams: Partial<FilterParams> }>()
);
export const reset = createAction('[ProductExplorer] Reset');
