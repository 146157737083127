<label class="toggle-switch">
	@if (labelA) {
		<span class="switch-label">{{ labelA | translate }}</span>
	}

	<label
		class="switch-wrap"
		[ngClass]="{
			'primary-background': backgroundColor === 'primary',
			'grey-background': backgroundColor === 'grey',
			'disabled': disabled
		}"
		[for]="inputId"
	>
		<input
			[id]="inputId"
			type="checkbox"
			[disabled]="disabled"
			[(ngModel)]="ngModelValue"
			(change)="onChange(ngModelValue ? valueB : valueA)"
			(blur)="_onTouched()"
		/>
		<div
			class="switch-panel"
			[ngClass]="{
				'primary-button': buttonColor === 'primary'
			}"
		></div>
	</label>

	@if (labelB) {
		<span class="switch-label">{{ labelB | translate }}</span>
	}
</label>
