import { Realty, Resource } from '@oper-client/shared/data-model';

import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';

export default function (
	realty: Partial<Realty>,
	resources: Resource[],
	canChangeVenalValueAfter: boolean,
	canChangeEstimationExpirationDate: boolean,
	realtyPurposeId: number,
	buildingPurpose: Resource
): InputBase<any>[] {
	return [
		...(!realtyPurposeId || buildingPurpose?.id !== realtyPurposeId
			? [
					new InputField({
						key: 'price',
						label: 'ç.question.fairMarketValue.label',
						value: realty?.price,
						type: 'number',
						currency: true,
						required: true,
						class: 'span3',
					}),
			  ]
			: [
					new InputField({
						key: 'priceLand',
						label: 'ç.question.priceLand.label',
						value: realty?.priceLand,
						type: 'number',
						currency: true,
						required: true,
						class: 'span3',
					}),
					new InputField({
						key: 'priceBuilding',
						label: 'ç.question.priceBuilding.label',
						value: realty?.priceBuilding,
						type: 'number',
						currency: true,
						required: true,
						class: 'span3',
					}),
					new InputField({
						key: 'venalValueAfter',
						label: 'ç.question.fairMarketValue.label',
						value: realty?.venalValueAfter,
						type: 'number',
						currency: true,
						required: true,
						class: 'span3',
					}),
			  ]),
	];
}
