import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { COMMON_REGEX_EXPRESSIONS } from '../services/validator-constants';
import { validateSeg9731Alg } from '../services/validator-util';

export function bankAccountValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}
		const value = control.value.replace(/-/g, '');
		if (value[0] === '0' || ![16, 24].includes(value.length) || !new RegExp(COMMON_REGEX_EXPRESSIONS.ONLY_NUMBERS).test(value)) {
			return { bankAccount: true };
		}

		return isValid(value) ? null : { bankAccount: true };
	};
}

function isValid(value: string): boolean {
	const firstSegment = value.slice(0, 7);
	const secondSegment = value.slice(8, 15);
	const thirdSegment = value.slice(8, 23);

	return (
		validateSeg9731Alg(firstSegment, parseInt(value.charAt(7))) &&
		(value.length === 24 ? true : validateSeg9731Alg(secondSegment, parseInt(value.charAt(15)))) &&
		(value.length === 16 ? true : validateSeg9731Alg(thirdSegment, parseInt(value.charAt(23))))
	);
}
