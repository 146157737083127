import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

export interface ConditionValidationConfig {
	validationMessageKey: string;
	conditionControlKey: string;
	condition: (controlValue, conditionControlValue) => boolean;
}
export function conditionValidator(config: ConditionValidationConfig): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const conditionControl: AbstractControl = control?.parent?.controls[config.conditionControlKey];
		if (conditionControl) {
			if (!config.condition(control.value, conditionControl.value)) {
				return {
					[config.validationMessageKey]: {
						value: control.value,
					},
				};
			}
			conditionControl.valueChanges.pipe(distinctUntilChanged()).subscribe(() => control.updateValueAndValidity());
			return null;
		}
		return null;
	};
}
