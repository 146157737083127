import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, IFinancialPlanConfigService } from '@oper-client/shared/data-access';
import { FinancialPlanConfig } from '@oper-client/shared/data-model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FinancialPlanConfigService implements IFinancialPlanConfigService{
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	load(configurationName?: string): Observable<FinancialPlanConfig> {
		let qparams = new HttpParams();
		if (configurationName) {
			qparams = qparams.append("configuration_name", configurationName);
		}
		return this.apiService.get(`/configuration/financial-plan/`, qparams);
	}
}
