import { AnyObject } from './any-object.model';
import { Address } from './address.model';

export interface MissingDataInstance {
	id: number;
	firstName?: string;
	lastName?: string;
	address?: Address;
}
export interface MissingDataSection {
	section?: string;
	sectionTotalMissedFields?: number;
	instance?: MissingDataInstance;
	missingData: AnyObject<string[][]>;
}

export type MissingData = Array<MissingDataSection>;
export interface MissingDataValidation {
	totalMissedFields: number;
	missingData: MissingData;
}

export enum MissingDataSectionEnum {
	CLIENT = 'client',
	REALTY = 'realty',
	LOAN_REQUEST_SUMMARY = 'loanRequestSummary',
	LOAN_REQUEST_DEED = 'loanRequestDeed',
	LIABILITY_INSURANCE = 'liabilityInsurance',
}
