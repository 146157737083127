import { HttpErrorResponse } from '@angular/common/http';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { BaseProductDiscounts, ActionState, ActionTypes, initialActionState, setActionState } from '@oper-client/shared/data-model';

import * as DiscountActions from './discount.actions';

export const DISCOUNT_ENTITY_KEY = 'discount';

export const discountAdapter: EntityAdapter<BaseProductDiscounts> = createEntityAdapter<BaseProductDiscounts>();

export interface DiscountState extends EntityState<BaseProductDiscounts> {
	actions: DiscountActionsState;
	error: HttpErrorResponse | undefined;
	selectedDiscounts: BaseProductDiscounts[];
}

export type DiscountActionTypes = 'loadProductDiscounts' | 'loadSelectedDiscountsFromOffer';
export type DiscountActionsState = Record<DiscountActionTypes, ActionState>;

export const initialState: DiscountState = discountAdapter.getInitialState({
	actions: {
		loadProductDiscounts: initialActionState,
		loadSelectedDiscountsFromOffer: initialActionState,
	},
	error: undefined,
	selectedDiscounts: [],
});

function setActionStates(
	actionState: DiscountActionsState,
	action: DiscountActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): DiscountActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	initialState,
	on(DiscountActions.loadProductDiscounts, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProductDiscounts', ActionTypes.loading),
	})),
	on(DiscountActions.loadProductDiscountsSuccess, (state, { discounts }) =>
		discountAdapter.setAll(discounts, {
			...state,
			actions: setActionStates(state.actions, 'loadProductDiscounts', ActionTypes.success),
		})
	),
	on(DiscountActions.loadProductDiscountsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProductDiscounts', ActionTypes.failure, error),
	})),

	on(DiscountActions.loadSelectedDiscountsFromOffer, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSelectedDiscountsFromOffer', ActionTypes.loading),
	})),
	on(DiscountActions.loadSelectedDiscountsFromOfferSuccess, (state, { discounts }) => ({
		...state,
		selectedDiscounts: discounts,
		actions: setActionStates(state.actions, 'loadSelectedDiscountsFromOffer', ActionTypes.success),
	})),
	on(DiscountActions.loadSelectedDiscountsFromOfferFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSelectedDiscountsFromOffer', ActionTypes.failure, error),
	})),
	on(DiscountActions.applyDiscounts, (state, { discounts }) =>
		discountAdapter.upsertMany(discounts, {
			...state,
			selectedDiscounts: discounts,
		})
	),
	on(DiscountActions.setSelectedDiscounts, (state, { discounts }) => ({
		...state,
		selectedDiscounts: discounts,
	})),
	on(DiscountActions.clearSelectedDiscounts, (state) => ({
		...state,
		selectedDiscounts: [],
	})),
	on(DiscountActions.clearProductDiscounts, (state) => discountAdapter.removeAll(state)),
	on(DiscountActions.resetDiscountsState, (state) => discountAdapter.removeAll({ ...state, selectedDiscounts: [], error: undefined }))
);
