import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import * as iamSelectors from '@oper-client/shared/iam/data-access-iam';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';

import { cleanGtmEventPayload, GtmDispatchPayload, GtmEventPayload } from './interfaces/gtm-event-payload';
import { GtmEventNameEnum } from './enums/gtm-event-name.enum';
import { GtmEventTracking } from './types/gtm-tracking.type';
import { APP_INSIGHTS_CONFIG, ApplicationInsights } from '@oper-client/shared/configuration';

@Injectable()
export class GtmFacade {
	private readonly customerSeparator: string = '-';
	protected readonly userPayload$: BehaviorSubject<Pick<GtmEventPayload, 'userId' | 'userRole'> | undefined>;

	constructor(
		private readonly store: Store<any>,
		private readonly localStorageService: LocalStorageService,
		@Inject(APP_INSIGHTS_CONFIG) private readonly applicationInsights: ApplicationInsights
	) {
		this.userPayload$ = new BehaviorSubject(undefined);

		this.store.pipe(select(iamSelectors.getIamUser)).subscribe((user) => {
			this.userPayload$.next(user ? { userId: user.id, userRole: user.role } : undefined);
		});
	}

	/**
	 * Get tenant name from application insights
	 * This approach is the only and most commonly used way in the app to get the tenant name
	 * @private
	 */
	private getCustomer(): string | null {
		const name = this.applicationInsights?.name;
		return !name.includes(this.customerSeparator) || name.split(this.customerSeparator).length === 0
			? null
			: name.split(this.customerSeparator)[0];
	}

	/**
	 * Log GTM event to console
	 * To enable logging, set 'gtmLog' in local storage to true
	 * NOTE: console.log() is intentionally left in the code in this case to allow for debugging
	 * @param dispatchPayload
	 * @private
	 */
	private logGtmEventToConsole(dispatchPayload: GtmDispatchPayload): void {
		if (this.localStorageService.get('gtmLog')) {
			console.log('sendEvent', dispatchPayload);
		}
	}

	/**
	 * Send GTM event
	 * @param eventName
	 * @param eventPayload
	 */
	public sendEvent(eventName: GtmEventNameEnum, eventPayload?: Partial<GtmEventPayload>): void {
		const dispatchPayload: GtmDispatchPayload = {
			type: `@tracking/${eventName}` as GtmEventTracking,
			payload: { ...cleanGtmEventPayload, ...this.userPayload$.value, ...eventPayload, tenant: this.getCustomer() },
		};

		this.logGtmEventToConsole(dispatchPayload);

		this.store.dispatch(dispatchPayload);
	}
}
