import { of } from 'rxjs';
import { InputField } from '../../../models/input-types.model';
import { InputBase } from '../../../models/input-base.model';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';

export default function (formData: any | null = null, resources: PartialNormalizedResource | null = null): InputBase<any>[] {
	return [
		new InputField({
			key: `proceedsFromPropertySale`,
			label: `ç.calculator.question.proceedsFromPropertySale.label`,
			helpText: of(`ç.calculator.question.proceedsFromPropertySale.helpText`),
			disabled: true,
			value: this.result ?? formData?.proceedsFromPropertySale ?? 0,
			type: 'number',
			currency: true,
			validators: [],
			required: false,
			class: 'span12',
		}),
	];
}
