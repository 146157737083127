import { Pipe, PipeTransform } from '@angular/core';
import { PreapprovalAcceptanceRule } from '@oper-client/shared/data-model';
import { AcceptanceRuleParameterValuePipe } from './acceptance-rule-parameter-value.pipe';
import { TranslatePipe } from '@ngx-translate/core';

const BY_CONFIG = 'ByConfig';
@Pipe({
	name: 'translateACCMessage',
	pure: false, // otherwise translation doesn't happen when switching language
})
export class TranslateACCMessagePipe implements PipeTransform {
	constructor(
		private readonly accParameterValueTypePipe: AcceptanceRuleParameterValuePipe,
		private readonly translatePipe: TranslatePipe
	) {}

	transform(value: PreapprovalAcceptanceRule, messageType: string): unknown {
		const translationParams = {};
		value.criteria.forEach((criterion) => {
			criterion.parameters.forEach((param) => {
				const defaultKeyName = `${param.name}${BY_CONFIG}`;
				translationParams[param.name] = this.accParameterValueTypePipe.transform(param.actualValue, param.type);
				translationParams[defaultKeyName] = this.accParameterValueTypePipe.transform(param.configuredValue, param.type);
			});
		});
		return this.translatePipe.transform(`ç.feature.offer.acceptanceRule.${value.name}.${messageType}`, translationParams);
	}
}
