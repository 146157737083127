import { of } from 'rxjs';

import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'householdIncome',
			label: 'ç.question.monthlyHouseholdIncome.label',
			helpText: of('ç.question.monthlyHouseholdIncome.helpText'),
			value: formData?.householdIncome || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'liabilities',
			label: 'ç.question.monthlyLiabilitiesExistingCredits.label',
			helpText: of('ç.question.monthlyLiabilitiesExistingCredits.helpText'),
			value: formData?.liabilities || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'ownFunds',
			label: 'ç.question.ownFunds.label',
			helpText: of('ç.question.ownFunds.helpText'),
			value: formData?.ownFunds || null,
			updateOn: 'change',
			type: 'text',
			currency: true,
			required: true,
		}),
	];
}
