import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface PanePosition {
	paneTop: number;
	paneLeft: number;
	paneWidth: number;
	arrowTop: number;
	arrowLeft: number;
}

interface ScrollPosition {
	top: number;
	left: number;
}

@Component({
	selector: 'oper-client-tooltip-pane',
	styleUrls: ['./tooltip-pane.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="panePosition$ | async as panePosition">
			<div class="tooltip__arrow" [ngStyle]="{ top: panePosition.arrowTop + 'px', left: panePosition.arrowLeft + 'px' }"></div>
			<div
				class="tooltip__pane-container"
				[ngStyle]="{
					top: panePosition.paneTop + 'px',
					left: panePosition.paneLeft + 'px',
					width: panePosition.paneWidth + 'px'
				}"
			>
				<div class="tooltip__pane">
					<div class="text" [innerHTML]="text"></div>
				</div>
			</div>
		</ng-container>
	`,
})
export class TooltipPaneComponent implements OnInit {
	private lastScrollTop = 0;
	private initialScrollSnapshot: ScrollPosition;
	private readonly currentPanePosition$: BehaviorSubject<PanePosition> = new BehaviorSubject(null);
	readonly panePosition$: Observable<PanePosition> = this.currentPanePosition$.asObservable();

	@Input() text: string;
	@Input() panePositioning: PanePosition;
	@Output() hide = new EventEmitter<void>();

	ngOnInit(): void {
		this.currentPanePosition$.next(this.panePositioning);
	}

	calculatePanePosition(scrollEvent: Event): void {
		const currentScrollPosition = this.getScrollPosition(scrollEvent.target as Element);
		if (!this.initialScrollSnapshot) {
			this.initialScrollSnapshot = currentScrollPosition;
		}

		if (currentScrollPosition.top > this.lastScrollTop) {
			// scrolling down
			const scrollTopDelta = currentScrollPosition.top - this.initialScrollSnapshot.top;
			const { paneLeft, arrowLeft, paneWidth } = this.currentPanePosition$.value;
			this.currentPanePosition$.next({
				paneTop: this.panePositioning.paneTop - scrollTopDelta,
				arrowTop: this.panePositioning.arrowTop - scrollTopDelta,
				paneLeft,
				arrowLeft,
				paneWidth,
			});
		} else {
			// scrolling up
			const scrollTopDelta = this.lastScrollTop - currentScrollPosition.top;
			const { paneLeft, arrowLeft, paneWidth, paneTop, arrowTop } = this.currentPanePosition$.value;
			this.currentPanePosition$.next({
				paneTop: paneTop + scrollTopDelta,
				arrowTop: arrowTop + scrollTopDelta,
				paneLeft,
				arrowLeft,
				paneWidth,
			});
		}
		this.lastScrollTop = currentScrollPosition.top;
	}

	private getScrollPosition(element: Element): ScrollPosition {
		return {
			top: element.scrollTop,
			left: element.scrollLeft,
		};
	}
}
