import { Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AUTH_SERVICE, IAuthService } from './../services/auth.service';

export class SsoRoleGuard implements CanActivate {
	constructor(
		@Inject(AUTH_SERVICE) private authService: IAuthService,
		private router: Router
	) {}

	private currentRole = '';
	private expectedRoles = [];

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.expectedRoles = route.data.expectedRoles;
		return this.authService.getCurrentUser().pipe(
			map((user) => {
				this.currentRole = user?.role;

				if (this.expectedRoles.find((expectedRole) => expectedRole === this.currentRole)) {
					return true;
				} else {
					return this.router.parseUrl('/auth/unauthorized');
				}
			})
		);
	}
}
