import { createAction, props } from '@ngrx/store';
import { CommissionStatement } from '@oper-client/shared/data-model';

export const loadCommissionStatements = createAction('[CommissionStatement] Load CommissionStatement', props<{ brokerId: number }>());

export const loadCommissionStatementsSuccess = createAction(
	'[CommissionStatement] Load CommissionStatement Success',
	props<{ commissionStatements: CommissionStatement[] }>()
);

export const loadCommissionStatementsFailure = createAction(
	'[CommissionStatement] Load CommissionStatement Failure',
	props<{ error: any }>()
);
