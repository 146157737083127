import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE, IApiService, IProductPricingService } from '@oper-client/shared/data-access';
import {
	AllowedProductVariability,
	BaseRate,
	CreateProductPricingSheetRequest,
	ProductPricingDetails,
	ProductPricingSheetDiscounts,
	ProductPricingSheet,
} from '@oper-client/shared/data-model';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductPricingService implements IProductPricingService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	getAllProducts(): Observable<ProductPricingDetails[]> {
		return this.apiService
			.get(`/api/base-products/`)
			.pipe(map((products: ProductPricingDetails[]) => products.sort((a, b) => a.creditProvider.id - b.creditProvider.id)));
	}

	getProductPricingDetails(id?: number, params?: HttpParams): Observable<ProductPricingDetails> {
		return this.apiService.get(`/api/base-products/${id}`, params);
	}

	getProductPricingSheets(productId: number): Observable<ProductPricingSheet[]> {
		return this.apiService.get(`/api/base-products/${productId}/pricing-sheets/`);
	}

	getProductPricingSheet(productId: number, pricingSheetId: number): Observable<ProductPricingSheet> {
		return this.apiService.get(`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}/`);
	}

	getProductPricingSheetDiscounts(productId: number, pricingSheetId: number): Observable<ProductPricingSheetDiscounts[]> {
		return this.apiService.get(`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}/discounts`);
	}

	getProductPricingSheetBaseRates(productId: number, pricingSheetId: number): Observable<BaseRate[]> {
		return this.apiService
			.get(`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}/base-rates/`)
			.pipe(map((products: BaseRate[]) => products.sort((a, b) => a.id - b.id)));
	}

	createPricingSheet(productId: number, request: CreateProductPricingSheetRequest, params?: HttpParams): Observable<ProductPricingSheet> {
		return this.apiService.post(`/api/base-products/${productId}/pricing-sheets/`, request, params);
	}

	updateProductPricingDetails(
		productId: number,
		product: Partial<ProductPricingDetails>,
		params?: HttpParams
	): Observable<ProductPricingDetails> {
		return this.apiService.patch(`/api/base-products/${productId}/`, product, params);
	}

	updateProductPricingSheetDiscounts(
		productId: number,
		pricingSheetId: number,
		discounts: Partial<ProductPricingSheetDiscounts>[]
	): Observable<ProductPricingSheetDiscounts[]> {
		return this.apiService.patch(`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}/bulk-update-discounts`, {
			discounts,
		});
	}

	updatePricingSheet(
		productId: number,
		pricingSheetId: number,
		pricingSheet: Partial<ProductPricingSheet>,
		params?: HttpParams
	): Observable<ProductPricingSheet> {
		return this.apiService.patch(`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}`, pricingSheet, params);
	}

	updatePricingSheetBaseRate(
		productId: number,
		pricingSheetId: number,
		baseRateId: number,
		baseRate: Partial<BaseRate>,
		params?: HttpParams
	): Observable<BaseRate> {
		return this.apiService.patch(
			`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}/base-rates/${baseRateId}`,
			baseRate,
			params
		);
	}

	updatePricingSheetBaseRates(
		productId: number,
		pricingSheetId: number,
		baseRates: Partial<BaseRate>[],
		params?: HttpParams
	): Observable<BaseRate[]> {
		return this.apiService.patch(
			`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}/bulk-update-base-rates/`,
			baseRates,
			params
		);
	}

	deletePricingSheet(productId: number, pricingSheetId: number): Observable<void> {
		return this.apiService.delete(`/api/base-products/${productId}/pricing-sheets/${pricingSheetId}/`);
	}

	getAllowedProductVariabilities(productId: number): Observable<AllowedProductVariability[]> {
		return this.apiService.get(`/api/base-products/${productId}/allowed-product-variabilities/`);
	}

	getAllowedProductVariability(productId: number, variabilityId: number): Observable<AllowedProductVariability> {
		return this.apiService.get(`/api/base-products/${productId}/allowed-product-variabilities/${variabilityId}/`);
	}

	updateAllowedProductVariability(
		productId: number,
		variabilityId: number,
		variability: Partial<AllowedProductVariability>,
		params?: HttpParams
	): Observable<AllowedProductVariability> {
		return this.apiService.patch(
			`/api/base-products/${productId}/allowed-product-variabilities/${variabilityId}/`,
			variability,
			params
		);
	}
}
