import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as IBAN from 'iban';

export function ibanValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (control.value && !IBAN.isValid(control.value)) {
			return { iban: { value: control.value } };
		}
		return null;
	};
}
