import { Injectable, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, withLatestFrom } from 'rxjs/operators';

import { FeatureFlagService } from '@oper-client/shared/util-feature-flag';
import { FeatureFlag } from '@oper-client/shared/data-model';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG } from '@oper-client/shared/configuration';

import { zendeskScriptInjector } from '../helpers/zendesk-script-loader';

@Injectable({
	providedIn: 'root',
})
export class ZendeskService {
	private static widgetTriggerButtonWhitelist: string[] = ['/app/loan-request', '/app/tasks', '/app/document-library', '/app/pricing'];

	readonly enableZendesk$ = this.featureFlagService.hasFeatureFlag(FeatureFlag.BROKERAGE_ZENDESK);

	constructor(
		private readonly window: Window,
		private readonly featureFlagService: FeatureFlagService,
		private readonly router: Router,
		@Inject(CUSTOMER_INSIGHTS_CONFIG) private customerInsights: CustomerInsights
	) {}

	init(): void {
		this.enableZendesk$.subscribe((enableZendesk) => {
			if (enableZendesk) {
				if (this.customerInsights.zendeskKey) {
					zendeskScriptInjector(this.customerInsights.zendeskKey).then(() => this.handleZendeskWidgetOnRouteChange());
				} else {
					throw new Error('brokerageZendesk feature flag is enabled, but zendeskKey is missing from customer configuration!');
				}
			}
		});
	}

	private handleZendeskWidgetOnRouteChange(): void {
		this.messengerHide();

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				withLatestFrom(this.enableZendesk$)
			)
			.subscribe(([event, enableZendesk]: [NavigationEnd, boolean]) => {
				const { url } = event;

				if (enableZendesk) {
					if (this.hasExactRouteMatch(url)) {
						this.messengerShow();
					} else {
						this.messengerHide();
					}
				}
			});
	}

	private hasRouteMatch(currentUrl: string): boolean {
		return ZendeskService.widgetTriggerButtonWhitelist.reduce((acc, route) => currentUrl.includes(route) || acc, false);
	}

	private hasExactRouteMatch(currentUrl: string): boolean {
		return ZendeskService.widgetTriggerButtonWhitelist.includes(currentUrl);
	}

	messengerShow(): void {
		if (typeof this.window.zE === 'undefined') {
			throw new Error('Zendesk widget is not loaded!');
		}

		window.zE('messenger', 'show');
	}

	messengerHide(): void {
		if (typeof this.window.zE === 'undefined') {
			throw new Error('Zendesk widget is not loaded!');
		}

		window.zE('messenger', 'hide');
	}

	messengerOpen(): void {
		if (typeof this.window.zE === 'undefined') {
			throw new Error('Zendesk widget is not loaded!');
		}

		window.zE('messenger', 'open');
	}

	messengerClose(): void {
		if (typeof this.window.zE === 'undefined') {
			throw new Error('Zendesk widget is not loaded!');
		}

		window.zE('messenger', 'close');
	}
}
