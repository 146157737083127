import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PasswordValidationConfig } from '../../interfaces/password-validation-config';

interface ValidationResult {
	label: string;
	validationResult: boolean;
	icon: string;
}

const defaultPasswordValidationConfig: PasswordValidationConfig[] = [
	{
		label: 'ç.feature.auth.passwordValidation.minLength',
		validate: (x) => x?.length >= 10,
	},
	{
		label: 'ç.feature.auth.passwordValidation.oneCapital',
		validate: (x) => /(.*[A-Z].*)/.test(x),
	},
	{
		label: 'ç.feature.auth.passwordValidation.oneLowercase',
		validate: (x) => /(.*[a-z].*)/.test(x),
	},
	{
		label: 'ç.feature.auth.passwordValidation.oneSymbol',
		validate: (x) => /(.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~].*)/.test(x),
	},
	{
		label: 'ç.feature.auth.passwordValidation.oneNumber',
		validate: (x) => /(.*\d.*)/.test(x),
	},
];

@Component({
	selector: 'oper-client-password-validation',
	templateUrl: './password-validation.component.html',
	styleUrls: ['./password-validation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordValidationComponent implements OnChanges {
	readonly validIcon = 'faCheckCircle';
	readonly invalidIcon = 'faCheckCircle';
	readonly validationResults$ = new BehaviorSubject<ValidationResult[]>([]);

	@Input() password: string;
	@Input() passwordValidationConfigs: PasswordValidationConfig[] = defaultPasswordValidationConfig;
	@Input() immediate = false;
	@Output() validationResult = new EventEmitter<boolean>();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['password'] && (!changes['password'].isFirstChange() || this.immediate)) {
			this.validatePassword();
		}
	}

	private validatePassword(): void {
		const validationResults: ValidationResult[] = [];
		const result: boolean =
			this.passwordValidationConfigs?.reduce((previousValue, current) => {
				const validationResult: boolean = current.validate(this.password);
				const icon = validationResult ? this.validIcon : this.invalidIcon;

				validationResults.push({ validationResult, label: current.label, icon });
				return previousValue && validationResult;
			}, true) ?? true;
		this.validationResults$.next(validationResults);
		this.validationResult.emit(result);
	}
}
