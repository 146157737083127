import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { FinancialAsset } from '@oper-client/shared/data-model';

export const loadFinancialAsset = createAction(
	'[FinancialAsset/API] Load a FinancialAsset of a Client',
	props<{ loanRequestId: number; clientId: number; financialAssetId: number }>()
);
export const loadFinancialAssetSuccess = createAction(
	'[FinancialAsset/API] Load a FinancialAsset of a Client Success',
	props<{ financialAsset: FinancialAsset }>()
);
export const loadFinancialAssetFailure = createAction(
	'[FinancialAsset/API] Load a FinancialAsset of a Client Failrue',
	props<{ error: HttpErrorResponse }>()
);

export const loadFinancialAssets = createAction(
	'[FinancialAsset/API] Load all FinancialAssets of a Client of a LoanRequest',
	props<{ loanRequestId: number; clientId: number }>()
);
export const loadFinancialAssetsSuccess = createAction(
	'[FinancialAsset/API] Load all FinancialAssets of a Client of a LoanRequest Success',
	props<{ financialAssets: FinancialAsset[] }>()
);
export const loadFinancialAssetsFailure = createAction(
	'[FinancialAsset/API] Load all FinancialAssets of a Client of a LoanRequest Failure',
	props<{ error: HttpErrorResponse }>()
);

export const loadLoanRequestFinancialAssets = createAction(
	'[FinancialAsset/API] Load all FinancialAssets of all Clients of a LoanRequest',
	props<{ loanRequestId: number }>()
);
export const loadLoanRequestFinancialAssetsSuccess = createAction(
	'[FinancialAsset/API] Load all FinancialAssets of all Clients of a LoanRequest Success',
	props<{ financialAssets: FinancialAsset[] }>()
);
export const loadLoanRequestFinancialAssetsFailure = createAction(
	'[FinancialAsset/API] Load all FinancialAssets of all Clients of a LoanRequest Failure',
	props<{ error: HttpErrorResponse }>()
);

export const addFinancialAsset = createAction(
	'[FinancialAsset/API] Add FinancialAsset',
	props<{ loanRequestId: number; clientId: number; financialAsset: Partial<FinancialAsset> }>()
);
export const addFinancialAssetSuccess = createAction(
	'[FinancialAsset/API] Add FinancialAsset Success',
	props<{ financialAsset: FinancialAsset }>()
);
export const addFinancialAssetFailure = createAction(
	'[FinancialAsset/API] Add FinancialAsset Failure',
	props<{ error: HttpErrorResponse }>()
);

export const updateFinancialAsset = createAction(
	'[FinancialAsset/API] Update FinancialAsset',
	props<{ loanRequestId: number; clientId: number; financialAsset: Update<FinancialAsset> }>()
);
export const updateFinancialAssetSuccess = createAction(
	'[FinancialAsset/API] Update FinancialAsset Success',
	props<{ financialAsset: Update<FinancialAsset> }>()
);
export const updateFinancialAssetFailure = createAction(
	'[FinancialAsset/API] Update FinancialAsset Failure',
	props<{ error: HttpErrorResponse }>()
);

export const deleteFinancialAsset = createAction(
	'[FinancialAsset/API] Delete FinancialAsset',
	props<{ loanRequestId: number; clientId: number; id: number }>()
);
export const deleteFinancialAssetSuccess = createAction('[FinancialAsset/API] Delete FinancialAsset Success', props<{ id: number }>());
export const deleteFinancialAssetFailure = createAction(
	'[FinancialAsset/API] Delete FinancialAsset Failure',
	props<{ error: HttpErrorResponse }>()
);

export const clearFinancialAssets = createAction('[FinancialAsset/API] Clear all FinancialAssets from the store');
