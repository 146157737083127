import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { catchError, finalize, filter, switchMap, take } from 'rxjs/operators';
import { AUTH_SERVICE, IAuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { UnsavedDataService } from '../services/unsaved-data.service';

@Injectable({
	providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {
	private refreshInProgress = false;
	private refreshTokenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		@Inject(AUTH_SERVICE) public authService: IAuthService,
		private readonly router: Router,
		private unsavedDataService: UnsavedDataService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error) => {
				// Don't intercept for jwt requests
				if (request.url.includes('jwt') || request.url.includes('otp')) {
					// If this already was a refresh, then we log out.
					if (request.url.includes('refresh')) {
						this.authService.logout();
					}
					// Throw the error normally
					return throwError(error);
				}

				// If it's not a 401, we just return the error
				if (!error.status || error.status !== 401) {
					return throwError(error);
				}

				if (this.refreshInProgress) {
					return this.refreshTokenSubject$.pipe(
						filter((result) => result != null),
						take(1),
						switchMap(() => {
							return next.handle(this.addRefreshedToken(request));
						})
					);
				} else {
					// Set that the refresh is in progress
					this.refreshInProgress = true;
					this.refreshTokenSubject$.next(null);

					// Refresh the token
					return this.authService.refreshToken().pipe(
						switchMap(() => {
							this.refreshTokenSubject$.next(this.authService.getAccessToken());
							return next.handle(this.addRefreshedToken(request));
						}),
						catchError(() => {
							this.authService.logout().subscribe(() => {
								this.unsavedDataService.untrackAll();
								this.router.navigate(['/auth/login']);
							});
							if (error.status === 401) {
								return of(error);
							}
							return throwError(error);
						}),
						finalize(() => {
							this.refreshInProgress = false;
						})
					);
				}
			})
		);
	}

	addRefreshedToken(request: HttpRequest<any>) {
		const token = this.authService.getAccessToken();

		if (token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Token ${token}`,
				},
			});
		}
		return request;
	}
}
