import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { Link } from '@oper-client/shared/data-model';
import { HttpParams } from '@angular/common/http';

export const LINK_SERVICE = new InjectionToken<ILinkService>('LINK_SERVICE');

export interface ILinkService {
	getAll(params?: HttpParams): Observable<Link[]>;
}
