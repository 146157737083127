import { Pipe, PipeTransform } from '@angular/core';
import { AppDate } from '../models/app-date';
import { DatePipe } from '@angular/common';
import { getCurrentUTCOffset } from '../datetime/util.datetime';
import { LocaleService } from '@oper-client/shared/util-language';

@Pipe({
	name: 'timeFormat',
	pure: false,
})
export class TimeFormatPipe extends DatePipe implements PipeTransform {
	constructor(private localeService: LocaleService) {
		super(localeService.locale);
	}
	transform(value: any, args?: any): any {
		return super.transform(value, AppDate.getTimeFormat(this.localeService.region), getCurrentUTCOffset());
	}
}
