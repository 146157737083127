import { Resource, Step } from '@oper-client/shared/data-model';

export interface SimulatorFlowConfig {
	label: string;
	description: string;
	simulationFlowType: Resource;
	steps: Step[];
}

export const SimulationFlowType: Record<string, Resource> = {
	AFFORDABILITY: { definition: 'affordability' },
	PRICE: { definition: 'price' },
};
