import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { Phone } from '@oper-client/shared/data-model';

export function formatInternational(phoneNo: Phone): string {
	if (!phoneNo) return null;
	const numberInstance = parsePhoneNumberFromString(phoneNo.value, phoneNo.countryCode.country as CountryCode);
	return numberInstance.formatInternational();
}

export function getFormattedMainNumber(numbers: Phone[]): string {
	return formatInternational(getMainNumber(numbers));
}

export function getMainNumber(numbers: Phone[]): Phone {
	if (!numbers || !Array.isArray(numbers)) return null;
	return (
		numbers?.find((item) => item.phoneType?.definition === 'main') ||
		(numbers?.length === 1 && numbers[0]?.phoneType?.definition !== 'landline' && numbers[0])
	);
}

export function getSecondNumber(numbers: Phone[]): Phone {
	if (!numbers || !Array.isArray(numbers)) return null;
	return numbers.find((item) => item.phoneType?.definition === 'landline');
}

export function getFormattedSecondNumber(numbers: Phone[]): string {
	return formatInternational(getSecondNumber(numbers));
}
