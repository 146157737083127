import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_SERVICE, convertObjectToHttpParams, IApiService, IOfferService } from '@oper-client/shared/data-access';
import {
	Offer,
	FileCostPrediction,
	mapDtoToOffer,
	mapOfferToDto,
	MissingDataValidation,
	MissingData,
} from '@oper-client/shared/data-model';
import { CaseStyleService } from '@oper-client/shared/util-formatting';

@Injectable()
export class OfferService implements IOfferService {
	constructor(
		@Inject(API_SERVICE) private apiService: IApiService,
		private readonly caseStyleService: CaseStyleService
	) {}

	getOffer(loanRequestId: number): Observable<Offer> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/offer/`).pipe(map((offer) => mapDtoToOffer(offer)));
	}

	getOfferById(loanRequestId: number, offerId: number, validation: boolean): Observable<Offer> {
		const loadOffer = this.apiService
			.get(`/api/loan-requests/${loanRequestId}/offers/${offerId}/`)
			.pipe(map((offer) => mapDtoToOffer(offer)));
		if (validation) {
			return forkJoin([loadOffer, this.getMissingData(loanRequestId, offerId)]).pipe(
				map(([offer, missingData]) => ({
					...offer,
					...(missingData ? { missingDataValidation: missingData } : {}),
				}))
			);
		}
		return loadOffer;
	}

	getMissingData(loanRequestId: number, offerId: number): Observable<MissingDataValidation> {
		return this.apiService
			.get(`/api/loan-requests/${loanRequestId}/offers/${offerId}/missing-data/`)
			.pipe(map((missingDataPayload: MissingData) => this.processMissingDataValidation(missingDataPayload)));
	}

	processMissingDataValidation(missingDataPayload: MissingData): MissingDataValidation {
		let totalMissedFields = 0;
		const missingData = missingDataPayload.map((item) => {
			const missingData = {};
			let sectionTotalMissedFields = 0;
			Object.keys(item.missingData).forEach((key) => {
				missingData[key] = item.missingData[key].map((fieldKeys) => {
					totalMissedFields++;
					sectionTotalMissedFields++;
					return fieldKeys.map((fieldKey) => this.caseStyleService.toCamelCase(fieldKey));
				});
			});
			return {
				...item,
				section: this.caseStyleService.toCamelCase(item.section),
				sectionTotalMissedFields,
				missingData,
			};
		});
		return { totalMissedFields, missingData };
	}

	getAll(loanRequestId: number, params: { [key: string]: string } = {}): Observable<Offer[]> {
		return this.apiService
			.get(`/api/loan-requests/${loanRequestId}/offers/`, convertObjectToHttpParams(params))
			.pipe(map((offers) => offers.map((offer) => mapDtoToOffer(offer))));
	}

	create(loanRequestId: number, offer: Partial<Offer>): Observable<Offer> {
		return this.apiService
			.post(`/api/loan-requests/${loanRequestId}/offers/`, mapOfferToDto(offer))
			.pipe(map((createdOffer) => mapDtoToOffer(createdOffer)));
	}

	update(loanRequestId: number, id: number, offer: Partial<Offer>): Observable<Offer> {
		return this.apiService
			.patch(`/api/loan-requests/${loanRequestId}/offers/${id}/`, mapOfferToDto(offer))
			.pipe(map((updatedOffer) => mapDtoToOffer(updatedOffer)));
	}

	delete(loanRequestId: number, offerId: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/offers/${offerId}/`);
	}

	getAllowedOffers(loanRequestId: number): Observable<any> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/allowed-offers/`);
	}

	predictFileCosts(loanRequestId: number, offer: Partial<Offer>): Observable<FileCostPrediction> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/offer/predict/`, offer);
	}
}
