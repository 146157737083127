import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, LoanRequestEntityService } from '@oper-client/shared/data-access';
import { LiabilityInsurance } from '@oper-client/shared/data-model';

@Injectable()
export class LiabilityInsuranceService implements LoanRequestEntityService<LiabilityInsurance> {
	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	public getAll(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/liability-insurances/`, params);
	}
	public create(
		loanRequestId: number,
		liabilityInsurance: LiabilityInsurance,
		params: HttpParams = new HttpParams()
	): Observable<LiabilityInsurance> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/liability-insurances/`, liabilityInsurance, params);
	}

	public update(
		loanRequestId: number,
		id: number,
		liabilityInsurance: LiabilityInsurance,
		params: HttpParams = new HttpParams()
	): Observable<LiabilityInsurance> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/liability-insurances/${id}/`, liabilityInsurance, params);
	}

	public delete(loanRequestId: number, id: number): Observable<void> {
		return this.apiService.delete(`/api/loan-requests/${loanRequestId}/liability-insurances/${id}/`);
	}
}
