import { Component, HostBinding, OnInit, TemplateRef, Type } from '@angular/core';
import { OperOverlayRef } from '../../overlay-ref';

@Component({
	selector: 'oper-client-overlay',
	templateUrl: './overlay.component.html',
	styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
	contentType: 'template' | 'string' | 'component';
	content: string | TemplateRef<any> | Type<any>;
	context;
	wrapperClasses: string[];
	@HostBinding('class') overlayClasses: string[];

	constructor(protected ref: OperOverlayRef) {}

	close() {
		this.ref.close(null);
	}

	ngOnInit() {
		this.wrapperClasses = this.ref.wrapperClasses;
		this.overlayClasses = this.ref.overlayClasses;
		this.content = this.ref.content;

		if (typeof this.content === 'string') {
			this.contentType = 'string';
		} else if (this.content instanceof TemplateRef) {
			this.contentType = 'template';
			this.context = {
				close: this.ref.close.bind(this.ref),
			};
		} else {
			this.contentType = 'component';
		}
	}
}
