import { PartialNormalizedResource, Liability, Periodicity, CreditProvider, ProductDurationConfig } from '@oper-client/shared/data-model';

import { InputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ValidatorService } from '../../services/validator.service';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../../services/validator-constants';


export default function (
	formData?: Partial<Liability>,
	resources?: PartialNormalizedResource,
	creditProviders?: CreditProvider[],
	durationConfig?: Partial<ProductDurationConfig & { periodicity?: Periodicity }>
): InputBase<any>[] {
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();
	return [
		...(durationConfig.periodicity === Periodicity.YEARLY
			? [
					new InputField({
						key: 'amount',
						label: 'ç.question.amountYearly.label',
						value: formData?.amount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
					}),
				]
			: [
					new InputField({
						key: 'monthlyAmount',
						label: 'ç.question.amountMonthly.label',
						value: formData?.monthlyAmount,
						required: true,
						type: 'number',
						currency: true,
						class: 'span6',
					}),
				]),
		new InputField({
			key: 'startDate',
			label: 'ç.question.startDate.label',
			value: formData?.startDate,
			type: 'date',
			required: false,
			validators: [
				ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE),
			],
			max: currentDate,
			class: 'span6',
		}),
		new InputField({
			key: 'endDate',
			label: 'ç.question.endDate.label',
			value: formData?.endDate,
			type: 'date',
			required: false,
			class: 'span6',
		}),
		new InputField({
			key: 'isOutstandingAmount',
			label: 'ç.question.outstandingAmount.label',
			value: typeof formData?.outstandingAmount !== 'undefined' && formData?.outstandingAmount !== null,
			type: 'checkbox',
			class: 'span6',
		}),
		new InputField({
			key: 'outstandingAmount',
			label: 'ç.question.outstandingAmount.label',
			value: formData?.outstandingAmount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span6',
			hidden: outstandingAmountHiddenAsync,
		}),
	];
}

const outstandingAmountHiddenAsync = (formGroup: FormGroup, destroy$: Subject<void>): Observable<boolean> => {
	const subject = new BehaviorSubject(!formGroup.value['isOutstandingAmount']);

	formGroup.controls['isOutstandingAmount'].valueChanges
		.pipe(
			map((value) => !value),
			takeUntil(destroy$)
		)
		.subscribe({
			complete: () => subject.complete(),
			error: (error) => subject.error(error),
			next: (value) => subject.next(value),
		});

	return subject.asObservable();
};
