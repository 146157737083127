import {
	PartialNormalizedResource,
	MortgageRank,
	CreditProvider,
} from '@oper-client/shared/data-model';

import { InputField, InputSelect } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { Validators } from '@angular/forms';

export default function(formData?: Partial<MortgageRank>, resources?: PartialNormalizedResource, creditProviders? : any[]): InputBase<any>[] {
	const creditProvidersAsResource = creditProviders.map((creditProvider: CreditProvider) => ({
		id: creditProvider.id,
		definition: creditProvider.name,
		key: creditProvider.name,
		order: creditProvider.order,
	}))
	return [
		new InputSelect({
			key: 'creditProvider.id',
			errorLabel: 'ç.feature.validator.required',
			value: formData?.creditProvider?.id,
			options: creditProvidersAsResource || [],
			required: true,
			class: 'span12',
		}),
		new InputField({
			key: 'amount',
			errorLabel: 'ç.feature.validator.required',
			value: formData?.amount,
			type: 'number',
			currency: true,
			class: 'span12',
			required: true,
			validators: [Validators.min(1)],
		}),
	];
}
