import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MORTGAGE_SIMULATOR_KEY, MortgageSimulatorState } from './mortgage-simulator.reducer';

export const selectMortgageSimulatorState = createFeatureSelector<MortgageSimulatorState>(MORTGAGE_SIMULATOR_KEY);

export const getConfiguration = createSelector(selectMortgageSimulatorState, (state) => state.configuration);
export const getActiveStep = createSelector(selectMortgageSimulatorState, (state) => state.activeStep);
export const getSimulationFlowType = createSelector(selectMortgageSimulatorState, (state) => state.simulationFlowType);
export const getRecalculationFormNames = createSelector(selectMortgageSimulatorState, (state) => state.formNames);
export const getFromQueryParam = createSelector(selectMortgageSimulatorState, (state) => state.fromQueryParam);

export const getLoadingIndicator = createSelector(selectMortgageSimulatorState, (state) => state.loadingIndicator);
export const getFinancialData = createSelector(selectMortgageSimulatorState, (state) => state.financialData);
export const getMortgageReport = createSelector(selectMortgageSimulatorState, (state) => state.mortgageReport);
export const getMortgageReportError = createSelector(selectMortgageSimulatorState, (state) => state.mortgageReport.errors);
export const loadMortgageReportActionState = createSelector(selectMortgageSimulatorState, (state) => state.actions.loadMortgageReport);
export const getError = createSelector(selectMortgageSimulatorState, (state) => state.error);
export const getDataPoints = createSelector(selectMortgageSimulatorState, (state) => state.dataPoints);
