import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import { formatNumber, formatCurrency } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnvironmentNumberFormatService, NumberInputOptions } from '@oper-client/shared/util-formatting';

@Directive({
	selector: '[operClientNumberToDecimalDirective]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NumberToDecimalDirective),
			multi: true,
		},
	],
})
export class NumberToDecimalDirective implements ControlValueAccessor {
	private readonly dot = '.';
	private readonly comma = ',';

	private _options: NumberInputOptions;
	private separators: RegExp;
	private thousandSeparators: RegExp;
	private numbersAndSeparators: RegExp;
	private hangingDecimalSeparatorOrZero: RegExp;
	private hangingZeroStart: RegExp;
	private hangingZeroEnd: RegExp;
	private numberOfFractionDigits = 4;

	@Input() set options(value: Partial<NumberInputOptions>) {
		const numberValue = this.parseToDecimal(this.el.nativeElement.value);
		this._options = {
			...this.environmentService.getDefaultCurrencyInputOptions(),
			...value,
		};
		this.init();
		if (numberValue !== null) {
			this.el.nativeElement.value = this.formatToString(numberValue, true);
		}
	}

	get options(): Partial<NumberInputOptions> {
		if (!this._options) {
			this._options = this.environmentService.getDefaultCurrencyInputOptions();
		}
		return this._options;
	}

	@Output() valueChanged = new EventEmitter<number>();

	private get decimal(): string {
		return this.options.decimal;
	}

	private get thousand(): string {
		return this.options.thousands;
	}

	private get digitsInfo(): string {
		return this.options.digitsInfo;
	}

	private get max() {
		return this.options.max;
	}

	private get min() {
		return this.options.min;
	}

	onChange: any = () => {};
	onTouch: any = () => {};

	constructor(
		private el: ElementRef,
		private environmentService: EnvironmentNumberFormatService
	) {
		this.init();
	}

	init() {
		this.separators = new RegExp(`[\\${this.dot}*\\${this.comma}*\\${this.decimal}*]`);
		this.thousandSeparators = new RegExp(`\\${this.thousand}`, 'g');
		this.numbersAndSeparators = new RegExp(`[\\d*\\${this.dot}*\\${this.comma}*\\${this.decimal}*]`);
		this.hangingDecimalSeparatorOrZero = new RegExp(`(\\${this.decimal}0*)$`, 'g');
		this.hangingZeroStart = new RegExp(`^[0|\\${this.thousand}][\\d|\\${this.thousand}]`);
		this.hangingZeroEnd = new RegExp(`\\${this.decimal}0*[1-9]+(0+)$`);
		this.numberOfFractionDigits = +(this.digitsInfo?.split('-')?.[1] || 4);
	}

	@HostListener('input', ['$event'])
	onInput(event: InputEvent) {
		const originalLength = this.el.nativeElement.value.length;

		const { initialStringValue, initialCaretPosition, isDecimalPointInput } = this.normaliseInitialStringValue(event);

		// storing the handling decimal point or handling zeros: 123',' or 123','0..0
		const hangingDecimalSeparatorOrZero: string = this.matchHangingDecimalPointOrZeros(initialStringValue);

		const numberValue = this.parseToDecimal(initialStringValue);
		if (isDecimalPointInput || !initialStringValue?.match(this.hangingZeroStart)) {
			const formattedStringValue = this.formatToString(numberValue, false) + hangingDecimalSeparatorOrZero;

			this.el.nativeElement.value = formattedStringValue || null;
			let caretPosition = initialCaretPosition;
			const indexOfDecimal = formattedStringValue.indexOf(this.decimal);
			if (isDecimalPointInput) {
				caretPosition = indexOfDecimal + 1;
			} else if (initialCaretPosition < indexOfDecimal || indexOfDecimal === -1) {
				caretPosition = formattedStringValue.length - originalLength + initialCaretPosition;
			}
			this.el.nativeElement.setSelectionRange(caretPosition, caretPosition);
		}

		this.onTouch(numberValue);
		this.onChange(numberValue);
		this.valueChanged.emit(numberValue);
	}

	normaliseInitialStringValue(event: InputEvent): {
		initialStringValue: string;
		initialCaretPosition: number;
		isDecimalPointInput: boolean;
	} {
		const initialCaretPosition = this.el.nativeElement.selectionStart;
		let initialStringValue: string = this.el.nativeElement.value;

		// dot, comma and locale decimal separators - keep/replace by the decimal separator at this position
		const decimalSeparatorInput = event?.data?.match(this.separators);
		if (decimalSeparatorInput) {
			initialStringValue =
				initialStringValue.substring(0, initialCaretPosition - 1).replace(this.decimal, '') +
				this.decimal +
				initialStringValue.substring(initialCaretPosition - 1 + this.decimal.length).replace(this.decimal, '');
			this.el.nativeElement.value = initialStringValue;
		}

		// trim characters that exceeds the number of decimals
		const indexOfDecimalSeparator = initialStringValue?.indexOf(this.decimal);
		if (indexOfDecimalSeparator > -1 && indexOfDecimalSeparator + this.numberOfFractionDigits + 1 < initialStringValue.length) {
			initialStringValue = initialStringValue.slice(0, indexOfDecimalSeparator + this.numberOfFractionDigits + 1);
		}

		return {
			initialStringValue,
			initialCaretPosition,
			isDecimalPointInput: !!decimalSeparatorInput,
		};
	}

	matchHangingDecimalPointOrZeros(stringValue: string): string {
		if (this.numberOfFractionDigits > 0) {
			let hangingDecimalSeparatorOrZero = stringValue.match(this.hangingDecimalSeparatorOrZero)?.[0] || '';
			if (hangingDecimalSeparatorOrZero === '') {
				const hangingZeroMatch = stringValue.match(this.hangingZeroEnd);
				hangingDecimalSeparatorOrZero = stringValue.match(this.hangingZeroEnd)?.[hangingZeroMatch.length - 1] || '';
			}
			return hangingDecimalSeparatorOrZero;
		}
		return '';
	}

	@HostListener('keypress', ['$event'])
	onKeyPress(event: KeyboardEvent) {
		if (event.ctrlKey || event.metaKey) return;

		if (event.key == null) return;
		if (!this.numbersAndSeparators) return;

		if (this.numbersAndSeparators.exec(event.key) === null) {
			event.preventDefault();
		}
	}

	@HostListener('focusout', ['$event'])
	onBlur() {
		const initialStringValue = this.el.nativeElement.value;
		const initialNumberValue = this.parseToDecimal(initialStringValue);

		let numberValue = initialNumberValue;
		//mimic current behaviour  of decimal fields TODO need to check behaviour and allow setting null (empty field)
		if (numberValue === null && this.options.inputMode !== 'currency') {
			numberValue = 0;
		}
		if (isNumber(numberValue)) {
			if (isNumber(this.min) && this.min > numberValue) {
				numberValue = this.min;
			}
			if (isNumber(this.max) && this.max < numberValue) {
				numberValue = this.max;
			}
		}

		const formattedStringValue = this.formatToString(numberValue, true);
		this.el.nativeElement.value = formattedStringValue || null;

		if (initialNumberValue !== numberValue) {
			this.onTouch(numberValue);
			this.onChange(numberValue);
			this.valueChanged.emit(numberValue);
		}
	}

	private formatToString(value: number, formatFractionDigits = false): string {
		if (!isNumber(value)) {
			return '';
		}
		switch (this.options.inputMode) {
			case 'currency':
				return formatCurrency(
					value,
					this.environmentService.getLocale(),
					'',
					'',
					formatFractionDigits ? this.digitsInfo : `1.0-${this.numberOfFractionDigits}`
				);
			case 'decimal':
			case 'percentage':
			default:
				return formatNumber(
					value,
					this.environmentService.getLocale(),
					formatFractionDigits ? this.digitsInfo : `1.0-${this.numberOfFractionDigits}`
				);
		}
	}

	parseToDecimal(value: string): number {
		if (value?.length < 1) {
			return null;
		}
		const normalizedDelimiters = value.replace(this.thousandSeparators, '').replace(new RegExp(`\\${this.decimal}`), '.');
		const decimal = parseFloat(normalizedDelimiters);
		if (isNaN(decimal)) {
			return null;
		}
		return decimal;
	}

	writeValue(value: string | number | null): void {
		let formattedNumberValue: string;
		if (typeof value === 'number') {
			formattedNumberValue = this.formatToString(value, true);
		} else if (typeof value === 'string') {
			formattedNumberValue = this.formatToString(this.parseToDecimal(value), true);
		} else {
			formattedNumberValue = null;
		}
		this.el.nativeElement.value = formattedNumberValue;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
}

function isNumber(value: number): boolean {
	return typeof value === 'number' && !isNaN(value);
}
