import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as DiscountActions from './discount.actions';
import { DiscountService } from '../../services/discount.service';

@Injectable()
export class DiscountEffects {
	constructor(private actions$: Actions, private discountService: DiscountService) {}

	loadDiscounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscountActions.loadProductDiscounts),
			switchMap(({ loanRequestId, queryParams }) => {
				return this.discountService.getProductDiscounts(loanRequestId, queryParams).pipe(
					map((discounts) => DiscountActions.loadProductDiscountsSuccess({ discounts })),
					catchError((error) => of(DiscountActions.loadProductDiscountsFailure({ error: error })))
				);
			})
		)
	);

	loadSelectedDiscountsFromOffer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscountActions.loadSelectedDiscountsFromOffer),
			switchMap(({ loanRequestId, offerId }) => {
				return this.discountService.getProductDiscounts(loanRequestId, { offerId }).pipe(
					map((discounts) => DiscountActions.loadSelectedDiscountsFromOfferSuccess({ discounts })),
					catchError((error) => of(DiscountActions.loadSelectedDiscountsFromOfferFailure({ error: error })))
				);
			})
		)
	);
}
