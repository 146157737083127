import { createSelector } from '@ngrx/store';

import { min, max } from '@oper-client/shared/util-array';
import { Client, Relationship } from '@oper-client/shared/data-model';
import { getClientFeatureState } from '../client.selectors';
import { CLIENT_ENTITY_KEY, clientAdapter, ClientActionTypes } from './client.reducer';

export const getClientState = createSelector(getClientFeatureState, (state) => state[CLIENT_ENTITY_KEY]);

const selectors = clientAdapter.getSelectors();

export const getClients = createSelector(getClientState, selectors.selectAll);
export const getClientEntities = createSelector(getClientState, selectors.selectEntities);
export const getCurrentClientId = createSelector(getClientState, (state) => state.currentClientId);
export const getCurrentClient = createSelector(getClientEntities, getCurrentClientId, (clientMap, currentClientId) => {
	if (currentClientId === null) {
		return null;
	} else if (currentClientId === 0) {
		return <Client>{};
	}
	return clientMap[currentClientId];
});
export const getMainClientId = createSelector(getClientState, ({ ids }) => min(ids));
export const getMainClient = createSelector(getClientEntities, getMainClientId, (clientMap, mainClientId) => clientMap?.[mainClientId]);

export const getSecondClientId = createSelector(getClientState, ({ ids }) => (ids.length > 1 ? max(ids) : null));
export const getSecondClient = createSelector(
	getClientEntities,
	getSecondClientId,
	(clientMap, secondClientId) => clientMap?.[secondClientId]
);

export const getCurrentClientRelationships = createSelector(getClientEntities, getCurrentClient, (clients, currentClient) => {
	const clientRelationsships: Relationship[] = [];

	for (const key in clients) {
		if (currentClient?.id !== +key && clients[key]?.loanRequest?.id === currentClient?.loanRequest?.id) {
			const relationship = currentClient?.relationships?.find((x) => x.clientTo?.id === +key);
			clientRelationsships.push({
				id: relationship?.id ?? null,
				clientTo: clients[key],
				clientFrom: currentClient,
				relationship: relationship?.relationship ?? null,
				liveInTheSameHousehold: relationship?.liveInTheSameHousehold ?? false,
			});
		}
	}
	return clientRelationsships;
});

export const getCreateNewClientModalShown = createSelector(getClientState, (state) => state.createNewClientModalShown);
export const getActionStates = createSelector(getClientState, (state) => state.actions);
export const getActionState = (actionType: ClientActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
