import { of } from 'rxjs';

import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any): InputBase<any>[] {
	return [
		new InputField({
			key: 'householdIncome',
			label: formData?.yearlyPeriodicity ? 'ç.question.yearlyHouseholdIncome.label' : 'ç.question.monthlyHouseholdIncome.label',
			helpText: of(
				formData?.yearlyPeriodicity ? 'ç.question.yearlyHouseholdIncome.helpText' : 'ç.question.monthlyHouseholdIncome.helpText'
			),
			value: formData?.householdIncome || null,
			updateOn: 'change',
			type: 'number',
			currency: true,
			required: true,
		}),
	];
}
