import { Ownership, PartialNormalizedResource, Resource } from '@oper-client/shared/data-model';

import { InputField, InputSelect, PercentageInputField } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { SelectOption } from '@oper-client/shared/util-data-model-transform';
import { Validators } from '@angular/forms';

export default function (
	formData?: Partial<Ownership>,
	resources?: PartialNormalizedResource,
	ownershipTypes?: Resource[],
	clientOptions?: SelectOption[],
	maxPercentage?: number
): InputBase<any>[] {
	return [
		new InputField({
			key: 'id',
			value: formData?.id,
			type: 'hidden',
			required: false,
		}),
		new InputSelect({
			key: 'client.id',
			label: 'ç.question.client.label',
			required: true,
			options: clientOptions,
			class: 'span12',
			bindLabel: 'name',
			value: formData?.client?.id,
		}),
		new InputSelect({
			key: 'ownershipType.id',
			label: 'ç.question.ownershipType.label',
			required: true,
			options: ownershipTypes,
			class: 'span12',
			bindLabel: 'name',
			value: formData?.ownershipType?.id,
		}),
		new PercentageInputField({
			key: 'percentage',
			label: 'ç.question.percentage.label',
			value: formData?.percentage,
			required: true,
			type: 'number',
			min: 0,
			max: 100,
			validators: [Validators.min(0), Validators.max(maxPercentage ?? 100)],
		}),
	];
}
