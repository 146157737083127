import { createSelector } from '@ngrx/store';

import { getClientFeatureState } from '../client.selectors';
import { INCOME_ENTITY_KEY, incomeAdapter, IncomeActionTypes } from './income.reducer';

export const getIncomeState = createSelector(getClientFeatureState, (state) => state[INCOME_ENTITY_KEY]);

const selectors = incomeAdapter.getSelectors();

export const getIncomes = createSelector(getIncomeState, selectors.selectAll);
export const getIncomeEntities = createSelector(getIncomeState, selectors.selectEntities);

export const getActionStates = createSelector(getIncomeState, (state) => state.actions);
export const getActionState = (actionType: IncomeActionTypes) => createSelector(getActionStates, (state) => state[actionType]);
