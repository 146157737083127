<div class="switch-wrap">
	<button [class.active]="value === valueA" (click)="onChange(valueA)" (blur)="_onTouched()">
		{{ labelA | translate }}
		<fa-icon *ngIf="value === valueA" class="icon" [icon]="iconActive"></fa-icon>
	</button>
	<input type="checkbox" [(ngModel)]="ngModelValue" (blur)="_onTouched()" />
	<button [class.active]="value === valueB" (click)="onChange(valueB)" (blur)="_onTouched()">
		{{ labelB | translate }}
		<fa-icon *ngIf="value === valueB" class="icon" [icon]="iconActive"></fa-icon>
	</button>
</div>
