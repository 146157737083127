import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import * as FeatureFlagActions from './feature-flag.actions';
import * as FeatureFlagSelectors from './feature-flag.selectors';

@Injectable()
export class FeatureFlagFacade {
	featureFlags$ = this.store.pipe(select(FeatureFlagSelectors.getFeatureFlags));
	loadFeatureFlagsActionState$ = this.store.pipe(select(FeatureFlagSelectors.getLoadFeatureFlagsActionState));

	constructor(private store: Store<any>) {}

	loadFeatureFlags(): void {
		this.dispatch(FeatureFlagActions.loadFeatureFlags());
	}

	dispatch(action: Action): void {
		this.store.dispatch(action);
	}
}
