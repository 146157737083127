import { createReducer, on } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import * as SimulatorActions from './advisor-simulator.actions';
import {
	ActionState,
	ActionTypes,
	setActionState,
	initialActionState,
	Simulator,
	ProductSearchFilterParams,
	ProductSortableKeys,
	Product,
	findUniqueProductIndex,
	Offer,
	AnyObject,
	BaseProductDiscounts,
	BorrowerUploadDocumentType,
} from '@oper-client/shared/data-model';
import { SortDirection, SortOrder, getNextSortOrderState, updateListItem } from '@oper-client/shared/util-array';

const DEFAULT_SIMULATOR_INFORMATION_BOX: AnyObject<boolean> = {
	[Simulator.SimulatorStepEnum.EXPLORE_PRODUCTS]: true,
	[Simulator.SimulatorStepEnum.CREATE_SOLUTIONS]: true,
};

const DEFAULT_PRODUCTS_SORT_ORDER: SortOrder<ProductSortableKeys> = {
	sortBy: 'rate',
	sortDirection: SortDirection.ASC,
};

const DEFAULT_SIMULATIONS_SORT_ORDER: SortOrder<Simulator.SortableKeys>[] = [{ sortBy: 'lastModified', sortDirection: SortDirection.DESC }];

export const ADVISOR_SIMULATOR_KEY = 'advisorSimulator';

export interface AdvisorSimulatorState extends EntityState<Simulator.SimulationSearchResult> {
	searchResponse: Simulator.SearchSimulationResponse;
	configuration: Simulator.CustomerConfiguration;
	convertToLoanRequestResponse: Simulator.ConvertSimulationToLoanRequestResponse;
	discounts: BaseProductDiscounts[];
	offers: Partial<Offer>[];
	productsSortOrder: SortOrder<ProductSortableKeys>;
	simulationsSortOrder: SortOrder<string>[];
	simulatorInformationBox: AnyObject<boolean>;
	assignAnalystDialogVisibility: boolean;
	viewMode: Simulator.ViewMode;
	products: Product[];
	selectedOfferIds: number[];
	currentSimulation: Simulator.Simulation;
	activeSimulatorStep: Simulator.SimulatorStepEnum;
	actions: SimulatorActionsState;
	productSearchFilters: ProductSearchFilterParams;
	documents: BorrowerUploadDocumentType[];
}

export type SimulatorActionTypes =
	| 'calculateSimulation'
	| 'loadSimulations'
	| 'loadMoreSimulations'
	| 'loadSimulation'
	| 'createSimulation'
	| 'updateSimulation'
	| 'removeSimulation'
	| 'cleanUpSimulations'
	| 'loadProducts'
	| 'loadOffers'
	| 'updateOffer'
	| 'loadDiscounts'
	| 'convertSimulationToLoanRequest'
	| 'loadConfiguration'
	| 'loadBorrowerDocumentTypes';

export type SimulatorActionsState = Record<SimulatorActionTypes, ActionState>;

export const simulationAdapter: EntityAdapter<Simulator.SimulationSearchResult> = createEntityAdapter<Simulator.SimulationSearchResult>();

export const initialState: AdvisorSimulatorState = simulationAdapter.getInitialState({
	searchResponse: null,
	configuration: null,
	currentSimulation: null,
	convertToLoanRequestResponse: null,
	simulationsSortOrder: DEFAULT_SIMULATIONS_SORT_ORDER,
	activeSimulatorStep: null,
	productsSortOrder: DEFAULT_PRODUCTS_SORT_ORDER,
	simulatorInformationBox: DEFAULT_SIMULATOR_INFORMATION_BOX,
	products: [],
	offers: [],
	selectedOfferIds: [],
	discounts: [],
	productSearchFilters: null,
	assignAnalystDialogVisibility: true,
	viewMode: null,
	documents: [],
	actions: {
		calculateSimulation: initialActionState,
		loadSimulations: initialActionState,
		loadMoreSimulations: initialActionState,
		loadSimulation: initialActionState,
		createSimulation: initialActionState,
		updateSimulation: initialActionState,
		removeSimulation: initialActionState,
		cleanUpSimulations: initialActionState,
		loadProducts: initialActionState,
		loadOffers: initialActionState,
		updateOffer: initialActionState,
		loadDiscounts: initialActionState,
		convertSimulationToLoanRequest: initialActionState,
		loadConfiguration: initialActionState,
		loadBorrowerDocumentTypes: initialActionState,
	},
});

function setActionStates(
	actionState: SimulatorActionsState,
	action: SimulatorActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null,
	ids?: any
): SimulatorActionsState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error, ids),
	};
}

function updateActionStates(
	actionState: SimulatorActionsState,
	action: SimulatorActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null,
	ids?: any
): SimulatorActionsState {
	return {
		...actionState,
		[action]: setActionState(actionState[action], actionType, error, ids),
	};
}

export const reducer = createReducer(
	initialState,
	on(SimulatorActions.calculateSimulation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.loading),
	})),
	on(SimulatorActions.calculateSimulationSuccess, (state, { result, payload }) => ({
		...state,
		currentSimulation: {
			...state.currentSimulation,
			...payload,
			...result,
		},
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.success),
	})),
	on(SimulatorActions.calculateSimulationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'calculateSimulation', ActionTypes.failure, error),
	})),
	on(SimulatorActions.loadSimulations, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSimulations', ActionTypes.loading),
	})),
	on(SimulatorActions.loadSimulationsSuccess, (state, { response }) =>
		simulationAdapter.setAll(response.results, {
			...state,
			searchResponse: response,
			actions: setActionStates(state.actions, 'loadSimulations', ActionTypes.success),
		})
	),
	on(SimulatorActions.loadSimulationsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSimulations', ActionTypes.failure, error),
	})),
	on(SimulatorActions.loadMoreSimulations, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMoreSimulations', ActionTypes.loading),
	})),
	on(SimulatorActions.loadMoreSimulationsSuccess, (state, { response }) =>
		simulationAdapter.addMany(response.results, {
			...state,
			searchResponse: response,
			actions: setActionStates(state.actions, 'loadMoreSimulations', ActionTypes.success),
		})
	),
	on(SimulatorActions.loadMoreSimulationsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadMoreSimulations', ActionTypes.failure, error),
	})),
	on(SimulatorActions.loadSimulation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSimulation', ActionTypes.loading),
	})),
	on(SimulatorActions.loadSimulationSuccess, (state, { simulation }) =>
		simulationAdapter.upsertOne(simulation, {
			...state,
			actions: setActionStates(state.actions, 'loadSimulation', ActionTypes.success),
		})
	),
	on(SimulatorActions.loadSimulationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSimulation', ActionTypes.failure, error),
	})),
	on(SimulatorActions.createSimulation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createSimulation', ActionTypes.loading),
	})),
	on(SimulatorActions.createSimulationSuccess, (state, { simulation }) =>
		simulationAdapter.addOne(simulation, {
			...state,
			actions: setActionStates(state.actions, 'createSimulation', ActionTypes.success, null, simulation.id),
		})
	),
	on(SimulatorActions.createSimulationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'createSimulation', ActionTypes.failure, error),
	})),
	on(SimulatorActions.updateSimulation, (state) => ({
		...state,
		actions: updateActionStates(state.actions, 'updateSimulation', ActionTypes.loading),
	})),
	on(SimulatorActions.updateSimulationSuccess, (state, { simulation }) =>
		simulationAdapter.updateOne(
			{ id: simulation.id, changes: simulation },
			{
				...state,
				actions: updateActionStates(state.actions, 'updateSimulation', ActionTypes.success),
			}
		)
	),
	on(SimulatorActions.updateSimulationFailure, (state, { error }) => ({
		...state,
		actions: updateActionStates(state.actions, 'updateSimulation', ActionTypes.failure, error),
	})),
	on(SimulatorActions.removeSimulation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'removeSimulation', ActionTypes.loading),
	})),
	on(SimulatorActions.removeSimulationSuccess, (state, { simulationId }) =>
		simulationAdapter.removeOne(simulationId, {
			...state,
			actions: setActionStates(state.actions, 'removeSimulation', ActionTypes.success),
		})
	),
	on(SimulatorActions.removeSimulationFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'removeSimulation', ActionTypes.failure, error),
	})),
	on(SimulatorActions.cleanUpSimulations, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'cleanUpSimulations', ActionTypes.loading),
	})),
	on(SimulatorActions.cleanUpSimulationsSuccess, (state) =>
		simulationAdapter.removeAll({
			...state,
			actions: setActionStates(state.actions, 'cleanUpSimulations', ActionTypes.success),
		})
	),
	on(SimulatorActions.cleanUpSimulationsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'cleanUpSimulations', ActionTypes.failure, error),
	})),
	on(SimulatorActions.setCurrentSimulation, (state, { simulation }) => ({
		...state,
		currentSimulation: simulation,
	})),
	on(SimulatorActions.clearCurrentSimulation, (state) => ({
		...state,
		currentSimulation: initialState.currentSimulation,
		viewMode: initialState.viewMode,
	})),
	on(SimulatorActions.setActiveSimulatorStep, (state, { step }) => ({
		...state,
		activeSimulatorStep: step,
	})),
	on(SimulatorActions.clearActiveSimulatorStep, (state) => ({
		...state,
		activeSimulatorStep: initialState.activeSimulatorStep,
	})),
	on(SimulatorActions.setProductSearchFilters, (state, { filters }) => ({
		...state,
		productSearchFilters: filters,
	})),
	on(SimulatorActions.clearProductSearchFilters, (state) => ({
		...state,
		productSearchFilters: initialState.productSearchFilters,
	})),
	on(SimulatorActions.hideInformationBox, (state, { step }) => ({
		...state,
		simulatorInformationBox: { ...state.simulatorInformationBox, [step]: false },
	})),
	on(SimulatorActions.loadProducts, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProducts', ActionTypes.loading),
	})),
	on(SimulatorActions.loadProductsSuccess, (state, { products }) => ({
		...state,
		products,
		actions: setActionStates(state.actions, 'loadProducts', ActionTypes.success),
	})),
	on(SimulatorActions.loadProductsFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadProducts', ActionTypes.failure, error),
	})),
	on(SimulatorActions.selectProducts, (state, { products }) => ({
		...state,
		currentSimulation: { ...state.currentSimulation, selectedProducts: [...state.currentSimulation.selectedProducts, ...products] },
	})),
	on(SimulatorActions.unselectProducts, (state, { products }) => ({
		...state,
		currentSimulation: {
			...state.currentSimulation,
			selectedProducts: state.currentSimulation.selectedProducts.filter(
				(selectedProduct) => !products.some((_, index, array) => index === findUniqueProductIndex(array, selectedProduct))
			),
		},
	})),
	on(SimulatorActions.selectAllProducts, (state) => ({
		...state,
		currentSimulation: {
			...state.currentSimulation,
			selectedProducts: [...state.products],
		},
	})),
	on(SimulatorActions.unselectAllProducts, (state) => ({
		...state,
		currentSimulation: {
			...state.currentSimulation,
			selectedProducts: [],
		},
	})),
	on(SimulatorActions.selectProduct, (state, { product }) => ({
		...state,
		currentSimulation: { ...state.currentSimulation, selectedProducts: [...state.currentSimulation.selectedProducts, product] },
	})),
	on(SimulatorActions.unselectProduct, (state, { product }) => ({
		...state,
		currentSimulation: {
			...state.currentSimulation,
			selectedProducts: state.currentSimulation.selectedProducts.filter(
				(_, index, array) => index !== findUniqueProductIndex(array, product)
			),
		},
	})),
	on(SimulatorActions.clearProducts, (state) => ({
		...state,
		products: initialState.products,
	})),
	on(SimulatorActions.sortProductsByKey, (state, { sortableKey }) => ({
		...state,
		productsSortOrder: getNextSortOrderState(sortableKey, state.productsSortOrder, DEFAULT_PRODUCTS_SORT_ORDER),
	})),
	on(SimulatorActions.clearProductsSortOrder, (state) => ({
		...state,
		productsSortOrder: DEFAULT_PRODUCTS_SORT_ORDER,
	})),
	on(SimulatorActions.setSimulationsSortOrder, (state, { ordering }) => ({
		...state,
		simulationsSortOrder: ordering,
	})),
	on(SimulatorActions.clearSimulationsSortOrder, (state) => ({
		...state,
		simulationsSortOrder: DEFAULT_SIMULATIONS_SORT_ORDER,
	})),
	on(SimulatorActions.loadOffers, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.loading),
	})),
	on(SimulatorActions.loadOffersSuccess, (state, { offers }) => ({
		...state,
		offers,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.success),
	})),
	on(SimulatorActions.loadOffersFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.failure, error),
	})),
	on(SimulatorActions.loadBorrowerDocumentTypes, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadBorrowerDocumentTypes', ActionTypes.loading),
	})),
	on(SimulatorActions.loadBorrowerDocumentTypesSuccess, (state, { documents }) => ({
		...state,
		documents,
		actions: setActionStates(state.actions, 'loadBorrowerDocumentTypes', ActionTypes.success),
	})),
	on(SimulatorActions.loadBorrowerDocumentTypesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'loadBorrowerDocumentTypes', ActionTypes.failure, error),
	})),
	on(SimulatorActions.updateOffer, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.loading),
	})),
	on(SimulatorActions.updateOfferSuccess, (state, { offer }) => ({
		...state,
		offers: updateListItem(
			state.offers,
			state.offers.findIndex((o) => o.id === offer.id),
			offer
		),
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.success),
	})),
	on(SimulatorActions.updateOfferFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, 'updateOffer', ActionTypes.failure, error),
	})),
	on(SimulatorActions.clearOffers, (state) => ({
		...state,
		offers: initialState.offers,
		selectedOfferIds: initialState.selectedOfferIds,
	})),
	on(SimulatorActions.selectAllOffers, (state) => ({
		...state,
		selectedOfferIds: state.offers.map((o) => o.id),
	})),
	on(SimulatorActions.unselectAllOffers, (state) => ({
		...state,
		selectedOfferIds: initialState.selectedOfferIds,
	})),
	on(SimulatorActions.selectOffer, (state, { offerId }) => ({
		...state,
		selectedOfferIds: [...state.selectedOfferIds, offerId],
	})),
	on(SimulatorActions.unselectOffer, (state, { offerId }) => ({
		...state,
		selectedOfferIds: state.selectedOfferIds.filter((id) => id !== offerId),
	})),
	on(SimulatorActions.removeOffer, (state, { offerId, creditProviderId }) => ({
		...state,
		offers: state.offers.filter((offer) => offer.id !== offerId),
		selectedOfferIds: state.selectedOfferIds.filter((id) => id !== offerId),
		currentSimulation: {
			...state.currentSimulation,
			selectedProducts: state.currentSimulation.selectedProducts.filter(
				(selectedProduct) => selectedProduct.creditProvider.id !== creditProviderId
			),
		},
	})),
	on(SimulatorActions.removeOffers, (state, { offerIds, creditProviderIds }) => ({
		...state,
		offers: state.offers.filter((offer) => !offerIds.includes(offer.id)),
		selectedOfferIds: state.selectedOfferIds.filter((id) => !offerIds.includes(id)),
		currentSimulation: {
			...state.currentSimulation,
			selectedProducts: state.currentSimulation.selectedProducts.filter(
				(selectedProduct) => !creditProviderIds.includes(selectedProduct.creditProvider.id)
			),
		},
	})),
	on(SimulatorActions.syncSelectedProducts, (state, { products }) => ({
		...state,
		currentSimulation: {
			...state.currentSimulation,
			selectedProducts: products,
		},
	})),
	on(SimulatorActions.clearActions, (state) => ({
		...state,
		actions: initialState.actions,
	})),
	on(SimulatorActions.loadConfiguration, (state) => ({
		...state,
		actions: updateActionStates(state.actions, 'loadConfiguration', ActionTypes.loading),
	})),
	on(SimulatorActions.loadConfigurationSuccess, (state, { configuration }) => ({
		...state,
		configuration,
		actions: updateActionStates(state.actions, 'loadConfiguration', ActionTypes.success),
	})),
	on(SimulatorActions.loadConfigurationFailure, (state, { error }) => ({
		...state,
		actions: updateActionStates(state.actions, 'loadConfiguration', ActionTypes.failure, error),
	})),
	on(SimulatorActions.loadDiscounts, (state) => ({
		...state,
		actions: updateActionStates(state.actions, 'loadDiscounts', ActionTypes.loading),
	})),
	on(SimulatorActions.loadDiscountsSuccess, (state, { discounts }) => ({
		...state,
		discounts,
		actions: updateActionStates(state.actions, 'loadDiscounts', ActionTypes.success),
	})),
	on(SimulatorActions.loadDiscountsFailure, (state, { error }) => ({
		...state,
		actions: updateActionStates(state.actions, 'loadDiscounts', ActionTypes.failure, error),
	})),

	on(SimulatorActions.convertSimulationToLoanRequest, (state) => ({
		...state,
		actions: updateActionStates(state.actions, 'convertSimulationToLoanRequest', ActionTypes.loading),
	})),
	on(SimulatorActions.convertSimulationToLoanRequestSuccess, (state, { response }) => ({
		...state,
		convertToLoanRequestResponse: response,
		actions: updateActionStates(state.actions, 'convertSimulationToLoanRequest', ActionTypes.success),
	})),
	on(SimulatorActions.convertSimulationToLoanRequestFailure, (state, { error }) => ({
		...state,
		actions: updateActionStates(state.actions, 'convertSimulationToLoanRequest', ActionTypes.failure, error),
	})),
	on(SimulatorActions.setAssignAnalystDialogVisibility, (state, { visibility }) => ({
		...state,
		assignAnalystDialogVisibility: visibility,
	})),
	on(SimulatorActions.setViewMode, (state, { viewMode }) => ({
		...state,
		viewMode,
	})),
	on(SimulatorActions.setSelectedDiscounts, (state, { discounts }) => ({
		...state,
		currentSimulation: {
			...state.currentSimulation,
			selectedDiscounts: discounts,
			isDefaultDiscountsOverridden: true,
		},
	})),
	on(SimulatorActions.setConfiguration, (state, { configuration }) => ({
		...state,
		configuration,
	})),
	on(SimulatorActions.clearDiscounts, (state) => ({
		...state,
		discounts: initialState.discounts,
	})),
	on(SimulatorActions.clearSimulations, (state) => ({
		...state,
		searchResponse: initialState.searchResponse,
		...simulationAdapter.removeAll(state),
	}))
);
