import { FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';

export function deepClone(source: any): any {
	if (Array.isArray(source)) {
		return source.map((item) => deepClone(item));
	}
	if (source instanceof Date) {
		return new Date(source.getTime());
	}
	if (source && typeof source === 'object') {
		return Object.getOwnPropertyNames(source).reduce(
			(o, prop) => {
				o[prop] = deepClone(source[prop]);
				return o;
			},
			Object.create(Object.getPrototypeOf(source))
		);
	}
	return source;
}

export function generateGuid(): string {
	const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
}

export function getValue(obj: Record<string, any>, path: string, defVal = null): any {
	if (obj[path]) return obj[path];
	if (typeof path === 'string') {
		const _path = path.split('.');
		return _path.reduce((acc, key) => (acc?.[key] ? acc[key] : defVal), obj);
	}
	return defVal;
}

export function convertObservableToBehaviorSubject<T>(observable: Observable<T>, initValue: T): BehaviorSubject<T> {
	const subject = new BehaviorSubject(initValue);

	observable.subscribe(subject);

	return subject;
}

export function deepEquals(object1: any, object2: any): boolean {
	if (typeof object1 === 'undefined' || typeof object2 === 'undefined') return false;
	if (object1 === null || object2 === null) return false;
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);
	if (keys1.length !== keys2.length) {
		return false;
	}
	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areObjects = isObject(val1) && isObject(val2);
		if ((areObjects && !deepEquals(val1, val2)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}
	return true;
}

function isObject(object: any): boolean {
	return object != null && typeof object === 'object';
}

export function flattenObject(obj: any, parentKey = '', sep = '.'): { [key: string]: any } {
	let items: { [key: string]: any } = {};

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const newKey = parentKey ? `${parentKey}${sep}${key}` : key;

			if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
				const nestedItems = flattenObject(obj[key], newKey, sep);
				items = { ...items, ...nestedItems };
			} else {
				items[newKey] = obj[key];
			}
		}
	}

	return items;
}

export function unflattenObject(flatObj: any, sep = '.'): any {
	const nestedObj: any = {};

	for (const key in flatObj) {
		if (Object.prototype.hasOwnProperty.call(flatObj, key)) {
			const keys = key.split(sep);
			keys.reduce((acc, part, index) => {
				if (index === keys.length - 1) {
					acc[part] = flatObj[key];
				} else {
					if (!acc[part]) {
						acc[part] = {};
					}
					return acc[part];
				}
			}, nestedObj);
		}
	}

	return nestedObj;
}

/**
 * Utility function to get the value of an object by key path
 * @param obj - the object
 * @param path - the key path
 * @returns the value of the key path or undefined if the key path does not exist
 */
export function getValueByKeyPath(obj: any, path: string): any {
	path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
	path = path.replace(/^\./, ''); // strip a leading dot
	const a = path.split('.');
	for (let i = 0, n = a.length; i < n; ++i) {
		const k = a[i];
		if (k in obj) {
			obj = obj[k];
		} else {
			return;
		}
	}
	return obj;
}

/**
 * Utility function to remove the undefined values from an object recursively.
 * @param obj - the object
 * @returns the object without the undefined values
 */
export function removeUndefinedValues<T>(obj: T): Partial<T> {
	if (Array.isArray(obj)) {
		return obj.map((item) => removeUndefinedValues(item)).filter((item) => item !== undefined) as unknown as Partial<T>;
	}

	if (typeof obj === 'object' && obj !== null) {
		return Object.entries(obj).reduce((acc, [key, value]) => {
			const cleanedValue = removeUndefinedValues(value);
			if (cleanedValue !== undefined) {
				return { ...acc, [key]: cleanedValue };
			}
			return acc;
		}, {} as Partial<T>);
	}

	return obj;
}

/**
 * Utility function to remove the empty (null/undefined/empty string/empty object) values from an object recursively.
 * @param obj - the object
 * @returns the object without the empty values
 */
export function removeNullValues<T>(obj: T): Partial<T> {
	if (Array.isArray(obj)) {
		return obj.map((item) => removeNullValues(item)).filter((item) => !isEmpty(item)) as unknown as Partial<T>;
	}

	if (typeof obj === 'object' && obj !== null) {
		return Object.entries(obj).reduce((acc, [key, value]) => {
			const cleanedValue = removeNullValues(value);
			if (!isEmpty(cleanedValue)) {
				return { ...acc, [key]: cleanedValue };
			}
			return acc;
		}, {} as Partial<T>);
	}

	return obj;
}

/**
 * Helper function to check if a value is empty (null/undefined/empty string/empty object/empty array).
 * @param value - the value to check
 * @returns true if the value is empty, false otherwise
 */
export function isEmpty(value: any): boolean {
	return (
		value === null ||
		value === undefined ||
		(typeof value === 'string' && value === '') ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === 'object' && Object.keys(value).length === 0)
	);
}

/**
 * Utility function to get the form value from valid form controls in a form group
 * @param formGroup - the form group
 * @returns the form value with only the valid form controls that have a value
 */
export function getValidFormValue<T>(formGroup: FormGroup): T {
	const formValue = Object.entries(formGroup.controls).reduce((acc, [key, control]) => {
		return control.valid ? { ...acc, [key]: control.value } : acc;
	}, {});

	return removeNullValues(formValue) as T;
}

export function updateNestedProperty(obj: any, fieldPath: string, value: any): any {
	const fields = fieldPath.split('.');
	const lastField = fields.pop();
	const newObj = { ...obj };

	const target = fields.reduce((acc, field) => {
		acc[field] = { ...acc[field] };
		return acc[field];
	}, newObj);

	if (lastField) {
		target[lastField] = value;
	}

	return newObj;
}

/**
 * This function merges two objects recursively. It takes the target object and the changes object and merges the changes into the target.
 * @param target - The target object to merge the changes into.
 * @param changes - The changes object to merge into the target.
 * @returns - The target object with the changes merged into it.
 */
export function mergeObjects(target: any, changes: any): any {
	for (const key in changes) {
		if (Object.prototype.hasOwnProperty.call(changes, key)) {
			mergeProperty(target, changes, key);
		}
	}
	return target;
}

function mergeProperty(target: any, changes: any, key: string): void {
	if (typeof changes[key] === 'object' && changes[key] !== null) {
		if (Array.isArray(changes[key])) {
			target[key] = changes[key]; // If the property is an array, replace the target array with the changes array
		} else {
			// If the property is an object, recursively merge
			if (!target[key]) {
				target[key] = {};
			}
			mergeObjects(target[key], changes[key]);
		}
	} else {
		target[key] = changes[key]; // If the property is a primitive value, directly assign it
	}
}

export function updateAndGetNestedProperty(obj: unknown, fieldPath: string, value: unknown): unknown {
	const fields = fieldPath.split('.');
	const lastField = fields.pop();

	const modifiedObj: any = {};

	let target = modifiedObj;

	fields.forEach((field) => {
		target[field] = {};
		target = target[field];
	});

	if (lastField) {
		target[lastField] = value;
	}

	return modifiedObj;
}

/**
 * This function checks if the properties of the `changes` object are equal to the corresponding properties in the `source` object recursively.
 * It does not require the `changes` object to have the same length as the `source` object.
 * Only the properties present in the `changes` object are compared.
 *
 * @param changes - The object containing the properties to compare.
 * @param source - The object to compare against.
 * @returns - True if the properties in the `changes` object are equal to the corresponding properties in the `source` object, false otherwise.
 */
export function isEqualCheck(changes: unknown, source: unknown): boolean {
	if (changes === source) {
		return true;
	}

	if (typeof changes !== 'object' || changes === null || typeof source !== 'object' || source === null) {
		return false;
	}

	if (Array.isArray(changes) !== Array.isArray(source)) {
		return false;
	}

	const changesKeys = Object.keys(changes as object);

	for (const key of changesKeys) {
		if (!isEqualCheck((changes as any)[key], (source as any)[key])) {
			return false;
		}
	}

	return true;
}
