import { Realty } from '@oper-client/shared/data-model';

import { InputSwitch } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function(formData?: Realty): InputBase<any>[] {
	return [
		new InputSwitch({
			key: 'realtyRealEstateDataOptional',
			label: 'ç.question.realtyRealEstateDataOptional.label',
			value: !!formData?.address,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
			class: 'span12',
		}),
	];
}
