import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Resource, ResourceType, TwoWayResourceType } from '@oper-client/shared/data-model';

export const loadResources = createAction('[Resources] Load Resources', props<{ kinds: ResourceType[] }>());
export const loadResourcesSuccess = createAction(
	'[Resources] Load Resources Success',
	props<{ resources: Resource[]; kind: TwoWayResourceType }>()
);
export const loadResourcesFailure = createAction(
	'[Resources] Load Resources Failure',
	props<{ httpError: HttpErrorResponse; kind: TwoWayResourceType }>()
);

// export const checkIfResourceIsAvailable = createAction('[Resources] Check if resource is available', props<{ kind: ResourceType }>());
export const resourceIsAvailable = createAction('[Resources] Resource is available');

export const updateResource = createAction('[Resources] Upload Resource', props<{ kind: ResourceType; resource: Resource }>());
export const updateResourceSuccess = createAction(
	'[Resources] Upload Resource Success',
	props<{ resource: Resource; kind: ResourceType }>()
);
export const updateResourceFailure = createAction(
	'[Resources] Upload Resource Failure',
	props<{ httpError: HttpErrorResponse; kind: ResourceType }>()
);
