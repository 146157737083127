import { InjectionToken } from '@angular/core';

/**
 * Interface declaring the types for the application environment config file.
 * This file is a typescript file and it's usually provided at build time by angular.json in the fileReplacements config field.
 */
export interface Environment {
	production: boolean;
	version: string;
	reviewEnvironment: boolean;
	config?: ApplicationInsights;
}

/**
 * Interface declaring the types for the application configuration file.
 * This file is a JSON file and will be loaded before the application's bootstrap phase.
 * See `main.ts` for reference
 */
export interface Configuration {
	applicationInsights: ApplicationInsights;
	customerInsights: CustomerInsights;
	featureConfigurations?: { [key: string]: any };
	aiValidationConfig: {
		current: AiValidationConfig,
		default: AiValidationConfig,
	};
}

/**
 * Interface declaring the types for the application insights (any config item that refers to the application global scope)
 */
export interface ApplicationInsights {
	appName: string;
	appTitle: string;
	name: string;
	sentryEnvironmentName?: string;
	configurationPanelUrl?: string;
	version: string;
	sentryDNS: string;
	googleJSAPIKey: string;
	polling?: boolean;
	pollingTime: number;
}

export interface EnvironmentCurrencyFormat {
	code: string;
	locale: string;
	/**
	 * Decimal representation options, specified by a string in the following format: {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
	 *
	 * - minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
	 * - minFractionDigits: The minimum number of digits after the decimal point. Default is 2.
	 * - maxFractionDigits: The maximum number of digits after the decimal point. Default is 2. If not provided, the number will be formatted with the proper amount of digits, depending on what the ISO 4217 specifies. For example, the Canadian dollar has 2 digits, whereas the Chilean peso has none.
	 *
	 * Optional. Default is undefined.
	 */
	digitsInfo?: string;
}

/**
 * Enum to have a list of existing regions
 */
export enum ExistingRegions {
	AT = 'AT',
	BE = 'BE',
	CH = 'CH',
	DE = 'DE',
	FR = 'FR',
	HU = 'HU', // :)
	IT = 'IT',
	NL = 'NL',
}

export enum AuthType {
	BASIC = 'basic',
	AZURE_SSO = 'azure-sso',
	KEYCLOAK_SSO = 'keycloak-sso',
}

export enum RedirectUrlElementEnum {
	HEADER_LOGO = 'headerLogo',
}

/**
 * Definition of the redirect URLs for customer specific HTML elements.
 @example In the customer insights config add the following:
 ```json
 "redirectUrl": {
			"headerLogo": "/mortgage-simulator"
		},
 ```
Additionally, in the component file add the following:
```typescript
	readonly RedirectUrlElementEnum = RedirectUrlElementEnum;
	readonly redirectUrlElementClicked$ = new Subject<RedirectUrlElementEnum>();
	...
		this.redirectUrlElementClicked$.pipe(takeUntil(this.destroy$)).subscribe((element) => {
			if (this.customerInsights?.redirectUrl && Object.keys(this.customerInsights.redirectUrl).includes(element)) {
				const redirectUrl = this.customerInsights.redirectUrl[element] ?? '/';
				this.router.navigate([redirectUrl]);
			}
		});
```

Then in the HTML file add the following:
```html
	<div (click)="redirectUrlElementClicked$.next(RedirectUrlElementEnum.HEADER_LOGO)"> ... </div>
```
 */
export type RedirectUrlsForHtmlElements = {
	[key in RedirectUrlElementEnum]: string;
};

/**
 * Interface declaring the types for the customer insights (any config item that refers to a customer configuration)
 * This is a way to chop up large config items and also enable future module maintainability.
 * Modules can further have their scoped config items e.g.(Lib => libRequiredConfigItem)
 */
export interface CustomerInsights {
	serverUrl: string;
	currency: EnvironmentCurrencyFormat;
	locale: string;
	fullNamePattern: string;
	region?: ExistingRegions;
	scanbotLicenseKey?: string;
	aiValidationIframeURL?: string;
	googleApiKey?: string;
	gtmContainerId?: string;
	zendeskKey: string;
	addressAutocompleteRestrictions?: { [key: string]: Array<string> };
	whitelistedDomains: Array<string>;
	authType?: AuthType;
	msAuth?: any;
	kcAuth?: any;
	selfServiceUrl?: any;
	interestRateUrl?: string;
	notifications?: {
		beamer?: {
			productId: string;
			theme: string;
			filter: string;
		};
	};
	appointmentBookingUrl?: string;
	applicationUserRoleGroups?: string[];
	/**
	 * Consists of redirect URLs for customer specific HTML elements where the key is the element name and the value is the URL to redirect to.
	 */
	redirectUrl?: RedirectUrlsForHtmlElements;
}

export interface CoreInformation {
	resources?: string;
	version?: string;
}

export const DEFAULT_NUMBER_FORMAT_OPTIONS = {
	decimalDigitsInfo: '1.0-4',
	percentageDigitsInfo: '1.2-2',
	currencyDigitsInfo: '1.2-2',
};
export interface NumberFormatOptions {
	decimalDigitsInfo: string;
	percentageDigitsInfo: string;
	currencyDigitsInfo: string;
}

export const definedLanguages = ['ar-AE', 'ar-SA', 'de-AT', 'de-CH', 'de-DE', 'en-UK', 'fr-BE', 'fr-CH', 'hu-HU', 'it-CH', 'nl-BE'];

export const ENVIRONMENT = new InjectionToken<Configuration>('environment');
export const APP_CONFIG = new InjectionToken<Configuration>('configuration');
export const APP_INSIGHTS_CONFIG = new InjectionToken<ApplicationInsights>('app-insights-config');
export const CUSTOMER_INSIGHTS_CONFIG = new InjectionToken<CustomerInsights>('customer-insights-config');
export const CORE_RESOURCES_VERSION = new InjectionToken<string>('core-resources-version');
export const CORE_VERSION = new InjectionToken<string>('core-version');
export const CORE_INFORMATION = new InjectionToken<CoreInformation>('core-information');
export const DEFAULT_LOCALE = new InjectionToken<string>('defaultLocale', {
	providedIn: 'root',
	factory: () => 'en-UK',
});

export const DEFINED_LANGUAGES = new InjectionToken<Array<string>>('definedLanguages', {
	providedIn: 'any',
	factory: () => definedLanguages,
});

export const NUMBER_FORMAT_OPTIONS = new InjectionToken<NumberFormatOptions>('numberFormat', {
	providedIn: 'root',
	factory: () => ({
		decimalDigitsInfo: '1.0-4',
		percentageDigitsInfo: '1.2-4',
		currencyDigitsInfo: '1.2-2',
	}),
});

export enum ApplicationName {
	BROKERAGE = 'brokerage',
	SELF_SERVICE = 'self-service',
	CONFIGURATION_PORTAL = 'configuration-portal',
}

export interface AiValidationConfig {
	fieldMapping: AiValidationFieldMappings;
	supportedDocuments: string[];
}
export interface AiValidationFieldMappings {
	[key: string]: AiValidationFieldMapping;
}

export interface AiValidationFieldMapping {
	section?: string;
	subSection?: string;
	propertyPath: string;
	resource?: string;
	type?: string;
	group?: boolean;
	fields?: { [key: string]: AiValidationFieldMapping };
}

export enum CustomersEnum {
	FINPORTA = 'finporta',
	AUSTRIA = 'austria',
	BELGIAN = 'belgian',
	CLER = 'cler',
	CREAFIN = 'creafin',
	CREDISHOP = 'credishop',
	FINTEAM = 'finteam',
	GERMAN = 'german',
	HILLEWAERE = 'hillewaere',
	HYPOEXPERT = 'hypoexpert',
	HYPONATION = 'hyponation',
	HYPOOOE = 'hypoooe',
	IFINCO = 'ifinco',
	OPER = 'oper',
	SWISS = 'swiss',
	MEDIRECT = 'medirect',
	TRIODOS = 'triodos',
	UK = 'uk',
	WILINK = 'wilink',
	VOLKSBANK = 'volksbank',
	VOLKSBANKWIEN = 'volksbankwien',
	VOLKSBANKTIROL = 'volksbanktirol',
}

export function getCustomer(applicationInsights: ApplicationInsights): string | null {
	return !applicationInsights.name.includes('-') || applicationInsights.name.split('-').length === 0
		? null
		: applicationInsights.name.split('-')[0];
}
