import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { cancelPendingHttpRequests } from '@oper-client/shared/app/data-access-app';

import { Step } from '@oper-client/shared/data-model';

import * as MortgageSimulatorActions from './mortgage-simulator.actions';
import { MORTGAGE_SIMULATOR_SERVICE, IMortgageSimulatorService } from '@oper-client/shared/data-access';

const DELAY_TIME = 500;

@Injectable()
export class MortgageSimulatorEffects {
	loadMortgageReport$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MortgageSimulatorActions.loadMortgageReport),
			switchMap(({ mortgageReportRequest }) =>
				this.mortgageSimulatorService.calculateClientSimulation(mortgageReportRequest).pipe(
					delay(typeof mortgageReportRequest.secondaryInterestRate !== 'number' ? DELAY_TIME : 0),
					map((mortgageReport) => MortgageSimulatorActions.loadMortgageReportSuccess({ mortgageReport })),
					catchError((error) => of(MortgageSimulatorActions.loadMortgageReportFailure({ error })))
				)
			)
		)
	);

	setConfiguration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MortgageSimulatorActions.setConfiguration),
			mergeMap(({ configuration, activeStep }) =>
				of(
					MortgageSimulatorActions.setActiveStep({
						step: activeStep ?? MortgageSimulatorEffects.calculateFirstStep(configuration),
					})
				)
			)
		)
	);

	syncSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MortgageSimulatorActions.syncSimulation),
			switchMap(({ simulationId }) =>
				this.mortgageSimulatorService.syncSimulation(simulationId).pipe(
					map(() => MortgageSimulatorActions.syncSimulationSuccess()),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(MortgageSimulatorActions.syncSimulationFailure({ error })))
				)
			)
		)
	);

	static calculateFirstStep(configuration: Step[]): Step {
		return configuration[0];
	}

	constructor(
		private actions$: Actions,
		@Inject(MORTGAGE_SIMULATOR_SERVICE) private mortgageSimulatorService: IMortgageSimulatorService
	) {}
}
