import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[operClientFormatWithDashes]',
})
export class FormatWithDashesDirective implements OnInit {
	@Input('operClientFormatWithDashes') dashPositions: number[];

	private sortedDashPositions: number[];

	constructor(private _ngControl: NgControl, private _el: ElementRef) {}

	@HostListener('input', ['$event']) onInputChange(): void {
		this.updateValue();
	}

	ngOnInit(): void {
		this.sortedDashPositions = this.dashPositions?.slice().sort((a, b) => a - b);
		this.updateValue();
	}

	private updateValue(): void {
		if (!this.dashPositions || !this._el.nativeElement.value) return;
		this._ngControl.control.setValue(this.formatValue(this._el.nativeElement.value));
	}

	private formatValue(value: string): string {

		const charArray = value.replace(/-/g, '').split('');
		let count = 0;

		this.sortedDashPositions.forEach((position) => {
			if (position + count < charArray.length) {
				charArray.splice(position + count, 0, '-');
				count++;
			}
		});

		return charArray.join('');
	}
}
