import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import {
	AllowedProductVariability,
	BaseRate,
	CreateProductPricingSheetRequest,
	ProductPricingDetails,
	ProductPricingSheet,
	ProductPricingSheetDiscounts,
} from '@oper-client/shared/data-model';
import { HttpParams } from '@angular/common/http';

export const PRODUCT_PRICING_SERVICE = new InjectionToken<IProductPricingService>('PRODUCT_PRICING_SERVICE');

export interface IProductPricingService {
	getAllProducts(): Observable<ProductPricingDetails[]>;
	getProductPricingDetails(id?: number, params?: HttpParams): Observable<ProductPricingDetails>;
	getProductPricingSheets(productId: number): Observable<ProductPricingSheet[]>;
	getProductPricingSheet(productId: number, pricingSheetId: number): Observable<ProductPricingSheet>;
	getProductPricingSheetBaseRates(productId: number, pricingSheetId: number): Observable<BaseRate[]>;
	getAllowedProductVariabilities(productId: number): Observable<AllowedProductVariability[]>;
	getAllowedProductVariability(productId: number, variabilityId: number): Observable<AllowedProductVariability>;
	createPricingSheet(productId: number, request: CreateProductPricingSheetRequest, params?: HttpParams): Observable<ProductPricingSheet>;
	getProductPricingSheetDiscounts(productId: number, pricingSheetId: number): Observable<ProductPricingSheetDiscounts[]>;
	updateProductPricingSheetDiscounts(
		productId: number,
		pricingSheetId: number,
		discounts: Partial<ProductPricingSheetDiscounts>[]
	): Observable<ProductPricingSheetDiscounts[]>;
	updateProductPricingDetails(
		productId: number,
		product: Partial<ProductPricingDetails>,
		params?: HttpParams
	): Observable<ProductPricingDetails>;
	updatePricingSheet(
		productId: number,
		pricingSheetId: number,
		pricingSheet: Partial<ProductPricingSheet>,
		params?: HttpParams
	): Observable<ProductPricingSheet>;
	updatePricingSheetBaseRate(
		productId: number,
		pricingSheetId: number,
		baseRateId: number,
		baseRate: Partial<BaseRate>,
		params?: HttpParams
	): Observable<BaseRate>;
	updatePricingSheetBaseRates(
		productId: number,
		pricingSheetId: number,
		baseRates: Partial<BaseRate>[],
		params?: HttpParams
	): Observable<BaseRate[]>;
	deletePricingSheet(productId: number, pricingSheetId: number): Observable<void>;
	updateAllowedProductVariability(
		productId: number,
		variabilityId: number,
		variability: Partial<AllowedProductVariability>,
		params?: HttpParams
	): Observable<AllowedProductVariability>;
}
