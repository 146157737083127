import { Component, Input } from '@angular/core';

@Component({
	selector: 'oper-client-dynamic-form-section',
	templateUrl: './dynamic-form-section.component.html',
	styleUrls: ['./dynamic-form-section.component.scss'],
})
export class DynamicFormSectionComponent {
	@Input() title = '';
	@Input() class = '';
}
