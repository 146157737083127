import { AbstractControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Validators } from '@angular/forms';

import { ResourceType, PartialNormalizedResource } from '@oper-client/shared/data-model';

import { InputSelect, InputSwitch, InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	function toggleRequiredValdiator(value: boolean, formGroupControl: AbstractControl, requiredCondition: (x) => boolean) {
		if (requiredCondition(value)) {
			formGroupControl.setValidators(Validators.required);
			return;
		}
		formGroupControl.removeValidators(Validators.required);
	}

	function hiddenObservable(
		formControlName: string,
		requiredCondition: (x) => boolean
	): (x: FormGroup, destroy$: Subject<void>) => Observable<boolean> {
		return (formGroup: FormGroup, destroy$: Subject<void>) => {
			const subject = new BehaviorSubject(formGroup.value['isExistingObject']);

			formGroup.controls['isExistingObject'].valueChanges
				.pipe(
					tap((isExistingOBject) =>
						toggleRequiredValdiator(isExistingOBject, formGroup.controls[formControlName], requiredCondition)
					),
					takeUntil(destroy$)
				)
				.subscribe({
					complete: () => subject.complete(),
					error: (error) => subject.error(error),
					next: (value) => subject.next(value),
				});

			return subject.asObservable();
		};
	}

	return [
		...(!formData?.isRefinanceLoan
			? [
					new InputSelect({
						key: 'isExistingObject',
						label: 'ç.question.existingObject.label',
						helpText: of('ç.question.existingObject.helpText'),
						value: formData?.isExistingObject,
						required: true,
						options: [
							{ id: false, key: 'ç.misc.no' },
							{ id: true, key: 'ç.misc.yes' },
						],
					}),

					new InputField({
						// when true show
						key: 'price',
						label: 'ç.question.propertyPrice.label',
						helpText: of('ç.question.propertyPrice.helpText'),
						value: formData?.price,
						type: 'text',
						currency: true,
						required: true,
						hidden: (formGroup, destroy$) => hiddenObservable('price', (x) => !!x)(formGroup, destroy$).pipe(map((x) => !x)),
					}),
					new InputField({
						// when true show
						key: 'renovations[0].amountContractor',
						label: 'ç.question.renovationPrice.label',
						helpText: of('ç.question.renovationPrice.helpText'),
						value:
							formData.renovations?.find((renovation) => renovation.renovationType.definition === 'other')
								?.amountContractor || null,
						type: 'text',
						currency: true,
						required: false,
						hidden: (formGroup, destroy$: Subject<void>) =>
							hiddenObservable('renovations[0].amountContractor', () => false)(formGroup, destroy$).pipe(map((x) => !x)),
					}),
					new InputField({
						// when false show
						key: 'priceBuilding',
						label: 'ç.question.totalProjectCost.label',
						helpText: of('ç.question.totalProjectCost.helpText'),
						value: formData?.priceBuilding,
						type: 'text',
						currency: true,
						required: true,
						hidden: hiddenObservable('priceBuilding', (x) => !x),
					}),
				]
			: [
					new InputField({
						// when false show
						key: 'venalValueAfter',
						label: 'ç.question.valueOfTheProperty.label',
						helpText: of('ç.question.valueOfTheProperty.helpText'),
						value: formData?.venalValueAfter,
						type: 'text',
						currency: true,
						required: true,
					}),
				]),
		new InputSelect({
			key: 'realtyType.id',
			label: 'ç.question.realtyType.label',
			value: formData?.realtyType?.id,
			helpText: of('ç.question.realtyType.helpText'),
			required: true,
			validators: [],
			options: resources?.[ResourceType.REALTY_TYPE] || [],
		}),
		new InputSelect({
			key: 'usageTypes[0].id',
			label: 'ç.question.usageType.label',
			helpText: of('ç.question.usageType.helpText'),
			value: formData?.usageTypes?.[0]?.id,
			required: true,
			options: resources?.[ResourceType.REALTY_USAGE_TYPE] || [],
		}),
		new InputSwitch({
			key: 'hasApplicableLandCharges',
			label: 'ç.question.landCharges.label',
			helpText: of('ç.question.landCharges.helpText'),
			value: formData?.hasApplicableLandCharges,
			type: 'button',
			required: true,
			labelA: 'ç.misc.no',
			labelB: 'ç.misc.yes',
			valueA: false,
			valueB: true,
		}),
	];
}
