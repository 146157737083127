import { Address, Income, PartialNormalizedResource, ResourceType } from '@oper-client/shared/data-model';
import { DashedInputField, InputField, InputSelect, Section } from '../../models/input-types.model';
import { InputBase } from '../../models/input-base.model';
import { ValidatorService } from '../../services/validator.service';
import { DATE_RESTRICTION, DATE_RESTRICTION_VALIDATION_CONSTANTS } from '../../services/validator-constants';

export default function (income: Partial<Income>, resources: PartialNormalizedResource): InputBase<any>[] {
	const address: Address = income?.employer?.address || <Address>{};
	const currentDate: number = ValidatorService.getDateByYearOffset(DATE_RESTRICTION_VALIDATION_CONSTANTS.ZERO).getTime();

	return [
		new InputField({
			key: 'amount',
			label: 'ç.question.amount.label',
			value: income?.amount,
			required: true,
			type: 'number',
			currency: true,
			class: 'span12',
		}),
		new InputField({
			key: 'employer.name',
			label: 'ç.question.companyName.label',
			value: income?.employer?.name,
			required: true,
			type: 'text',
			class: 'span12',
		}),
		new InputField({
			key: 'startDate',
			label: 'ç.question.startDate.label',
			value: income?.startDate,
			required: true,
			validators: [ValidatorService.getDateRestrictionValidatior('past', currentDate, DATE_RESTRICTION.FUTURE)],
			max: currentDate,
			type: 'date',
			class: 'span12',
		}),
		new DashedInputField({
			key: 'employer.vatNumber',
			label: 'ç.question.vatNumber.label',
			value: income?.employer?.vatNumber,
			required: false,
			validators: [ValidatorService.getVatNumberValidator()],
			type: 'text',
			class: 'span12',
			dashesConfig: [8, 9]
		}),
		new Section({
			class: 'span12',
			title: 'ç.question.address.details',
		}),
		new InputSelect({
			key: 'employer.address.country.id',
			label: 'ç.question.country.label',
			value: address.country?.id,
			options: resources?.[ResourceType.COUNTRY] || [],
			required: false,
			validators: [],
			class: 'span12',
			updateValidityOnFormValueChanges: true,
		}),
	];
}
