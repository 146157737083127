import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppFacade } from './+state/app.facade';
import { AppEffects } from './+state/app.effects';
import * as AppReducer from './+state/app.reducer';

@NgModule({
	imports: [CommonModule, StoreModule.forFeature(AppReducer.APP_KEY, AppReducer.reducer), EffectsModule.forFeature([AppEffects])],
	providers: [AppFacade],
})
export class DataAccessAppModule {}
