import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoanRequestPartialState } from '../loan-request.reducer';
import { loadMilestones, updateMilestone } from './milestone.actions';
import { getMilestones } from './milestone.selectors';

import { Milestone, parseActionState, ParsedActionState } from '@oper-client/shared/data-model';

import { MilestoneActionTypes } from './milestone.reducer';
import * as MilestoneSelectors from './milestone.selectors';

@Injectable()
export class MilestoneFacade {
	milestones$ = this.store.pipe(select(getMilestones));
	loadMilestonesActionState$ = this.selectActionState('loadMilestones');

	constructor(private store: Store<LoanRequestPartialState>) {}

	public loadMilestones(loanRequestId: number) {
		this.store.dispatch(loadMilestones({ loanRequestId }));
	}

	public updateMilestone(loanRequestId: number, milestoneId: number, milestone: Partial<Milestone>) {
		this.store.dispatch(updateMilestone({ loanRequestId, milestoneId, milestone }));
	}

	private selectActionState(actionType: MilestoneActionTypes): Observable<ParsedActionState> {
		return this.store.pipe(select(MilestoneSelectors.getActionState(actionType)), map(parseActionState));
	}
}
