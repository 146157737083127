import { createReducer, on } from '@ngrx/store';
import * as ProductExplorerActions from './product-explorer.actions';

import { FilterParams, Offer, Product, ProductExplorerStep } from '@oper-client/shared/data-model';

export const PRODUCT_EXPLORER_ENTITY_KEY = 'productExplorer';

export interface State {
	activeStep: ProductExplorerStep;
	offerToUpdate: Partial<Offer>;
	selectedProducts: Product[];
	searchFilterParams: FilterParams;
}

export const initialState: State = {
	activeStep: null,
	offerToUpdate: null,
	selectedProducts: [],
	searchFilterParams: {
		creditProvider: null,
		productType: null,
		loanType: null,
		minDuration: null,
		maxDuration: null,
		variabilityGroup: null,
		fixedVariability: null,
	},
};

export const reducer = createReducer(
	initialState,

	on(ProductExplorerActions.setActiveStep, (state, { activeStep }) => ({
		...state,
		activeStep,
	})),

	on(ProductExplorerActions.setOfferToUpdate, (state, { offerToUpdate }) => ({
		...state,
		offerToUpdate,
	})),

	on(ProductExplorerActions.setSelectedProducts, (state, { selectedProducts }) => ({
		...state,
		selectedProducts,
	})),

	on(ProductExplorerActions.setSearchFilterParams, (state, { searchFilterParams }) => ({
		...state,
		searchFilterParams: {
			...state.searchFilterParams,
			...searchFilterParams,
		},
	})),

	on(ProductExplorerActions.reset, () => ({
		...initialState,
	}))
);
