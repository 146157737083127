import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	forwardRef,
	HostBinding,
	HostListener,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

import { SelectOption } from '@oper-client/shared/util-data-model-transform';

import { fadeInOut } from '../../animations/fade-in-out.animation';

type Value = Array<number | string> | number | string;

@Component({
	selector: 'oper-client-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: fadeInOut,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DropdownComponent),
			multi: true,
		},
	],
})
export class DropdownComponent implements ControlValueAccessor, OnInit {
	@Input() formName: string;
	@Input() formControlName: string;
	@Input() label: string;
	@Input() disabled: boolean;
	@Input() multiselect: boolean;
	@Input() clearable: boolean;
	@Input() options: SelectOption[];
	@Input() bindValue = 'id';

	@Input() set value(value: Value) {
		this.writeValue(value);
	}

	get value(): Array<number | string> {
		return this.selectedOptions;
	}

	@Output() valueChange = new EventEmitter<any>();

	@HostBinding('class') get ClassName() {
		return { disabled: this.disabled };
	}

	@HostListener('click', ['$event']) handleClick() {
		if (!this.disabled) {
			this.toggleOptions();
		}
	}

	testLabel: string;
	completeOptions: Array<SelectOption & { testLabel: string }> = [];
	open: boolean;
	showCounter = true;
	selectedOptions: any = [];

	get selectedLabel(): string {
		const selectedOption = this.completeOptions.find((option) => option[this.bindValue] === this.selectedOptionId);
		return selectedOption ? selectedOption?.key : this.label;
	}

	get selectedOptionId(): number | string | null {
		return this.selectedOptions[0];
	}

	onChangeCallback: (selectedOptions: Array<number | string>) => void = noop;
	onTouchedCallback: (selectedOptions: Array<number | string>) => void = noop;

	registerOnChange(fn: () => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedCallback = fn;
	}

	writeValue(value: Value): void {
		this.selectedOptions = this.mapValuesFromOptions(value);
	}

	ngOnInit(): void {
		this.testLabel = !this.formName && !this.formControlName ? '' : `${this.formName || ''}-${this.formControlName || ''}`;
		this.completeOptions = this.options?.map((el) => ({ ...el, testLabel: `${this.testLabel}-${el[this.bindValue]}` }));
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	toggleOptions(): void {
		this.open = !this.open;
	}

	clearOptions(): void {
		this.selectedOptions = [];

		this.onChangeCallback(this.selectedOptions);
		this.valueChange.emit(this.selectedOptions);
	}

	handleClickOutside() {
		this.open = false;
	}

	optionSelected(option: SelectOption): boolean {
		return this.selectedOptions.includes(option[this.bindValue]);
	}

	optionClicked(event: MouseEvent, option: SelectOption): void {
		event.stopPropagation();

		if (this.optionSelected(option)) {
			this.selectedOptions = this.multiselect
				? this.selectedOptions.filter((selectedOption) => selectedOption !== option[this.bindValue])
				: this.selectedOptions === option[this.bindValue];
		} else {
			if (this.multiselect) {
				this.selectedOptions = [...this.selectedOptions, option[this.bindValue]];
			} else {
				this.selectedOptions = [option[this.bindValue]];
			}
		}

		const selectedOptions = this.multiselect ? this.selectedOptions : this.selectedOptions[0];

		this.onChangeCallback(selectedOptions);
		this.valueChange.emit(selectedOptions);
	}

	private mapValuesFromOptions(value) {
		const values = Array.isArray(value) ? value : [value];

		return values.reduce((acc, item) => {
			const option = this.findOption(item);
			return acc.concat(option ? option : []);
		}, []);
	}

	private findOption(item) {
		const matchingOption = this.completeOptions.find((option) => option[this.bindValue] === item);
		return matchingOption ? matchingOption[this.bindValue] : null;
	}
}
