import { Inject, Injectable, signal } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AI_VALIDATION_SERVICE, IAiValidationServiceService } from '@oper-client/shared/data-access';
import { concatMap, delay, filter, mergeMap, Observable, of, tap } from 'rxjs';
import { Action } from '@ngrx/store';

import { catchError, map, switchMap } from 'rxjs/operators';
import * as AIValidationActions from './ai-validation.actions';

@Injectable()
export class AiValidationEffects {
	$validationEnabled = signal(false);

	loadFieldMapping$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.loadFieldMapping),
				switchMap(() => {
					return this.validationService.getConfig().pipe(
						map((config) =>
							AIValidationActions.loadFieldMappingSuccess({
								config,
							})
						),
						catchError((error) => of(AIValidationActions.loadFieldMappingFailure({ error: error })))
					);
				})
			)
	);

	triggerValidation$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.triggerValidation),
				filter(() => this.$validationEnabled()),
				concatMap((props) => {
					return this.validationService.triggerValidation(props.loanRequestId, props.proofId, props.docId).pipe(
						map((res) => {
							return AIValidationActions.triggerValidationSuccess({
								...props,
								id: res.id,
							});
						}),
						catchError((error) => of(AIValidationActions.triggerValidationFailure({ error: error })))
					);
				})
			)
	);

	loadValidationResult$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AIValidationActions.triggerValidationSuccess),
			delay(5000),
			filter(() => this.$validationEnabled()),
			mergeMap((props) =>
				this.validationService.getValidationResult(props.loanRequestId, props.proofId, props.docId, props.id).pipe(
					map((validationRes) => {
						if (!validationRes) {
							return AIValidationActions.loadValidationResultFailure({
								validationResult: {
									id: props.docId,
									payload: null,
									category: null,
									docName: null,
									proofId: null,
									categoryEntityId: props.categoryEntityId,
								},
							});
						}
						if (validationRes && validationRes.status === 'finished') {
							return AIValidationActions.loadValidationResultSuccess({
								validationResult: {
									categoryEntityId: props.categoryEntityId,
									id: props.docId,
									category: props.category,
									docName: props.docName,
									proofId: props.proofId,
									payload: validationRes.payload,
								},
							});
						} else {
							return AIValidationActions.triggerValidationSuccess({
								...props,
								id: props.id,
								retry: true,
							});
						}
					})
				)
			)
		)
	);
	
	disableValidationTrigger$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.clearValidationResults),
				tap(() => this.$validationEnabled.set(false))
			),
		{ dispatch: false }
	);

	enableValidationTrigger$ = createEffect(
		() => (): Observable<Action> =>
			this.actions$.pipe(
				ofType(AIValidationActions.enableValidationTrigger),
				tap(() => this.$validationEnabled.set(true))
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		@Inject(AI_VALIDATION_SERVICE) private validationService: IAiValidationServiceService
	) {}
}
