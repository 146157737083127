import { Resource } from './resource.model';
import { PhoneNumber } from './phone.model';

export interface User {
	id: number; // Primary ID
	firstName: string;
	lastName: string;
	role: Resource;
	email?: string;
	phoneNumbers?: Array<PhoneNumber>;
}

export interface UserGroupRole {
	id: number;
	name: GroupRole;
}

export enum userRoles {
	CONFIGURATION_MANAGER = 'configurationManager',
	CLIENT = 'client',
	HEAD_DECIDER = 'headDecider',
	SENIOR_DECIDER = 'seniorDecider',
	UPLOAD = 'upload',
	BROKER = 'broker',
	ANALYST = 'analyst',
	BROKER_MANAGER = 'brokerManager',
	ACCOUNT_MANAGER = 'accountManager',
	DECIDER = 'decider',
	OPERATIONS_MANAGER = 'operationsManager',
}

export enum GroupRole {
	ACCOUNT_CREATOR = 'Role - Account creator',
	AGENT = 'Role - Agent',
	AGENT_ADMIN = 'Role - Agent Admin',
	ANALYST = 'Role - Analyst',
	ANALYST_ADMIN = 'Role - Analyst Admin',
	BASIC = 'Role - Basic',
	BULK_IMPORT_ADMIN = 'Role - Bulk Import Admin',
	CLIENT = 'Role - Client',
	CO_BROKER = 'Role - Co-broker',
	COMMISSION_ADMIN = 'Role - Commission Admin',
	CONFIGURATION_MANAGER = 'Role - Configuration Manager',
	DECIDER = 'Role - Decider',
	INTEGRATOR = 'Role - Integrator',
	SENIOR_DECIDER = 'Role - Senior Decider',
	SYNC_CONTENT_TOOLING = 'Role - Sync Content Tooling',
}
