import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	ProductPricingDetailsEntityState,
	PRODUCT_PRICING_FEATURE_KEY,
	productPricingDetailsAdapter,
	ProductPricingFeatueActionTypes,
	ProductPricingState,
	productPricingSheetsAdapter,
	pricingSheetBaseRatesAdapter,
	ProductPricingSheetEntityState,
	allowedProductVariabilitiesAdapter,
	productPricingSheetDiscountsAdapter,
} from './product-pricing.reducer';

const getProductPricingFeatureState = createFeatureSelector<ProductPricingState>(PRODUCT_PRICING_FEATURE_KEY);
const productPricingDetailsSelectors = productPricingDetailsAdapter.getSelectors();
const productPricingSheetsSelectors = productPricingSheetsAdapter.getSelectors();
const productPricingSheetBaseRatesSelectors = pricingSheetBaseRatesAdapter.getSelectors();
const allowedProductVariabilitiesSelectors = allowedProductVariabilitiesAdapter.getSelectors();
const productPricingSheetDiscountsSelectors = productPricingSheetDiscountsAdapter.getSelectors();

// Product pricing details
export const getProductPricingDetailsState = createSelector(getProductPricingFeatureState, (state: ProductPricingState) => state.products);
export const getProductPricingDetailsActionStates = createSelector(getProductPricingDetailsState, (state) => state.actions);
export const getActiveProducts = createSelector(getProductPricingDetailsState, productPricingDetailsSelectors.selectAll);
export const getActiveProductsEntities = createSelector(getProductPricingDetailsState, productPricingDetailsSelectors.selectEntities);
export const getCurrentPricingDetailsId = createSelector(
	getProductPricingDetailsState,
	(state: ProductPricingDetailsEntityState) => state.currentProductPricingDetailsId
);

export const getCurrentPricingDetails = createSelector(
	getProductPricingDetailsState,
	(state: ProductPricingDetailsEntityState) => state.currentProductPricingDetails
);

// Product pricing sheets
export const getProductPricingSheetsState = createSelector(
	getProductPricingFeatureState,
	(state: ProductPricingState) => state.pricingSheets
);
export const getProductPricingSheetsActionStates = createSelector(getProductPricingSheetsState, (state) => state.actions);
export const getProductPricingSheetEntities = createSelector(getProductPricingSheetsState, productPricingSheetsSelectors.selectEntities);
export const getAllProductPricingSheets = createSelector(getProductPricingSheetsState, productPricingSheetsSelectors.selectAll);
export const getCurrentPricingSheetId = createSelector(
	getProductPricingSheetsState,
	(state: ProductPricingSheetEntityState) => state.currentPricingSheetId
);
export const getCurrentPricingSheet = createSelector(
	getProductPricingSheetsState,
	(state: ProductPricingSheetEntityState) => state.currentPricingSheet
);

export const getCurrentActivePricingSheet = createSelector(getAllProductPricingSheets, (sheets) => {
	return sheets.find((sheet) => sheet.isActive === true);
});

export const getUpcomingPricingSheetVersions = createSelector(getAllProductPricingSheets, (pricingSheets) => {
	return pricingSheets.filter((sheet) => new Date(sheet.validFrom).getTime() > new Date().getTime());
});

export const getProductPricingSheets = createSelector(getAllProductPricingSheets, (sheets) => {
	return sheets;
});

// Product pricing sheet base rates
export const getProductPricingSheetBaseRatesState = createSelector(
	getProductPricingFeatureState,
	(state: ProductPricingState) => state.baseRates
);
export const getProductPricingSheetBaseRatesActionStates = createSelector(getProductPricingSheetBaseRatesState, (state) => state.actions);

export const getProductPricingSheetBaseRates = createSelector(
	getProductPricingSheetBaseRatesState,
	productPricingSheetBaseRatesSelectors.selectAll
);

// Product pricing sheet discounts
export const getProductPricingSheetDiscountsState = createSelector(
	getProductPricingFeatureState,
	(state: ProductPricingState) => state.discounts
);
export const getProductPricingSheetDiscountsActionStates = createSelector(getProductPricingSheetDiscountsState, (state) => state.actions);

export const getProductPricingSheetDiscounts = createSelector(
	getProductPricingSheetDiscountsState,
	productPricingSheetDiscountsSelectors.selectAll
);

// Allowed product variabilities
export const getAllowedProductVariabilitiesState = createSelector(
	getProductPricingFeatureState,
	(state: ProductPricingState) => state.allowedVariabilities
);
export const getAllowedProductVariabilitiesActionStates = createSelector(getAllowedProductVariabilitiesState, (state) => state.actions);

export const getAllowedProductVariabilities = createSelector(
	getAllowedProductVariabilitiesState,
	allowedProductVariabilitiesSelectors.selectAll
);

export const getActionState = (actionType: ProductPricingFeatueActionTypes, actionStates) =>
	createSelector(actionStates, (state) => state[actionType]);
