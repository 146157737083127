import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import localeDECH from '@angular/common/locales/de-CH';
import localeNLBE from '@angular/common/locales/nl-BE';
import localeENUK from '@angular/common/locales/en-GB';
import localeDEAT from '@angular/common/locales/de-AT';
import localeDEDE from '@angular/common/locales/de';
import localFRBE from '@angular/common/locales/fr-BE';
import localFRCH from '@angular/common/locales/fr-CH';
import localeITCH from '@angular/common/locales/it-CH';
import localeHUHU from '@angular/common/locales/hu';
import localeARAE from '@angular/common/locales/ar-AE';
import localeARSA from '@angular/common/locales/ar-SA';
import localeCSCZ from '@angular/common/locales/cs';

import { UiModule } from '@oper-client/ui';
import { SentryErrorHandler } from '@oper-client/shared/util-error-handling';
import { metaReducers, numberFormatFactory } from '@oper-client/shared/util-bootstrap';
import { API_SERVICE, APPLICATION_FLOW_SERVICE, ConnectionLostInterceptor, DataAccessModule } from '@oper-client/shared/data-access';
import { DataAccessAppModule } from '@oper-client/shared/app/data-access-app';
import { DataAccessIamModule } from '@oper-client/shared/iam/data-access-iam';
import { DataAccessResourceModule } from '@oper-client/shared/resources/data-access-resource';
import { DataAccessThirdPartyModule } from '@oper-client/shared/third-party/data-access-third-party';
import { DataAccessClientModule } from '@oper-client/shared/client/data-access-client';
import { DataAccessDocumentModule } from '@oper-client/shared/document/data-access-document';
import { DataAccessUserModule } from '@oper-client/shared/user/data-access';
import { DataAccessLoanRequestModule } from '@oper-client/shared/loan-request/data-access-loan-request';
import { DataAccessCommissionModule } from '@oper-client/shared/commission/data-access-commission';
import { DataAccessRealtyModule } from '@oper-client/shared/realty/data-access';
import { DataAccessProductPricingModule } from '@oper-client/shared/product-pricing/data-access';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
	APP_CONFIG,
	APP_INSIGHTS_CONFIG,
	Configuration,
	CORE_INFORMATION,
	CORE_RESOURCES_VERSION,
	CoreInformation,
	CUSTOMER_INSIGHTS_CONFIG,
	CustomerInsights,
	ENVIRONMENT,
	NUMBER_FORMAT_OPTIONS,
} from '@oper-client/shared/configuration';
import { CaseStyleInterceptor } from '@oper-client/shared/util-formatting';
import { ApplicationFlowService } from '@oper-client/shared/api-v2.0';
import { FeatureFlagModule } from '@oper-client/shared/util-feature-flag';
import { HttpLoaderFactory, MissingCustomTranslationHandler } from '@oper-client/shared/translation';

import { environment } from '../environments/environment';
import { AI_VALIDATION_CONFIG, aiValidationConfigurationFactory } from '@oper-client/shared/ai-validation/data-access';
import {
	ADVISOR_SIMULATOR_FEATURE_CONFIGURATION,
	advisorSimulatorConfigurationFactory,
} from '@oper-client/shared/mortgage-simulator/data-access-mortgage-simulator';

registerLocaleData(localeNLBE, 'nl-BE');
registerLocaleData(localeDECH, 'de-CH');
registerLocaleData(localeENUK, 'en-UK');
registerLocaleData(localeDEAT, 'de-AT');
registerLocaleData(localeDEDE, 'de-DE');
registerLocaleData(localFRBE, 'fr-BE');
registerLocaleData(localFRCH, 'fr-CH');
registerLocaleData(localeITCH, 'it-CH');
registerLocaleData(localeHUHU, 'hu-HU');
registerLocaleData(localeARAE, 'ar-AE');
registerLocaleData(localeARSA, 'ar-SA');
registerLocaleData(localeCSCZ, 'cs-CZ');

@NgModule({
	declarations: [],
	exports: [UiModule],
	imports: [
		/* Angular */
		BrowserModule,
		BrowserAnimationsModule,
		OverlayModule,
		StoreModule.forRoot({ routerReducer }, { metaReducers }),
		StoreRouterConnectingModule.forRoot(),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
			connectInZone: true,
		}),
		/* Store modules imported eagerly */
		DataAccessModule,
		DataAccessAppModule,
		DataAccessIamModule,
		DataAccessResourceModule,
		DataAccessThirdPartyModule,
		DataAccessClientModule,
		DataAccessDocumentModule,
		DataAccessUserModule,
		DataAccessLoanRequestModule,
		DataAccessCommissionModule,
		DataAccessRealtyModule,
		DataAccessProductPricingModule,
		FeatureFlagModule,
		UiModule,
		/* translations config */
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: MissingCustomTranslationHandler,
				deps: [API_SERVICE],
			},
			useDefaultLang: false,
			defaultLanguage: 'en-UK',
		}),
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CaseStyleInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ConnectionLostInterceptor,
			multi: true,
		},
		/* API providers */
		{ provide: APPLICATION_FLOW_SERVICE, useClass: ApplicationFlowService, deps: [API_SERVICE] },
		/* Global providers */
		{ provide: Window, useValue: window },
		{
			provide: NUMBER_FORMAT_OPTIONS,
			useFactory: numberFormatFactory,
			deps: [CUSTOMER_INSIGHTS_CONFIG],
		},
		{ provide: ErrorHandler, useClass: SentryErrorHandler },
		{ provide: ENVIRONMENT, useValue: environment },
		{ provide: APP_INSIGHTS_CONFIG, useFactory: (config: Configuration) => config.applicationInsights, deps: [APP_CONFIG] },
		{ provide: CUSTOMER_INSIGHTS_CONFIG, useFactory: (env: Configuration) => env.customerInsights, deps: [APP_CONFIG] },
		{ provide: AI_VALIDATION_CONFIG, useFactory: (env: Configuration) => aiValidationConfigurationFactory(env), deps: [APP_CONFIG] },
		{ provide: LOCALE_ID, useFactory: (config: CustomerInsights) => config.locale, deps: [CUSTOMER_INSIGHTS_CONFIG] },
		{ provide: CORE_RESOURCES_VERSION, useFactory: (info: CoreInformation) => info.resources, deps: [CORE_INFORMATION] },
		{
			provide: ADVISOR_SIMULATOR_FEATURE_CONFIGURATION,
			useFactory: (config: Configuration) => advisorSimulatorConfigurationFactory(config),
			deps: [APP_CONFIG],
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class CommonBrokerageAppModule {}
