import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { IamEffects } from './+state/iam.effects';
import { IamFacade } from './+state/iam.facade';
import * as fromIam from './+state/iam.reducer';

@NgModule({
	imports: [CommonModule, StoreModule.forFeature(fromIam.IAM_FEATURE_KEY, fromIam.reducer), EffectsModule.forFeature([IamEffects])],
	providers: [
		IamFacade
	],
})
export class DataAccessIamModule {}
