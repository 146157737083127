import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as CommissionFileActions from './commission-file.actions';
import { ActionState, setActionState, ActionTypes, CommissionFile, initialActionState } from '@oper-client/shared/data-model';

export const COMMISSION_FILE_FEATURE_KEY = 'commissionFile';

export interface CommissionFilePartialState {
	readonly [COMMISSION_FILE_FEATURE_KEY]: CommissionFileState;
}

export enum CommissionFileActionTypes {
	LOAD_COMMISSION_FILE = 'loadCommissionFile',
	LOAD_COMMISSION_FILES = 'loadCommissionFiles',
	RECOVER_COMMISSION_FILE = 'recoverCommissionFile',
	CREATE_COMMISSION_FILE = 'createCommissionFile',
}

export type CommissionFileActionsState = Record<CommissionFileActionTypes, ActionState>;

export interface CommissionFileState extends EntityState<CommissionFile> {
	error: HttpErrorResponse | undefined;
	actions: CommissionFileActionsState;
}

export const commissionFileAdapter: EntityAdapter<CommissionFile> = createEntityAdapter<CommissionFile>();

export const commissionFileInitialState: CommissionFileState = commissionFileAdapter.getInitialState({
	error: null,
	actions: {
		loadCommissionFile: initialActionState,
		loadCommissionFiles: initialActionState,
		recoverCommissionFile: initialActionState,
		createCommissionFile: initialActionState,
	},
});

function setActionStates(
	actionState: CommissionFileActionsState,
	action: CommissionFileActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null
): CommissionFileActionsState {
	return {
		...commissionFileInitialState.actions,
		[action]: setActionState(actionState[action], actionType, error),
	};
}

export const reducer = createReducer(
	commissionFileInitialState,

	on(CommissionFileActions.loadCommissionFiles, state => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.LOAD_COMMISSION_FILES, ActionTypes.loading),
	})),

	on(CommissionFileActions.loadCommissionFilesSuccess, (state, { commissionFiles }) =>
		commissionFileAdapter.setAll(commissionFiles, {
			...state,
			actions: setActionStates(state.actions, CommissionFileActionTypes.LOAD_COMMISSION_FILES, ActionTypes.success),
		})
	),

	on(CommissionFileActions.loadCommissionFilesFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.LOAD_COMMISSION_FILES, ActionTypes.failure, error),
	})),

	on(CommissionFileActions.loadCommissionFile, state => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.LOAD_COMMISSION_FILE, ActionTypes.loading),
	})),

	on(CommissionFileActions.loadCommissionFileSuccess, (state, { commissionFile }) =>
		commissionFileAdapter.setOne(commissionFile, {
			...state,
			actions: setActionStates(state.actions, CommissionFileActionTypes.LOAD_COMMISSION_FILE, ActionTypes.success),
		})
	),

	on(CommissionFileActions.loadCommissionFileFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.LOAD_COMMISSION_FILE, ActionTypes.failure, error),
	})),

	on(CommissionFileActions.recoverCommissionFile, state => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.RECOVER_COMMISSION_FILE, ActionTypes.loading),
	})),

	on(CommissionFileActions.recoverCommissionFileSuccess, state => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.RECOVER_COMMISSION_FILE, ActionTypes.success),
	})),

	on(CommissionFileActions.recoverCommissionFileFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.RECOVER_COMMISSION_FILE, ActionTypes.failure, error),
	})),

	on(CommissionFileActions.createCommissionFile, state => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.CREATE_COMMISSION_FILE, ActionTypes.loading),
	})),

	on(CommissionFileActions.createCommissionFileSuccess, (state, { commissionFile }) =>
		commissionFileAdapter.addOne(commissionFile, {
			...state,
			actions: setActionStates(state.actions, CommissionFileActionTypes.CREATE_COMMISSION_FILE, ActionTypes.success),
		})
	),

	on(CommissionFileActions.createCommissionFileFailure, (state, { error }) => ({
		...state,
		actions: setActionStates(state.actions, CommissionFileActionTypes.CREATE_COMMISSION_FILE, ActionTypes.failure, error),
	}))
);
