import { HttpErrorResponse } from '@angular/common/http';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { User } from '@oper-client/shared/data-model';
import * as UserActions from './user.actions';

export const USERS_FEATURE_KEY = 'users';

export interface UserState extends EntityState<User> {
	error: HttpErrorResponse | undefined;
}

export interface UserPartialState {
	readonly [USERS_FEATURE_KEY]: UserState;
}

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: UserState = userAdapter.getInitialState({
	// additional entity state properties
	error: undefined,
});

const userReducer = createReducer(
	initialState,
	on(UserActions.loadUsers, (state) => ({ ...state, error: undefined })),
	on(UserActions.loadUsersSuccess, (state, { users }) => userAdapter.setAll(users, { ...state, error: undefined })),
	on(UserActions.loadUsersFailure, (state, { error }) => ({ ...state, error: error })),
	on(UserActions.clearUsers, (state) => userAdapter.removeAll(state))
);

export function reducer(state: UserState | undefined, action: Action) {
	return userReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = userAdapter.getSelectors();
