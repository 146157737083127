import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';

@Injectable({
	providedIn: 'root',
})
export class ThirdPartyService {
	private basePath = '/api/third-parties';
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	public getThirdParties(key: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`${this.basePath}/${key}/`, params);
	}

	public getThirdParty(key: string, id: number, params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`${this.basePath}/${key}/${id}/`, params);
	}

	public updateThirdParty(key: string, id: number, body: Partial<any>): Observable<any> {
		return this.apiService.patch(`${this.basePath}/${key}/${id}/`, body);
	}
}
