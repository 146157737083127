import { createSelector } from '@ngrx/store';

import { PAYMENT_FILE_FEATURE_KEY, paymentFileAdapter, PaymentFileActionTypes } from './payment-file.reducer';
import { getCommissionFeatureState } from '../commission.selectors';

export const getPaymentFileState = createSelector(getCommissionFeatureState, state => state[PAYMENT_FILE_FEATURE_KEY]);

const selectors = paymentFileAdapter.getSelectors();

export const getDownloadPaymentFile = createSelector(getPaymentFileState, state => state.downloadPaymentFile);

export const getPaymentFiles = createSelector(getPaymentFileState, selectors.selectAll);
export const getPaymentFileEntities = createSelector(getPaymentFileState, selectors.selectEntities);

export const getActionStates = createSelector(getPaymentFileState, state => state.actions);
export const getActionState = (actionType: PaymentFileActionTypes) => createSelector(getActionStates, state => state[actionType]);
