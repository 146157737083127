import { InputField } from '../models/input-types.model';
import { InputBase } from '../models/input-base.model';
import { PartialNormalizedResource } from '@oper-client/shared/data-model';
import { Validators } from '@angular/forms';
import { of } from 'rxjs';

export default function (formData?: any, resources?: PartialNormalizedResource): InputBase<any>[] {
	return [
		new InputField({
			key: 'price',
			label: 'ç.question.propertyPrice.label',
			helpText: of('ç.question.propertyPrice.helpText'),
			value: formData?.price || null,
			type: 'text',
			currency: true,
			required: true,
		}),
		new InputField({
			key: 'renovationPrice',
			label: 'ç.question.renovationPrice.label',
			helpText: of('ç.question.renovationPrice.helpText'),
			value: formData?.renovationPrice || null,
			type: 'text',
			currency: true,
			required: false,
		}),
		new InputField({
			key: 'epcAfterRenovations',
			label: 'ç.question.epcScore.label',
			value: formData?.epcAfterRenovations,
			updateOn: 'change',
			type: 'number',
			validators: [Validators.min(0), Validators.pattern('^[0-9]*$'), Validators.max(1000)],
			required: true,
			additionalDisclaimer: 'ç.misc.kWhM2PerYearOrLess',
			class: 'span12',
			transform: (value) => +value,
			helpText: of('ç.question.epcScoreAfterRenovation.helpText'),
		}),
	];
}
