import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { CreditProvider } from '@oper-client/shared/data-model';

export const loadCreditProviders = createAction('[LoanRequest] Load CreditProviders', props<{ active?: boolean, isLiabilityProvider?: boolean }>());

export const loadCreditProvidersSuccess = createAction(
	'[LoanRequest] Load CreditProviders Success',
	props<{ creditProviders: CreditProvider[] }>()
);

export const loadCreditProvidersFailure = createAction('[LoanRequest] Load CreditProviders Failure', props<{ error: HttpErrorResponse }>());

export const loadBrokerCreditProviders = createAction('[LoanRequest] Load BrokerCreditProviders', props<{ active?: boolean, isLiabilityProvider?: boolean }>());

export const loadBrokerCreditProvidersSuccess = createAction(
	'[LoanRequest] Load BrokerCreditProviders Success',
	props<{ creditProviders: CreditProvider[] }>()
);

export const loadBrokerCreditProvidersFailure = createAction('[LoanRequest] Load BrokerCreditProviders Failure', props<{ error: HttpErrorResponse }>());
