import { Inject, Injectable } from '@angular/core';
import { API_SERVICE, IApiService } from '@oper-client/shared/data-access';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ValidatorApiService {
	constructor(@Inject(API_SERVICE) private readonly apiService: IApiService) {}

	validateBelgiumNationalNumberValidation(nationalNumber: string): Observable<any> {
		return this.apiService.post(`/api/validators/belgian-national-number`, { national_number: nationalNumber });
	}
}
