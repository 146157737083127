import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { EnvironmentNumberFormatService } from '../services/environment-number-format.service';

@Pipe({
	name: 'percent',
	pure: false, // otherwise formatting doesn't change when switching language
})
export class PercentagePipe implements PipeTransform {
	constructor(private readonly numberFormatService: EnvironmentNumberFormatService) {}

	transform(value?: number, digits = 2): string {
		const decimal = getLocaleNumberSymbol(this.numberFormatService.getLocale(), NumberSymbol.CurrencyDecimal);
		return typeof value === 'number' ? (value * 100).toFixed(digits).replace('.', decimal) + '%' : '-';
	}
}
