import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, combineReducers } from '@ngrx/store';

import * as fromThirdParty from './+state/third-party.reducer';
import * as fromBroker from './+state/broker/broker.reducer';
import * as fromInsuranceProvider from './+state/insurance-provider/insurance-provider.reducer';
import * as fromNotary from './+state/notary/notary.reducer';

import { BrokerEffects } from './+state/broker/broker.effects';
import { BrokerFacade } from './+state/broker/broker.facade';
import { InsuranceProviderEffects } from './+state/insurance-provider/insurance-provider.effects';
import { InsuranceProviderFacade } from './+state/insurance-provider/insurance-provider.facade';
import { NotaryEffects } from './+state/notary/notary.effects';
import { NotaryFacade } from './+state/notary/notary.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromThirdParty.THIRD_PARTY_FEATURE_KEY,
			combineReducers({
				[fromBroker.BROKER_FEATURE_KEY]: fromBroker.reducer,
				[fromInsuranceProvider.INSURANCE_PROVIDER_FEATURE_KEY]: fromInsuranceProvider.reducer,

				[fromNotary.NOTARY_FEATURE_KEY]: fromNotary.reducer,
			})
		),
		EffectsModule.forFeature([BrokerEffects, InsuranceProviderEffects, NotaryEffects]),
	],
	providers: [BrokerFacade, InsuranceProviderFacade, NotaryFacade],
})
export class DataAccessThirdPartyModule {}
