import { InjectionToken } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export const API_SERVICE = new InjectionToken<IApiService>('API_SERVICE');

export interface IApiService {
	get(path: string, params?: HttpParams, headers?: HttpHeaders, options?: { [key: string]: string | boolean }): Observable<any>;

	put(
		path: string,
		body?: object,
		params?: HttpParams,
		headers?: HttpHeaders,
		options?: { [key: string]: string | boolean }
	): Observable<any>;

	patch(
		path: string,
		body?: object,
		params?: HttpParams,
		headers?: HttpHeaders,
		options?: { [key: string]: string | boolean }
	): Observable<any>;

	post(
		path: string,
		body?: object,
		params?: HttpParams,
		headers?: HttpHeaders,
		options?: { [key: string]: string | boolean }
	): Observable<any>;

	delete(path: string, headers?: HttpHeaders): Observable<any>;

	formatErrors(error: any): Observable<any>;
}
