import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { API_SERVICE, IApiService, ILoanRequestService } from '@oper-client/shared/data-access';
import {
	BorrowerModes,
	LoanRequest,
	LoanRequestComment,
	LoanRequestCommunicationHistory,
	LoanRequestDecision,
	LoanRequestDecisionStatistics,
	LoanRequestHistory,
	LoanRequestStatusHistory,
	PreapprovalResponse,
	Resource,
} from '@oper-client/shared/data-model';

@Injectable()
export class LoanRequestService implements ILoanRequestService {
	private static mapLoanRequestToDto(loanRequest: Partial<LoanRequest>): Partial<LoanRequest> {
		const {
			id,
			status,
			borrowerReference,
			possibleStatuses,
			lastModified,
			created,
			prospectus,
			needsSeniorDecision,
			needsSeniorDecisionOverwrite,
			actionRequired,
			filedOtherProvider,
			internalReference,
			liquidities,
			ownFunds,
			businessCapital,
			purposes,

			costPurchase,
			costRenovation,
			costBuilding,
			costBuyout,
			costCreditTakeover,
			costEstimation,
			costFile,
			costHandling,
			costInsurance,
			costAdditionalFinancingNeed,
			loanAmount,
			costDistributionNotary,
			costDistributionRegistration,
			costCreditNotary,
			costCreditRegistration,
			costPurchaseNotary,
			costPurchaseRegistration,
			costAdministration,
			costRealtor,
			costLandRegistry,

			deedDate,
			dateFundsRequested,
			dateFundsProvisioned,
			dateOfferMade,
			dateOfferAccepted,
			dateInitialAgreement,
			dateSubmission,
			dateContractAllowed,
			dateCompletion,
			dateDecision,
			dateDipAccepted,
			dateDipRejected,
			dateCreditClosed,
			dateEsisAccepted,
			dateContractRequested,
			dateContractSent,
			dateAnalysisStarted,

			broker,
			analyst,
			owner,
			coBroker,
			notary,
			otherCreditProviderRequests,
			acquisitionSource,
			source,
			externalReference,
			financialPlanLoanAmountItems,
			financialPlanOwnFundItems,
		} = loanRequest;
		return {
			id,
			status,
			borrowerReference,
			possibleStatuses,
			lastModified,
			created,
			prospectus,
			needsSeniorDecision,
			needsSeniorDecisionOverwrite,
			actionRequired,
			filedOtherProvider,
			internalReference,
			liquidities,
			ownFunds,
			businessCapital,
			purposes,
			externalReference,

			costPurchase,
			costRenovation,
			costBuilding,
			costBuyout,
			costCreditTakeover,
			costEstimation,
			costFile,
			costHandling,
			costInsurance,
			costAdditionalFinancingNeed,
			loanAmount,
			costDistributionNotary,
			costDistributionRegistration,
			costCreditNotary,
			costCreditRegistration,
			costPurchaseNotary,
			costPurchaseRegistration,
			costAdministration,
			costRealtor,
			costLandRegistry,

			deedDate,
			dateFundsRequested,
			dateFundsProvisioned,
			dateOfferMade,
			dateOfferAccepted,
			dateInitialAgreement,
			dateSubmission,
			dateContractAllowed,
			dateCompletion,
			dateDecision,
			dateDipAccepted,
			dateDipRejected,
			dateCreditClosed,
			dateEsisAccepted,
			dateContractRequested,
			dateContractSent,
			dateAnalysisStarted,

			broker,
			analyst,
			owner,
			coBroker,
			notary,
			otherCreditProviderRequests,
			acquisitionSource,
			source,
			financialPlanLoanAmountItems,
			financialPlanOwnFundItems,
		};
	}

	constructor(@Inject(API_SERVICE) private apiService: IApiService) {}

	// Partial as Overview serializer provides an incomplete return
	// FIXME Fix type
	public getLoanRequests(params: HttpParams = new HttpParams()): Observable<any> {
		return this.apiService.get(`/api/loan-requests/`, params);
	}

	public getLoanRequestsTotalAmount(params: HttpParams = new HttpParams()): Observable<number> {
		return this.apiService.get(`/api/loan-requests/total-loan-amount/`, params);
	}

	public getLoanRequestStatuses(loanRequestId: number, params: HttpParams = new HttpParams()): Observable<Resource[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/status-changes/`, params);
	}

	public resetLoanRequestStatus(loanRequestId: number, body: Partial<LoanRequest>): Observable<LoanRequest> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/reset-status/`, LoanRequestService.mapLoanRequestToDto(body));
	}

	updateLoanRequestStatus(loanRequestId: number, body: Partial<LoanRequest>): Observable<LoanRequest> {
		return this.apiService.patch(
			`/api/loan-requests/${loanRequestId}/force-update-status/`,
			LoanRequestService.mapLoanRequestToDto(body)
		);
	}

	public createLoanRequest(data: Partial<LoanRequest> = {}): Observable<LoanRequest> {
		return this.apiService.post(`/api/loan-requests/`, LoanRequestService.mapLoanRequestToDto(data));
	}

	// Partial as Overview serializer provides an incomplete return
	public getLoanRequest(loanRequestId: number, queryParams: { [key: string]: string } = {}): Observable<Partial<LoanRequest>> {
		//TODO Extract params conversion from LoanRequestService::getLoanRequest, LoanRequestService::getHistories
		//     and DocumentService::setHttpParams to a static function in ApiService.
		let params = new HttpParams();
		for (const key of Object.keys(queryParams)) {
			params = params.set(key, queryParams[key]);
		}
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/`, params);
	}

	public updateLoanRequest(loanRequestId: number, body: Partial<LoanRequest>): Observable<LoanRequest> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/`, body);
	}

	public assignAnalystToLoanRequest(loanRequestId: number, analystId: number | null): Observable<LoanRequest> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/`, { analyst: analystId !== null ? { id: analystId } : null });
	}

	public assignBrokerToLoanRequest(loanRequestId: number, brokerId: number | null): Observable<LoanRequest> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/`, { broker: brokerId !== null ? { id: brokerId } : null });
	}

	public updateAcquisitionSource(loanRequestId: number, acquisitionSourceId: number | null): Observable<LoanRequest> {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/`, {
			acquisitionSource: acquisitionSourceId !== null ? { id: acquisitionSourceId } : null,
		});
	}

	public createDecision(loanRequestId: number, body: LoanRequestDecision): Observable<LoanRequestDecision> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/decisions/`, body);
	}

	public getDecisions(loanRequestId: number): Observable<LoanRequestDecision[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/decisions/`);
	}

	public getDecisionsStatistics(loanRequestId: number): Observable<LoanRequestDecisionStatistics> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/decisions/required`);
	}

	public createComment(loanRequestId: number, body: LoanRequestComment): Observable<LoanRequestComment> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/comments/`, body);
	}

	public getComments(loanRequestId: number): Observable<LoanRequestComment[]> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/comments/`);
	}

	public getHistories(
		loanRequestId: number,
		queryParams = {}
	): Observable<(LoanRequestHistory | LoanRequestStatusHistory | LoanRequestCommunicationHistory)[]> {
		//TODO Extract params conversion from LoanRequestService::getLoanRequest, LoanRequestService::getHistories
		//     and DocumentService::setHttpParams to a static function in ApiService.
		let params = new HttpParams();
		for (const key of Object.keys(queryParams)) {
			params = params.set(key, queryParams[key]);
		}
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/history/`, params);
	}

	public getPreapproval(loanRequestId: number, borrowerMode: BorrowerModes): Observable<PreapprovalResponse> {
		return this.apiService.post(`/api/loan-requests/${loanRequestId}/pre-approval/`, { borrowerMode });
	}

	public loadPreapproval(loanRequestId: number): Observable<PreapprovalResponse> {
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/pre-approval/`);
	}

	public toggleLoanRequestCancellation(loanRequestId, body = {}) {
		return this.apiService.patch(`/api/loan-requests/${loanRequestId}/toggle-cancel/`, body);
	}
}
