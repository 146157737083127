import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	AllowedProductVariability,
	BaseRate,
	CreateProductPricingSheetRequest,
	parseActionState,
	ParsedActionState,
	ProductPricingDetails,
	ProductPricingSheet,
	ProductPricingSheetDiscounts,
} from '@oper-client/shared/data-model';
import * as ProductPricingSelectors from './product-pricing.selectors';
import * as ProductPricingActions from './product-pricing.actions';
import { ProductPricingFeatueActionTypes, ProductPricingState } from './product-pricing.reducer';

@Injectable()
export class ProductPricingFacade {
	products$ = this.store.pipe(select(ProductPricingSelectors.getActiveProducts));
	allPricingSheetsVersions$ = this.store.pipe(select(ProductPricingSelectors.getAllProductPricingSheets));
	shownPricingSheetVersions$ = this.store.pipe(select(ProductPricingSelectors.getProductPricingSheets));
	baseRates$ = this.store.pipe(select(ProductPricingSelectors.getProductPricingSheetBaseRates));
	discounts$ = this.store.pipe(select(ProductPricingSelectors.getProductPricingSheetDiscounts));
	shownProductDetails$ = this.store.pipe(select(ProductPricingSelectors.getCurrentPricingDetails));
	shownProductDetailsId$ = this.store.pipe(select(ProductPricingSelectors.getCurrentPricingDetailsId));
	activePricingSheetVersion$ = this.store.pipe(select(ProductPricingSelectors.getCurrentActivePricingSheet));
	shownPricingSheetVersion$ = this.store.pipe(select(ProductPricingSelectors.getCurrentPricingSheet));
	shownPricingSheetVersionId$ = this.store.pipe(select(ProductPricingSelectors.getCurrentPricingSheetId));
	allowedVariabilities$ = this.store.pipe(select(ProductPricingSelectors.getAllowedProductVariabilities));

	loadProductsActionState$ = this.selectActionState('loadProducts', ProductPricingSelectors.getProductPricingDetailsActionStates);
	loadProductPricingDetailsActionState$ = this.selectActionState(
		'loadProductPricingDetails',
		ProductPricingSelectors.getProductPricingDetailsActionStates
	);
	updateProductPricingDetailsActionState$ = this.selectActionState(
		'updateProductPricingDetails',
		ProductPricingSelectors.getProductPricingDetailsActionStates
	);
	loadProductPricingSheetsActionState$ = this.selectActionState(
		'loadProductPricingSheets',
		ProductPricingSelectors.getProductPricingSheetsActionStates
	);
	loadProductPricingSheetActionState$ = this.selectActionState(
		'loadProductPricingSheet',
		ProductPricingSelectors.getProductPricingSheetsActionStates
	);
	createProductPricingSheetActionState$ = this.selectActionState(
		'createProductPricingSheet',
		ProductPricingSelectors.getProductPricingSheetsActionStates
	);
	updateProductPricingSheetActionState$ = this.selectActionState(
		'updateProductPricingSheet',
		ProductPricingSelectors.getProductPricingSheetsActionStates
	);
	loadProductPricingSheetBaseRatesActionState$ = this.selectActionState(
		'loadBaseRates',
		ProductPricingSelectors.getProductPricingSheetBaseRatesActionStates
	);
	updateProductPricingSheetBaseRateActionState$ = this.selectActionState(
		'updateBaseRate',
		ProductPricingSelectors.getProductPricingSheetBaseRatesActionStates
	);
	updateProductPricingSheetBaseRatesActionState$ = this.selectActionState(
		'updateBaseRates',
		ProductPricingSelectors.getProductPricingSheetBaseRatesActionStates
	);
	loadProductPricingSheetDiscountsActionState$ = this.selectActionState(
		'loadProductPricingSheetDiscounts',
		ProductPricingSelectors.getProductPricingSheetDiscountsActionStates
	);
	updateProductPricingSheetDiscountsActionState$ = this.selectActionState(
		'updateProductPricingSheetDiscounts',
		ProductPricingSelectors.getProductPricingSheetDiscountsActionStates
	);
	loadAllowedProductVariabilitiesActionState$ = this.selectActionState(
		'loadAllowedProductVariabilities',
		ProductPricingSelectors.getAllowedProductVariabilitiesActionStates
	);
	loadAllowedProductVariabilityActionState$ = this.selectActionState(
		'loadAllowedProductVariability',
		ProductPricingSelectors.getAllowedProductVariabilitiesActionStates
	);
	updateAllowedProductVariabilityActionState$ = this.selectActionState(
		'updateAllowedProductVariability',
		ProductPricingSelectors.getAllowedProductVariabilitiesActionStates
	);

	removeProductPricingSheetActionState$ = this.selectActionState(
		'removeProductPricingSheet',
		ProductPricingSelectors.getProductPricingSheetsActionStates
	);

	constructor(private store: Store<ProductPricingState>) {}

	loadProducts(searchTerm?: string): void {
		this.store.dispatch(ProductPricingActions.loadProducts({ searchTerm }));
	}

	loadProductPricingDetails(productId: number): void {
		this.store.dispatch(ProductPricingActions.loadProductPricingDetails({ productId }));
	}

	updateProductPricingDetails(productId: number, pricingDetails: Partial<ProductPricingDetails>): void {
		this.store.dispatch(
			ProductPricingActions.updateProductPricingDetails({ productId, pricingDetails: { id: productId, changes: pricingDetails } })
		);
	}

	setCurrentProductPricingDetails(pricingDetails: ProductPricingDetails): void {
		this.store.dispatch(ProductPricingActions.resetProductPricingSheetBaseRatesState());
		this.store.dispatch(ProductPricingActions.resetAllowedProductVariabilityState());
		this.store.dispatch(ProductPricingActions.setCurrentProductPricingDetails({ pricingDetails }));
	}

	loadProductPricingSheets(productId: number): void {
		this.store.dispatch(ProductPricingActions.loadProductPricingSheets({ productId }));
	}

	loadAllProductPricingSheets(productId: number): void {
		this.store.dispatch(ProductPricingActions.loadProductPricingSheets({ productId }));
	}

	setCurrentProductPricingSheet(pricingSheet: ProductPricingSheet): void {
		this.store.dispatch(ProductPricingActions.setCurrentProductPricingSheet({ pricingSheet }));
	}

	createProductPricingSheet(productId: number, request: CreateProductPricingSheetRequest): void {
		this.store.dispatch(ProductPricingActions.createProductPricingSheet({ productId, request }));
	}

	updateProductPricingSheet(productId: number, pricingSheetId: number, pricingSheet: Partial<ProductPricingSheet>): void {
		this.store.dispatch(
			ProductPricingActions.updateProductPricingSheet({ productId, pricingSheet: { id: pricingSheetId, changes: pricingSheet } })
		);
	}

	loadBaseRates(productId: number, pricingSheetId: number): void {
		this.store.dispatch(ProductPricingActions.loadBaseRates({ productId, pricingSheetId }));
	}

	loadProductPricingSheetDiscounts(productId: number, pricingSheetId: number): void {
		this.store.dispatch(ProductPricingActions.loadProductPricingSheetDiscounts({ productId, pricingSheetId }));
	}

	updateProductPricingSheetBaseRate(productId: number, pricingSheetId: number, baseRateId: number, baseRate: Partial<BaseRate>) {
		this.store.dispatch(
			ProductPricingActions.updateProductPricingSheetBaseRate({
				productId,
				pricingSheetId,
				baseRateId,
				baseRate: { id: baseRateId, changes: baseRate },
			})
		);
	}

	updateProducPricingSheetBaseRates(productId: number, pricingSheetId: number, baseRates: Partial<BaseRate>[]): void {
		this.store.dispatch(
			ProductPricingActions.updateProductPricingSheetBaseRates({
				productId,
				pricingSheetId,
				baseRates: baseRates.map((rate) => {
					return { id: rate.id, changes: rate };
				}),
			})
		);
	}

	updateProductPricingSheetDiscounts(
		productId: number,
		pricingSheetId: number,
		discounts: Partial<ProductPricingSheetDiscounts>[]
	): void {
		this.store.dispatch(
			ProductPricingActions.updateProductPricingSheetDiscounts({
				productId,
				pricingSheetId,
				discounts: discounts.map((discount) => {
					return { id: discount.name, changes: discount };
				}),
			})
		);
	}

	loadAllowedVariabilities(productId: number): void {
		this.store.dispatch(ProductPricingActions.loadAllowedVariabilities({ productId }));
	}

	loadAllowedProductVariability(productId: number, variabilityId: number): void {
		this.store.dispatch(ProductPricingActions.loadAllowedProductVariability({ productId, variabilityId }));
	}

	updateAllowedProductVariability(productId: number, variabilityId: number, variability: Partial<AllowedProductVariability>) {
		this.store.dispatch(
			ProductPricingActions.updateAllowedProductVariability({
				productId,
				variability: { id: variabilityId, changes: variability },
			})
		);
	}

	resetState(): void {
		this.store.dispatch(ProductPricingActions.resetProductPricingDetailsState());
		this.store.dispatch(ProductPricingActions.resetProductPricingSheetsState());
		this.store.dispatch(ProductPricingActions.resetProductPricingSheetBaseRatesState());
		this.store.dispatch(ProductPricingActions.resetAllowedProductVariabilityState());
		this.store.dispatch(ProductPricingActions.resetProductPricingSheetDiscountsActionState());
	}

	removePricingSheet(productId: number, pricingSheetId: number) {
		this.store.dispatch(ProductPricingActions.removeProductPricingSheet({ productId, pricingSheetId }));
	}

	private selectActionState(actionType: ProductPricingFeatueActionTypes, actionState): Observable<ParsedActionState> {
		return this.store.pipe(select(ProductPricingSelectors.getActionState(actionType, actionState)), map(parseActionState));
	}
}
