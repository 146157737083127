import { Action, select, Store } from '@ngrx/store';
import * as InsuranceProviderSelectors from './insurance-provider.selectors';
import { ThirdPartyPartialState } from '../third-party.reducer';
import { Injectable } from '@angular/core';

@Injectable()
export class InsuranceProviderFacade {
	insuranceProviders$ = this.store.pipe(select(InsuranceProviderSelectors.getInsuranceProviders));
	constructor(private store: Store<ThirdPartyPartialState>) {}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
