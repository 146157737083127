import { PROOF_ENTITY_KEY, ProofState } from './proof/proof.reducer';
import {
	COMMISSION_STATEMENT_DOCUMENT_ENTITY_KEY,
	CommissionStatementDocumentState,
} from './commission-statement/commission-statement.reducer';

export const DOCUMENT_FEATURE_KEY = 'document';

export interface DocumentEntityState {
	readonly [PROOF_ENTITY_KEY]: ProofState;
	readonly [COMMISSION_STATEMENT_DOCUMENT_ENTITY_KEY]: CommissionStatementDocumentState;
}

export interface DocumentPartialState {
	readonly [DOCUMENT_FEATURE_KEY]: DocumentEntityState;
}
