import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Client } from '@oper-client/shared/data-model';

export const loadClient = createAction('[Client/API] Load Client', props<{ loanRequestId: number; clientId: number }>());
export const loadClientSuccess = createAction('[Client/API] Load Client Success', props<{ client: Client }>());
export const loadClientFailure = createAction('[Client/API] Load Client Failure', props<{ error: HttpErrorResponse }>());

export const loadClients = createAction('[Client/API] Load Clients', props<{ loanRequestId: number }>());
export const loadClientsSuccess = createAction('[Client/API] Load Clients Success', props<{ clients: Client[] }>());
export const loadClientsFailure = createAction('[Client/API] Load Clients Failure', props<{ error: HttpErrorResponse }>());

export const addClient = createAction('[Client/API] Add Client', props<{ loanRequestId: number; client: Partial<Client> }>());
export const addClientSuccess = createAction('[Client/API] Add Client Success', props<{ client: Client }>());
export const addClientFailure = createAction('[Client/API] Add Client Failure', props<{ error: HttpErrorResponse }>());

export const updateClient = createAction('[Client/API] Update Client', props<{ loanRequestId: number; client: Update<Client> }>());
export const updateClientSuccess = createAction('[Client/API] Update Client Success', props<{ client: Update<Client> }>());
export const updateClientFailure = createAction('[Client/API] Update Client Failure', props<{ error: HttpErrorResponse }>());

export const inviteClient = createAction('[Client/API] Invite Client', props<{ loanRequestId: number; clientId: number }>());
export const inviteClientSuccess = createAction('[Client/API] Invite Client Success', props<{ client: Update<Client> }>());
export const inviteClientFailure = createAction('[Client/API] Invite Client Failure', props<{ error: HttpErrorResponse }>());

export const removeClient = createAction('[Client/API] Remove Client', props<{ loanRequestId: number; clientId: number }>());
export const removeClientSuccess = createAction('[Client/API] Remove Client Success', props<{ clientId: number }>());
export const removeClientFailure = createAction('[Client/API] Remove Client Failure', props<{ error: HttpErrorResponse }>());

export const clearClients = createAction('[Client] Clear Clients');
export const clearClientActionStates = createAction('[Client] Clear Client Action States');
export const clearCurrentClientId = createAction('[Client] Clear Current Client Id');
export const setCurrentClientId = createAction('[Client] Set current client Id', props<{ clientId: number }>());
export const resetClientState = createAction('[Client] Reset Client State');

export const showCreateNewClientModal = createAction('[Client] Show Create New Client Modal');
export const hideCreateNewClientModal = createAction('[Client] Hide Create New Client Modal');
