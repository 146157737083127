import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './+state/ai-validation.reducer';
import { AiValidationEffects } from './+state/ai-validation.effects';
import { AiValidationFacade } from './+state/ai-validation.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromStore.AI_VALIDATION_FEATURE_KEY, fromStore.reducer),
		EffectsModule.forFeature([AiValidationEffects]),
	],
	providers: [AiValidationFacade],
})
export class DataAccessAiValidationModule {}
