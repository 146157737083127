import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, first } from 'rxjs/operators';

@Pipe({
	name: 'translateParams',
	pure: false, // otherwise translation doesn't happen when switching language
})
export class TranslateParamsPipe implements PipeTransform {
	constructor(private readonly translateService: TranslateService) {}

	transform(params: any): any {
		if (!params) {
			return params;
		}

		const translatedParams: any = Object.assign({}, params);

		Object.keys(params)
			.filter(key => !!params[key].length)
			.forEach(key => {
				this.translateService
					.get(params[key])
					.pipe(
						first(),
						map(value => value)
					)
					.subscribe(value => {
						if (
							Object.keys(value).some(
								k => /* eslint-disable no-bitwise */ ~k.indexOf('ç.resource.') /* eslint-enable no-bitwise */
							)
						) {
							value = Object.values(value).join(', ');
						}
						return (translatedParams[key] = value);
					});
			});

		return translatedParams;
	}
}
