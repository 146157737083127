import { Inject, Pipe, PipeTransform } from '@angular/core';
import { CustomerInsights, CUSTOMER_INSIGHTS_CONFIG, ExistingRegions } from '@oper-client/shared/configuration';
import { Address } from '@oper-client/shared/data-model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'addressPreview',
})
export class AddressPreviewPipe implements PipeTransform {
	constructor(
		@Inject(CUSTOMER_INSIGHTS_CONFIG) public customerInsights: CustomerInsights,
        private readonly translate: TranslateService
	) {}

	public transform(address: Address, showCountry = false): string {
		if (!address) {
			return '';
		}

		switch (this.customerInsights?.region) {
			case ExistingRegions.HU:
				return `${address.zipCode ? address.zipCode : ''} ${address.city}, ${address.street} ${
					this.replaceDotAndTrim(address.houseNumber) + (address.floor ? '.' + this.replaceDotAndTrim(address.floor) : '')
				} ${address.box ?? ''} ${this.getCountry(showCountry, address.country?.definition)}`.trim();
			default:
				return `${address.street} ${address.houseNumber}${address.box ? '/' + address.box : ''}, ${address.zipCode ?? ''} ${
					address.city
				} ${this.getCountry(showCountry, address.country?.definition)}`.trim();
		}
	}

	private replaceDotAndTrim(text: string): string {
		return text?.replace('.', '')?.trim();
	}
	
	private getCountry(showCountry: boolean, countryDefinition: string): string {
		if (!showCountry || !countryDefinition) {
			return '';
		}
		return this.translate.instant('ç.resource.country.' + countryDefinition);
	}
}
