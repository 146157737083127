import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'absolute',
})
export class AbsolutePipe implements PipeTransform {
	transform(value: any): number {
		if (isNaN(+value)) {
			return value;
		}

		const number = +value;
		return number >= 0 ? number : number * -1;
	}
}
