import { CreditProviderBaseProductsGroup } from '../models/credit-provider-base-products-group';
import { BaseProductDiscounts } from '../models/base-product-discounts.model';
import { DiscountConfigurationSummary } from '../models/discount-configuration-summary.model';
import { DiscountConfiguration } from '../models/discount-configuration.model';
import { ProductItem, SelectedBaseProductDiscounts } from '../models/offer.model';

export function updateSelectedDiscounts(
	selectedDiscounts: SelectedBaseProductDiscounts[],
	updatedDiscounts: BaseProductDiscounts[]
): SelectedBaseProductDiscounts[] {
	const discountsMap = new Map<number, DiscountConfiguration[]>(
		selectedDiscounts.map(({ baseProductId, discounts }) => [baseProductId, discounts])
	);

	updatedDiscounts.forEach(({ discounts, id }) => {
		const selected = filterSelectedDiscounts(discounts);
		const baseProductId = id as number;
		if (selected.length > 0) {
			discountsMap.set(baseProductId, selected);
		} else if (discountsMap.has(baseProductId)) {
			discountsMap.delete(baseProductId);
		}
	});

	return Array.from(discountsMap.entries(), ([baseProductId, discounts]) => ({
		baseProductId,
		discounts,
	}));
}

export function getSelectedBaseProductDiscounts(baseProducts: BaseProductDiscounts[]): SelectedBaseProductDiscounts[] {
	return baseProducts
		?.map(
			(baseProduct) =>
				({
					baseProductId: baseProduct.id,
					discounts: filterSelectedDiscounts(baseProduct.discounts),
				}) as SelectedBaseProductDiscounts
		)
		.filter((selected) => selected.discounts.length > 0);
}

export function getSelectedDiscountsFromProductItems(items: ProductItem[]): SelectedBaseProductDiscounts[] {
	const discounts = new Map<number, DiscountConfiguration[]>();

	items.forEach((item: ProductItem) => {
		discounts.set(item.product.baseProductId, item.discounts);
	});

	return Array.from(discounts)
		.map(([baseProductId, discounts]) => ({
			baseProductId,
			discounts: filterSelectedDiscounts(discounts),
		}))
		.filter((selected) => selected.discounts.length > 0);
}

export function isValidDiscount(discount: DiscountConfiguration): boolean {
	return discount.isValid && !discount.isOutdated;
}

export function calculateTotalInterestRate(discountsConfiguration: DiscountConfiguration[]): number {
	let result = 0;
	for (const configuration of discountsConfiguration) {
		if (configuration.discounts) {
			result = result + calculateTotalInterestRate(configuration.discounts);
		} else if (isValidDiscount(configuration) && configuration.isSelected) {
			result += configuration.interestRate;
		}
	}

	return result;
}

export function getNumberOfSelectedDiscountCategories(baseProducts: BaseProductDiscounts[]): number {
	const discountCategories = new Set<string>();
	baseProducts.forEach((product) => {
		product.discounts.forEach((discountCategory) => {
			if (discountCategory.isSelected && discountCategory.discounts?.length > 0) {
				discountCategories.add(discountCategory.name);
			}
		});
	});
	return discountCategories.size;
}

export function getApplicableDiscounts(discountsConfiguration: DiscountConfiguration[]): DiscountConfiguration[] {
	const result: DiscountConfiguration[] = [];
	for (const configuration of discountsConfiguration) {
		if (configuration.discounts) {
			const applicableDiscounts = getApplicableDiscounts(configuration.discounts).slice(0, configuration.maxDiscountsAllowed);
			result.push(...applicableDiscounts);
		} else if (isValidDiscount(configuration)) {
			result.push(configuration);
		}
	}

	return result;
}

export function getSelectedDiscounts(discountsConfiguration: DiscountConfiguration[]): DiscountConfiguration[] {
	const result: DiscountConfiguration[] = [];
	for (const configuration of discountsConfiguration) {
		if (configuration.discounts) {
			const applicableDiscounts = getSelectedDiscounts(configuration.discounts);
			result.push(...applicableDiscounts);
		} else if (isValidDiscount(configuration) && configuration.isSelected) {
			result.push(configuration);
		}
	}

	return result;
}

export function unselectAll(configuration: DiscountConfiguration): DiscountConfiguration {
	if (configuration.isSelected) {
		const result: DiscountConfiguration = { ...configuration, isSelected: false };
		if (configuration.discounts) {
			const unselected: DiscountConfiguration[] = [];
			result.discounts.forEach((d) => {
				unselected.push(unselectAll(d));
			});
			result.discounts = unselected;
		}
		return result;
	}

	return configuration;
}

export function generateDiscountConfigurationSummary(baseProduct: BaseProductDiscounts): DiscountConfigurationSummary {
	const totalNumberOfAllowedDiscounts = getApplicableDiscounts(baseProduct.discounts).length;
	const selectedDiscounts = getSelectedDiscounts(baseProduct.discounts);
	const totalNumberOfSelectedDiscounts = selectedDiscounts.length;
	const totalAppliedInterestRate = selectedDiscounts.map((d) => d.interestRate).reduce((partialSum, a) => partialSum + a, 0);
	return {
		baseProduct,
		totalAppliedInterestRate,
		totalNumberOfAllowedDiscounts,
		totalNumberOfSelectedDiscounts,
	};
}

export function filterSelectedDiscounts(configurations: DiscountConfiguration[]): DiscountConfiguration[] {
	const selected = configurations.filter((d) => d.isSelected);
	const result: DiscountConfiguration[] = [];

	for (const configuration of selected) {
		const { name, interestRate, discounts, isSelected } = configuration;
		if (discounts) {
			const selectedDiscounts = filterSelectedDiscounts(discounts);
			result.push({
				name,
				discounts: selectedDiscounts,
			});
		} else if (isValidDiscount(configuration) && isSelected) {
			result.push({
				name,
				interestRate,
			});
		}
	}
	return result;
}

export function filterDefaultDiscounts(configurations: DiscountConfiguration[]): DiscountConfiguration[] {
	const result: DiscountConfiguration[] = [];

	for (const configuration of configurations) {
		const { name, interestRate, discounts, isDefault } = configuration;
		if (discounts) {
			const defaultDiscounts = filterDefaultDiscounts(discounts);
			if (defaultDiscounts.length > 0) {
				result.push({
					name,
					discounts: defaultDiscounts,
				});
			}
		} else if (isValidDiscount(configuration) && isDefault) {
			result.push({
				name,
				interestRate,
			});
		}
	}
	return result;
}

export function selectDefaultDiscountConfiguration(configurations: DiscountConfiguration[]): DiscountConfiguration[] {
	const result: DiscountConfiguration[] = [];
	for (const configuration of configurations) {
		if (configuration.discounts) {
			const discounts = selectDefaultDiscountConfiguration(configuration.discounts);
			result.push({
				...configuration,
				discounts,
				isSelected: discounts.some((d) => d.isSelected),
			});
		} else {
			result.push({ ...configuration, isSelected: !!configuration.isDefault });
		}
	}

	return result;
}

export function unselectDiscountConfiguration(configurations: DiscountConfiguration[]): DiscountConfiguration[] {
	const result: DiscountConfiguration[] = [];
	for (const configuration of configurations) {
		if (configuration.discounts) {
			const discounts = unselectDiscountConfiguration(configuration.discounts);
			result.push({
				...configuration,
				discounts,
				isSelected: false,
			});
		} else {
			result.push({
				...configuration,
				isSelected: false,
			});
		}
	}

	return result;
}

export function getCreditProviderDiscountSummaryGroups(list: BaseProductDiscounts[]): CreditProviderBaseProductsGroup[] {
	const groups: CreditProviderBaseProductsGroup[] = [];
	list.forEach((item) => {
		const foundIndex = groups.findIndex((g) => g.creditProviderId === item.creditProvider.id);
		if (foundIndex > -1) {
			groups[foundIndex].baseProducts.push(item);
			groups[foundIndex].baseProducts.sort((previous, next) => next.discounts.length - previous.discounts.length);
		} else {
			groups.push({
				creditProviderId: item.creditProvider.id,
				creditProviderName: item.funder?.name ?? item.creditProvider.name,
				logoUrl: item.logoUrl,
				baseProducts: [item],
			});
		}
	});
	return groups;
}
